import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBBtn } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUploadPhotoModalStates,
  uploadPhoto,
} from "../../Store/Slices/Sidebar/Donors/DonorsCrudSlice";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const UpdatePhotoPopup = ({
  token,
  specificDonorData,
  setUpdatePhotoPopup,
  photoURL,
  photoFile,
}) => {
  // redux state
  const { uploadPhotoLoading, uploadPhotoData } = useSelector(
    (state) => state.donors
  );

  // close modal
  const handleCloseModal = () => {
    if (!uploadPhotoLoading) {
      setUpdatePhotoPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // upload photo function
  const handlePhotoUpload = () => {
    dispatch(
      uploadPhoto({
        token,
        tagNumber: specificDonorData.tagNumber,
        profileImage: photoFile,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setUpdatePhotoPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        dispatch(clearUploadPhotoModalStates());
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  // isLoaded
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Update Profile Image</div>
            <div
              className="update-widgets-cancel-icon"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="modal-body">
            <div className="dp-container">
              <img
                src={photoURL}
                alt="dp"
                onLoad={() => {
                  setTimeout(() => {
                    setIsLoaded(true);
                  }, 1000);
                }}
                style={{ display: isLoaded ? "block" : "none" }}
              />
            </div>

            {uploadPhotoData &&
              uploadPhotoData.tagNumber === specificDonorData.tagNumber &&
              (uploadPhotoData.apiError || uploadPhotoData.axiosError) && (
                <div className="error-msg">
                  {uploadPhotoData.apiError || uploadPhotoData.axiosError}
                </div>
              )}

            <MDBBtn
              type="button"
              className="save-btn"
              onClick={handlePhotoUpload}
              disabled={!isLoaded || uploadPhotoLoading}
            >
              {uploadPhotoLoading ? "Saving..." : "Save"}
            </MDBBtn>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default UpdatePhotoPopup;
