import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getCampaignByDay = createAsyncThunk(
  "campaignByDay/getCampaignByDay",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/campaignByDay?groupData=false&year=${singleObj.year}&month=${singleObj.month}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredCampaignByDay = createAsyncThunk(
  "filteredCampaignByDay/getFilteredCampaignByDay",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/campaignByDay?groupData=false&year=${singleObj.year}&month=${singleObj.month}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const campaignByDaySlice = createSlice({
  name: "campaignByDay",
  initialState: {
    campaignByDayLoading: false,
    campaignByDayData: null,
    campaignByDayError: null,
    filteredCampaignByDayLoading: false,
  },
  extraReducers: (builder) => {
    builder
      // first render
      .addCase(getCampaignByDay.pending, (state) => {
        state.campaignByDayLoading = true;
        state.campaignByDayData = null;
        state.campaignByDayError = null;
      })
      .addCase(getCampaignByDay.fulfilled, (state, action) => {
        state.campaignByDayLoading = false;
        state.campaignByDayData = action.payload;
        state.campaignByDayError = null;
      })
      .addCase(getCampaignByDay.rejected, (state, action) => {
        state.campaignByDayLoading = false;
        state.campaignByDayData = null;
        state.campaignByDayError = action.error.message;
      })
      // filter render
      .addCase(getFilteredCampaignByDay.pending, (state) => {
        state.filteredCampaignByDayLoading = true;
        state.campaignByDayData = null;
        state.campaignByDayError = null;
      })
      .addCase(getFilteredCampaignByDay.fulfilled, (state, action) => {
        state.filteredCampaignByDayLoading = false;
        state.campaignByDayData = action.payload;
        state.campaignByDayError = null;
      })
      .addCase(getFilteredCampaignByDay.rejected, (state, action) => {
        state.filteredCampaignByDayLoading = false;
        state.campaignByDayData = null;
        state.campaignByDayError = action.error.message;
      });
  },
});

export default campaignByDaySlice.reducer;
