/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// get user from LS
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

const QuickRedirect = () => {
  // state
  const [user] = useState(getUser());

  // navigate
  const navigate = useNavigate();

  // if user navigate dashboard else login
  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, []);

  return null;
};

export default QuickRedirect;
