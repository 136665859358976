import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// Create an async thunk get all campaign
export const getCampaign = createAsyncThunk(
  "CAMPAIGN/GET",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/campaign?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// Create an async thunk get all campaign
export const filterCampaign = createAsyncThunk(
  "CAMPAIGN/FILTER",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "name",
      "description",
      "start_date",
      "end_date",
      "status",
      "issue_tax_receipt",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const request = await axios.get(
      `${hostName}api/admin/v1/campaign?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// Create an async thunk delete campaign
export const deleteCampaign = createAsyncThunk(
  "CAMPAIGN/DELETE",
  async ({ token, id }) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/campaign/${id}`,
        axiosConfig
      );
      const response = await request.data;
      return { response, id };
    } catch (err) {
      throw err.response.data.messageDetails[0];
    }
  }
);

// Create an async thunk update campaign
export const updateCampaign = createAsyncThunk(
  "CAMPAIGN/UPDATE",
  async ({ id, token, obj }) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/campaign/${id}`,
        obj,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        campaignTag: id,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        const { messageDetails } = error.response.data;

        // Check if messageDetails is an array with a single string
        if (Array.isArray(messageDetails) && messageDetails.length > 0) {
          apiError = messageDetails[0]; // Take the first string in the array
        } else {
          apiError = messageDetails;
        }
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
        campaignTag: id,
      };
    }
  }
);

// create an async thunk get list of value by filter
export const listOfValues = createAsyncThunk(
  "CAMPAIGN/LISTOFVALUES",
  async ({ token, listName }) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/lov?listName=${listName}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// Create an async thunk add campaign
export const addCampaign = createAsyncThunk(
  "CAMPAIGN/CREATE",
  async ({ token, obj }) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/campaign`,
        obj,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        const { messageDetails } = error.response.data;

        // Check if messageDetails is an array with a single string
        if (Array.isArray(messageDetails) && messageDetails.length > 0) {
          apiError = messageDetails[0]; // Take the first string in the array
        } else {
          apiError = messageDetails;
        }
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
      };
    }
  }
);

// upload banner
export const uploadBanner = createAsyncThunk(
  "campaigns/uploadBanner",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
          "Content-Type": "multipart/form-data", // Set the Content-Type header to 'multipart/form-data'
        },
      };

      const formData = new FormData();
      formData.append("banner", singleObj.bannerImage);
      formData.append("campaignTag", singleObj.tagNumber);

      const request = await axios.post(
        `${hostName}api/admin/v1/campaignBanner?_method=PUT`,
        formData,
        axiosConfig
      );

      const response = await request.data;

      return {
        data: response.data, // will be the bannerImage URL
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// delete banner
export const deleteBanner = createAsyncThunk(
  "campaigns/deleteBanner",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/campaignBanner`,
        {
          ...axiosConfig,
          data: { campaignTag: singleObj.campaignTag },
        }
      );
      const response = await request.data;
      return {
        data: response.data, // response.data will be null
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        campaignTag: singleObj.campaignTag,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        campaignTag: singleObj.campaignTag,
      };
    }
  }
);

export const CampaignSlice = createSlice({
  name: "CAMPAIGN",
  initialState: {
    // get data
    loader: false,
    campaignData: null,
    error: null,

    // delete data
    deleteLoader: false,
    deleteSuccess: null,
    deleteError: null,

    // update data
    updateLoader: false,
    updateData: null,

    // listOfValues
    listOfValuesLoader: false,
    listOfValuesData: [],
    listOfValuesErr: null,

    // create data
    createLoader: false,
    createData: null,

    // upload banner
    uploadBannerLoading: false,
    uploadBannerData: null,

    // delete banner
    deleteBannerLoading: false,
    deleteBannerData: null,
  },
  reducers: {
    deleteModalMount: (state) => {
      state.deleteLoader = false;
      state.deleteSuccess = null;
      state.deleteError = null;
    },
    updateModalMount: (state) => {
      state.updateLoader = false;
      state.updateData = null;
    },
    createModalMount: (state) => {
      state.createLoader = false;
      state.createData = null;
    },
    clearUploadBannerModalStates: (state) => {
      state.uploadBannerData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get
      .addCase(getCampaign.pending, (state) => {
        state.loader = true;
        state.campaignData = null;
        state.error = null;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.loader = false;
        state.campaignData = action.payload;
        state.error = null;
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.loader = false;
        state.campaignData = null;
        state.error = action.error.message;
      })

      // filter
      .addCase(filterCampaign.pending, (state) => {
        state.loader = true;
        state.campaignData = null;
        state.error = null;
      })
      .addCase(filterCampaign.fulfilled, (state, action) => {
        state.loader = false;
        state.campaignData = action.payload;
        state.error = null;
      })
      .addCase(filterCampaign.rejected, (state, action) => {
        state.loader = false;
        state.campaignData = null;
        state.error = action.error.message;
      })

      // delete
      .addCase(deleteCampaign.pending, (state) => {
        state.deleteLoader = true;
        state.deleteSuccess = null;
        state.deleteError = null;
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        state.deleteLoader = false;
        state.deleteSuccess = action.payload.response.messageDetails[0];
        if (action.payload.response.result) {
          state.campaignData.data = state.campaignData.data.filter(
            (filtered) => filtered.tagNumber !== action.payload.id
          );
        }
        state.deleteError = null;
      })
      .addCase(deleteCampaign.rejected, (state, action) => {
        state.deleteLoader = false;
        state.deleteSuccess = null;
        state.deleteError = action.error.message;
      })

      // update
      .addCase(updateCampaign.pending, (state) => {
        state.updateLoader = true;
        state.updateData = null;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.updateLoader = false;
        state.updateData = action.payload;
        const { data, campaignTag } = action.payload;
        if (data) {
          const updIndex = state.campaignData.data.findIndex(
            (item) => item.tagNumber === campaignTag
          );
          if (updIndex !== -1) {
            state.campaignData.data[updIndex] = data;
          }
        }
      })

      // listOfValues
      .addCase(listOfValues.pending, (state) => {
        state.listOfValuesLoader = true;
        state.listOfValuesData = [];
        state.listOfValuesErr = null;
      })
      .addCase(listOfValues.fulfilled, (state, action) => {
        state.listOfValuesLoader = false;
        state.listOfValuesData = action.payload;
        state.listOfValuesErr = null;
      })
      .addCase(listOfValues.rejected, (state, action) => {
        state.listOfValuesLoader = false;
        state.listOfValuesData = [];
        state.listOfValuesErr = action.error.message;
      })

      // create
      .addCase(addCampaign.pending, (state) => {
        state.createLoader = true;
        state.createData = null;
      })
      .addCase(addCampaign.fulfilled, (state, action) => {
        state.createLoader = false;
        state.createData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.campaignData.data.unshift(data);
        }
      })
      // upload banner
      .addCase(uploadBanner.pending, (state) => {
        state.uploadBannerLoading = true;
        state.uploadBannerData = null;
      })
      .addCase(uploadBanner.fulfilled, (state, action) => {
        state.uploadBannerLoading = false;
        state.uploadBannerData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const campaignIndex = state.campaignData.data.findIndex(
            (campaign) => campaign.tagNumber === tagNumber
          );
          if (campaignIndex !== -1) {
            state.campaignData.data[campaignIndex].banner = data;
          }
        }
      })
      // delete banner
      .addCase(deleteBanner.pending, (state) => {
        state.deleteBannerLoading = true;
        state.deleteBannerData = null;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.deleteBannerLoading = false;
        state.deleteBannerData = action.payload;
        const { data, campaignTag, axiosError } = action.payload;
        if (!axiosError) {
          const campaignIndex = state.campaignData.data.findIndex(
            (campaign) => campaign.tagNumber === campaignTag
          );
          if (campaignIndex !== -1) {
            state.campaignData.data[campaignIndex].banner = data;
          }
        }
      });
  },
});
export const {
  deleteModalMount,
  createModalMount,
  updateModalMount,
  clearUploadBannerModalStates,
} = CampaignSlice.actions;
export default CampaignSlice.reducer;
