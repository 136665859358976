/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../CSS/UI/Header.css";
import { Icon } from "react-icons-kit";
import { menu } from "react-icons-kit/feather/menu";
import { arrows_squares } from "react-icons-kit/linea/arrows_squares";
import { droplet } from "react-icons-kit/feather/droplet";
import { dollarSign } from "react-icons-kit/feather/dollarSign";
import { users } from "react-icons-kit/feather/users";
import { basic_server_cloud } from "react-icons-kit/linea/basic_server_cloud";
import { arrows_question } from "react-icons-kit/linea/arrows_question";
import { ecommerce_megaphone } from "react-icons-kit/linea/ecommerce_megaphone";
import Tippy from "@tippyjs/react";
import axios from "axios";
import hostName from "../../config";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../Images/header-logo.png";
import packageFile from "../../../package.json";
import SearchableDropdown from "./SearchableDropdown";
import NewsPopup from "../News/NewsPopup";

// get user
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

// get organization
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const Header = ({
  sidebarToggled,
  setSidebarToggled,
  quickLinksDropdown,
  setQuickLinksDropdown,
}) => {
  // states
  const [organizations] = useState(getUser()?.organizations || []);
  const [selectedOrganization] = useState(getOrganization());
  const [loading, setLoading] = useState(false);

  // navigate user to login if selectedOrganization is null
  useEffect(() => {
    if (!selectedOrganization) {
      navigate("/login");
    }
  }, [selectedOrganization]);

  // navigate
  const navigate = useNavigate();

  // setOrganization
  const handleSelectedOrganization = (org) => {
    if (org.tagNumber !== selectedOrganization.tagNumber) {
      setLoading(true);
      axios
        .put(
          `${hostName}api/admin/v1/auth/setOrganization`,
          {
            organizationTag: org.tagNumber,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${getUser().token}`,
            },
          }
        )
        .then(() => {
          localStorage.setItem("organization", JSON.stringify(org));
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          if (error.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        })
        .finally(() => setLoading(false));
    } else {
      console.log("already selected");
    }
  };

  // Ref to the dropdown container
  const dropdownRef = useRef(null);

  // Function to close the dropdown
  const closeDropdown = () => {
    setQuickLinksDropdown(false);
  };

  // Add a click event listener to the document
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Click occurred outside the dropdown, close it
        closeDropdown();
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // destructure version
  const { version } = packageFile;

  // display value
  const [value, setValue] = useState("");

  // setting display value
  useEffect(() => {
    if (selectedOrganization) {
      setValue(
        () =>
          `${selectedOrganization.name}${
            selectedOrganization.location
              ? ` - ${selectedOrganization.location}`
              : ""
          }`
      );
    } else {
      setValue("Select an organization");
    }
  }, [selectedOrganization]);

  // news popup
  const [newsPopup, setNewsPopup] = useState(false);

  return (
    <>
      <header>
        <div className="header-leftside">
          <div className="header-logo">
            <img src={logo} alt="logo" />
          </div>
          <div
            className="header-menu-icon"
            onClick={() => setSidebarToggled(!sidebarToggled)}
          >
            <Icon icon={menu} size={14} />
          </div>
          <SearchableDropdown
            options={organizations}
            label="name"
            selectedVal={loading ? "Loading..." : value || ""}
            handleSelectedOrganization={handleSelectedOrganization}
            handleChange={(val) => setValue(val)}
            selectedOrganization={selectedOrganization}
          />
        </div>
        <div className="header-rightside">
          {/* quick links */}
          <Tippy content="Quick Links" zIndex={10005}>
            <span>
              <Icon
                icon={arrows_squares}
                size={22}
                className="rightside-links quick-links-dropdown-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setQuickLinksDropdown(!quickLinksDropdown);
                }}
              />
            </span>
          </Tippy>
          <div
            className={
              quickLinksDropdown
                ? "header-dropdown quick-links open"
                : "header-dropdown quick-links"
            }
            ref={dropdownRef}
          >
            <div
              className="dropdown-box"
              onClick={() => {
                navigate("/campaigns");
                setQuickLinksDropdown(false);
              }}
            >
              <Icon icon={droplet} size={32} className="blue" />
              <h5>
                <NavLink to="/campaigns" className="dropdown-box-links">
                  Campaigns
                </NavLink>
              </h5>
            </div>
            <div
              className="dropdown-box"
              onClick={() => {
                navigate("/fundraising/transactions");
                setQuickLinksDropdown(false);
              }}
            >
              <Icon icon={dollarSign} size={32} className="yellow" />
              <h5>
                <NavLink
                  to="/fundraising/transactions"
                  className="dropdown-box-links"
                >
                  Transactions
                </NavLink>
              </h5>
            </div>
            <div
              className="dropdown-box"
              onClick={() => {
                navigate("/donors");
                setQuickLinksDropdown(false);
              }}
            >
              <Icon icon={users} size={32} className="green" />
              <h5>
                <NavLink to="/donors" className="dropdown-box-links">
                  Donors
                </NavLink>
              </h5>
            </div>
          </div>
          {/* news */}
          <Tippy content={`New In Donor Choice`} zIndex={10005}>
            <span onClick={() => setNewsPopup(true)}>
              <Icon
                icon={ecommerce_megaphone}
                size={22}
                className="rightside-links version-links-dropdown-btn"
              />
            </span>
          </Tippy>
          {/* version */}
          <Tippy content={`Version: ${version}`} zIndex={10005}>
            <span>
              <Icon
                icon={basic_server_cloud}
                size={22}
                className="rightside-links version-links-dropdown-btn"
              />
            </span>
          </Tippy>
          {/* help */}
          <Tippy content={"User Manual"} zIndex={10005}>
            <a
              href="https://help.donorchoice.ca/guides/userManual.pdf"
              target="blank"
              rel="help"
            >
              <span>
                <Icon
                  icon={arrows_question}
                  size={22}
                  className="rightside-links version-links-dropdown-btn"
                />
              </span>
            </a>
          </Tippy>
        </div>
      </header>

      {/* news popup */}
      {newsPopup && <NewsPopup setNewsPopup={setNewsPopup} />}
    </>
  );
};

export default Header;
