import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

export const getRegisteredDonors = createAsyncThunk(
  "registeredDonors/getRegisteredDonors",
  async (adminToken) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${adminToken}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/numberOfDonors`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const registeredDonorsSlice = createSlice({
  name: "registeredDonors",
  initialState: {
    registeredDonorsLoading: false,
    registeredDonorsData: null,
    registeredDonorsError: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRegisteredDonors.pending, (state) => {
        state.registeredDonorsLoading = true;
        state.registeredDonorsData = null;
        state.registeredDonorsError = null;
      })
      .addCase(getRegisteredDonors.fulfilled, (state, action) => {
        state.registeredDonorsLoading = false;
        state.registeredDonorsData = action.payload;
        state.registeredDonorsError = null;
      })
      .addCase(getRegisteredDonors.rejected, (state, action) => {
        state.registeredDonorsLoading = false;
        state.registeredDonorsData = null;
        state.registeredDonorsError = action.error.message;
      });
  },
});

export default registeredDonorsSlice.reducer;
