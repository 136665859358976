import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { basic_paperplane } from "react-icons-kit/linea/basic_paperplane";
import { basic_download } from "react-icons-kit/linea/basic_download";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Tippy from "@tippyjs/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  sendEmail,
  downloadTransactionReceipt,
} from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { MetroSpinner } from "react-spinners-kit";
import { toast } from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const RecurringDetailsPopup = ({
  token,
  recurringObj,
  setRecurringDetailsPopup,
}) => {
  // redux state
  const {
    sendEmailLoading,
    sendEmailData,
    downloadReceiptLoading,
    downloadReceiptData,
  } = useSelector((state) => state.transactions);

  // redux state
  const { recurringPaymentsData } = useSelector(
    (state) => state.recurringPayments
  );

  // close modal
  const handleCloseModal = () => {
    if (!sendEmailLoading && !downloadReceiptLoading) {
      setRecurringDetailsPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // email send event
  const handleSendEmail = (transaction) => {
    dispatch(
      sendEmail({
        token,
        tagNumber: transaction.tagNumber,
      })
    ).then((res) => {
      if (!res.payload.error) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (res.payload.error === "Request failed with status code 401") {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(`${res.payload.error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // download receipt
  const handleDownloadReceipt = (transaction) => {
    dispatch(
      downloadTransactionReceipt({
        token,
        transactionTag: transaction.tagNumber,
      })
    ).then((res) => {
      if (!res.payload.error) {
        // download pdf
        const url = window.URL.createObjectURL(new Blob([res.payload.file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${transaction.tagNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (res.payload.error === "Request failed with status code 401") {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(`${res.payload.error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media extra-width"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Transactions Details</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div
            className="update-widgets-modal-body transactions-popup"
            style={{ padding: 5 + "px" }}
          >
            <div className="transactions-table">
              <MDBTable align="middle">
                <MDBTableHead className="thead">
                  <tr>
                    <th scope="col">Amount</th>
                    <th scope="col">Invoice</th>
                    <th scope="col">Sequence No.</th>
                    <th scope="col">Transaction Date</th>
                    <th scope="col">Actions</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {recurringObj.transactions.map((transaction, index) => (
                    <tr
                      key={transaction.tagNumber}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                      }}
                    >
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {recurringPaymentsData && (
                            <>{recurringPaymentsData.data.currencySymbol}</>
                          )}
                          {transaction.approvedAmount}
                        </p>
                      </td>
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {transaction.invoice}
                        </p>
                      </td>
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {transaction.sequenceNumber}
                        </p>
                      </td>
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {transaction.transactionDate.split(" ")[0]}
                        </p>
                      </td>
                      <td className="icons-td">
                        <div className="actions-icon-container">
                          {/* send email */}
                          {sendEmailData &&
                          sendEmailData.tagNumber === transaction.tagNumber &&
                          sendEmailLoading ? (
                            <span className="loader-span">
                              <MetroSpinner
                                loading={sendEmailLoading}
                                color="#007c16"
                                size={20}
                              />
                            </span>
                          ) : (
                            <Tippy content="Send Email" zIndex={1000001}>
                              <span
                                onClick={() => handleSendEmail(transaction)}
                              >
                                <Icon icon={basic_paperplane} size={20} />
                              </span>
                            </Tippy>
                          )}
                          {/* download receipt */}
                          {downloadReceiptData &&
                          downloadReceiptData.transactionTag ===
                            transaction.tagNumber &&
                          downloadReceiptLoading ? (
                            <span className="loader-span">
                              <MetroSpinner
                                loading={downloadReceiptLoading}
                                color="#007c16"
                                size={20}
                              />
                            </span>
                          ) : (
                            <Tippy content="Download Receipt" zIndex={1000001}>
                              <span
                                onClick={() =>
                                  handleDownloadReceipt(transaction)
                                }
                              >
                                <Icon icon={basic_download} size={20} />
                              </span>
                            </Tippy>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>
            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default RecurringDetailsPopup;
