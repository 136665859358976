import React from "react";
import { useSelector } from "react-redux";

const KeyDates = () => {
  // redux state
  const { donorKeyDatesData, donorKeyDatesError } = useSelector(
    (state) => state.donorHighlights
  );

  return (
    <>
      <div className="box-header">
        <span>Key Dates</span>
      </div>
      <div className="lifetime-content-wrapper">
        {donorKeyDatesError ? (
          <div className="error-msg" style={{ marginTop: 0 }}>
            {donorKeyDatesError}
          </div>
        ) : (
          <>
            {donorKeyDatesData && (
              <>
                <div className="data-item">
                  <div className="data-label">Registeration Date</div>
                  <div className="data-value">
                    {donorKeyDatesData.registrationDate}
                  </div>
                </div>
                <div className="data-item">
                  <div className="data-label">First Contribution</div>
                  <div className="data-value">
                    {donorKeyDatesData.firstContribution}
                  </div>
                </div>
                <div className="data-item">
                  <div className="data-label">Last Contribution</div>
                  <div className="data-value">
                    {donorKeyDatesData.lastContribution}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default KeyDates;
