/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getChannelDetails } from "../../Store/Slices/Sidebar/Channels/ChannelsSlice";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { MetroSpinner } from "react-spinners-kit";
import Select from "react-select";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const ChannelDetailsPopup = ({
  token,
  setChannelDetailsPopup,
  channelDetailsObj,
}) => {
  // redux state
  const {
    channelsData,
    channelDetailsLoading,
    channelDetailsData,
    channelDetailsError,
  } = useSelector((state) => state.channels);

  // close modal
  const handleCloseModal = () => {
    if (!channelDetailsLoading) {
      setChannelDetailsPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // states
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // getting month options
  const monthOptions = [];
  for (let i = 1; i <= 12; i++) {
    const monthValue = i.toString().padStart(2, "0");
    monthOptions.push({ value: monthValue, label: monthValue });
  }

  // day options
  const dayOptions = [];
  for (let i = 1; i <= 31; i++) {
    const dayValue = i.toString().padStart(2, "0");
    dayOptions.push({ value: dayValue, label: dayValue });
  }

  // get details
  useEffect(() => {
    dispatch(
      getChannelDetails({
        token,
        tagNumber: channelDetailsObj.tagNumber,
        year: "",
        month: "",
        day: "",
      })
    )
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // channel details filter
  const handleChannelDetailsFilter = (e) => {
    e.preventDefault();
    dispatch(
      getChannelDetails({
        token,
        tagNumber: channelDetailsObj.tagNumber,
        year: year || "",
        month: month || "",
        day: day || "",
      })
    )
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  // calculate total amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (channelDetailsData && channelDetailsData.length > 0) {
      const calculatedAmounts = channelDetailsData.map((item) => {
        const { amount } = item;
        // Convert the amount from string to number using parseFloat and strip commas
        return parseFloat(amount.replace(/,/g, ""));
      });

      const totalAmount = calculatedAmounts.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const roundedSum = totalAmount.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [channelDetailsData]);

  // calculate total transactions
  const [transactions, setTransactions] = useState("0.00");
  useEffect(() => {
    if (channelDetailsData && channelDetailsData.length > 0) {
      const calculatedTransactions = channelDetailsData.map((item) => {
        const { numberOfTransaction } = item;
        return numberOfTransaction;
      });

      const totalTransactions = calculatedTransactions.reduce(
        (acc, curr) => acc + curr,
        0
      );
      setTransactions(totalTransactions);
    } else {
      setTransactions("0.00");
    }
  }, [channelDetailsData]);

  // backgroundColor of last static row
  const [lastRowBgColor, setLastRowBgColor] = useState("#fff");
  useEffect(() => {
    if (channelDetailsData && channelDetailsData.length > 0) {
      // data is neccessary for proper indexing, index is always the second parameter
      channelDetailsData.forEach((data, index) => {
        const bgColor = index % 2 === 0 ? "#fff" : "#fafafa";
        setLastRowBgColor(bgColor);
      });
    }
  }, [channelDetailsData]);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              Channel Details - {channelDetailsObj.organizationDefinedName}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div
            className="update-widgets-modal-body transactions-popup"
            style={{ padding: 5 + "px" }}
          >
            {channelDetailsLoading ? (
              <div
                style={{
                  padding: 150 + "px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MetroSpinner
                  size={30}
                  color="#007C16"
                  loading={channelDetailsLoading}
                />
              </div>
            ) : (
              <>
                <form
                  className="filter-channel-details-div"
                  onSubmit={handleChannelDetailsFilter}
                >
                  <div className="filter-div">
                    <label>Year</label>
                    <Select
                      value={yearOptions.find(
                        (option) => option.value === year
                      )}
                      onChange={(option) =>
                        setYear(option ? option.value : null)
                      }
                      options={yearOptions}
                      styles={styles}
                      isClearable
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="filter-div">
                    <label>Month</label>
                    <Select
                      value={monthOptions.find(
                        (option) => option.value === month
                      )}
                      onChange={(option) =>
                        setMonth(option ? option.value : null)
                      }
                      options={monthOptions}
                      styles={styles}
                      isClearable
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="filter-div">
                    <label>Day</label>
                    <Select
                      value={dayOptions.find((option) => option.value === day)}
                      onChange={(option) =>
                        setDay(option ? option.value : null)
                      }
                      options={dayOptions}
                      styles={styles}
                      isClearable
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="filter-div">
                    <button type="submit">Filter</button>
                  </div>
                </form>
                {channelDetailsError ? (
                  <div
                    style={{
                      padding: 150 + "px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="error-msg">{channelDetailsError}</div>
                  </div>
                ) : (
                  <>
                    {channelDetailsData && channelDetailsData.length > 0 ? (
                      <div className="transactions-table">
                        <MDBTable align="middle">
                          <MDBTableHead className="thead" style={{ zIndex: 0 }}>
                            <tr>
                              <th scope="col">Campaign</th>
                              <th
                                scope="col"
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                Transactions
                              </th>
                              <th
                                scope="col"
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                Amount
                              </th>
                            </tr>
                          </MDBTableHead>
                          <MDBTableBody>
                            {channelDetailsData.map((data, index) => {
                              return (
                                <tr
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#fff" : "#fafafa",
                                  }}
                                  key={index}
                                >
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{
                                        fontSize: "16px",
                                      }}
                                    >
                                      {data.campaign}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {data.numberOfTransaction}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {channelsData && (
                                        <>
                                          {channelsData.data[0].currencySymbol}
                                        </>
                                      )}
                                      {data.amount}
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                            <tr
                              style={{
                                backgroundColor:
                                  lastRowBgColor === "#fff"
                                    ? "#fafafa"
                                    : "#fff",
                              }}
                            >
                              <td>
                                <p
                                  className="fw-bold mb-1"
                                  style={{ fontSize: "16px", color: "#007C16" }}
                                >
                                  Total
                                </p>
                              </td>
                              <td>
                                <p
                                  className="fw-bold mb-1"
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "right",
                                  }}
                                >
                                  {transactions}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="fw-bold mb-1"
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "right",
                                  }}
                                >
                                  {channelsData && (
                                    <>{channelsData.data[0].currencySymbol}</>
                                  )}
                                  {sum}
                                </p>
                              </td>
                            </tr>
                          </MDBTableBody>
                        </MDBTable>
                      </div>
                    ) : (
                      <div
                        className="no-data-found-div"
                        style={{ padding: 150 + "px" }}
                      >
                        <Tippy content="No Data Found" zIndex={100005}>
                          <img src={noDataFound} alt="No Data Found" />
                        </Tippy>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            <div className="submit-and-cancel-div" style={{ borderTop: 0 }}>
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ChannelDetailsPopup;
