import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllNotifications,
  filterNotifications,
} from "../../Store/Slices/Sidebar/Notifications/NotificationsSlice";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Icon from "react-icons-kit";
import { software_pencil } from "react-icons-kit/linea/software_pencil";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const NotificationsTable = ({
  token,
  filteredParams,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
  setPageSize,
  setLoadings,
  handleNotificationDetails,
  handleEditNotification,
}) => {
  // redux state
  const { notificationsData, notificationsError } = useSelector(
    (state) => state.notifications
  );

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterNotifications({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getAllNotifications({
            token,
            page: 1,
            pageSize: value,
            by: "status",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterNotifications({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getAllNotifications({
            token,
            page: value,
            pageSize: perPage,
            by: "status",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  return (
    <>
      {notificationsError ? (
        <div className="error-msg" style={{ width: "fit-content" }}>
          {notificationsError}
        </div>
      ) : (
        <>
          {notificationsData?.data?.length > 0 ? (
            <>
              <div className="transactions-table">
                <MDBTable align="middle">
                  <MDBTableHead className="thead">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Notification Type</th>
                      <th scope="col">Message</th>
                      <th scope="col">Status</th>
                      <th scope="col">State</th>
                      <th scope="col">Last Modified By</th>
                      <th scope="col">Create Date Time</th>
                      <th scope="col">Update Date Time</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {notificationsData.data.map((data, index) => (
                      <tr
                        key={data.tagNumber}
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNotificationDetails(data);
                        }}
                      >
                        <td>
                          <Tippy content={data.type} zIndex={10004}>
                            <img
                              src={data.typeImage}
                              alt="type"
                              style={{
                                width: 30 + "px",
                                cursor: "pointer",
                              }}
                            />
                          </Tippy>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            {data.transactionType}
                          </p>
                        </td>
                        <td>
                          {data.messageShort
                            .split("<br/>")
                            .map((line, index) => (
                              <p
                                key={index}
                                style={{
                                  marginBottom: "4px",
                                  fontSize: "16px",
                                  fontWeight:
                                    data.status === "Unread"
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {line}
                              </p>
                            ))}
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            {data.status}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            {data.state}
                          </p>
                        </td>

                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            {data.lastModifiedBy}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            {data.createDatetime}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            {data.updateDatetime}
                          </p>
                        </td>
                        <td className="icons-td">
                          <div className="actions-icon-container">
                            {/* edit */}
                            <Tippy content="Edit" zIndex={10004}>
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditNotification(data);
                                }}
                              >
                                <Icon icon={software_pencil} size={20} />
                              </span>
                            </Tippy>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div className="transactions-pagination">
                <div className="per-page-and-pagination">
                  <div className="per-page">
                    <label>Records per page</label>
                    <Select
                      value={perPageOptions.find(
                        (option) => option.value === String(perPage)
                      )}
                      onChange={(option) => handlePerPage(option.value)}
                      options={perPageOptions}
                      styles={styles}
                      menuPlacement="top"
                    />
                  </div>
                  <ResponsivePagination
                    current={currentPage}
                    total={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Notifications Found">
                <img src={noDataFound} alt="No Notifications Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NotificationsTable;
