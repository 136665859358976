import React from "react";
import { useSelector } from "react-redux";
import { MetroSpinner } from "react-spinners-kit";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

const ContactDetailsForm = ({
  organization,
  setOrganization,
  handleOrganizationDetailsSubmit,
}) => {
  // redux state
  const { updateOrganizationLoading, updateOrganizationData } = useSelector(
    (state) => state.organization
  );

  return (
    <form
      onSubmit={handleOrganizationDetailsSubmit}
      className="update-media-form"
    >
      <h4
        style={{
          borderBottom: 1 + "px solid #e4e4e4",
          paddingBottom: 5 + "px",
          marginBottom: 15 + "px",
        }}
      >
        Contact Details
      </h4>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Name</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.contactName}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                contactName: e.target.value,
              }))
            }
            required
            placeholder="Name"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Email</label>
          <input
            type="email"
            className="edit-media-inputs"
            value={organization.contactEmail}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                contactEmail: e.target.value,
              }))
            }
            placeholder="Email"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Phone</label>
          <input
            type="tel"
            className="edit-media-inputs"
            value={organization.contactPhone}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                contactPhone: e.target.value,
              }))
            }
            placeholder="Phone"
          />
        </MDBCol>
      </MDBRow>

      {updateOrganizationData &&
        (updateOrganizationData.apiError ? (
          <div className="error-msg" style={{ width: "fit-content" }}>
            {typeof updateOrganizationData.apiError === "object" ? (
              // Case 1: If apiError is an object
              Object.keys(updateOrganizationData.apiError).map(
                (field, index) => (
                  <p key={index} style={{ marginBottom: 0 }}>
                    {updateOrganizationData.apiError[field][0]}
                  </p>
                )
              )
            ) : (
              // Case 2: If apiError is a simple string
              <>{updateOrganizationData.apiError}</>
            )}
          </div>
        ) : (
          // Case 3: If apiError does not exist, render axiosError
          <>
            {updateOrganizationData && updateOrganizationData.axiosError && (
              <div className="error-msg" style={{ width: "fit-content" }}>
                {updateOrganizationData.axiosError}
              </div>
            )}
          </>
        ))}

      <div className="submit-and-cancel-div">
        <button
          type="submit"
          className="submit addModal"
          disabled={updateOrganizationLoading}
        >
          {updateOrganizationLoading ? (
            <MetroSpinner
              loading={updateOrganizationLoading}
              size={22}
              color="#fff"
            />
          ) : (
            "UPDATE"
          )}
        </button>
      </div>
    </form>
  );
};

export default ContactDetailsForm;
