/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../../CSS/Sidebar/Channels.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  filterChannels,
  getChannelsData,
  getChannelsSummary,
} from "../../Store/Slices/Sidebar/Channels/ChannelsSlice";
import { Toaster } from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { filter } from "react-icons-kit/feather/filter";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import ChannelsSummary from "../Channels/ChannelsSummary";
import Channels from "../Channels/Channels";
import UpdateChannelPopup from "../Channels/UpdateChannelPopup";
import ChannelDetailsPopup from "../Channels/ChannelDetailsPopup";
import FilterChannelsModal from "../Channels/FilterChannelsModal";
import axios from "axios";
import hostName from "../../config";

const ChannelsComponent = ({ token }) => {
  // redux states
  const {
    channelsSummaryLoading,
    channelsSummaryError,
    channelsLoading,
    channelsError,
  } = useSelector((state) => state.channels);

  // states for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("10");

  // page size is used in filter modal
  const [pageSize, setPageSize] = useState("10");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get channels summary
  useEffect(() => {
    dispatch(getChannelsSummary(token))
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // filtered flag
  const [filteredFlag, setFilteredFlag] = useState(null);

  // filtered params
  const [filteredParams, setFilteredParams] = useState(null);

  // filter channels modal
  const [filterChannelsModal, setFilterChannelsModal] = useState(false);

  // get channels data
  useEffect(() => {
    if (filteredParams) {
      dispatch(
        filterChannels({
          token,
          page: 1,
          pageSize: filteredParams.pageSize,
          order: filteredParams.order,
          apiData: filteredParams.apiData,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    } else {
      dispatch(
        getChannelsData({
          token,
          page: 1,
          pageSize: "10",
          order: "asc",
          by: "payment_processor",
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    }
  }, [filteredFlag, filteredParams]);

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // entity options
  const [entityOptions, setEntityOptions] = useState([]);
  const [entityOptionsLoading, setEntityOptionsLoading] = useState(true);
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${hostName}api/admin/v1/lov?listName=channelFilter`, axiosConfig)
      .then((res) => {
        setEntityOptions(res.data.data);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setEntityOptionsLoading(false));
  }, []);

  // route states
  const [statusOptions, setStatusOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [paymentProcessorOptions, setPaymentProcessorOptions] = useState([]);
  const [routeLoading, setRouteLoading] = useState(true);
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${hostName}api/admin/v1/routeLov?routeName=channel`, axiosConfig)
      .then((res) => {
        setStatusOptions(res.data.data.status);
        setTypeOptions(res.data.data.type);
        setPaymentProcessorOptions(res.data.data.payment_processor);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setRouteLoading(false));
  }, []);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [
    channelsSummaryLoading,
    channelsLoading,
    entityOptionsLoading,
    routeLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // organization defined name
  const [organizationDefinedName, setOrganizationDefinedName] = useState("");

  // serial number
  const [serialNumber, setSerialNumber] = useState("");

  // type
  const [type, setType] = useState([]);
  const handleTypeChange = (item) => {
    if (type.includes(item)) {
      setType(type.filter((checkedItem) => checkedItem !== item));
    } else {
      setType([...type, item]);
    }
  };

  // payment_processor
  const [paymentProcessor, setPaymentProcessor] = useState([]);
  const handlePaymentProcessorChange = (item) => {
    if (paymentProcessor.includes(item)) {
      setPaymentProcessor(
        paymentProcessor.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setPaymentProcessor([...paymentProcessor, item]);
    }
  };

  // status
  const [status, setStatus] = useState([]);
  const handleStatusChange = (item) => {
    if (status.includes(item)) {
      setStatus(status.filter((checkedItem) => checkedItem !== item));
    } else {
      setStatus([...status, item]);
    }
  };

  // sort by
  const [sortBy, setSortBy] = useState(null);

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // reset filter
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilteredFlag(null);
      setFilteredParams(null);
      setFilterChannelsModal(false);
    }
    setEntitySelected([]);
    setOrganizationDefinedName("");
    setSerialNumber("");
    setType([]);
    setPaymentProcessor([]);
    setStatus([]);
    setPageSize(perPage);
    setSortBy(null);
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset entities
  const resetEntities = () => {
    setEntitySelected([]);
    setOrganizationDefinedName("");
    setSerialNumber("");
    setType([]);
    setPaymentProcessor([]);
    setStatus([]);
    setPageSize(perPage);
    setSortBy(null);
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // update channel popup
  const [updateChannelPopup, setUpdateChannelPopup] = useState(false);
  const [updateChannelObj, setUpdateChannelObj] = useState(null);
  const handleUpdateChannelPopup = (channel) => {
    setUpdateChannelPopup(true);
    setUpdateChannelObj(channel);
  };

  // channel details popup
  const [channelDetailsPopup, setChannelDetailsPopup] = useState(false);
  const [channelDetailsObj, setChannelDetailsObj] = useState(null);
  const handleChannelDetailsPopup = (channel) => {
    setChannelDetailsPopup(true);
    setChannelDetailsObj(channel);
  };

  return (
    <>
      <Toaster />
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              <h6>Channels Summary</h6>
              {channelsSummaryError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {channelsSummaryError}
                </div>
              ) : (
                <ChannelsSummary
                  setLoadings={setLoadings}
                  filteredFlag={filteredFlag}
                  setFilteredFlag={setFilteredFlag}
                  setFilteredParams={setFilteredParams}
                  resetEntities={resetEntities}
                />
              )}
              <div className="media-heading-button-container">
                <h6>Registered Channels</h6>
                <div className="add-and-filter-btns-div">
                  <div className="filter-and-reset-btns-div">
                    <Tippy content="Filter Records">
                      <button
                        className="filter-media-modal-btn"
                        type="button"
                        onClick={() => setFilterChannelsModal(true)}
                      >
                        <Icon icon={filter} size={24} />
                      </button>
                    </Tippy>
                    {filteredParams && (
                      <Tippy content="Reset Records">
                        <button
                          className="filter-media-modal-btn reset"
                          type="button"
                          onClick={handleResetFilter}
                        >
                          <Icon icon={refreshCw} size={24} />
                        </button>
                      </Tippy>
                    )}
                  </div>
                </div>
              </div>
              {channelsError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {channelsError}
                </div>
              ) : (
                <>
                  <div className="manage-channels-row">
                    <Channels
                      token={token}
                      filteredParams={filteredParams}
                      perPage={perPage}
                      setPerPage={setPerPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                      setTotalPages={setTotalPages}
                      setPageSize={setPageSize}
                      handleUpdateChannelPopup={handleUpdateChannelPopup}
                      handleChannelDetailsPopup={handleChannelDetailsPopup}
                      setLoadings={setLoadings}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {updateChannelPopup && (
        <UpdateChannelPopup
          token={token}
          setUpdateChannelPopup={setUpdateChannelPopup}
          updateChannelObj={updateChannelObj}
        />
      )}
      {channelDetailsPopup && (
        <ChannelDetailsPopup
          token={token}
          setChannelDetailsPopup={setChannelDetailsPopup}
          channelDetailsObj={channelDetailsObj}
        />
      )}
      {filterChannelsModal && (
        <FilterChannelsModal
          setFilterChannelsModal={setFilterChannelsModal}
          handleSelectChange={handleSelectChange}
          entitySelected={entitySelected}
          entityOptions={entityOptions}
          setFilteredParams={setFilteredParams}
          organizationDefinedName={organizationDefinedName}
          setOrganizationDefinedName={setOrganizationDefinedName}
          serialNumber={serialNumber}
          setSerialNumber={setSerialNumber}
          type={type}
          typeOptions={typeOptions}
          handleTypeChange={handleTypeChange}
          paymentProcessor={paymentProcessor}
          paymentProcessorOptions={paymentProcessorOptions}
          handlePaymentProcessorChange={handlePaymentProcessorChange}
          status={status}
          statusOptions={statusOptions}
          handleStatusChange={handleStatusChange}
          pageSize={pageSize}
          handlePerPage={handlePerPage}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          setLoadings={setLoadings}
          handleResetFilter={handleResetFilter}
          setFilteredFlag={setFilteredFlag}
        />
      )}
    </>
  );
};

export default ChannelsComponent;
