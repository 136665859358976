import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  filterDeclinedRecords,
  readDeclinedTransactions,
} from "../../Store/Slices/Sidebar/RecurringPayments/RecurringPaymentsSlice";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const DeclinedRecurringsTable = ({
  token,
  listViewFilteredParams,
  listViewPerPage,
  setListViewPerPage,
  listViewCurrentPage,
  setListViewCurrentPage,
  listViewTotalPages,
  setListViewTotalPages,
  setListViewPageSize,
  setLoadings,
}) => {
  // redux state
  const { declinedRecurringsData } = useSelector(
    (state) => state.recurringPayments
  );

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (listViewPerPage !== value) {
      setListViewPerPage(value);
      setLoadings(true);
      if (listViewFilteredParams) {
        dispatch(
          filterDeclinedRecords({
            token,
            page: 1,
            pageSize: value,
            order: listViewFilteredParams.order,
            apiData: listViewFilteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          readDeclinedTransactions({
            token,
            page: 1,
            pageSize: value,
            by: "email",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (listViewCurrentPage !== value) {
      setListViewCurrentPage(value);
      setLoadings(true);
      if (listViewFilteredParams) {
        dispatch(
          filterDeclinedRecords({
            token,
            page: value,
            pageSize: listViewPerPage,
            order: listViewFilteredParams.order,
            apiData: listViewFilteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          readDeclinedTransactions({
            token,
            page: value,
            pageSize: listViewPerPage,
            by: "email",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  return (
    <>
      {declinedRecurringsData && declinedRecurringsData.data.length > 0 ? (
        <>
          <div className="transactions-table">
            <MDBTable align="middle">
              <MDBTableHead className="thead">
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">Campaign</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Account ID</th>
                  <th scope="col">Invoice</th>
                  <th scope="col">Cardholder Name</th>
                  <th scope="col">Card Number</th>
                  <th scope="col">Card Expiry</th>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">Transaction Date</th>
                  <th scope="col">Message</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {declinedRecurringsData.data.map((data, index) => (
                  <tr
                    key={data.tagNumber}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                    }}
                  >
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.email ? (
                          <>
                            {data.donor ? (
                              <Tippy
                                content={`${data.donor.firstName} ${data.donor.lastName}`}
                                zIndex={10004}
                              >
                                <Link
                                  to={`/donors/${data.donor.firstName}_${data.donor.lastName}_${data.donor.tagNumber}`}
                                  className="donor-hyper-link"
                                >
                                  {data.email}
                                </Link>
                              </Tippy>
                            ) : (
                              <>{data.email}</>
                            )}
                          </>
                        ) : (
                          <>Anonymous</>
                        )}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.campaign}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{
                          fontSize: "16px",
                          textAlign: "right",
                        }}
                      >
                        {declinedRecurringsData?.currencySymbol}
                        {data.amount}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.accountId}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.invoice}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.cardholderName}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.cardNumber}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.cardExpiry}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.transactionId}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.transactionDate}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.message}
                      </p>
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          </div>

          {/* pagination */}
          <div className="transactions-pagination">
            <div className="per-page-and-pagination">
              <div className="per-page">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === String(listViewPerPage)
                  )}
                  onChange={(option) => handlePerPage(option.value)}
                  options={perPageOptions}
                  styles={styles}
                  menuPlacement="top"
                />
              </div>
              <ResponsivePagination
                current={listViewCurrentPage}
                total={listViewTotalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No Declined Recurring Payments Found">
            <img src={noDataFound} alt="No Declined Recurring Payments Found" />
          </Tippy>
        </div>
      )}
    </>
  );
};

export default DeclinedRecurringsTable;
