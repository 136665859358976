import axios from "axios";
import hostName from "../../../../config";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// get imports --- array of objects
export const getImports = createAsyncThunk(
  "data/getImports",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/import?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter records
export const filterDataRecords = createAsyncThunk(
  "data/filterDataRecords",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = ["type", "status", "by"];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/import?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// import details
export const getImportDetails = createAsyncThunk(
  "data/getImportDetails",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/import/${singleObj.tagNumber}`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// import data
export const importRecords = createAsyncThunk(
  "data/importRecords",
  async (singleObj) => {
    try {
      const requestedData = {
        type: singleObj.type,
        importFile: singleObj.file,
      };
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/import`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
      };
    }
  }
);

// delete import
export const deleteImport = createAsyncThunk(
  "data/deleteImport",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/import/${singleObj.tagNumber}`,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

const DataSlice = createSlice({
  name: "data",
  initialState: {
    // get all imports
    importsLoading: false,
    importsData: null,
    importsError: null,
    // get import details
    importDetailsLoading: false,
    importDetailsData: null,
    importDetailsError: null,
    // new import
    newImportLoading: false,
    newImportData: null,
    // delete import
    deleteImportLoading: false,
    deleteImportData: null,
  },
  reducers: {
    clearPrevImportedData: (state) => {
      state.newImportData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get imports
      .addCase(getImports.pending, (state) => {
        state.importsLoading = true;
        state.importsData = null;
        state.importsError = null;
      })
      .addCase(getImports.fulfilled, (state, action) => {
        state.importsLoading = false;
        state.importsData = action.payload;
        state.importsError = null;
      })
      .addCase(getImports.rejected, (state, action) => {
        state.importsLoading = false;
        state.importsData = null;
        state.importsError = action.error.message;
      })
      // filter import records
      .addCase(filterDataRecords.pending, (state) => {
        state.importsLoading = true;
        state.importsData = null;
        state.importsError = null;
      })
      .addCase(filterDataRecords.fulfilled, (state, action) => {
        state.importsLoading = false;
        state.importsData = action.payload;
        state.importsError = null;
      })
      .addCase(filterDataRecords.rejected, (state, action) => {
        state.importsLoading = false;
        state.importsData = null;
        state.importsError = action.error.message;
      })
      // get import details
      .addCase(getImportDetails.pending, (state) => {
        state.importDetailsLoading = true;
        state.importDetailsData = null;
        state.importDetailsError = null;
      })
      .addCase(getImportDetails.fulfilled, (state, action) => {
        state.importDetailsLoading = false;
        state.importDetailsData = action.payload;
        state.importDetailsError = null;
      })
      .addCase(getImportDetails.rejected, (state, action) => {
        state.importDetailsLoading = false;
        state.importDetailsData = null;
        state.importDetailsError = action.error.message;
      })
      // new import
      .addCase(importRecords.pending, (state) => {
        state.newImportLoading = true;
        state.newImportData = null;
      })
      .addCase(importRecords.fulfilled, (state, action) => {
        state.newImportLoading = false;
        state.newImportData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.importsData.data.unshift(data);
        }
      })
      // delete import...it is working like update, we are closing the import
      .addCase(deleteImport.pending, (state) => {
        state.deleteImportLoading = true;
        state.deleteImportData = null;
      })
      .addCase(deleteImport.fulfilled, (state, action) => {
        state.deleteImportLoading = false;
        state.deleteImportData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const dataIndex = state.importsData.data.findIndex(
            (item) => item.tagNumber === tagNumber
          );

          if (dataIndex !== -1) {
            state.importsData.data[dataIndex] = data;
          }
        }
      });
  },
});

export default DataSlice.reducer;
export const { clearPrevImportedData } = DataSlice.actions;
