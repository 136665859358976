import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredTopDonations } from "../../Store/Slices/Sidebar/Analytics/TopDonationsSlice";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import noDataFound from "../../Images/noDataFound.png";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

const TopDonations = ({ token }) => {
  // redux state
  const { filteredTopDonationsLoading, topDonationsData, topDonationsError } =
    useSelector((state) => state.topDonations);

  // Calculate the total sum of amounts
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (topDonationsData && topDonationsData.values.length > 0) {
      const totalSum = topDonationsData.values.reduce(
        (sum, obj) => sum + obj.amount,
        0
      );
      const roundedSum = totalSum.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [topDonationsData]);

  // month options array
  const monthOptions = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];

  // get month label
  const getMonthLabel = (monthValue) => {
    const monthObj = monthOptions.find((option) => option.value === monthValue);
    return monthObj ? monthObj.label : "";
  };

  // current date
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = currentDate.getFullYear();

  // getting year options
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // states
  const [month, setMonth] = useState({
    value: currentMonth,
    label: getMonthLabel(currentMonth),
  });
  const [year, setYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [selectedYear, setSelectedYear] = useState(year);
  const [popup, setPopup] = useState(false);

  const [numberInput, setNumberInput] = useState(5);

  // number change
  const handleNumberChange = (event) => {
    let newValue = parseInt(event.target.value, 10);
    if (newValue < 1) {
      newValue = 1;
    } else if (newValue > 20) {
      newValue = 20;
    }
    setNumberInput(newValue);
  };

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle filter
  const handleMonthAndYearFilter = () => {
    setPopup(false);
    setSelectedMonth(month);
    setSelectedYear(year);
    // Check if numberInput is NaN, and if it is, set it to 1
    let filteredNumber = isNaN(numberInput) ? 1 : numberInput;
    const singleObj = {
      token,
      n: filteredNumber,
      year,
      month: month.value,
    };
    dispatch(getFilteredTopDonations(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  // custom styles
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#e1e1e9",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#b1b1b9",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
      boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
      "&:hover": {
        border: "1px solid #007C16",
        boxShadow: "0px 0px 1px #007C16",
      },
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#007C16"
        : isFocused
        ? "rgba(0, 124, 22, 0.2)"
        : base.backgroundColor,
      color: isSelected ? "white" : base.color,
      "&:active": {
        backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
      },
    }),
  };

  return (
    <>
      <div className="text-and-filter-div">
        <span>
          Top Donations in {selectedMonth.label} {selectedYear}:{" "}
          <span style={{ color: "#007C16" }}>
            {topDonationsData && <>{topDonationsData.currencySymbol}</>}
            {sum}
          </span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Month, Year and Top Records</h4>
          <div className="month-summary-filter-form">
            <div className="filter-form-col">
              <label>Month</label>
              <Select
                value={monthOptions.find(
                  (option) => option.value === month.value
                )}
                onChange={(option) => setMonth(option)}
                options={monthOptions}
                styles={styles}
              />
            </div>
            <div className="filter-form-col">
              <label>Year</label>
              <Select
                value={yearOptions.find((option) => option.value === year)}
                onChange={(option) => setYear(option.value)}
                options={yearOptions}
                styles={styles}
              />
            </div>
            <div className="filter-form-col">
              <label>Top Records</label>
              <CustomTextField
                type="number"
                variant="outlined"
                fullWidth
                autoComplete="off"
                value={numberInput}
                onChange={handleNumberChange}
                min={1}
                max={20}
                size="small"
              />
            </div>
            <div className="popup-filter-btn-div">
              <button type="button" onClick={handleMonthAndYearFilter}>
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`custom-table-container${
          topDonationsData && topDonationsData.values.length > 0 ? "" : " flex"
        }`}
      >
        {filteredTopDonationsLoading ? (
          <MetroSpinner
            size={30}
            color="#007C16"
            loading={filteredTopDonationsLoading}
          />
        ) : (
          <>
            {topDonationsError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {topDonationsError}
              </div>
            ) : (
              <>
                {topDonationsData && topDonationsData.values.length > 0 ? (
                  <div className="top-donations-data-row">
                    {topDonationsData.values.map((data, index) => (
                      <div
                        className="top-donations-data-box"
                        key={index}
                        style={{
                          borderTop: `2px solid`,
                          borderTopColor: "#007C16",
                        }}
                      >
                        <h4>
                          {topDonationsData.currencySymbol}
                          {data.amount}
                        </h4>
                        <div className="text-muted mb-0">
                          {data.firstName && <>{data.firstName} </>}
                          {data.lastName && <>{data.lastName}, </>}
                          {data.transactionDate.substring(0, 10)}
                        </div>
                        <div className="text-muted mb-0">
                          {data.channelType}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Data Found">
                      <img src={noDataFound} alt="No Data Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TopDonations;
