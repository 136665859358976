import React from "react";
import { useSelector } from "react-redux";
import Icon from "react-icons-kit";
import { refreshCcw } from "react-icons-kit/feather/refreshCcw";
import CountUp from "react-countup";
import noDataFound from "../../Images/noDataFound.png";
import Tippy from "@tippyjs/react";

const ActiveRecurring = () => {
  // redux state
  const { activeRecurringData, activeRecurringError } = useSelector(
    (state) => state.activeRecurring
  );

  return (
    <div className="col-box bottom-most">
      <div className="icon-with-text">
        <span>Active Recurring</span>
        <Icon icon={refreshCcw} size={36} />
      </div>
      {activeRecurringError ? (
        <div className="error-msg" style={{ marginTop: 0 }}>
          {activeRecurringError}
        </div>
      ) : (
        <>
          {activeRecurringData !== null ? (
            <CountUp
              className="h1"
              start={0}
              end={activeRecurringData}
              duration={2.5}
              decimals={0}
              decimal="."
              prefix=""
              suffix=""
            />
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Data Found">
                <img src={noDataFound} alt="No Data Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ActiveRecurring;
