import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteMedia } from "../../Store/Slices/Sidebar/Media/GetMediaSlice";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const DeleteMediaModal = ({ token, setDeleteMediaModal, deleteMediaObj }) => {
  // redux state
  const { deleteMediaLoading, deleteMediaData } = useSelector(
    (state) => state.media
  );

  // close modal
  const handleCloseModal = () => {
    if (!deleteMediaLoading) {
      setDeleteMediaModal(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // delete media
  const handleDeleteMedia = () => {
    dispatch(deleteMedia({ token, tagNumber: deleteMediaObj.tagNumber })).then(
      (res) => {
        if (res.payload.successMsg) {
          setDeleteMediaModal(false);
          toast.success(`${res.payload.successMsg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          res.payload.axiosError === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        }
      }
    );
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Delete Media</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="delete-consequences-container">
              <p style={{ color: "#DC3545" }}>
                Are you sure you want to delete this media?
              </p>
              <p style={{ color: "#6a6c6f" }}>You cannot undo this action.</p>
            </div>
            {deleteMediaData &&
              deleteMediaData.apiError &&
              deleteMediaData.tagNumber === deleteMediaObj.tagNumber && (
                <div className="error-msg">{deleteMediaData.apiError}</div>
              )}
            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CANCEL
              </button>
              <button
                className="submit delete"
                disabled={deleteMediaLoading}
                onClick={handleDeleteMedia}
              >
                {deleteMediaLoading ? (
                  <MetroSpinner
                    loading={deleteMediaLoading}
                    size={22}
                    color="#fff"
                  />
                ) : (
                  "DELETE"
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DeleteMediaModal;
