import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

export const getFollowers = createAsyncThunk(
  "noOfFollowers/getFollowers",
  async (adminToken) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${adminToken}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/numberOfFollowers`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const noOfFollowersSlice = createSlice({
  name: "noOfFollowers",
  initialState: {
    noOfFollowersLoading: false,
    noOfFollowersData: null,
    noOfFollowersError: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFollowers.pending, (state) => {
        state.noOfFollowersLoading = true;
        state.noOfFollowersData = null;
        state.noOfFollowersError = null;
      })
      .addCase(getFollowers.fulfilled, (state, action) => {
        state.noOfFollowersLoading = false;
        state.noOfFollowersData = action.payload;
        state.noOfFollowersError = null;
      })
      .addCase(getFollowers.rejected, (state, action) => {
        state.noOfFollowersLoading = false;
        state.noOfFollowersData = null;
        state.noOfFollowersError = action.error.message;
      });
  },
});

export default noOfFollowersSlice.reducer;
