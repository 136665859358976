/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/RecurringPayments.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getRecurringSummaryData,
  getAllData,
  filterRecurringRecords,
  readDeclinedTransactions,
  filterDeclinedRecords,
} from "../../Store/Slices/Sidebar/RecurringPayments/RecurringPaymentsSlice";
import { getNodes } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { MetroSpinner } from "react-spinners-kit";
import { Toaster } from "react-hot-toast";
import Icon from "react-icons-kit";
import { filter } from "react-icons-kit/feather/filter";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import { grid } from "react-icons-kit/feather/grid";
import { list } from "react-icons-kit/feather/list";
import Tippy from "@tippyjs/react";
import axios from "axios";
import hostName from "../../config";
import RecurringSummary from "../Recurring/RecurringSummary";
import RecurringContainer from "../Recurring/RecurringContainer";
import DeclinedRecurringsTable from "../Recurring/DeclinedRecurringsTable";
import RecurringDetailsPopup from "../Recurring/RecurringDetailsPopup";
import ChangeAmountPopup from "../Recurring/ChangeAmountPopup";
import CloseRecurringPopup from "../Recurring/CloseRecurringPopup";
import CardTransactionsPopup from "../Transactions/CardTransactionsPopup";
import FilterRecurringModal from "../Recurring/FilterRecurringModal";
import UpdateCardPopup from "../Recurring/UpdateCardPopup";
import FilterDeclinedRecordsModal from "../Recurring/FilterDeclinedRecordsModal";
import SwitchToActivePopup from "../Recurring/SwitchToActivePopup";
import SwitchToHoldPopup from "../Recurring/SwitchToHoldPopup";
import DeclinedTransactionsPopup from "../Recurring/DeclinedTransactionsPopup";
import HistoryNotesPopup from "../Recurring/HistoryNotesPopup";

const RecurringPaymentsComponent = ({ token }) => {
  // redux state
  const {
    recurringSummaryLoading,
    recurringSummaryError,
    recurringPaymentsLoading,
    recurringPaymentsError,
    declinedRecurringsLoading,
    declinedRecurringsError,
  } = useSelector((state) => state.recurringPayments);

  // states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("10");

  // page size is used in filter modal
  const [pageSize, setPageSize] = useState("10");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // states for list view
  const [listViewCurrentPage, setListViewCurrentPage] = useState(1);
  const [listViewTotalPages, setListViewTotalPages] = useState(1);
  // per page is used outside filter modal
  const [listViewPerPage, setListViewPerPage] = useState("20");

  // page size is used in filter modal
  const [listViewPageSize, setListViewPageSize] = useState("20");
  const handlePerPageInListView = (value) => {
    setListViewPageSize(value);
  };

  //  dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // recurring summary
  useEffect(() => {
    dispatch(getRecurringSummaryData(token))
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // layout
  const [layout, setLayout] = useState("grid");

  // filtered flag
  const [filteredFlag, setFilteredFlag] = useState(null);

  // filtered params
  const [filteredParams, setFilteredParams] = useState(null);
  const [listViewFilteredParams, setListViewFilteredParams] = useState(null);

  // filter modal
  const [filterRecurringModal, setFilterRecurringModal] = useState(false);
  const [filterListViewModal, setFilterListViewModal] = useState(false);

  // get layouts data
  useEffect(() => {
    if (layout === "grid") {
      if (!filteredParams) {
        dispatch(
          getAllData({
            token,
            page: 1,
            pageSize: "10",
            by: "email",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          filterRecurringRecords({
            token,
            page: 1,
            pageSize: filteredParams.pageSize,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setTotalPages(res.payload.links.lastPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
          }
        });
      }
    } else if (layout === "list") {
      if (!listViewFilteredParams) {
        // get all listview data
        dispatch(
          readDeclinedTransactions({
            token,
            page: 1,
            pageSize: "20",
            order: "asc",
            by: "email",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          filterDeclinedRecords({
            token,
            page: 1,
            pageSize: listViewFilteredParams.pageSize,
            order: listViewFilteredParams.order,
            apiData: listViewFilteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  }, [layout, filteredFlag, filteredParams, listViewFilteredParams]);

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // entity selected list view
  const [entitySelectedListView, setEntitySelectedListView] = useState([]);
  const handleSelectChangeListView = (selectedOptions) => {
    setEntitySelectedListView(selectedOptions);
  };

  // all api options data states
  const [entityOptions, setEntityOptions] = useState([]);
  const [listViewEntityOptions, setListViewEntityOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [allOptionsLoading, setAllOptionsLoading] = useState(true);
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    Promise.all([
      axios.get(
        `${hostName}api/admin/v1/lov?listName=recurringFilter`,
        axiosConfig
      ),
      axios.get(
        `${hostName}api/admin/v1/lov?listName=recurringDeclinedFilter`,
        axiosConfig
      ),
      axios.get(`${hostName}api/v1/public/country`),
      axios.get(`${hostName}api/v1/public/state?countryCode=US`),
      axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
      dispatch(getNodes(token)),
    ])
      .then(
        ([
          gridRes,
          listViewRes,
          countriesRes,
          usaStatesRes,
          provincesRes,
          campaignRes,
        ]) => {
          // Handle filters
          setEntityOptions(gridRes.data.data);
          setListViewEntityOptions(listViewRes.data.data);

          // Handle countries
          const countryOptions = countriesRes.data.map((country) => ({
            value: country.code,
            label: country.name,
          }));
          setCountries(countryOptions);

          // Handle USA states
          const usaStatesOptions = usaStatesRes.data.map((state) => ({
            value: state.code,
            label: state.name,
          }));
          setUsaStates(usaStatesOptions);

          // Handle Canada provinces
          const provincesOptions = provincesRes.data.map((province) => ({
            value: province.code,
            label: province.name,
          }));
          setProvinces(provincesOptions);

          // Handle active campaign options
          setCampaignOptions(campaignRes.payload.active_campaign);
        }
      )
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setAllOptionsLoading(false));
  }, []);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  // checking if any loading is true
  const allLoadingStates = [
    recurringSummaryLoading,
    recurringPaymentsLoading,
    declinedRecurringsLoading,
    allOptionsLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allLoadingStates.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadingStates]);

  // filter states recurring payments
  // amount
  const [amount, setAmount] = useState("");
  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue);
  };

  // account id
  const [accountId, setAccountId] = useState("");

  // campaignTag
  const [campaignTag, setCampaignTag] = useState([]);
  const handleCampaignTagChange = (item) => {
    if (campaignTag.includes(item)) {
      setCampaignTag(campaignTag.filter((checkedItem) => checkedItem !== item));
    } else {
      setCampaignTag([...campaignTag, item]);
    }
  };

  // invoice
  const [invoice, setInvoice] = useState("");

  // email
  const [email, setEmail] = useState("");

  // card num
  const [cardNumber, setCardNumber] = useState("");

  // notes
  const [notes, setNotes] = useState("");

  // created by
  const [createdBy, setCreatedBy] = useState("");

  // status
  const [status, setStatus] = useState([]);
  const handleStatusChange = (item) => {
    if (status.includes(item)) {
      setStatus(status.filter((checkedItem) => checkedItem !== item));
    } else {
      setStatus([...status, item]);
    }
  };

  // billing period
  const [billingPeriod, setBillingPeriod] = useState([]);
  const handleBillingPeriodChange = (item) => {
    if (billingPeriod.includes(item)) {
      setBillingPeriod(
        billingPeriod.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setBillingPeriod([...billingPeriod, item]);
    }
  };

  // sort by
  const [sortBy, setSortBy] = useState({
    value: "email",
    label: "Email",
  });

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // declined recurring payments filter states
  // campaignTag
  const [campaignTagListView, setCampaignTagListView] = useState([]);
  const handleCampaignTagChangeListView = (item) => {
    if (campaignTagListView.includes(item)) {
      setCampaignTagListView(
        campaignTagListView.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setCampaignTagListView([...campaignTagListView, item]);
    }
  };

  // amount
  const [amountListView, setAmountListView] = useState("");
  const handleAmountChangeListview = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmountListView(newValue);
  };

  // invoice
  const [invoiceListView, setInvoiceListView] = useState("");

  // email
  const [emailListView, setEmailListView] = useState("");

  // account id
  const [accountIdListView, setAccountIdListView] = useState("");

  // card
  const [cardholderName, setCardholderName] = useState("");
  const [cardNumberListView, setCardNumberListView] = useState("");

  // transaction date
  const [transactionDate, setTransactionDate] = useState("");

  // msg
  const [message, setMessage] = useState("");

  // sort by
  const [sortByListView, setSortByListView] = useState({
    value: "email",
    label: "Email",
  });

  // order by
  const [orderByListView, setOrderByListView] = useState({
    value: "asc",
    label: "Ascending",
  });

  // recurring details popup
  const [recurringDetailsPopup, setRecurringDetailsPopup] = useState(false);
  const [recurringObj, setRecurringObj] = useState(null);
  const handleRecurringDetailsPopup = (recurring) => {
    setRecurringDetailsPopup(true);
    setRecurringObj(recurring);
  };

  // declined transactions popup
  const [declinedTransactionsPopup, setDeclinedTransactionsPopup] =
    useState(false);
  const [declinedTransactionsObj, setDeclinedTransactionsObj] = useState(null);
  const handleDeclinedTransactionsPopup = (recurring) => {
    setDeclinedTransactionsPopup(true);
    setDeclinedTransactionsObj(recurring);
  };

  // history notes popup
  const [historyNotesPopup, setHistoryNotesPopup] = useState(false);
  const [historyNotesObj, setHistoryNotesObj] = useState(null);
  const handleHistoryNotesPopup = (recurring) => {
    setHistoryNotesPopup(true);
    setHistoryNotesObj(recurring);
  };

  // update card popup
  const [updateCardPopup, setUpdateCardPopup] = useState(false);
  const [updateCardObj, setUpdateCardObj] = useState(null);
  const handleUpdateCardPopup = (recurring) => {
    setUpdateCardPopup(true);
    setUpdateCardObj(recurring);
  };

  // change amount popup
  const [changeAmountPopup, setChangeAmountPopup] = useState(false);
  const [changeAmountObj, setChangeAmountObj] = useState(null);
  const handleChangeAmountPopup = (recurring) => {
    setChangeAmountPopup(true);
    setChangeAmountObj(recurring);
  };

  // close recurring popup
  const [closeRecurringPopup, setCloseRecurringPopup] = useState(false);
  const [closeRecurringObj, setCloseRecurringObj] = useState(null);
  const handleCloseRecurringPopup = (recurring) => {
    setCloseRecurringPopup(true);
    setCloseRecurringObj(recurring);
  };

  // switch to active popup
  const [switchToActivePopup, setSwitchToActivePopup] = useState(false);
  const [switchToActiveObj, setSwitchToActiveObj] = useState(null);
  const handleSwitchToActivePopup = (recurring) => {
    setSwitchToActivePopup(true);
    setSwitchToActiveObj(recurring);
  };

  // switch to hold popup
  const [switchToHoldPopup, setSwitchToHoldPopup] = useState(false);
  const [switchToHoldObj, setSwitchToHoldObj] = useState(null);
  const handleSwitchToHoldPopup = (recurring) => {
    setSwitchToHoldPopup(true);
    setSwitchToHoldObj(recurring);
  };

  // new recurring modal
  const [cardTransactionsPopup, setCardTransactionsPopup] = useState(false);

  // reset filters
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilteredParams(null);
      setFilteredFlag(null);
      setFilterRecurringModal(false);
    }
    setEntitySelected([]);
    setCampaignTag([]);
    setAmount("");
    setAccountId("");
    setEmail("");
    setInvoice("");
    setCardNumber("");
    setNotes("");
    setCreatedBy("");
    setBillingPeriod([]);
    setStatus([]);
    setPageSize(perPage);
    setSortBy({
      value: "email",
      label: "Email",
    });
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset filter list view
  const handleListViewResetFilter = () => {
    if (listViewFilteredParams) {
      setLoadings(true);
      setListViewFilteredParams(null);
      setFilterListViewModal(false);
    }
    setEntitySelectedListView([]);
    setCampaignTagListView([]);
    setAmountListView("");
    setAccountId("");
    setInvoiceListView("");
    setEmailListView("");
    setCardholderName("");
    setCardNumber("");
    setTransactionDate("");
    setMessage("");
    setListViewPageSize(listViewPerPage);
    setSortByListView({
      value: "email",
      label: "Email",
    });
    setOrderByListView({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset entities
  const resetEntities = () => {
    setEntitySelected([]);
    setCampaignTag([]);
    setAmount("");
    setAccountId("");
    setEmail("");
    setInvoice("");
    setCardNumber("");
    setNotes("");
    setCreatedBy("");
    setBillingPeriod([]);
    setStatus([]);
    setPageSize(perPage);
    setSortBy({
      value: "email",
      label: "Email",
    });
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset entities list view
  const resetEntitiesListView = () => {
    setEntitySelectedListView([]);
    setCampaignTagListView([]);
    setAmountListView("");
    setAccountIdListView("");
    setEmailListView("");
    setInvoiceListView("");
    setCardNumberListView("");
    setCardholderName("");
    setTransactionDate("");
    setMessage("");
    setListViewPageSize(listViewPerPage);
    setSortByListView({
      value: "email",
      label: "Email",
    });
    setOrderByListView({
      value: "asc",
      label: "Ascending",
    });
  };

  // one time
  const [oneTime] = useState(false);

  return (
    <>
      <Toaster />
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              {/* show recurring summary only on grid view */}
              {layout === "grid" && (
                <>
                  <h6>Recurring Summary</h6>
                  {recurringSummaryError ? (
                    <div className="error-msg" style={{ width: "fit-content" }}>
                      {recurringSummaryError}
                    </div>
                  ) : (
                    <RecurringSummary
                      filteredFlag={filteredFlag}
                      setFilteredFlag={setFilteredFlag}
                      layout={layout}
                      setLoadings={setLoadings}
                      setFilteredParams={setFilteredParams}
                      resetEntities={resetEntities}
                    />
                  )}
                </>
              )}

              {/* module heading and buttons */}
              <div className="media-heading-button-container">
                {/* heading */}
                <h6>
                  {layout === "grid"
                    ? "Recurring Payments"
                    : "Declined Recurring Payments"}
                </h6>

                {/* buttons container */}
                <div className="add-and-filter-btns-div">
                  {/* new button */}
                  <div className="action-btns-div">
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setCardTransactionsPopup(true)}
                      style={{
                        opacity: layout === "list" ? 0.5 : 1,
                        pointerEvents: layout === "list" ? "none" : "auto",
                      }}
                    >
                      New Recurring
                    </button>
                  </div>

                  {/* layout and filter/reset container */}
                  <div className="filter-and-reset-btns-div">
                    {/* grid btn */}
                    <Tippy content="Recurring Payments">
                      <button
                        className={`filter-media-modal-btn ${
                          layout === "grid" ? "selected" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          if (layout !== "grid") {
                            setLoadings(true);
                            setLayout("grid");
                            setListViewFilteredParams(null);
                            resetEntitiesListView();
                          }
                        }}
                      >
                        <Icon icon={grid} size={24} />
                      </button>
                    </Tippy>

                    {/* list btn */}
                    <Tippy content="Declined Recurring Payments">
                      <button
                        className={`filter-media-modal-btn reset ${
                          layout === "list" ? "selected" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          if (layout !== "list") {
                            setLoadings(true);
                            setLayout("list");
                            setFilteredParams(null);
                            resetEntities();
                            setFilteredFlag(null);
                          }
                        }}
                      >
                        <Icon icon={list} size={24} />
                      </button>
                    </Tippy>

                    {/* recurring payments filter and reset button */}
                    {layout === "grid" && (
                      <>
                        <Tippy content="Filter Records">
                          <button
                            className="filter-media-modal-btn reset"
                            type="button"
                            onClick={() => setFilterRecurringModal(true)}
                          >
                            <Icon icon={filter} size={24} />
                          </button>
                        </Tippy>
                        {filteredParams && (
                          <Tippy content="Reset Records">
                            <button
                              className="filter-media-modal-btn reset"
                              type="button"
                              onClick={handleResetFilter}
                            >
                              <Icon icon={refreshCw} size={24} />
                            </button>
                          </Tippy>
                        )}
                      </>
                    )}

                    {/* declined recurring filter and reset button */}
                    {layout === "list" && (
                      <>
                        <Tippy content="Filter Records">
                          <button
                            className="filter-media-modal-btn reset"
                            type="button"
                            onClick={() => setFilterListViewModal(true)}
                          >
                            <Icon icon={filter} size={24} />
                          </button>
                        </Tippy>
                        {listViewFilteredParams && (
                          <Tippy content="Reset Records">
                            <button
                              className="filter-media-modal-btn reset"
                              type="button"
                              onClick={handleListViewResetFilter}
                            >
                              <Icon icon={refreshCw} size={24} />
                            </button>
                          </Tippy>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* grid layout */}
              {layout === "grid" && (
                <>
                  {recurringPaymentsError ? (
                    <div className="error-msg" style={{ width: "fit-content" }}>
                      {recurringPaymentsError}
                    </div>
                  ) : (
                    <div className="media-row">
                      <RecurringContainer
                        token={token}
                        filteredParams={filteredParams}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                        setTotalPages={setTotalPages}
                        setPageSize={setPageSize}
                        handleRecurringDetailsPopup={
                          handleRecurringDetailsPopup
                        }
                        handleUpdateCardPopup={handleUpdateCardPopup}
                        handleChangeAmountPopup={handleChangeAmountPopup}
                        handleCloseRecurringPopup={handleCloseRecurringPopup}
                        handleSwitchToActivePopup={handleSwitchToActivePopup}
                        handleSwitchToHoldPopup={handleSwitchToHoldPopup}
                        handleDeclinedTransactionsPopup={
                          handleDeclinedTransactionsPopup
                        }
                        handleHistoryNotesPopup={handleHistoryNotesPopup}
                        setLoadings={setLoadings}
                      />
                    </div>
                  )}
                </>
              )}

              {/* list layout */}
              {layout === "list" && (
                <>
                  {declinedRecurringsError ? (
                    <div className="error-msg" style={{ width: "fit-content" }}>
                      {declinedRecurringsError}
                    </div>
                  ) : (
                    <DeclinedRecurringsTable
                      token={token}
                      listViewFilteredParams={listViewFilteredParams}
                      listViewPerPage={listViewPerPage}
                      setListViewPerPage={setListViewPerPage}
                      listViewCurrentPage={listViewCurrentPage}
                      setListViewCurrentPage={setListViewCurrentPage}
                      listViewTotalPages={listViewTotalPages}
                      setListViewTotalPages={setListViewTotalPages}
                      setListViewPageSize={setListViewPageSize}
                      setLoadings={setLoadings}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* recurring details */}
      {recurringDetailsPopup && (
        <RecurringDetailsPopup
          token={token}
          recurringObj={recurringObj}
          setRecurringDetailsPopup={setRecurringDetailsPopup}
        />
      )}
      {/* declined transactions */}
      {declinedTransactionsPopup && (
        <DeclinedTransactionsPopup
          setDeclinedTransactionsPopup={setDeclinedTransactionsPopup}
          declinedTransactionsObj={declinedTransactionsObj}
        />
      )}
      {/* history notes */}
      {historyNotesPopup && (
        <HistoryNotesPopup
          setHistoryNotesPopup={setHistoryNotesPopup}
          historyNotesObj={historyNotesObj}
        />
      )}
      {/* update card */}
      {updateCardPopup && (
        <UpdateCardPopup
          token={token}
          setUpdateCardPopup={setUpdateCardPopup}
          updateCardObj={updateCardObj}
        />
      )}
      {/* change amount */}
      {changeAmountPopup && (
        <ChangeAmountPopup
          token={token}
          setChangeAmountPopup={setChangeAmountPopup}
          changeAmountObj={changeAmountObj}
        />
      )}
      {/* close recurring popup */}
      {closeRecurringPopup && (
        <CloseRecurringPopup
          token={token}
          setCloseRecurringPopup={setCloseRecurringPopup}
          closeRecurringObj={closeRecurringObj}
        />
      )}
      {/* switch to active popup */}
      {switchToActivePopup && (
        <SwitchToActivePopup
          token={token}
          setSwitchToActivePopup={setSwitchToActivePopup}
          switchToActiveObj={switchToActiveObj}
        />
      )}
      {/* switch to hold popup */}
      {switchToHoldPopup && (
        <SwitchToHoldPopup
          token={token}
          setSwitchToHoldPopup={setSwitchToHoldPopup}
          switchToHoldObj={switchToHoldObj}
        />
      )}
      {/* new recurring */}
      {cardTransactionsPopup && (
        <CardTransactionsPopup
          token={token}
          setCardTransactionsPopup={setCardTransactionsPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
          oneTime={oneTime}
        />
      )}
      {/* filter recurring popup */}
      {filterRecurringModal && (
        <FilterRecurringModal
          setFilterRecurringModal={setFilterRecurringModal}
          setFilteredParams={setFilteredParams}
          setLoadings={setLoadings}
          handleResetFilter={handleResetFilter}
          setFilteredFlag={setFilteredFlag}
          entityOptions={entityOptions}
          entitySelected={entitySelected}
          handleSelectChange={handleSelectChange}
          amount={amount}
          handleAmountChange={handleAmountChange}
          accountId={accountId}
          setAccountId={setAccountId}
          email={email}
          setEmail={setEmail}
          invoice={invoice}
          setInvoice={setInvoice}
          campaignTag={campaignTag}
          handleCampaignTagChange={handleCampaignTagChange}
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          notes={notes}
          setNotes={setNotes}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          billingPeriod={billingPeriod}
          handleBillingPeriodChange={handleBillingPeriodChange}
          status={status}
          handleStatusChange={handleStatusChange}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          pageSize={pageSize}
          handlePerPage={handlePerPage}
        />
      )}
      {/* filter declined recurrings popup */}
      {filterListViewModal && (
        <FilterDeclinedRecordsModal
          setFilterListViewModal={setFilterListViewModal}
          setListViewFilteredParams={setListViewFilteredParams}
          setLoadings={setLoadings}
          handleListViewResetFilter={handleListViewResetFilter}
          listViewEntityOptions={listViewEntityOptions}
          entitySelectedListView={entitySelectedListView}
          handleSelectChangeListView={handleSelectChangeListView}
          amountListView={amountListView}
          handleAmountChangeListview={handleAmountChangeListview}
          campaignTagListView={campaignTagListView}
          handleCampaignTagChangeListView={handleCampaignTagChangeListView}
          emailListView={emailListView}
          setEmailListView={setEmailListView}
          invoiceListView={invoiceListView}
          setInvoiceListView={setInvoiceListView}
          accountIdListView={accountIdListView}
          setAccountIdListView={setAccountIdListView}
          cardholderName={cardholderName}
          setCardholderName={setCardholderName}
          cardNumberListView={cardNumberListView}
          setCardNumberListView={setCardNumberListView}
          transactionDate={transactionDate}
          setTransactionDate={setTransactionDate}
          message={message}
          setMessage={setMessage}
          sortByListView={sortByListView}
          setSortByListView={setSortByListView}
          orderByListView={orderByListView}
          setOrderByListView={setOrderByListView}
          listViewPageSize={listViewPageSize}
          handlePerPageInListView={handlePerPageInListView}
        />
      )}
    </>
  );
};

export default RecurringPaymentsComponent;
