import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  filterChannels,
  getChannelsData,
} from "../../Store/Slices/Sidebar/Channels/ChannelsSlice";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { edit } from "react-icons-kit/feather/edit";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const Channels = ({
  token,
  filteredParams,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
  setPageSize,
  handleUpdateChannelPopup,
  handleChannelDetailsPopup,
  setLoadings,
}) => {
  // redux state
  const { channelsData } = useSelector((state) => state.channels);

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "50",
      label: "50",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterChannels({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getChannelsData({
            token,
            page: 1,
            pageSize: value,
            order: "asc",
            by: "payment_processor",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterChannels({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getChannelsData({
            token,
            page: value,
            pageSize: perPage,
            order: "asc",
            by: "payment_processor",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  return (
    <>
      {channelsData && channelsData.data.length > 0 ? (
        <>
          {channelsData.data.map((channel) => (
            <div
              key={channel.tagNumber}
              className="channels-card"
              style={{
                borderTop:
                  channel.status === true
                    ? "3px solid #007c16"
                    : "3px solid #f2b92c",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleChannelDetailsPopup(channel);
              }}
            >
              <div className="edit-icon">
                <Tippy content="Edit Channel">
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUpdateChannelPopup(channel);
                    }}
                  >
                    <Icon icon={edit} size={30} />
                  </span>
                </Tippy>
              </div>

              <div className="section">
                <div className="section-item">
                  {channel.organizationDefinedName}
                </div>
                <div className="section-item">{channel.type}</div>
                <div className="section-item green">{channel.tagNumber}</div>
              </div>

              <div className="section">
                <div className="section-item">{channel.paymentProcessor}</div>
                <div className="section-item">
                  {channel.serialNumber !== ""
                    ? channel.serialNumber
                    : "\u200B"}
                </div>
                <div className="section-item">
                  {channel.userName !== "" ? channel.userName : "\u200B"}
                </div>
              </div>

              <div className="section">
                <div className="section-item green">
                  {channel.currencySymbol}
                  {channel.raisedAmount}
                </div>
                <div className="section-item">
                  {channel.installDate !== "" ? channel.installDate : "\u200B"}
                </div>
                <div className="section-item">
                  {channel.passcode !== "" ? channel.passcode : "\u200B"}
                </div>
              </div>

              <div className="section last">
                <div className="model-image">
                  <img src={channel.modelImage} alt="model" />
                </div>
              </div>
            </div>
          ))}
          <div className="per-page-and-pagination">
            <div className="per-page">
              <label>Records per page</label>
              <Select
                value={perPageOptions.find(
                  (option) => option.value === String(perPage)
                )}
                onChange={(option) => handlePerPage(option.value)}
                options={perPageOptions}
                styles={styles}
                menuPlacement="top"
              />
            </div>
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No Channels Found">
            <img src={noDataFound} alt="No Channels Found" />
          </Tippy>
        </div>
      )}
    </>
  );
};

export default Channels;
