import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getReceiptedDonations = createAsyncThunk(
  "receiptedDonations/getReceiptedDonations",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/receipted?year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredReceiptedDonations = createAsyncThunk(
  "filteredReceiptedDonations/getFilteredReceiptedDonations",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/receipted?year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const receiptedDonationsSlice = createSlice({
  name: "receiptedDonations",
  initialState: {
    receiptedDonationsLoading: false,
    receiptedDonationsData: null,
    receiptedDonationsError: null,
    filteredReceiptedDonationsLoading: false,
  },
  extraReducers: (builder) => {
    builder
      // first render
      .addCase(getReceiptedDonations.pending, (state) => {
        state.receiptedDonationsLoading = true;
        state.receiptedDonationsData = null;
        state.receiptedDonationsError = null;
      })
      .addCase(getReceiptedDonations.fulfilled, (state, action) => {
        state.receiptedDonationsLoading = false;
        state.receiptedDonationsData = action.payload;
        state.receiptedDonationsError = null;
      })
      .addCase(getReceiptedDonations.rejected, (state, action) => {
        state.receiptedDonationsLoading = false;
        state.receiptedDonationsData = null;
        state.receiptedDonationsError = action.error.message;
      })
      // filter render
      .addCase(getFilteredReceiptedDonations.pending, (state) => {
        state.filteredReceiptedDonationsLoading = true;
        state.receiptedDonationsData = null;
        state.receiptedDonationsError = null;
      })
      .addCase(getFilteredReceiptedDonations.fulfilled, (state, action) => {
        state.filteredReceiptedDonationsLoading = false;
        state.receiptedDonationsData = action.payload;
        state.receiptedDonationsError = null;
      })
      .addCase(getFilteredReceiptedDonations.rejected, (state, action) => {
        state.filteredReceiptedDonationsLoading = false;
        state.receiptedDonationsData = null;
        state.receiptedDonationsError = action.error.message;
      });
  },
});

export default receiptedDonationsSlice.reducer;
