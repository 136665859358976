import React from "react";
import { useSelector } from "react-redux";

const Lifetime = () => {
  // redux state
  const { donorLifetimeData, donorLifetimeError } = useSelector(
    (state) => state.donorHighlights
  );

  return (
    <>
      <div className="box-header">
        <span>Lifetime</span>
      </div>
      <div className="lifetime-content-wrapper">
        {donorLifetimeError ? (
          <div className="error-msg" style={{ marginTop: 0 }}>
            {donorLifetimeError}
          </div>
        ) : (
          <>
            {donorLifetimeData && (
              <>
                <div className="data-item">
                  <div className="data-label">Max Contribution</div>
                  <div className="data-value">
                    {donorLifetimeData.currencySymbol}
                    {donorLifetimeData.maxContribution}
                  </div>
                </div>
                <div className="data-item">
                  <div className="data-label">Min Contribution</div>
                  <div className="data-value">
                    {donorLifetimeData.currencySymbol}
                    {donorLifetimeData.minContribution}
                  </div>
                </div>
                <div className="data-item">
                  <div className="data-label">Number of Contributions</div>
                  <div className="data-value">
                    {donorLifetimeData.numberOfContribution}
                  </div>
                </div>
                <div className="data-item">
                  <div className="data-label">Total Contribution</div>
                  <div className="data-value">
                    {donorLifetimeData.currencySymbol}
                    {donorLifetimeData.totalContribution}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Lifetime;
