import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredReceiptedDonations } from "../../Store/Slices/Sidebar/Analytics/ReceiptedDonationsSlice";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import { minus } from "react-icons-kit/feather/minus";
import noDataFound from "../../Images/noDataFound.png";
import {
  MDBBadge,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";

// generate colors
function generateColors(length) {
  const colors = [];

  // Define the colors in the gradient
  const gradientColors = [
    "#34495e",
    "#9b59b6",
    "#3498db",
    "#62cb31",
    "#ffb606",
    "#e67e22",
    "#e74c3c",
    "#c0392b",
    "#175935",
    "#1f9d61",
    "#cc2473",
    "#d828c9",
    "#453095",
    "#9100ff",
    "#884a39",
    "#c38154",
  ];

  for (let i = 0; i < length; i++) {
    const index = i % gradientColors.length;
    colors.push(gradientColors[index]);
  }

  return colors;
}

const ReceiptedDonations = ({ token }) => {
  // redux state
  const {
    filteredReceiptedDonationsLoading,
    receiptedDonationsData,
    receiptedDonationsError,
  } = useSelector((state) => state.receiptedDonations);

  // Calculate the total sum of amounts
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (receiptedDonationsData && receiptedDonationsData.values.length > 0) {
      const totalSum = receiptedDonationsData.values.reduce(
        (sum, obj) => sum + obj.amount,
        0
      );
      const roundedSum = totalSum.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [receiptedDonationsData]);

  // progress bars colors
  const [progressColors, setProgressColors] = useState([]);
  useEffect(() => {
    if (receiptedDonationsData && receiptedDonationsData.values.length > 0) {
      const colors = generateColors(receiptedDonationsData.values.length);
      setProgressColors(colors);
    } else {
      setProgressColors([]);
    }
  }, [receiptedDonationsData]);

  // popup state
  const [popup, setPopup] = useState(false);

  // year state
  const [year, setYear] = useState(new Date().getFullYear().toString());

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }
  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle year change
  const handleYearChange = (value) => {
    if (value !== year) {
      setPopup(false);
      setYear(value);
      const singleObj = {
        token,
        year: value,
      };
      dispatch(getFilteredReceiptedDonations(singleObj))
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    }
  };

  // custom styles
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#e1e1e9",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#b1b1b9",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
      boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
      "&:hover": {
        border: "1px solid #007C16",
        boxShadow: "0px 0px 1px #007C16",
      },
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#007C16"
        : isFocused
        ? "rgba(0, 124, 22, 0.2)"
        : base.backgroundColor,
      color: isSelected ? "white" : base.color,
      "&:active": {
        backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
      },
    }),
  };

  return (
    <>
      <div className="text-and-filter-div">
        <span>
          Total Amount <Icon icon={minus} size={12} /> {year}{" "}
          <Icon icon={minus} size={12} />{" "}
          <span style={{ color: "#007C16" }}>
            {receiptedDonationsData && (
              <>{receiptedDonationsData.currencySymbol}</>
            )}
            {sum}
          </span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Year</h4>
          <Select
            value={yearOptions.find((option) => option.value === year)}
            onChange={(option) => handleYearChange(option.value)}
            options={yearOptions}
            styles={styles}
          />
        </div>
      </div>
      <div
        className={`custom-table-container${
          receiptedDonationsData && receiptedDonationsData.values.length > 0
            ? " special-height-case"
            : " flex"
        }`}
      >
        {filteredReceiptedDonationsLoading ? (
          <MetroSpinner
            size={30}
            color="#007C16"
            loading={filteredReceiptedDonationsLoading}
          />
        ) : (
          <>
            {receiptedDonationsError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {receiptedDonationsError}
              </div>
            ) : (
              <>
                {receiptedDonationsData &&
                receiptedDonationsData.values.length > 0 ? (
                  <MDBTable align="middle">
                    <MDBTableHead className="thead">
                      <tr>
                        <th scope="col">Receipt Type</th>
                        <th scope="col">Amount</th>
                        <th scope="col">No. of transactions</th>
                        <th scope="col" style={{ width: "25%" }}>
                          Progress
                        </th>
                        <th scope="col">Percentage</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {receiptedDonationsData.values.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <p className="fw-bold mb-1">{data.receiptedType}</p>
                          </td>
                          <td>
                            <p
                              className="fw-normal mb-1"
                              style={{ fontSize: "16px" }}
                            >
                              {receiptedDonationsData.currencySymbol}
                              {data.amount}
                            </p>
                          </td>
                          <td>
                            <MDBBadge color="success" pill>
                              {data.numberOfTransaction}
                            </MDBBadge>
                          </td>
                          <td>
                            <div className="progress-trail">
                              <div
                                className="table-progressbar"
                                style={{
                                  width: `${data.percentage}%`,
                                  backgroundColor: progressColors[index],
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <MDBBtn
                              color="link"
                              rounded
                              size="sm"
                              style={{
                                fontSize: "16px",
                                whiteSpace: "nowrap",
                                backgroundColor: progressColors[index],
                                color: "#fff",
                                width: "85px",
                              }}
                            >
                              {data.percentage} %
                            </MDBBtn>
                          </td>
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Data Found">
                      <img src={noDataFound} alt="No Data Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ReceiptedDonations;
