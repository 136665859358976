import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { edit } from "react-icons-kit/feather/edit";
import { trash2 } from "react-icons-kit/feather/trash2";
import { check } from "react-icons-kit/feather/check";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import toast from "react-hot-toast";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const AmountsDrawer = ({ setAmountsDrawer, amounts, setAmounts }) => {
  // form state
  const [amountValue, setAmountValue] = useState("");

  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmountValue(newValue);
  };

  // form submit
  const handleAddAmount = (e) => {
    e.preventDefault();
    if (amountValue && Number(amountValue) >= 0) {
      if (!amounts.includes(Number(amountValue))) {
        // Add the new amount and sort the array in ascending order
        const updatedAmounts = [...amounts, Number(amountValue)].sort(
          (a, b) => a - b
        );
        setAmounts(updatedAmounts);
        setAmountValue("");
      } else {
        toast.error("This amount is already present in the list", {
          position: "top-center",
          duration: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  // remove amount from amounts
  const removeAmount = (index) => {
    const newAmounts = amounts.filter((_, i) => i !== index);
    setAmounts(newAmounts);
  };

  // edit
  const [amountToEdit, setAmountToEdit] = useState(null);
  const [amountIndex, setAmountIndex] = useState(null);

  const triggerEditAmount = (amount, index) => {
    setAmountToEdit(amount);
    setAmountIndex(index);
    setAmountValue("");
  };

  const handleEditAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmountToEdit(newValue);
  };

  const handleUpdateAmount = (e) => {
    e.preventDefault();
    // Update the amount at the matching index and then sort the array
    setAmounts((prevAmounts) => {
      // Update the value at the matched index
      const updatedAmounts = prevAmounts.map((amount, index) =>
        index === amountIndex ? amountToEdit : amount
      );

      // Sort the array in ascending order
      return updatedAmounts.sort((a, b) => a - b);
    });
    setAmountToEdit(null);
    setAmountIndex(null);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Add Amounts</div>
            <div
              className="cancel-widget-update-icon"
              onClick={() => setAmountsDrawer(false)}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form
              style={{ marginTop: 10 + "px" }}
              className="update-media-form"
              onSubmit={
                amountToEdit !== null ? handleUpdateAmount : handleAddAmount
              }
            >
              <div
                className="input-margins-div"
                style={{ marginBottom: "8px" }}
              >
                <label>Amount</label>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={amountToEdit !== null ? amountToEdit : amountValue}
                    onChange={(e) =>
                      amountToEdit !== null
                        ? handleEditAmountChange(e)
                        : handleAmountChange(e)
                    }
                    inputMode="decimal"
                    step="0.01"
                    title=""
                    required
                    placeholder="Enter Amount"
                    helperText={
                      amountValue !== "" && amountValue < 0
                        ? "Value must not be less than 0"
                        : ""
                    }
                    error={amountValue !== "" && amountValue < 0}
                  />
                  {amountToEdit !== null ? (
                    <div style={{ display: "flex" }}>
                      <button
                        type="submit"
                        style={{
                          fontSize: "14px",
                          padding: "8px 12px",
                          marginLeft: "2px",
                          borderRadius: "3px",
                          color: "#fff",
                          background: "#007c16",
                          border: "1px solid #007c16",
                          outline: "none",
                        }}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        style={{
                          fontSize: "14px",
                          padding: "8px 12px",
                          marginLeft: "2px",
                          borderRadius: "3px",
                          color: "#fff",
                          background: "#6b6c70",
                          border: "1px solid #6b6c70",
                          outline: "none",
                        }}
                        onClick={() => {
                          setAmountToEdit(null);
                          setAmountIndex(null);
                        }}
                      >
                        CANCEL
                      </button>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      style={{
                        fontSize: "24px",
                        fontWeight: 600,
                        padding: "0 12px",
                        marginLeft: "2px",
                        borderRadius: "3px",
                        color: "#fff",
                        background: "#007c16",
                        border: "1px solid #007c16",
                        outline: "none",
                      }}
                    >
                      +
                    </button>
                  )}
                </div>
              </div>

              {amounts?.length > 0 ? (
                <ul
                  style={{ listStyle: "none", paddingLeft: "0", margin: "0" }}
                >
                  {amounts.map((amt, index) => (
                    <li
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "8px 12px",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f8f9fa",
                      }}
                    >
                      <span style={{ flexGrow: 1, textAlign: "left" }}>
                        {amt}
                      </span>
                      <div>
                        <button
                          type="button"
                          onClick={() => triggerEditAmount(amt, index)}
                          style={{
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            color: "#6b6c70",
                            marginRight: "4px",
                          }}
                        >
                          <Icon icon={edit} size={24} color="#6b6c70" />
                        </button>
                        <button
                          type="button"
                          onClick={() => removeAmount(index)}
                          style={{
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            color: "#dc3545",
                          }}
                        >
                          <Icon icon={trash2} size={24} color="#dc3545" />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="info-msg">
                  No amounts found for your campaign. Use the textbox above to
                  add a amount
                </div>
              )}

              <div className="reset-and-filter-div" style={{ width: "100%" }}>
                <button
                  type="button"
                  className="reset-fields-btn"
                  onClick={() => setAmountsDrawer(false)}
                  style={{ width: "100%", background: "#007c16" }}
                >
                  <Icon icon={check} size={24} style={{ marginRight: "4px" }} />
                  DONE
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AmountsDrawer;
