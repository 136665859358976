import React from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { info } from "react-icons-kit/feather/info";

const ScheduleSummary = ({
  filteredFlag,
  setFilteredFlag,
  layout,
  setLayout,
  setLoadings,
  setListViewFilteredParams,
  resetEntities,
}) => {
  // redux state
  const { scheduleSummaryData } = useSelector((state) => state.eCheck);

  // handle card click
  const handleFilteredFlag = (flag) => {
    if (filteredFlag !== flag) {
      setLoadings(true);
      setLayout("list");
      resetEntities();
      setFilteredFlag(flag);
      setListViewFilteredParams({
        apiData: {
          by: "schedule_status",
          schedule_status: [flag],
        },
        order: "asc",
        pageSize: "20",
      });
    }
  };

  return (
    <div className="schedule-summary-row">
      {scheduleSummaryData && scheduleSummaryData.length > 0 ? (
        <>
          {scheduleSummaryData.map((data, index) => (
            <div
              className="summary-box"
              key={index}
              onClick={() => handleFilteredFlag(data.filteredFlag)}
              style={{
                border:
                  filteredFlag === data.filteredFlag &&
                  layout === "list" &&
                  "2px solid #007c16",
              }}
            >
              <h4>
                {data.label}
                <Tippy content={data.description}>
                  <span>
                    <Icon icon={info} size={20} />
                  </span>
                </Tippy>
              </h4>
              <p>{data.value}</p>
              <div className="summary-progress-bar-div">
                <div
                  className="summary-progress-bar"
                  style={{
                    width: data.percentage + "%",
                  }}
                ></div>
              </div>
              <img src={data.statusImage} alt="icon" />
            </div>
          ))}
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No eCheck Status Summary Found">
            <img src={noDataFound} alt="No eCheck Status Summary Found" />
          </Tippy>
        </div>
      )}
    </div>
  );
};

export default ScheduleSummary;
