import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredDonorYearly } from "../../../../Store/Slices/Sidebar/Donors/DonorHighlightsSlice";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import Tippy from "@tippyjs/react";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import noDataFound from "../../../../Images/noDataFound.png";

// custom styles
const styles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const ThisYear = ({ token, email }) => {
  // redux state
  const { donorFilteredYearlyLoading, donorYearlyData, donorYearlyError } =
    useSelector((state) => state.donorHighlights);

  // popup state
  const [popup, setPopup] = useState(false);

  // year state
  const [year, setYear] = useState(new Date().getFullYear().toString());

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle year change
  const handleYearChange = (value) => {
    if (year !== value) {
      setPopup(false);
      setYear(value);
      const singleObj = {
        token,
        year: value,
        email,
      };
      dispatch(getFilteredDonorYearly(singleObj))
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    }
  };

  return (
    <>
      <div className="box-header">
        <span>
          Year - <span style={{ color: "#007c16" }}>{year}</span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span
            style={{ cursor: "pointer", color: "#6a6c6f" }}
            onClick={() => setPopup(!popup)}
          >
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Year</h4>
          <Select
            value={yearOptions.find((option) => option.value === year)}
            onChange={(option) => handleYearChange(option.value)}
            options={yearOptions}
            styles={styles}
            menuPortalTarget={document.body} 
          />
        </div>
      </div>
      <div className="lifetime-content-wrapper">
        {donorFilteredYearlyLoading ? (
          <div className="content-wrappr-loader">
            <MetroSpinner
              loading={donorFilteredYearlyLoading}
              size={30}
              color="#007c16"
            />
          </div>
        ) : (
          <>
            {donorYearlyError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {donorYearlyError}
              </div>
            ) : (
              <>
                {donorYearlyData ? (
                  <>
                    <div className="data-item">
                      <div className="data-label">Max Contribution</div>
                      <div className="data-value">
                        {donorYearlyData.currencySymbol}
                        {donorYearlyData.maxContribution}
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-label">Min Contribution</div>
                      <div className="data-value">
                        {donorYearlyData.currencySymbol}
                        {donorYearlyData.minContribution}
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-label">Number of Contributions</div>
                      <div className="data-value">
                        {donorYearlyData.numberOfContribution}
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-label">Total Contribution</div>
                      <div className="data-value">
                        {donorYearlyData.currencySymbol}
                        {donorYearlyData.totalContribution}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="content-wrappr-loader">
                    <Tippy content="No Data Found">
                      <img
                        src={noDataFound}
                        alt="No Data Found"
                        width={`100px`}
                        height={`auto`}
                      />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ThisYear;
