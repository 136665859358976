import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { MDBCheckbox } from "mdb-react-ui-kit";
import DatePicker from "react-date-picker";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// multi select animated components
const animatedComponents = makeAnimated();

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const FilterDeclinedRecordsModal = ({
  setFilterListViewModal,
  setListViewFilteredParams,
  setLoadings,
  handleListViewResetFilter,
  listViewEntityOptions,
  entitySelectedListView,
  handleSelectChangeListView,
  amountListView,
  handleAmountChangeListview,
  campaignTagListView,
  handleCampaignTagChangeListView,
  emailListView,
  setEmailListView,
  invoiceListView,
  setInvoiceListView,
  accountIdListView,
  setAccountIdListView,
  cardholderName,
  setCardholderName,
  cardNumberListView,
  setCardNumberListView,
  transactionDate,
  setTransactionDate,
  message,
  setMessage,
  sortByListView,
  setSortByListView,
  orderByListView,
  setOrderByListView,
  listViewPageSize,
  handlePerPageInListView,
}) => {
  // redux nodes
  const { nodesData } = useSelector((state) => state.transactions);

  // close modal
  const handleCloseModal = () => {
    setFilterListViewModal(false);
  };

  // should render fields?
  const shouldRenderAmountField = entitySelectedListView.some(
    (option) => option.value === "amount"
  );

  const shouldRenderCampaignTagField = entitySelectedListView.some(
    (option) => option.value === "campaign_tag"
  );

  const shouldRenderEmailField = entitySelectedListView.some(
    (option) => option.value === "email"
  );

  const shouldRenderInvoiceField = entitySelectedListView.some(
    (option) => option.value === "invoice"
  );

  const shouldRenderAccountIdField = entitySelectedListView.some(
    (option) => option.value === "account_id"
  );

  const shouldRenderCardholderNameField = entitySelectedListView.some(
    (option) => option.value === "cardholder_name"
  );

  const shouldRenderCardNumberField = entitySelectedListView.some(
    (option) => option.value === "card_number"
  );

  const shouldRenderTransactionDateField = entitySelectedListView.some(
    (option) => option.value === "transaction_date"
  );

  const shouldRenderMessageField = entitySelectedListView.some(
    (option) => option.value === "message"
  );

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // handle transaction date
  const handleTransactionDateChange = (value) => {
    if (value) {
      setTransactionDate(value);
    } else {
      setTransactionDate(null);
    }
  };

  // form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Prepare the data to be sent as API parameters based on the selected options
    const apiData = {};

    // Add 'amount' to the apiData if it's selected
    if (shouldRenderAmountField) {
      apiData.amount = parseFloat(amountListView);
    }

    // Add 'campaign_tag' to the apiData if it's selected
    if (shouldRenderCampaignTagField) {
      apiData.campaign_tag = campaignTagListView;
    }

    // Add 'invoice' to the apiData if it's selected
    if (shouldRenderInvoiceField) {
      apiData.invoice = invoiceListView;
    }

    // Add 'email' to the apiData if it's selected
    if (shouldRenderEmailField) {
      apiData.email = emailListView;
    }

    // Add 'account_id' to the apiData if it's selected
    if (shouldRenderAccountIdField) {
      apiData.account_id = accountIdListView;
    }

    // add cardholder_name
    if (shouldRenderCardholderNameField) {
      apiData.cardholder_name = cardholderName;
    }

    // add card number
    if (shouldRenderCardNumberField) {
      apiData.card_number = cardNumberListView;
    }

    // add transaction date
    if (shouldRenderTransactionDateField) {
      apiData.transaction_date = formatDate(transactionDate);
    }

    // add message
    if (shouldRenderMessageField) {
      apiData.message = message;
    }

    // sort by
    if (sortByListView) {
      apiData.by = sortByListView.value;
    }

    // if amount > 0
    if (shouldRenderAmountField && amountListView > 0) {
      setLoadings(true);
      setFilterListViewModal(false);
      setListViewFilteredParams({
        apiData,
        pageSize: listViewPageSize,
        order: orderByListView.value,
      });
    } else if (!shouldRenderAmountField) {
      setLoadings(true);
      setFilterListViewModal(false);
      setListViewFilteredParams({
        apiData,
        pageSize: listViewPageSize,
        order: orderByListView.value,
      });
    }
  };

  // format date
  const formatDate = (dateObj) => {
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Filter Declined Recurring Payments</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <h6
              style={{
                borderBottom: 1 + "px solid #eaeaea",
                paddingBottom: 5 + "px",
              }}
            >
              Select Fields
            </h6>

            <Select
              isMulti
              components={animatedComponents}
              styles={styles}
              options={listViewEntityOptions}
              onChange={handleSelectChangeListView}
              value={entitySelectedListView}
            />
            <form
              style={{ marginTop: 10 + "px" }}
              className="update-media-form"
              onSubmit={handleFormSubmit}
            >
              {/* amount */}
              {shouldRenderAmountField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "amount"
                      )?.label
                    }
                  </label>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={amountListView}
                    onChange={(e) => handleAmountChangeListview(e)}
                    inputMode="decimal"
                    step="0.01"
                    title=""
                    required
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "amount"
                      )?.label
                    }
                    helperText={
                      amountListView !== "" && amountListView <= 0
                        ? "Value must be greater than 0"
                        : ""
                    }
                    error={amountListView !== "" && amountListView <= 0}
                  />
                </div>
              )}
              {/* campaign_tag */}
              {shouldRenderCampaignTagField && nodesData && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "campaign_tag"
                      )?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {nodesData.campaign_tag.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={campaignTagListView.includes(item.value)}
                          onChange={() =>
                            handleCampaignTagChangeListView(item.value)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* email */}
              {shouldRenderEmailField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "email"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "email"
                      )?.label
                    }
                    required
                    value={emailListView}
                    onChange={(e) => setEmailListView(e.target.value)}
                  />
                </div>
              )}
              {/* invoice */}
              {shouldRenderInvoiceField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "invoice"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "invoice"
                      )?.label
                    }
                    required
                    value={invoiceListView}
                    onChange={(e) => setInvoiceListView(e.target.value)}
                  />
                </div>
              )}
              {/* account_id */}
              {shouldRenderAccountIdField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "account_id"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "account_id"
                      )?.label
                    }
                    required
                    value={accountIdListView}
                    onChange={(e) => setAccountIdListView(e.target.value)}
                  />
                </div>
              )}
              {/* cardholder name */}
              {shouldRenderCardholderNameField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "cardholder_name"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "cardholder_name"
                      )?.label
                    }
                    required
                    value={cardholderName}
                    onChange={(e) => setCardholderName(e.target.value)}
                  />
                </div>
              )}
              {/* card_number */}
              {shouldRenderCardNumberField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "card_number"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "card_number"
                      )?.label
                    }
                    required
                    value={cardNumberListView}
                    onChange={(e) => setCardNumberListView(e.target.value)}
                  />
                </div>
              )}
              {/* transaction date */}
              {shouldRenderTransactionDateField && (
                <div className="input-margins-div">
                  <h6>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "transaction_date"
                      )?.label
                    }
                  </h6>
                  <DatePicker
                    value={transactionDate}
                    onChange={handleTransactionDateChange}
                    clearIcon={false}
                    required
                    showLeadingZeros
                  />
                </div>
              )}
              {/* message */}
              {shouldRenderMessageField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "message"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "message"
                      )?.label
                    }
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              )}
              {/* page size */}
              <div className="input-margins-div">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === listViewPageSize
                  )}
                  onChange={(option) => handlePerPageInListView(option.value)}
                  options={perPageOptions}
                  styles={styles}
                />
              </div>
              {/* sort by */}
              <div className="input-margins-div">
                <label>Sort by</label>
                <Select
                  value={sortByListView}
                  onChange={(option) => setSortByListView(option)}
                  options={listViewEntityOptions}
                  styles={styles}
                  isClearable
                />
              </div>
              {/* order by */}
              <div className="input-margins-div">
                <label>Order by</label>
                <Select
                  value={orderByListView}
                  onChange={(option) => setOrderByListView(option)}
                  options={[
                    { value: "asc", label: "Ascending" },
                    { value: "desc", label: "Descending" },
                  ]}
                  styles={styles}
                />
              </div>
              <div className="reset-and-filter-div">
                <button
                  type="button"
                  className="reset-fields-btn"
                  onClick={handleListViewResetFilter}
                >
                  RESET
                </button>
                <button type="submit" className="filter-records-btn">
                  FILTER
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterDeclinedRecordsModal;
