import React from "react";
import Select from "react-select";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { MetroSpinner } from "react-spinners-kit";
import ReactDatePicker from "react-datepicker";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const CharityDetailsForm = ({
  organization,
  setOrganization,
  countries,
  usaStates,
  provinces,
  handleOrganizationDetailsSubmit,
  setSignaturePopup,
}) => {
  // redux state
  const { updateOrganizationLoading, updateOrganizationData } = useSelector(
    (state) => state.organization
  );

  return (
    <form
      onSubmit={handleOrganizationDetailsSubmit}
      className="update-media-form"
    >
      <h4
        style={{
          borderBottom: 1 + "px solid #e4e4e4",
          paddingBottom: 5 + "px",
          marginBottom: 15 + "px",
        }}
      >
        Profile
      </h4>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Charity Organization</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.charityOrganization}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                charityOrganization: e.target.value,
              }))
            }
            required={organization.charityRegisterationNumber !== ""}
            placeholder="Charity Organization"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Registeration Number</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.charityRegisterationNumber}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                charityRegisterationNumber: e.target.value,
              }))
            }
            placeholder="Registeration Number"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0 signature-col">
          <div className="sign-img-container">
            <img
              src={`data:${organization.signatureImageType};base64,${organization.signatureImageBytes}`}
              alt="signature"
            />
          </div>
          <button
            className="update-sign-popup-btn"
            type="button"
            onClick={() => setSignaturePopup(true)}
            disabled={updateOrganizationLoading}
          >
            Update Signature
          </button>
        </MDBCol>
      </MDBRow>
      <br />
      <h4
        style={{
          borderBottom: 1 + "px solid #e4e4e4",
          paddingBottom: 5 + "px",
          marginBottom: 15 + "px",
        }}
      >
        Address
      </h4>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Street Address</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.charityStreetAddress}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                charityStreetAddress: e.target.value,
              }))
            }
            required={organization.charityRegisterationNumber !== ""}
            placeholder="Street Address"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Unit Number</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.charityUnitNumber}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                charityUnitNumber: e.target.value,
              }))
            }
            placeholder="Unit Number"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>City</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.charityCity}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                charityCity: e.target.value,
              }))
            }
            required={organization.charityRegisterationNumber !== ""}
            placeholder="City"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Country</label>
          <Select
            value={countries.find(
              (option) => option.label === organization.country
            )}
            isDisabled
            styles={styles}
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          {organization.country === "Canada" ? (
            <>
              <label>Province</label>
              <Select
                value={provinces.find(
                  (option) => option.value === organization.charityProvinceState
                )}
                onChange={(option) =>
                  setOrganization((prevOrganization) => ({
                    ...prevOrganization,
                    charityProvinceState: option.value,
                  }))
                }
                options={provinces}
                styles={styles}
                required={organization.charityRegisterationNumber !== ""}
                menuPlacement="top"
                menuPosition="fixed"
              />
            </>
          ) : (
            <>
              {organization.country === "United States" ? (
                <>
                  <label>State</label>
                  <Select
                    value={usaStates.find(
                      (option) =>
                        option.value === organization.charityProvinceState
                    )}
                    onChange={(option) =>
                      setOrganization((prevOrganization) => ({
                        ...prevOrganization,
                        charityProvinceState: option.value,
                      }))
                    }
                    options={usaStates}
                    styles={styles}
                    required={organization.charityRegisterationNumber !== ""}
                    menuPlacement="top"
                    menuPosition="fixed"
                  />
                </>
              ) : (
                <>
                  <label>Province</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={organization.charityProvinceState}
                    onChange={(e) =>
                      setOrganization((prevOrganization) => ({
                        ...prevOrganization,
                        charityProvinceState: e.target.value,
                      }))
                    }
                    required={organization.charityRegisterationNumber !== ""}
                    placeholder="Province"
                  />
                </>
              )}
            </>
          )}
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>
            {organization.country === "United States"
              ? "Zip Code"
              : "Postal Code"}
          </label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.charityPostalZipCode}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                charityPostalZipCode: e.target.value,
              }))
            }
            required={organization.charityRegisterationNumber !== ""}
            placeholder={
              organization.country === "United States"
                ? "Zip Code"
                : "Postal Code"
            }
          />
        </MDBCol>
      </MDBRow>
      <br />
      <h4
        style={{
          borderBottom: 1 + "px solid #e4e4e4",
          paddingBottom: 5 + "px",
          marginBottom: 15 + "px",
        }}
      >
        Supplementary Data
      </h4>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Tax Receipt Start Date</label>
          <ReactDatePicker
            className="edit-media-inputs"
            selected={organization.taxReceiptStartDate}
            onChange={(date) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                taxReceiptStartDate: date,
              }))
            }
            placeholderText="Tax Receipt Start Date"
            dateFormat="yyyy-MM-dd"
            required
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Tax Receipt Footer</label>
          <input
            className="edit-media-inputs"
            value={organization.taxReceiptFooter}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                taxReceiptFooter: e.target.value,
              }))
            }
            required={
              organization.taxReceiptStartDate &&
              organization.taxReceiptStartDate !== ""
            }
            placeholder="Tax Receipt Footer"
          />
        </MDBCol>
      </MDBRow>

      {updateOrganizationData &&
        (updateOrganizationData.apiError ? (
          <div className="error-msg" style={{ width: "fit-content" }}>
            {typeof updateOrganizationData.apiError === "object" ? (
              // Case 1: If apiError is an object
              Object.keys(updateOrganizationData.apiError).map(
                (field, index) => (
                  <p key={index} style={{ marginBottom: 0 }}>
                    {updateOrganizationData.apiError[field][0]}
                  </p>
                )
              )
            ) : (
              // Case 2: If apiError is a simple string
              <>{updateOrganizationData.apiError}</>
            )}
          </div>
        ) : (
          // Case 3: If apiError does not exist, render axiosError
          <>
            {updateOrganizationData && updateOrganizationData.axiosError && (
              <div className="error-msg" style={{ width: "fit-content" }}>
                {updateOrganizationData.axiosError}
              </div>
            )}
          </>
        ))}

      <div className="submit-and-cancel-div">
        <button
          type="submit"
          className="submit addModal"
          disabled={updateOrganizationLoading}
        >
          {updateOrganizationLoading ? (
            <MetroSpinner
              loading={updateOrganizationLoading}
              size={22}
              color="#fff"
            />
          ) : (
            "UPDATE"
          )}
        </button>
      </div>
    </form>
  );
};

export default CharityDetailsForm;
