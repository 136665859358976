import React from "react";
import LeftSide from "../../Components/Common/LeftSide";
import ResetForm from "../../Components/Auth/ResetForm";
import AuthLogo from "../../Components/Common/AuthLogo";
import AppAndPlayStore from "../../Components/Auth/AppAndPlayStore";

export const ResetPassword = () => {
  return (
    <div className="login-background">
      <div className="login-box">
        <LeftSide />
        <div className="rightside">
          <AuthLogo />
          <h4>
            <span className="green">RESET</span> YOUR PASSWORD
          </h4>
          <ResetForm />
        </div>
      </div>
      <AppAndPlayStore />
    </div>
  );
};

export default ResetPassword;
