import React, { useState } from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import noDataFound from "../../Images/noDataFound.png";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Icon from "react-icons-kit";
import { check } from "react-icons-kit/feather/check";

// get organization
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const CrmUsers = ({ handleUpdateModal, handleDeleteModal }) => {
  // state
  const [selectedOrganization] = useState(getOrganization());

  // redux state
  const { usersData, usersError } = useSelector((state) => state.crmUsers);

  return (
    <>
      {usersError ? (
        <div className="error-msg" style={{ width: "fit-content" }}>
          {usersError}
        </div>
      ) : (
        <>
          {usersData && usersData.length > 0 ? (
            <>
              {usersData.map((data) => {
                const organization = data.organizations.find(
                  (org) => org.tagNumber === selectedOrganization.tagNumber
                );
                const status = organization ? organization.status : false;
                return (
                  <div
                    key={data.tagNumber}
                    className="media-box"
                    style={{
                      borderTop: status
                        ? "3px solid #007C16"
                        : "3px solid #f2b92c",
                      background: "#fff",
                    }}
                  >
                    <div className="crm-users-info">
                      <div
                        style={{
                          backgroundColor: data.status ? "#007C16" : "#f2b92c",
                          minWidth: 60 + "px",
                          minHeight: 60 + "px",
                          borderRadius: 50 + "%",
                          color: data.status ? "#fff" : "#000",
                          fontWeight: 600,
                          fontSize: 18 + "px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          letterSpacing: 0.09 + "em",
                        }}
                      >
                        {data.firstName.charAt(0)}
                        {data.lastName.charAt(0)}
                      </div>
                      <div className="user-name-email">
                        <h1 className="media-name">
                          {data.firstName} {data.lastName}
                        </h1>
                        <h4>{data.email}</h4>
                      </div>
                    </div>
                    <div className="media-table-container">
                      <MDBTable align="middle">
                        <MDBTableHead className="media-thead">
                          <tr>
                            <th scope="col">Status</th>
                            <th scope="col">Language</th>
                            <th scope="col">Created</th>
                            <th scope="col">Updated</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody className="media-body">
                          <tr>
                            <td>
                              <div
                                style={{
                                  backgroundColor: status
                                    ? "#007C16"
                                    : "#f2b92c",
                                  width: 40 + "px",
                                  height: 40 + "px",
                                  borderRadius: 50 + "%",
                                  color: status ? "#fff" : "#000",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Icon size={24} icon={check} />
                              </div>
                            </td>
                            <td>
                              <div>{data.language}</div>
                            </td>
                            <td>
                              <p className="text-muted mb-0">
                                {data.createDateTime.split(" ")[0]}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0">
                                {data.updateDateTime.split(" ")[0]}
                              </p>
                            </td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                    <div className="edit-and-delete-media-div">
                      <button
                        className="edit-btn"
                        onClick={() => handleUpdateModal(data)}
                      >
                        Edit
                      </button>
                      <button
                        className="delete-btn"
                        onClick={() => handleDeleteModal(data)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Users Found">
                <img src={noDataFound} alt="No Users Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CrmUsers;
