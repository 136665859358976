import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateWidget } from "../../Store/Slices/Sidebar/Media/GetWidgetsSlice";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const UpdateWidgetsModal = ({ token, setUpdateWidgetsModal, widgetData }) => {
  // redux state
  const { updateWidgetLoading, updateWidgetData } = useSelector(
    (state) => state.widgets
  );

  // close modal
  const handleCloseModal = () => {
    if (!updateWidgetLoading) {
      setUpdateWidgetsModal(false);
    }
  };

  // states
  const [textareaValue, setTextareaValue] = useState(
    widgetData.valueRequired ? widgetData.widgetValue : null
  );
  const [status, setStatus] = useState(widgetData.status);

  // radio change
  const handleStatusChange = () => {
    setStatus((prevStatus) => !prevStatus);
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // update widgets form submit
  const handleUpdateWidgetsForm = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      tagNumber: widgetData.tagNumber,
      status,
      widgetValue: textareaValue,
    };
    dispatch(updateWidget(singleObj)).then((res) => {
      if (res.payload.successMsg) {
        setUpdateWidgetsModal(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Update Widget</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form
              className="update-widgets-form"
              onSubmit={handleUpdateWidgetsForm}
            >
              {widgetData.valueRequired === true && (
                <>
                  <label style={{ marginBottom: 3 + "px" }}>Widget Value</label>
                  <textarea
                    className="custom-textarea"
                    value={textareaValue}
                    onChange={(e) => setTextareaValue(e.target.value)}
                    required
                  ></textarea>
                </>
              )}
              <h6>Status</h6>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={status}
                  onChange={handleStatusChange}
                />
                <span className="slider round"></span>
              </label>
              {updateWidgetData &&
                updateWidgetData.apiError &&
                widgetData.tagNumber === updateWidgetData.tagNumber && (
                  <div className="error-msg">{updateWidgetData.apiError}</div>
                )}
              <div className="update-btn-div">
                <button
                  type="button"
                  className="cancel-widget-update"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="update-widget"
                  disabled={updateWidgetLoading}
                >
                  {updateWidgetLoading ? (
                    <MetroSpinner
                      loading={updateWidgetLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default UpdateWidgetsModal;
