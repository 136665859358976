import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getTenderTypes = createAsyncThunk(
  "tenderTypes/getTenderTypes",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/tenderTypes?year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredTenderTypes = createAsyncThunk(
  "filteredTenderTypes/getFilteredTenderTypes",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/tenderTypes?year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const tenderTypesSlice = createSlice({
  name: "tenderTypes",
  initialState: {
    tenderTypesLoading: false,
    tenderTypesData: null,
    tenderTypesError: null,
    filteredTenderTypesLoading: false,
  },
  extraReducers: (builder) => {
    builder
      // first render
      .addCase(getTenderTypes.pending, (state) => {
        state.tenderTypesLoading = true;
        state.tenderTypesData = null;
        state.tenderTypesError = null;
      })
      .addCase(getTenderTypes.fulfilled, (state, action) => {
        state.tenderTypesLoading = false;
        state.tenderTypesData = action.payload;
        state.tenderTypesError = null;
      })
      .addCase(getTenderTypes.rejected, (state, action) => {
        state.tenderTypesLoading = false;
        state.tenderTypesData = null;
        state.tenderTypesError = action.error.message;
      })
      // filter render
      .addCase(getFilteredTenderTypes.pending, (state) => {
        state.filteredTenderTypesLoading = true;
        state.tenderTypesData = null;
        state.tenderTypesError = null;
      })
      .addCase(getFilteredTenderTypes.fulfilled, (state, action) => {
        state.filteredTenderTypesLoading = false;
        state.tenderTypesData = action.payload;
        state.tenderTypesError = null;
      })
      .addCase(getFilteredTenderTypes.rejected, (state, action) => {
        state.filteredTenderTypesLoading = false;
        state.tenderTypesData = null;
        state.tenderTypesError = action.error.message;
      });
  },
});

export default tenderTypesSlice.reducer;
