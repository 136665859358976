import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateChannel } from "../../Store/Slices/Sidebar/Channels/ChannelsSlice";
import { MetroSpinner } from "react-spinners-kit";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const UpdateChannelPopup = ({
  token,
  setUpdateChannelPopup,
  updateChannelObj,
}) => {
  // redux state
  const { updateChannelLoading, updateChannelData } = useSelector(
    (state) => state.channels
  );

  // close modal
  const handleCloseModal = () => {
    if (!updateChannelLoading) {
      setUpdateChannelPopup(false);
    }
  };

  // organization defined name
  const [organizationDefinedName, setOrganziationDefinedName] = useState(
    updateChannelObj.organizationDefinedName
  );

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // update channel
  const handleChannelUpdate = (e) => {
    e.preventDefault();
    dispatch(
      updateChannel({
        token,
        tagNumber: updateChannelObj.tagNumber,
        organizationDefinedName,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setUpdateChannelPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Update Channel</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form onSubmit={handleChannelUpdate}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Channel Name</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder="Channel Name"
                    required
                    value={organizationDefinedName}
                    onChange={(e) => setOrganziationDefinedName(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>

              {updateChannelData &&
              updateChannelData.apiError &&
              updateChannelData.tagNumber === updateChannelObj.tagNumber ? (
                <div className="error-msg">{updateChannelData.apiError}</div>
              ) : (
                <>
                  {updateChannelData &&
                    updateChannelData.axiosError &&
                    updateChannelData.tagNumber ===
                      updateChannelObj.tagNumber && (
                      <div className="error-msg">
                        {updateChannelData.axiosError}
                      </div>
                    )}
                </>
              )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={updateChannelLoading}
                >
                  {updateChannelLoading ? (
                    <MetroSpinner
                      loading={updateChannelLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default UpdateChannelPopup;
