import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getDonationsPyramid = createAsyncThunk(
  "donationsPyramid/getDonationsPyramid",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/donationPyramid?year=${singleObj.year}&values=${singleObj.values}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredDonationsPyramid = createAsyncThunk(
  "filteredDonationsPyramid/getFilteredDonationsPyramid",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/donationPyramid?year=${singleObj.year}&values=${singleObj.values}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const donationsPyramidSlice = createSlice({
  name: "donationsPyramid",
  initialState: {
    donationsPyramidLoading: false,
    donationsPyramidData: null,
    donationsPyramidError: null,
    filteredDonationsPyramidLoading: false,
  },
  extraReducers: (builder) => {
    builder
      // first render
      .addCase(getDonationsPyramid.pending, (state) => {
        state.donationsPyramidLoading = true;
        state.donationsPyramidData = null;
        state.donationsPyramidError = null;
      })
      .addCase(getDonationsPyramid.fulfilled, (state, action) => {
        state.donationsPyramidLoading = false;
        state.donationsPyramidData = action.payload;
        state.donationsPyramidError = null;
      })
      .addCase(getDonationsPyramid.rejected, (state, action) => {
        state.donationsPyramidLoading = false;
        state.donationsPyramidData = null;
        state.donationsPyramidError = action.error.message;
      })
      // filter render
      .addCase(getFilteredDonationsPyramid.pending, (state) => {
        state.filteredDonationsPyramidLoading = true;
        state.donationsPyramidData = null;
        state.donationsPyramidError = null;
      })
      .addCase(getFilteredDonationsPyramid.fulfilled, (state, action) => {
        state.filteredDonationsPyramidLoading = false;
        state.donationsPyramidData = action.payload;
        state.donationsPyramidError = null;
      })
      .addCase(getFilteredDonationsPyramid.rejected, (state, action) => {
        state.filteredDonationsPyramidLoading = false;
        state.donationsPyramidData = null;
        state.donationsPyramidError = action.error.message;
      });
  },
});

export default donationsPyramidSlice.reducer;
