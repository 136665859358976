import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../../config";

// get all apps
export const getAllApps = createAsyncThunk(
  "appExplorer/getAllApps",
  async (token) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/integrations/apps`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// get call log
export const getCallLog = createAsyncThunk(
  "appExplorer/getCallLog",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };

    const request = await axios.get(
      `${hostName}api/admin/v1/integrations/logs?integrationTag=${singleObj.integrationTag}`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// test connection
export const testConnection = createAsyncThunk(
  "appExplorer/testConnection",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };

    const request = await axios.get(
      `${hostName}api/admin/v1/integrations/test?integrationTag=${singleObj.integrationTag}`,
      axiosConfig
    );
    const response = await request.data;
    return response;
  }
);

// disconnect integration
export const disconnectIntegration = createAsyncThunk(
  "appExplorer/disconnectIntegration",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/integrations/apps`,
        {
          data: {
            organizationIntegrationTag: singleObj.organizationIntegrationTag,
          },
          ...axiosConfig, // spread to include other axios config options like headers
        }
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        organizationIntegrationTag: singleObj.organizationIntegrationTag,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }

      return {
        data: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
        organizationIntegrationTag: singleObj.organizationIntegrationTag,
      };
    }
  }
);

// share integration
export const shareIntegration = createAsyncThunk(
  "appExplorer/shareIntegration",
  async (singleObj) => {
    try {
      const data = {
        integrationTag: singleObj.integrationTag,
        organizationTags: singleObj.organizationTags,
      };

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/integrations/share`,
        data,
        axiosConfig
      );

      const response = await request.data;

      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        integrationTag: singleObj.integrationTag,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
        integrationTag: singleObj.integrationTag,
      };
    }
  }
);

// sync integration
export const syncIntegration = createAsyncThunk(
  "appExplorer/syncIntegration",
  async (singleObj) => {
    try {
      const data = {
        integrationTag: singleObj.integrationTag,
      };

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/integrations/sync`,
        data,
        axiosConfig
      );

      const response = await request.data;

      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        integrationTag: singleObj.integrationTag,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
        integrationTag: singleObj.integrationTag,
      };
    }
  }
);

// toggle status
export const toggleStatus = createAsyncThunk(
  "appExplorer/toggleStatus",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/integrations/toggleStatus`,
        {
          integrationTag: singleObj.integrationTag,
        },
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        integrationTag: singleObj.integrationTag,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }

      return {
        data: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
        integrationTag: singleObj.integrationTag,
      };
    }
  }
);

// post request
export const postRequest = createAsyncThunk(
  "appExplorer/postRequest",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${singleObj.targetUrl}`,
        singleObj.retrievedParams,
        axiosConfig
      );

      const response = await request.data;

      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        retrievedParams: singleObj.retrievedParams,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
        retrievedParams: singleObj.retrievedParams,
      };
    }
  }
);

const appExplorerSlice = createSlice({
  name: "appExplorer",
  initialState: {
    // apps
    appsLoading: false,
    appsData: null,
    appsError: null,
    // call log
    callLogLoading: false,
    callLogData: null,
    callLogError: null,
    // test connection
    testLoading: false,
    testData: null,
    testError: null,
    // disconnect integration
    disconnectIntegrationLoading: false,
    disconnectIntegrationData: null,
    // share integration
    shareLoading: false,
    shareData: null,
    // sync integration
    syncLoading: false,
    syncData: null,
    // toggle status
    toggleLoading: false,
    toggleData: null,
    // post request
    postLoading: false,
    postData: null,
  },
  extraReducers: (builder) => {
    builder
      // get all apps
      .addCase(getAllApps.pending, (state) => {
        state.appsLoading = true;
        state.appsData = null;
        state.appsError = null;
      })
      .addCase(getAllApps.fulfilled, (state, action) => {
        state.appsLoading = false;
        state.appsData = action.payload;
        state.appsError = null;
      })
      .addCase(getAllApps.rejected, (state, action) => {
        state.appsLoading = false;
        state.appsData = null;
        state.appsError = action.error.message;
      })
      // get call log
      .addCase(getCallLog.pending, (state) => {
        state.callLogLoading = true;
        state.callLogData = null;
        state.callLogError = null;
      })
      .addCase(getCallLog.fulfilled, (state, action) => {
        state.callLogLoading = false;
        state.callLogData = action.payload;
        state.callLogError = null;
      })
      .addCase(getCallLog.rejected, (state, action) => {
        state.callLogLoading = false;
        state.callLogData = null;
        state.callLogError = action.error.message;
      })
      // test connection
      .addCase(testConnection.pending, (state) => {
        state.testLoading = true;
        state.testData = null;
        state.testError = null;
      })
      .addCase(testConnection.fulfilled, (state, action) => {
        state.testLoading = false;
        state.testData = action.payload;
        state.testError = null;
      })
      .addCase(testConnection.rejected, (state, action) => {
        state.testLoading = false;
        state.testData = null;
        state.testError = action.error.message;
      })
      // disconnect integration
      .addCase(disconnectIntegration.pending, (state) => {
        state.disconnectIntegrationLoading = true;
        state.disconnectIntegrationData = null;
      })
      .addCase(disconnectIntegration.fulfilled, (state, action) => {
        state.disconnectIntegrationLoading = false;
        state.disconnectIntegrationData = action.payload;
        const { data, organizationIntegrationTag } = action.payload;
        if (data) {
          const index = state.appsData.findIndex(
            (data) =>
              data.organizationIntegrationTag === organizationIntegrationTag
          );
          if (index !== -1) {
            state.appsData[index] = data;
          }
        }
      })
      // share integration
      .addCase(shareIntegration.pending, (state) => {
        state.shareLoading = true;
        state.shareData = null;
      })
      .addCase(shareIntegration.fulfilled, (state, action) => {
        state.shareLoading = false;
        state.shareData = action.payload;
      })
      // sync integration
      .addCase(syncIntegration.pending, (state) => {
        state.syncLoading = true;
        state.syncData = null;
      })
      .addCase(syncIntegration.fulfilled, (state, action) => {
        state.syncLoading = false;
        state.syncData = action.payload;
      })
      // toggle status
      .addCase(toggleStatus.pending, (state) => {
        state.toggleLoading = true;
        state.toggleData = null;
      })
      .addCase(toggleStatus.fulfilled, (state, action) => {
        state.toggleLoading = false;
        state.toggleData = action.payload;
        const { data, integrationTag } = action.payload;
        if (data) {
          const index = state.appsData.findIndex(
            (data) => data.tagNumber === integrationTag
          );
          if (index !== -1) {
            state.appsData[index] = data;
          }
        }
      })
      // post request
      .addCase(postRequest.pending, (state) => {
        state.postLoading = true;
        state.postData = null;
      })
      .addCase(postRequest.fulfilled, (state, action) => {
        state.postLoading = false;
        state.postData = action.payload;

        const { data, retrievedParams } = action.payload;
        const { integrationTag } = retrievedParams;
        if (data) {
          const index = state.appsData.findIndex(
            (data) => data.tagNumber === integrationTag
          );
          if (index !== -1) {
            state.appsData[index] = data;
          }
        }
      });
  },
});

export default appExplorerSlice.reducer;
