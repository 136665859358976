import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { ic_close } from "react-icons-kit/md/";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import { filter } from "react-icons-kit/feather/filter";
import { useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ReactDatePicker from "react-datepicker";

// multi select animated components
const animatedComponents = makeAnimated();

// select styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

function FilterCampaignDrawer({
  handleSelectChange,
  entitySelected,
  setEntitySelected,
  filteredParams,
  setFilteredParams,
  name,
  setName,
  description,
  setDescription,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleDate,
  formatDate,
  status,
  setStatus,
  handleBoolean,
  issueTaxReceipt,
  setIssueTaxReceipt,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
  setLoadings,
  perPage,
  pageSize,
  setPageSize,
  handlePerPage,
}) {
  // redux state
  const { listOfValuesData } = useSelector((state) => state.campaign);

  // drawer show state
  const [isDrawerOpen, setIsDrawer] = useState(false);

  // should render fields?
  const shouldRenderNameField = entitySelected.some(
    (option) => option.value === "name"
  );

  const shouldRenderDescriptionField = entitySelected.some(
    (option) => option.value === "description"
  );

  const shouldRenderStartDateField = entitySelected.some(
    (option) => option.value === "start_date"
  );

  const shouldRenderEndDateField = entitySelected.some(
    (option) => option.value === "end_date"
  );

  const shouldRenderStatusField = entitySelected.some(
    (option) => option.value === "status"
  );

  const shouldRenderIssueTaxReceiptField = entitySelected.some(
    (option) => option.value === "issue_tax_receipt"
  );

  // per page dropdown options
  const perPageOptions = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
  ];

  // form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Prepare the data to be sent as API parameters based on the selected options
    const apiData = {};

    // Add 'name' to the apiData if it's selected
    if (shouldRenderNameField) {
      apiData.name = name;
    }

    // Add 'description' to the apiData if it's selected
    if (shouldRenderDescriptionField) {
      apiData.description = description;
    }

    // Add 'start_date' to the apiData if it's selected
    if (shouldRenderStartDateField) {
      apiData.start_date = formatDate(startDate);
    }

    // Add 'end_date' to the apiData if it's selected
    if (shouldRenderEndDateField) {
      apiData.end_date = formatDate(endDate);
    }

    // add status
    if (shouldRenderStatusField) {
      apiData.status = status;
    }

    // add issue_tax_receipt
    if (shouldRenderIssueTaxReceiptField) {
      apiData.issue_tax_receipt = issueTaxReceipt;
    }

    // sort by
    if (sortBy) {
      apiData.by = sortBy.value;
    }

    setLoadings(true);
    setIsDrawer(false);
    setFilteredParams({
      apiData,
      pageSize,
      order: orderBy.value,
    });
  };

  // reset filter
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilteredParams(null);
      setIsDrawer(false);
    }
    setEntitySelected([]);
    setName("");
    setDescription("");
    setStartDate(null);
    setEndDate(null);
    setStatus(0);
    setIssueTaxReceipt(0);
    setPageSize(perPage);
    setSortBy(null);
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  return (
    <>
      <Tippy content="Filter Records">
        <button
          onClick={() => setIsDrawer(true)}
          className="filter-media-modal-btn"
          type="button"
        >
          <Icon icon={filter} size={24} />
        </button>
      </Tippy>
      {filteredParams && (
        <Tippy content="Reset Records">
          <button
            onClick={handleResetFilter}
            className="filter-media-modal-btn reset"
            type="button"
          >
            <Icon icon={refreshCw} size={24} />
          </button>
        </Tippy>
      )}
      <div className="filterCampaign">
        {isDrawerOpen && (
          <AnimatePresence>
            <motion.div
              variants={backVariants}
              initial="hidden"
              animate="visible"
              className="backdrop"
            >
              <motion.div
                variants={modalVariants}
                initial="hidden"
                animate="visible"
                className="drawer"
              >
                <div className="drawer-content">
                  <div className="drawerHeader text-white p-2">
                    <h5 className="mb-0 fs-14">Filter Campaigns</h5>
                    <button
                      onClick={() => setIsDrawer(false)}
                      className="close"
                    >
                      <Icon icon={ic_close} size={20} />
                    </button>
                  </div>
                  <div className="drawerBody p-3">
                    {listOfValuesData && (
                      <>
                        <div className="form-group mb-2">
                          <label className="form-label d-block pb-1 border-bottom">
                            Select Fields
                          </label>
                          <Select
                            isMulti
                            components={animatedComponents}
                            styles={styles}
                            options={listOfValuesData}
                            onChange={handleSelectChange}
                            value={entitySelected}
                          />
                        </div>
                        <form onSubmit={handleFormSubmit}>
                          {entitySelected?.some(
                            (option) => option.value === "name"
                          ) && (
                            <div className="form-group mb-2">
                              <label className="form-label">
                                {
                                  entitySelected.find(
                                    (option) => option.value === "name"
                                  )?.label
                                }
                              </label>
                              <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder={
                                  entitySelected.find(
                                    (option) => option.value === "name"
                                  )?.label
                                }
                                className="form-control"
                                required
                              />
                            </div>
                          )}
                          {entitySelected?.some(
                            (option) => option.value === "description"
                          ) && (
                            <div className="form-group mb-2">
                              <label className="form-label">
                                {
                                  entitySelected.find(
                                    (option) => option.value === "description"
                                  )?.label
                                }
                              </label>
                              <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder={
                                  entitySelected.find(
                                    (option) => option.value === "description"
                                  )?.label
                                }
                                className="form-control"
                                rows={2}
                                required
                              ></textarea>
                            </div>
                          )}
                          {entitySelected?.some(
                            (option) => option.value === "start_date"
                          ) && (
                            <div className="form-group mb-2">
                              <label className="form-label">
                                {
                                  entitySelected.find(
                                    (option) => option.value === "start_date"
                                  )?.label
                                }
                              </label>
                              <ReactDatePicker
                                style={{
                                  width: 100 + "%",
                                }}
                                className="edit-media-inputs"
                                selected={startDate}
                                onChange={handleDate(setStartDate)}
                                dateFormat="yyyy-MM-dd"
                                isClearable
                                required
                                placeholderText={
                                  entitySelected.find(
                                    (option) => option.value === "start_date"
                                  )?.label
                                }
                              />
                            </div>
                          )}
                          {entitySelected?.some(
                            (option) => option.value === "end_date"
                          ) && (
                            <div className="form-group mb-2">
                              <label className="form-label">
                                {
                                  entitySelected.find(
                                    (option) => option.value === "end_date"
                                  )?.label
                                }
                              </label>
                              <ReactDatePicker
                                style={{
                                  width: 100 + "%",
                                }}
                                className="edit-media-inputs"
                                selected={endDate}
                                onChange={handleDate(setEndDate)}
                                dateFormat="yyyy-MM-dd"
                                isClearable
                                required
                                placeholderText={
                                  entitySelected.find(
                                    (option) => option.value === "end_date"
                                  )?.label
                                }
                              />
                            </div>
                          )}
                          {entitySelected?.some(
                            (option) => option.value === "status"
                          ) && (
                            <div className="form-group mb-2">
                              <label className="form-label">
                                {
                                  entitySelected.find(
                                    (option) => option.value === "status"
                                  )?.label
                                }
                              </label>
                              <label className="switch d-block">
                                <input
                                  type="checkbox"
                                  checked={status}
                                  onChange={handleBoolean(setStatus)}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          )}
                          {entitySelected?.some(
                            (option) => option.value === "issue_tax_receipt"
                          ) && (
                            <div className="form-group mb-2">
                              <label className="form-label">
                                {
                                  entitySelected.find(
                                    (option) =>
                                      option.value === "issue_tax_receipt"
                                  )?.label
                                }
                              </label>
                              <label className="switch d-block">
                                <input
                                  type="checkbox"
                                  checked={issueTaxReceipt}
                                  onChange={handleBoolean(setIssueTaxReceipt)}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          )}
                          <div className="form-group mb-2">
                            <label className="form-label">
                              Records per page
                            </label>
                            <Select
                              value={perPageOptions.find(
                                (options) => options.value === String(pageSize)
                              )}
                              onChange={(option) => handlePerPage(option.value)}
                              options={perPageOptions}
                              styles={styles}
                            />
                          </div>
                          <div className="form-group mb-2">
                            <label className="form-label">Sort by</label>
                            <Select
                              value={sortBy}
                              onChange={(option) => setSortBy(option)}
                              options={listOfValuesData}
                              styles={styles}
                              isClearable
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label className="form-label">Order by</label>
                            <Select
                              value={orderBy}
                              onChange={(option) => setOrderBy(option)}
                              options={[
                                { value: "asc", label: "Ascending" },
                                { value: "desc", label: "Descending" },
                              ]}
                              styles={styles}
                            />
                          </div>
                          <div className="d-flex">
                            <button
                              onClick={handleResetFilter}
                              type="button"
                              className="resetBtn"
                            >
                              RESET
                            </button>
                            <button type="submit" className="submitBtn">
                              FILTER
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </>
  );
}

export default FilterCampaignDrawer;
