import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getTopDonations = createAsyncThunk(
  "topDonations/getTopDonations",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/topDonationYearMonth?n=${singleObj.n}&year=${singleObj.year}&month=${singleObj.month}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredTopDonations = createAsyncThunk(
  "filteredTopDonations/getFilteredTopDonations",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/topDonationYearMonth?n=${singleObj.n}&year=${singleObj.year}&month=${singleObj.month}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const topDonationsSlice = createSlice({
  name: "topDonations",
  initialState: {
    topDonationsLoading: false,
    topDonationsData: null,
    topDonationsError: null,
    filteredTopDonationsLoading: false,
  },
  extraReducers: (builder) => {
    builder
      // first render
      .addCase(getTopDonations.pending, (state) => {
        state.topDonationsLoading = true;
        state.topDonationsData = null;
        state.topDonationsError = null;
      })
      .addCase(getTopDonations.fulfilled, (state, action) => {
        state.topDonationsLoading = false;
        state.topDonationsData = action.payload;
        state.topDonationsError = null;
      })
      .addCase(getTopDonations.rejected, (state, action) => {
        state.topDonationsLoading = false;
        state.topDonationsData = null;
        state.topDonationsError = action.error.message;
      })
      // filter render
      .addCase(getFilteredTopDonations.pending, (state) => {
        state.filteredTopDonationsLoading = true;
        state.topDonationsData = null;
        state.topDonationsError = null;
      })
      .addCase(getFilteredTopDonations.fulfilled, (state, action) => {
        state.filteredTopDonationsLoading = false;
        state.topDonationsData = action.payload;
        state.topDonationsError = null;
      })
      .addCase(getFilteredTopDonations.rejected, (state, action) => {
        state.filteredTopDonationsLoading = false;
        state.topDonationsData = null;
        state.topDonationsError = action.error.message;
      });
  },
});

export default topDonationsSlice.reducer;
