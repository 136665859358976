import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const DeclinedTransactionsPopup = ({
  setDeclinedTransactionsPopup,
  declinedTransactionsObj,
}) => {
  // redux state
  const { recurringPaymentsData } = useSelector(
    (state) => state.recurringPayments
  );

  // close modal
  const handleCloseModal = () => {
    setDeclinedTransactionsPopup(false);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media extra-width"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Declined Transactions</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div
            className="update-widgets-modal-body transactions-popup"
            style={{ padding: 5 + "px" }}
          >
            <div className="transactions-table">
              <MDBTable align="middle">
                <MDBTableHead className="thead">
                  <tr>
                    <th scope="col">Amount</th>
                    <th scope="col">Cardholder Name</th>
                    <th scope="col">Card Number</th>
                    <th scope="col">Card Expiry</th>
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Transaction Date</th>
                    <th scope="col">Message</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {declinedTransactionsObj.declineTransactions.map(
                    (data, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                        }}
                      >
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                              textAlign: "right",
                            }}
                          >
                            {recurringPaymentsData?.data?.currencySymbol}
                            {data.amount}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            {data.cardholderName}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            {data.cardNumber}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            {data.cardExpiry}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            {data.transactionId}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            {data.transactionDate}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            {data.message}
                          </p>
                        </td>
                      </tr>
                    )
                  )}
                </MDBTableBody>
              </MDBTable>
            </div>
            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DeclinedTransactionsPopup;
