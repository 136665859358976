import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

export const getTotalDonations = createAsyncThunk(
  "totalDonations/getTotalDonations",
  async (adminToken) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${adminToken}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/totalDonations`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const totalDonationsSlice = createSlice({
  name: "totalDonations",
  initialState: {
    totalDonationsLoading: false,
    totalDonationsData: null,
    totalDonationsError: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTotalDonations.pending, (state) => {
        state.totalDonationsLoading = true;
        state.totalDonationsData = null;
        state.totalDonationsError = null;
      })
      .addCase(getTotalDonations.fulfilled, (state, action) => {
        state.totalDonationsLoading = false;
        state.totalDonationsData = action.payload;
        state.totalDonationsError = null;
      })
      .addCase(getTotalDonations.rejected, (state, action) => {
        state.totalDonationsLoading = false;
        state.totalDonationsData = null;
        state.totalDonationsError = action.error.message;
      });
  },
});

export default totalDonationsSlice.reducer;
