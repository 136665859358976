import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MDBCheckbox } from "mdb-react-ui-kit";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// multi select animated components
const animatedComponents = makeAnimated();

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const FilterDataModal = ({
  setFilterDataModal,
  entityOptions,
  entitySelected,
  handleSelectChange,
  type,
  handleTypeChange,
  typeOptions,
  status,
  handleStatusChange,
  statusOptions,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
  pageSize,
  handlePerPage,
  setFilteredParams,
  setLoadings,
  handleResetFilter,
}) => {
  // close modal
  const handleCloseModal = () => {
    setFilterDataModal(false);
  };

  // should render fields?
  const shouldRenderStatusField = entitySelected.some(
    (option) => option.value === "status"
  );

  const shouldRenderTypeField = entitySelected.some(
    (option) => option.value === "type"
  );

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "50",
      label: "50",
    },
  ];

  // form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const apiData = {};
    // Add 'billing_period' to the apiData if it's selected
    if (shouldRenderTypeField) {
      apiData.type = type;
    }

    // add status
    if (shouldRenderStatusField) {
      apiData.status = status;
    }

    // sort by
    if (sortBy) {
      apiData.by = sortBy.value;
    }
    setLoadings(true);
    setFilterDataModal(false);
    setFilteredParams({
      apiData,
      pageSize,
      order: orderBy.value,
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Filter Data</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <h6
              style={{
                borderBottom: 1 + "px solid #eaeaea",
                paddingBottom: 5 + "px",
              }}
            >
              Select Fields
            </h6>

            <Select
              isMulti
              components={animatedComponents}
              styles={styles}
              options={entityOptions}
              onChange={handleSelectChange}
              value={entitySelected}
            />
            <form
              style={{ marginTop: 10 + "px" }}
              className="update-media-form"
              onSubmit={handleFormSubmit}
            >
              {/* type */}
              {shouldRenderTypeField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "type")
                        ?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {typeOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={type.includes(item.value)}
                          onChange={() => handleTypeChange(item.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* status */}
              {shouldRenderStatusField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "status")
                        ?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {statusOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={status.includes(item.value)}
                          onChange={() => handleStatusChange(item.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* page size */}
              <div className="input-margins-div">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === pageSize
                  )}
                  onChange={(option) => handlePerPage(option.value)}
                  options={perPageOptions}
                  styles={styles}
                />
              </div>
              {/* sort by */}
              <div className="input-margins-div">
                <label>Sort by</label>
                <Select
                  value={sortBy}
                  onChange={(option) => setSortBy(option)}
                  options={entityOptions}
                  styles={styles}
                  isClearable
                />
              </div>
              {/* order by */}
              <div className="input-margins-div">
                <label>Order by</label>
                <Select
                  value={orderBy}
                  onChange={(option) => setOrderBy(option)}
                  options={[
                    { value: "asc", label: "Ascending" },
                    { value: "desc", label: "Descending" },
                  ]}
                  styles={styles}
                />
              </div>
              <div className="reset-and-filter-div">
                <button
                  type="button"
                  className="reset-fields-btn"
                  onClick={handleResetFilter}
                >
                  RESET
                </button>
                <button type="submit" className="filter-records-btn">
                  FILTER
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterDataModal;
