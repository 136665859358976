import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// create async thunk getAllReports
export const getAllReports = createAsyncThunk(
  "REPORTS/GETALLREPORTS",
  async (token) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const queryParams = {
      caller: "reports",
    };
    const request = await axios.get(`${hostName}api/admin/v1/report`, {
      params: queryParams,
      ...axiosConfig,
    });
    const response = await request.data;
    return response.data;
  }
);

// create async thunk getUniqueReport
export const getUniqueReport = createAsyncThunk(
  "REPORTS/GETUNIQUEREPORT",
  async ({ token, tag }) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/report/${tag}`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    reportsLoading: false,
    reportsData: null,
    reportsError: null,
    reportsUniqueLoading: false,
    reportsUniqueData: null,
    reportsUniqueError: null,
    reportTag: null,
    reportDescription: null,
    reportLabel: null,
  },
  reducers: {
    setDisplayValuesForSelect: (state, action) => {
      state.reportTag = action.payload.reportTag;
      state.reportLabel = action.payload.reportLabel;
      state.reportDescription = action.payload.reportDescription;
    },
  },
  extraReducers: (builder) => {
    builder
      // get all reports
      .addCase(getAllReports.pending, (state) => {
        state.reportsLoading = true;
        state.reportsData = null;
        state.reportsError = null;
      })
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.reportsLoading = false;
        state.reportsData = action.payload;
        state.reportsError = null;
      })
      .addCase(getAllReports.rejected, (state, action) => {
        state.reportsLoading = false;
        state.reportsData = null;
        state.reportsError = action.error.message;
      })
      // get unique reports
      .addCase(getUniqueReport.pending, (state) => {
        state.reportsUniqueLoading = true;
        state.reportsUniqueData = null;
        state.reportsUniqueError = null;
      })
      .addCase(getUniqueReport.fulfilled, (state, action) => {
        state.reportsUniqueLoading = false;
        state.reportsUniqueData = action.payload;
        state.reportsUniqueError = null;
      })
      .addCase(getUniqueReport.rejected, (state, action) => {
        state.reportsUniqueLoading = false;
        state.reportsUniqueData = null;
        state.reportsUniqueError = action.error.message;
      })
  },
});

export default reportsSlice.reducer;
export const { setDisplayValuesForSelect } = reportsSlice.actions;
