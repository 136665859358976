import React from "react";
import Icon from "react-icons-kit";
import { arrows_right } from "react-icons-kit/linea/arrows_right";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isBlurhashValid } from "blurhash";
import { Blurhash } from "react-blurhash";

// box variants
const boxVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
  hover: { scale: 1.05, boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)" },
};

const DonorBox = React.memo(
  ({
    donor,
    goToSpecificDonor,
    handleEditDonor,
    handleDeleteDonor,
    loadedImages,
  }) => {
    const validRes = isBlurhashValid(donor.blurHash);
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        whileHover="hover"
        variants={boxVariants}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="media-box donors-box"
        style={{
          borderTop:
            donor.status === 1 ? "3px solid #007C16" : "3px solid #f2b92c",
          background: "#fff",
        }}
      >
        <div className="crm-users-info flex-start">
          <div
            style={{
              backgroundColor: donor.profileImage
                ? "#f0f7f9"
                : donor.status === 1
                ? "#007C16"
                : "#f2b92c",
              minWidth: 60 + "px",
              minHeight: 60 + "px",
              borderRadius: 50 + "%",
              color: donor.status === 1 ? "#fff" : "#000",
              fontWeight: "bold",
              fontSize: 18 + "px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              letterSpacing: 0.09 + "em",
              padding: donor.profileImage ? "0px" : "7px",
            }}
          >
            {donor.profileImage ? (
              loadedImages[donor.tagNumber] ? (
                <LazyLoadImage
                  src={donor.profileImage}
                  alt="dp"
                  effect="blur"
                  width={"60px"}
                  height={"60px"}
                  style={{
                    borderRadius: "50%",
                    objectFit: "cover",
                    overflowClipMargin: "unset",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  {validRes.result && (
                    <Blurhash
                      hash={donor.blurHash}
                      width="100%"
                      height="100%"
                      resolutionX={32}
                      resolutionY={32}
                      punch={1}
                    />
                  )}
                </div>
              )
            ) : (
              <>
                {donor.firstName.charAt(0)}
                {donor.lastName.charAt(0)}
              </>
            )}
          </div>
          <div className="user-name-email">
            <h1 className="media-name">
              {donor.firstName} {donor.lastName}
            </h1>
            <h4>
              {donor.streetAddress}, {donor.city}, {donor.provinceState}
            </h4>
            <h4>
              {donor.postalZipCode}, {donor.country}
            </h4>
            <h4>{donor.email}</h4>
            <span
              style={{ color: "#007C16", cursor: "pointer" }}
              onClick={() => goToSpecificDonor(donor)}
            >
              More Details <Icon icon={arrows_right} size={16} />
            </span>
          </div>
        </div>
        <div className="donors-table-container">
          {donor.businessName && (
            <div className="donors-row">
              <h4>Business Name</h4>
              <div className="text-muted mb-0">{donor.businessName}</div>
            </div>
          )}
          <div className="donors-row">
            <h4>Phone</h4>
            <div className="text-muted mb-0">{donor.phone}</div>
          </div>
          <div className="donors-row">
            <h4>Created</h4>
            <div className="text-muted mb-0">
              {donor.createDateTime.split(" ")[0]}
            </div>
          </div>
          <div className="donors-row">
            <h4>Updated</h4>
            <div className="text-muted mb-0">
              {donor.updateDateTime.split(" ")[0]}
            </div>
          </div>
        </div>
        <div className="edit-and-delete-media-div">
          <button className="edit-btn" onClick={() => handleEditDonor(donor)}>
            Edit
          </button>
          <button
            className="delete-btn"
            onClick={() => handleDeleteDonor(donor)}
          >
            Delete
          </button>
        </div>
      </motion.div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.donor === nextProps.donor &&
      prevProps.loadedImages[nextProps.donor.tagNumber] ===
        nextProps.loadedImages[nextProps.donor.tagNumber]
    );
  }
);

export default DonorBox;
