import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MDBCheckbox } from "mdb-react-ui-kit";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// multi select animated components
const animatedComponents = makeAnimated();

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const FilterTaxReceiptsModal = ({
  setFilterTaxReceiptsModal,
  setFilteredParams,
  entityOptions,
  handleSelectChange,
  entitySelected,
  email,
  setEmail,
  states,
  handleStatesChange,
  statesOptions,
  code,
  setCode,
  filterYear,
  setFilterYear,
  setYear,
  yearOptions,
  receiptNumber,
  setReceiptNumber,
  setLoadings,
  setFilteredFlag,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
  handleResetFilter,
  pageSize,
  handlePerPage,
}) => {
  // close modal
  const handleCloseModal = () => {
    setFilterTaxReceiptsModal(false);
  };

  // should render fields?
  const shouldRenderYearField = entitySelected.some(
    (option) => option.value === "year"
  );

  const shouldRenderStateField = entitySelected.some(
    (option) => option.value === "state"
  );

  const shouldRenderEmailField = entitySelected.some(
    (option) => option.value === "email"
  );

  const shouldRenderReceiptNumberField = entitySelected.some(
    (option) => option.value === "receipt_number"
  );

  const shouldRenderCodeField = entitySelected.some(
    (option) => option.value === "code"
  );

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Prepare the data to be sent as API parameters based on the selected options
    const apiData = {};
    // Add 'email' to the apiData if it's selected
    if (shouldRenderEmailField) {
      apiData.email = email;
    }

    // Add 'year' to the apiData if it's selected
    if (shouldRenderYearField) {
      apiData.year = filterYear;
      setYear(filterYear);
    }

    // Add 'receipt_number' to the apiData if it's selected
    if (shouldRenderReceiptNumberField) {
      apiData.receipt_number = receiptNumber;
    }

    // Add 'code' to the apiData if it's selected
    if (shouldRenderCodeField) {
      apiData.code = code;
    }

    // Add 'state' to the apiData if it's selected
    if (shouldRenderStateField) {
      apiData.state = states;
    }

    // sort by
    if (sortBy) {
      apiData.by = sortBy.value;
    }

    setLoadings(true);
    setFilteredFlag(null);
    setFilterTaxReceiptsModal(false);
    setFilteredParams({
      apiData,
      pageSize: pageSize,
      order: orderBy.value,
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Filter Tax Receipts</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <h6
              style={{
                borderBottom: 1 + "px solid #eaeaea",
                paddingBottom: 5 + "px",
              }}
            >
              Select Fields
            </h6>

            <Select
              isMulti
              components={animatedComponents}
              styles={styles}
              options={entityOptions}
              onChange={handleSelectChange}
              value={entitySelected}
            />
            <form
              style={{ marginTop: 10 + "px" }}
              className="update-media-form"
              onSubmit={handleFormSubmit}
            >
              {/* email */}
              {shouldRenderEmailField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "email")
                        ?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelected.find((option) => option.value === "email")
                        ?.label
                    }
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              )}
              {/* year */}
              {shouldRenderYearField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "year")
                        ?.label
                    }
                  </label>
                  <Select
                    value={yearOptions.find(
                      (option) => option.value === filterYear
                    )}
                    onChange={(option) => {
                      setFilterYear(option ? option.value : null);
                    }}
                    options={yearOptions}
                    styles={styles}
                    menuPosition="fixed"
                  />
                </div>
              )}
              {/* receipt number */}
              {shouldRenderReceiptNumberField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "receipt_number"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelected.find(
                        (option) => option.value === "receipt_number"
                      )?.label
                    }
                    required
                    value={receiptNumber}
                    onChange={(e) => setReceiptNumber(e.target.value)}
                  />
                </div>
              )}
              {/* code */}
              {shouldRenderCodeField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "code")
                        ?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelected.find((option) => option.value === "code")
                        ?.label
                    }
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
              )}
              {/* states */}
              {shouldRenderStateField && statesOptions && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "state")
                        ?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {statesOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={states.includes(item.value)}
                          onChange={() => handleStatesChange(item.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* page size */}
              <div className="input-margins-div">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === pageSize
                  )}
                  onChange={(option) => handlePerPage(option.value)}
                  options={perPageOptions}
                  styles={styles}
                />
              </div>
              {/* sort by */}
              <div className="input-margins-div">
                <label>Sort by</label>
                <Select
                  value={sortBy}
                  onChange={(option) => setSortBy(option)}
                  options={entityOptions}
                  styles={styles}
                  isClearable
                />
              </div>
              {/* order by */}
              <div className="input-margins-div">
                <label>Order by</label>
                <Select
                  value={orderBy}
                  onChange={(option) => setOrderBy(option)}
                  options={[
                    { value: "asc", label: "Ascending" },
                    { value: "desc", label: "Descending" },
                  ]}
                  styles={styles}
                />
              </div>
              <div className="reset-and-filter-div">
                <button
                  type="button"
                  className="reset-fields-btn"
                  onClick={handleResetFilter}
                >
                  RESET
                </button>
                <button type="submit" className="filter-records-btn">
                  FILTER
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterTaxReceiptsModal;
