import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

export const getTotalTransactions = createAsyncThunk(
  "totalTransactions/getTotalTransactions",
  async (adminToken) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${adminToken}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/totalTransactions`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const totalTransactionsSlice = createSlice({
  name: "totalTransactions",
  initialState: {
    totalTransactionsLoading: false,
    totalTransactionsData: null,
    totalTransactionsError: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTotalTransactions.pending, (state) => {
        state.totalTransactionsLoading = true;
        state.totalTransactionsData = null;
        state.totalTransactionsError = null;
      })
      .addCase(getTotalTransactions.fulfilled, (state, action) => {
        state.totalTransactionsLoading = false;
        state.totalTransactionsData = action.payload;
        state.totalTransactionsError = null;
      })
      .addCase(getTotalTransactions.rejected, (state, action) => {
        state.totalTransactionsLoading = false;
        state.totalTransactionsData = null;
        state.totalTransactionsError = action.error.message;
      });
  },
});

export default totalTransactionsSlice.reducer;
