import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { MetroSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { shareIntegration } from "../../Store/Slices/Sidebar/AppExplorer/AppExplorerSlice";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const SharePopup = ({
  token,
  setSharePopup,
  shareObj,
  organizations,
  selectedOrganization,
}) => {
  // filtered organizations
  const [filteredOrgs, setFilteredOrgs] = useState([]);

  // handle filtering
  useEffect(() => {
    const filteredOrganizations = organizations.filter(
      (org) => org.tagNumber !== selectedOrganization.tagNumber
    );
    setFilteredOrgs(filteredOrganizations);
  }, [organizations, selectedOrganization]);

  // redux state
  const { shareLoading, shareData } = useSelector((state) => state.appExplorer);

  // close modal
  const handleCloseModal = () => {
    if (!shareLoading) {
      setSharePopup(false);
    }
  };

  // organization tags
  const [organizationTags, setOrganizationTags] = useState([]);

  // Function to handle switch toggle
  const handleTagChange = (tagNumber) => (event) => {
    if (event.target.checked) {
      // Add tagNumber to the array when switched on
      setOrganizationTags((prevTags) => [...prevTags, tagNumber]);
    } else {
      // Remove tagNumber from the array when switched off
      setOrganizationTags((prevTags) =>
        prevTags.filter((tag) => tag !== tagNumber)
      );
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // share
  const handleShare = () => {
    dispatch(
      shareIntegration({
        token,
        integrationTag: shareObj.tagNumber,
        organizationTags,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setSharePopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Share Integration - {shareObj.applicationName}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="transactions-table">
              <MDBTable align="middle">
                <MDBTableHead className="thead" style={{ zIndex: 0 }}>
                  <tr>
                    <th scope="col">Organization</th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Share
                    </th>
                  </tr>
                </MDBTableHead>

                <MDBTableBody>
                  {filteredOrgs.map((org, index) => (
                    <tr
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                      }}
                      key={org.tagNumber}
                    >
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {org.name} {org.location ? `- ${org.location}` : ""}
                        </p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={handleTagChange(org.tagNumber)}
                            checked={organizationTags.includes(org.tagNumber)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>

              {shareData &&
                shareData.integrationTag === shareObj.tagNumber &&
                (shareData.apiError || shareData.axiosError) && (
                  <div className="error-msg">
                    {shareData.apiError ? (
                      <>
                        {typeof shareData.apiError === "object" ? (
                          // Case 1: If apiError is an object
                          Object.keys(shareData.apiError).map(
                            (field, index) => (
                              <p key={index} style={{ marginBottom: 0 }}>
                                {shareData.apiError[field][0]}
                              </p>
                            )
                          )
                        ) : (
                          // Case 2: If apiError is a simple string
                          <>{shareData.apiError}</>
                        )}
                      </>
                    ) : (
                      <>{shareData.axiosError}</>
                    )}
                  </div>
                )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="submit addModal"
                  onClick={handleShare}
                  disabled={shareLoading}
                  style={{
                    pointerEvents:
                      organizationTags.length < 1 ? "none" : "auto",
                    opacity: organizationTags.length < 1 ? 0.5 : 1,
                  }}
                >
                  {shareLoading ? (
                    <MetroSpinner
                      loading={shareLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "SHARE"
                  )}
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default SharePopup;
