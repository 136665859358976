import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register the plugin to all charts:
ChartJS.register(ChartDataLabels);

const Last12MonthsData = () => {
  // redux state
  const { last12MonthsData, last12MonthsError } = useSelector(
    (state) => state.last12MonthsData
  );

  // calculate last 12 months amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (
      last12MonthsData &&
      last12MonthsData.datasets &&
      last12MonthsData.datasets[0] &&
      last12MonthsData.datasets[0].data
    ) {
      if (last12MonthsData.datasets[0].data.length > 0) {
        const calculatedSum = last12MonthsData.datasets[0].data.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        const roundedSum = calculatedSum.toFixed(2);
        setSum(roundedSum);
      } else {
        setSum("0.00");
      }
    } else {
      setSum("0.00");
    }
  }, [last12MonthsData]);

  return (
    <>
      <span className="graph-text">
        Donations in last 12 months:{" "}
        <span style={{ color: "#007C16" }}>
          {last12MonthsData && <>{last12MonthsData.currencySymbol}</>}
          {sum}
        </span>
      </span>
      <div className="graph-container">
        {last12MonthsError ? (
          <div className="error-msg" style={{ marginTop: 0 }}>
            {last12MonthsError}
          </div>
        ) : (
          <>
            {last12MonthsData &&
            last12MonthsData.datasets &&
            last12MonthsData.datasets[0] &&
            last12MonthsData.datasets[0].data &&
            last12MonthsData.datasets[0].data.length > 0 ? (
              <Line
                data={last12MonthsData}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        callback: (value) => {
                          if (value >= 1000) {
                            return value
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          } else {
                            return value.toString();
                          }
                        },
                      },
                    },
                  },
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      backgroundColor: "#fff",
                      titleColor: "#000",
                      bodyColor: "#000",
                      callbacks: {
                        label: (context) =>
                          `${last12MonthsData.currencySymbol}` +
                          context.formattedValue,
                      },
                    },
                    datalabels: {
                      display: false,
                    },
                  },
                }}
              />
            ) : (
              <div className="no-data-found-div">
                <Tippy content="No Data Found">
                  <img src={noDataFound} alt="No Data Found" />
                </Tippy>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Last12MonthsData;
