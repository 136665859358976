import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getMedia,
  filterMediaRecords,
} from "../../Store/Slices/Sidebar/Media/GetMediaSlice";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import placeholderImg from "../../Images/placeholderImg.png";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Icon from "react-icons-kit";
import { play } from "react-icons-kit/feather/play";
import { basic_picture } from "react-icons-kit/linea/basic_picture";
import { basic_video } from "react-icons-kit/linea/basic_video";
import { music_volume_down } from "react-icons-kit/linea/music_volume_down";
import { music_mute } from "react-icons-kit/linea/music_mute";
import { basic_eye } from "react-icons-kit/linea/basic_eye";
import { basic_eye_closed } from "react-icons-kit/linea/basic_eye_closed";
import ResponsivePagination from "react-responsive-pagination";
import Select from "react-select";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const Media = ({
  token,
  filteredParams,
  isLoadingImg,
  currentPage,
  totalPages,
  perPage,
  setCurrentPage,
  setPerPage,
  setTotalPages,
  setPageSize,
  handleEditModal,
  handleDeleteModal,
  handleVideoModal,
  setLoadings,
}) => {
  // redux state
  const { mediaData, mediaError } = useSelector((state) => state.media);

  // per page options array
  const perPageOptions = [
    {
      value: "02",
      label: "02",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "06",
      label: "06",
    },
    {
      value: "08",
      label: "08",
    },
    {
      value: "10",
      label: "10",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterMediaRecords({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getMedia({
            token,
            page: 1,
            pageSize: value,
            order: "asc",
            by: "url",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterMediaRecords({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getMedia({
            token,
            page: value,
            pageSize: perPage,
            order: "asc",
            by: "url",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  return (
    <>
      {mediaError ? (
        <div className="error-msg" style={{ width: "fit-content" }}>
          {mediaError}
        </div>
      ) : (
        <>
          {mediaData && mediaData.data.length > 0 ? (
            <>
              {mediaData.data.map((data) => (
                <div
                  key={data.tagNumber}
                  className="media-box"
                  style={{
                    borderTop: data.status
                      ? "3px solid #007C16"
                      : "3px solid #f2b92c",
                    background: "#fff",
                  }}
                >
                  <div className="media-flex-between-row">
                    <h1 className="media-name">{data.name}</h1>
                    <div className="mediatype-and-status-div">
                      <Tippy
                        content={data.mediaType === "image" ? "Image" : "Video"}
                      >
                        <span className="media-type">
                          {data.mediaType === "image" ? (
                            <Icon icon={basic_picture} size={24} />
                          ) : (
                            <Icon icon={basic_video} size={24} />
                          )}
                        </span>
                      </Tippy>
                      <Tippy content={data.isMuted ? "Muted" : "Unmuted"}>
                        <span className="media-muted">
                          {data.isMuted === true ? (
                            <Icon icon={music_mute} size={24} />
                          ) : (
                            <Icon icon={music_volume_down} size={24} />
                          )}
                        </span>
                      </Tippy>
                      <Tippy content={data.status ? "Active" : "Inactive"}>
                        <span className="media-muted">
                          {data.status === true ? (
                            <Icon icon={basic_eye} size={24} />
                          ) : (
                            <Icon icon={basic_eye_closed} size={24} />
                          )}
                        </span>
                      </Tippy>
                    </div>
                  </div>
                  <div className="media-flex-between-row">
                    <div className="media-description">{data.description}</div>
                    {data.mediaType === "image" ? (
                      <div
                        className="media-img-div"
                        onClick={() => handleVideoModal(data)}
                      >
                        {isLoadingImg.tagNumber &&
                        isLoadingImg.value &&
                        isLoadingImg.tagNumber === data.tagNumber ? (
                          <img src={placeholderImg} alt="media"></img>
                        ) : (
                          <img src={data.path} alt="media"></img>
                        )}
                      </div>
                    ) : (
                      <div
                        className="media-img-div video"
                        onClick={() => handleVideoModal(data)}
                      >
                        <Icon
                          size={32}
                          icon={play}
                          style={{ marginLeft: 5 + "px" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="media-table-container">
                    <MDBTable align="middle">
                      <MDBTableHead className="media-thead">
                        <tr>
                          <th scope="col">Created</th>
                          <th scope="col">Duration</th>
                          <th scope="col">Sort Order</th>
                          <th scope="col">Full Screen</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody className="media-body">
                        <tr>
                          <td>
                            <p className="text-muted mb-0">
                              {data.createDateTime.split(" ")[0]}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0">
                              {data.duration} seconds
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0">{data.sortOrder}</p>
                          </td>
                          <td>
                            <p className="text-muted mb-0">
                              {data.fullScreen ? "Yes" : "No"}
                            </p>
                          </td>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                  <div className="edit-and-delete-media-div">
                    <button
                      className="edit-btn"
                      onClick={() => handleEditModal(data)}
                    >
                      Edit
                    </button>
                    <button
                      className="delete-btn"
                      onClick={() => handleDeleteModal(data)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
              <div className="per-page-and-pagination">
                <div className="per-page">
                  <label>Records per page</label>
                  <Select
                    value={perPageOptions.find(
                      (option) => option.value === perPage
                    )}
                    onChange={(option) => handlePerPage(option.value)}
                    options={perPageOptions}
                    styles={styles}
                    menuPlacement="top"
                  />
                </div>
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Media Found">
                <img src={noDataFound} alt="No Media Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Media;
