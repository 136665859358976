/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CustomTextField } from '../Common/CustomTextField';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleForgotPassword, resetForgotPasswordStates } from '../../Store/Slices/Auth/ForgotPasswordSlice';

export const ForgotForm = () => {
  // states
  const [email, setEmail] = useState('');

  // Redux state
  const { successMessage, loading, error } = useSelector((state)=>state.forgotPassword);

  // forgot password event
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleForgotPasswordEvent=(e)=>{
    e.preventDefault();
    dispatch(handleForgotPassword(email)).then((result)=>{
      if(result.payload){
        setTimeout(()=>{
          navigate('/resetpassword');
        },5000)
      };
    })
  }

  // prevent links click when forget password loading is true
  const handleLinkClick=(e)=>{
    if(loading){
      e.preventDefault();
    }
  }

  // reset messages when the component loads
  useEffect(()=>{
    dispatch(resetForgotPasswordStates());
  },[])

  return (
    <form className="custom-form" onSubmit={handleForgotPasswordEvent}>
      {successMessage&&(<div className="success-msg">
        {successMessage} You will now get automatically redirected to the reset password page in 5 seconds.
      </div>)}
      <CustomTextField
        label="Email"
        type="email"
        variant="standard"
        fullWidth
        required
        autoComplete="off"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button type="submit" className="login-submit-btn" disabled={loading}>
        {loading ? 'LOADING...' : 'SUBMIT'}
      </button>
      {error && <div className="error-msg">{error}</div>}
      <div className="forgot-password-div">
        <Link to="/login" className="forgot-password-link" onClick={handleLinkClick}>BACK</Link>
      </div>
    </form>
  )
}

export default ForgotForm;
