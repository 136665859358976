import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getMonthSummary = createAsyncThunk(
  "monthSummary/getMonthSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/monthSummary?month=${singleObj.month}&year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    if (response.values.length > 0) {
      return {
        currencySymbol: response.currencySymbol,
        labels: response.values.map((individualData) => individualData.day),
        datasets: [
          {
            label: "Amount",
            data: response.values.map((individualData) =>
              parseFloat(individualData.amount.replace(/,/g, ""))
            ),
            backgroundColor: "#d3ecc8",
            borderColor: "#007C16",
            fill: true,
            lineTension: 0.4,
          },
        ],
      };
    } else {
      return {
        currencySymbol: response.currencySymbol
      };
    }
  }
);

// filter render
export const getFilteredMonthSummary = createAsyncThunk(
  "filteredMonthSummary/getFilteredMonthSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/monthSummary?month=${singleObj.month}&year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    if (response.values.length > 0) {
      return {
        currencySymbol: response.currencySymbol,
        labels: response.values.map((individualData) => individualData.day),
        datasets: [
          {
            label: "Amount",
            data: response.values.map((individualData) =>
              parseFloat(individualData.amount.replace(/,/g, ""))
            ),
            backgroundColor: "#d3ecc8",
            borderColor: "#007C16",
            fill: true,
            lineTension: 0.4,
          },
        ],
      };
    } else {
      return {
        currencySymbol: response.currencySymbol
      }
    }
  }
);

const monthSummarySlice = createSlice({
  name: "monthSummary",
  initialState: {
    monthSummaryLoading: false,
    monthSummaryData: null,
    monthSummaryError: null,
    filteredMonthSummaryLoading: false,
  },
  extraReducers: (builder) => {
    // first render
    builder
      .addCase(getMonthSummary.pending, (state) => {
        state.monthSummaryLoading = true;
        state.monthSummaryData = null;
        state.monthSummaryError = null;
      })
      .addCase(getMonthSummary.fulfilled, (state, action) => {
        state.monthSummaryLoading = false;
        state.monthSummaryData = action.payload;
        state.monthSummaryError = null;
      })
      .addCase(getMonthSummary.rejected, (state, action) => {
        state.monthSummaryLoading = false;
        state.monthSummaryData = null;
        state.monthSummaryError = action.error.message;
      })
      // filter render
      .addCase(getFilteredMonthSummary.pending, (state) => {
        state.filteredMonthSummaryLoading = true;
        state.monthSummaryData = null;
        state.monthSummaryError = null;
      })
      .addCase(getFilteredMonthSummary.fulfilled, (state, action) => {
        state.filteredMonthSummaryLoading = false;
        state.monthSummaryData = action.payload;
        state.monthSummaryError = null;
      })
      .addCase(getFilteredMonthSummary.rejected, (state, action) => {
        state.filteredMonthSummaryLoading = false;
        state.monthSummaryData = null;
        state.monthSummaryError = action.error.message;
      });
  },
});

export default monthSummarySlice.reducer;
