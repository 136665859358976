import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  filterTaxReceiptsRecords,
  getAllData,
  sendEmailInTaxReceipts,
  updateDonorInTaxReceiptTable,
} from "../../Store/Slices/Sidebar/TaxReceipts/TaxReceiptsSlice";
import { addDonorFromTransactions } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import {
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import Tippy from "@tippyjs/react";
import { basic_paperplane } from "react-icons-kit/linea/basic_paperplane";
import { basic_download } from "react-icons-kit/linea/basic_download";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import noDataFound from "../../Images/noDataFound.png";
import toast from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import axios from "axios";
import hostName from "../../config";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import apiKey from "../private/private";

// custom styles
const styles = {
  menuPortal: (base) => ({ ...base, zIndex: 10004 }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const TaxReceiptTable = ({
  token,
  filteredParams,
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  totalPages,
  setTotalPages,
  setPageSize,
  setLoadings,
  year,
  countries,
  usaStates,
  provinces,
  donorSpecific,
  donorSpecificEmail,
}) => {
  // hook
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
    debounce: 1000,
    options: {
      componentRestrictions: {
        country: ["ca", "us"],
      },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  // states
  const [selectedPrediction, setSelectedPrediction] = useState(null);

  // input change
  const handleInputChange = (evt) => {
    const inputValue = evt.target.value;
    setStreetAddress(inputValue);
    getPlacePredictions({ input: inputValue });
    setSelectedPrediction(null);
  };

  // prediction click
  const handlePredictionClick = (prediction) => {
    setSelectedPrediction(prediction);

    // Extract address components from the prediction
    placesService?.getDetails(
      {
        placeId: prediction.place_id,
      },
      (place) => {
        const addressComponents = place.address_components;
        let mergedStreetAddress = "";

        // Loop through address components to find street number and route
        addressComponents.forEach((component) => {
          switch (component.types[0]) {
            case "street_number":
            case "route":
              mergedStreetAddress += `${component.long_name} `;
              break;
            case "locality":
              setCity(component.long_name);
              break;
            case "administrative_area_level_1":
              setProvinceState(component.short_name);
              break;
            case "country":
              setCountry(component.long_name);
              break;
            case "postal_code":
              setPostalZipCode(component.long_name);
              break;
            default:
              console.warn(
                "Unhandled address component type:",
                component.types[0]
              );
              // You can add additional handling for unhandled address component types here
              break;
          }
        });

        // Update the street address state variable with the merged street number and route
        setStreetAddress(mergedStreetAddress.trim());
      }
    );
  };

  // redux state
  const { allData } = useSelector((state) => state.taxReceipts);

  // redux state
  const { sendEmailInTaxReceiptsLoading, sendEmailInTaxReceiptsData } =
    useSelector((state) => state.taxReceipts);

  // redux state
  const { addDonorFromTransactionsLoading, addDonorFromTransactionsError } =
    useSelector((state) => state.transactions);

  // close modal
  const handleCloseModal = () => {
    if (!addDonorFromTransactionsLoading) {
      setAddDonorStep(false);
    }
  };

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (filteredParams && donorSpecific === undefined) {
        dispatch(
          filterTaxReceiptsRecords({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else if (donorSpecific === true) {
        // get donor specific tax receipts data
        dispatch(
          filterTaxReceiptsRecords({
            token,
            page: 1,
            pageSize: value,
            order: "desc",
            apiData: {
              email: donorSpecificEmail,
              by: "email",
            },
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getAllData({
            token,
            page: 1,
            pageSize: value,
            by: "email",
            order: "asc",
            year,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (filteredParams && donorSpecific === undefined) {
        dispatch(
          filterTaxReceiptsRecords({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else if (donorSpecific === true) {
        // get donor specific tax receipts data
        dispatch(
          filterTaxReceiptsRecords({
            token,
            page: value,
            pageSize: perPage,
            order: "desc",
            apiData: {
              email: donorSpecificEmail,
              by: "email",
            },
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getAllData({
            token,
            page: value,
            pageSize: perPage,
            by: "email",
            order: "asc",
            year,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // email send event
  const handleSendEmail = (data) => {
    dispatch(
      sendEmailInTaxReceipts({
        token,
        tagNumber: data.tagNumber,
      })
    ).then((res) => {
      if (!res.payload.axiosError) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(`${res.payload.apiError}`, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // download receipt states
  const [downloadReceiptLoading, setDownloadReceiptLoading] = useState(false);
  const [downloadReceiptCode, setDownloadReceiptCode] = useState(null);
  const [tagNumber, setTagNumber] = useState(null);
  const [addDonorStep, setAddDonorStep] = useState(false);

  // handle download receipt
  const handleDownloadReceipt = (data) => {
    if (data.donor) {
      setDownloadReceiptLoading(true);
      setDownloadReceiptCode(data.code);

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      };

      axios
        .get(
          `${hostName}api/admin/v1/taxReceipt/download?code=${data.code}`,
          axiosConfig
        )
        .then((response) => {
          // download pdf
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `taxReceipt.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          if (error.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            toast.error(`${error.message}`, {
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .finally(() => setDownloadReceiptLoading(false));
    } else {
      setAddDonorStep(true);
      setEmail(data.email);
      setDownloadReceiptCode(data.code);
      setTagNumber(data.tagNumber);
    }
  };

  // donor form states
  // profile
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const [phone, setPhone] = useState("");

  // address
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [provinceState, setProvinceState] = useState("");
  const [postalZipCode, setPostalZipCode] = useState("");

  // handle country change
  const handleCountryChange = (label) => {
    setCountry(label);
    setProvinceState("");
    setPostalZipCode("");
  };

  // add donor submit
  const handleAddDonorSubmit = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      firstName,
      middleName,
      lastName,
      phone,
      email,
      streetAddress,
      city,
      country,
      provinceState,
      postalZipCode,
    };
    if (password) {
      singleObj.password = password;
    }
    dispatch(addDonorFromTransactions(singleObj)).then((res) => {
      if (res.payload !== undefined) {
        setAddDonorStep(false);
        setDownloadReceiptLoading(true);
        const axiosConfig = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        };
        dispatch(
          updateDonorInTaxReceiptTable({
            donor: res.payload.data,
            tagNumber,
          })
        );
        axios
          .get(
            `${hostName}api/admin/v1/taxReceipt/download?code=${downloadReceiptCode}`,
            axiosConfig
          )
          .then((response) => {
            // download pdf
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `taxReceipt.pdf`);
            document.body.appendChild(link);
            link.click();
            // empty form
            setFirstName("");
            setMiddleName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setPassword(null);
            setStreetAddress("");
            setCountry("");
            setCity("");
            setPostalZipCode("");
            setProvinceState("");
          })
          .catch((error) => {
            if (error.message === "Request failed with status code 401") {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              toast.error(`${error.message}`, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .finally(() => setDownloadReceiptLoading(false));
      } else if (
        res.error &&
        res.error.message === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <>
      {allData && allData.data.length > 0 ? (
        <>
          <div className="transactions-table">
            <MDBTable align="middle">
              <MDBTableHead className="thead">
                <tr>
                  {donorSpecific === undefined && <th scope="col">Email</th>}
                  <th scope="col">Year</th>
                  <th scope="col">Receipt Number</th>
                  <th scope="col">Code</th>
                  <th scope="col">Issue Date</th>
                  <th scope="col">State</th>
                  <th scope="col">Actions</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {allData.data.map((data, index) => (
                  <tr
                    key={data.tagNumber}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                    }}
                  >
                    {donorSpecific === undefined && (
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {data.email ? (
                            <>
                              {data.donor ? (
                                <Tippy
                                  content={`${data.donor.firstName} ${data.donor.lastName}`}
                                  zIndex={10004}
                                >
                                  <Link
                                    to={`/donors/${data.donor.firstName}_${data.donor.lastName}_${data.donor.tagNumber}`}
                                    className="donor-hyper-link"
                                  >
                                    {data.email}
                                  </Link>
                                </Tippy>
                              ) : (
                                <>{data.email}</>
                              )}
                            </>
                          ) : (
                            <>Anonymous</>
                          )}
                        </p>
                      </td>
                    )}

                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.year}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.receiptNumber}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.code}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.issueDate}
                      </p>
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <Tippy content={data.state} zIndex={10004}>
                        <img
                          src={data.stateImage}
                          alt="status"
                          style={{
                            width: 30 + "px",
                            cursor: "pointer",
                          }}
                        />
                      </Tippy>
                    </td>

                    <td className="icons-td">
                      <div className="actions-icon-container">
                        {/* send email */}
                        {sendEmailInTaxReceiptsData &&
                        sendEmailInTaxReceiptsData.tagNumber ===
                          data.tagNumber &&
                        sendEmailInTaxReceiptsLoading ? (
                          <span className="loader-span">
                            <MetroSpinner
                              loading={sendEmailInTaxReceiptsLoading}
                              color="#007c16"
                              size={20}
                            />
                          </span>
                        ) : (
                          <Tippy content="Send Email" zIndex={10004}>
                            <span
                              onClick={() => handleSendEmail(data)}
                              style={{
                                opacity: data.tagNumber ? 1 : 0.5,
                                pointerEvents: data.tagNumber ? "auto" : "none",
                              }}
                            >
                              <Icon icon={basic_paperplane} size={20} />
                            </span>
                          </Tippy>
                        )}
                        {/* download receipt */}
                        {downloadReceiptLoading &&
                        downloadReceiptCode === data.code ? (
                          <span className="loader-span">
                            <MetroSpinner
                              loading={downloadReceiptLoading}
                              color="#007c16"
                              size={20}
                            />
                          </span>
                        ) : (
                          <Tippy content="Download Receipt" zIndex={10004}>
                            <span
                              onClick={() => handleDownloadReceipt(data)}
                              style={{
                                opacity: data.code ? 1 : 0.5,
                                pointerEvents: data.code ? "auto" : "none",
                              }}
                            >
                              <Icon icon={basic_download} size={20} />
                            </span>
                          </Tippy>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          </div>
          <div className="transactions-pagination">
            <div className="per-page-and-pagination">
              <div className="per-page">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === String(perPage)
                  )}
                  onChange={(option) => handlePerPage(option.value)}
                  options={perPageOptions}
                  styles={styles}
                  menuPlacement="top"
                  menuPortalTarget={document.body}
                />
              </div>
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No Tax Receipt Data Found">
            <img src={noDataFound} alt="No Tax Receipt Data Found" />
          </Tippy>
        </div>
      )}
      {addDonorStep && (
        <AnimatePresence>
          <motion.div
            className="update-widgets-backdrop"
            variants={backVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div
              className="update-widgets-modal media extra-width"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
            >
              <div className="update-widgets-modal-header">
                <div>Add Donor</div>
                <div
                  className="cancel-widget-update-icon"
                  onClick={handleCloseModal}
                >
                  <Icon icon={x} size={16} />
                </div>
              </div>
              <div className="update-widgets-modal-body">
                <form
                  className="update-media-form"
                  onSubmit={handleAddDonorSubmit}
                >
                  <div className="info-msg">
                    Register the donor account to download the tax receipt.
                  </div>
                  <h4
                    style={{
                      borderBottom: 1 + "px solid #e4e4e4",
                      paddingBottom: 5 + "px",
                      marginBottom: 15 + "px",
                    }}
                  >
                    Profile
                  </h4>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        placeholder="First Name"
                      />
                    </MDBCol>
                    <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                      <label>Middle Name</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                        placeholder="Middle Name"
                      />
                    </MDBCol>
                    <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        placeholder="Last Name"
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                      <label>Phone</label>
                      <input
                        type="tel"
                        pattern="[0-9]{10,15}"
                        title="Please enter a valid phone number with 10 to 15 digits."
                        className="edit-media-inputs"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        placeholder="Phone"
                      />
                    </MDBCol>
                    <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                      <label>Email</label>
                      <input
                        type="email"
                        className="edit-media-inputs"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly
                        required
                        placeholder="Email"
                      />
                    </MDBCol>
                    <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                      <label>Password</label>
                      <input
                        type="password"
                        className="edit-media-inputs"
                        value={password || ""}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        autoComplete="new-password"
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <h4
                    style={{
                      borderBottom: 1 + "px solid #e4e4e4",
                      paddingBottom: 5 + "px",
                      marginBottom: 15 + "px",
                    }}
                  >
                    Address
                  </h4>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                      <label>Street Address</label>
                      <div className="email-input-container">
                        <input
                          type="text"
                          className="edit-media-inputs"
                          value={streetAddress}
                          onChange={handleInputChange}
                          required
                          placeholder="Street Address"
                          autoComplete="new-street-alias"
                        />
                        {!isPlacePredictionsLoading &&
                          placePredictions.length > 0 &&
                          !selectedPrediction && (
                            <div className="suggestions-popup address">
                              {placePredictions.map((suggestion, index) => (
                                <div
                                  key={index}
                                  className="suggestion-item"
                                  onClick={() =>
                                    handlePredictionClick(suggestion)
                                  }
                                >
                                  <div className="suggestion-email">
                                    {suggestion.description}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    </MDBCol>
                    <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                      <label>City</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                        placeholder="City"
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                      <label>Country</label>
                      <Select
                        value={countries.find(
                          (option) => option.label === country
                        )}
                        onChange={(option) => handleCountryChange(option.label)}
                        options={countries}
                        styles={styles}
                        required
                        menuPlacement="top"
                      />
                    </MDBCol>
                    <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                      {country === "Canada" ? (
                        <>
                          <label>Province</label>
                          <Select
                            value={provinces.find(
                              (option) => option.value === provinceState
                            )}
                            onChange={(option) =>
                              setProvinceState(option.value)
                            }
                            options={provinces}
                            styles={styles}
                            required
                            menuPlacement="top"
                          />
                        </>
                      ) : (
                        <>
                          {country === "United States" ? (
                            <>
                              <label>State</label>
                              <Select
                                value={usaStates.find(
                                  (option) => option.value === provinceState
                                )}
                                onChange={(option) =>
                                  setProvinceState(option.value)
                                }
                                options={usaStates}
                                styles={styles}
                                required
                                menuPlacement="top"
                              />
                            </>
                          ) : (
                            <>
                              <label>Province</label>
                              <input
                                type="text"
                                className="edit-media-inputs"
                                value={provinceState}
                                onChange={(e) =>
                                  setProvinceState(e.target.value)
                                }
                                required
                                placeholder="Province"
                              />
                            </>
                          )}
                        </>
                      )}
                    </MDBCol>
                    <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                      <label>
                        {country === "United States"
                          ? "Zip Code"
                          : "Postal Code"}
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        value={postalZipCode}
                        onChange={(e) => setPostalZipCode(e.target.value)}
                        required
                        placeholder={
                          country === "United States"
                            ? "Zip Code"
                            : "Postal Code"
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                  {addDonorFromTransactionsError && (
                    <div className="error-msg">
                      {addDonorFromTransactionsError}
                    </div>
                  )}
                  <div className="submit-and-cancel-div">
                    <button
                      type="button"
                      className="cancel"
                      onClick={() => setAddDonorStep(false)}
                      disabled={addDonorFromTransactionsLoading}
                    >
                      CLOSE
                    </button>
                    <button
                      type="submit"
                      className="submit addModal"
                      disabled={addDonorFromTransactionsLoading}
                    >
                      {addDonorFromTransactionsLoading ? (
                        <MetroSpinner
                          loading={addDonorFromTransactionsLoading}
                          size={22}
                          color="#fff"
                        />
                      ) : (
                        "ADD"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default TaxReceiptTable;
