import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredChannelTypes } from "../../Store/Slices/Sidebar/Analytics/ChannelTypesSlice";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import { ecommerce_banknotes } from "react-icons-kit/linea/ecommerce_banknotes";
import Tippy from "@tippyjs/react";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import noDataFound from "../../Images/noDataFound.png";

// genearte border colors
function generateBorderColors(length) {
  const colors = [];

  // Define the colors in the gradient
  const gradientColors = [
    "#34495e",
    "#9b59b6",
    "#3498db",
    "#62cb31",
    "#ffb606",
  ];

  for (let i = 0; i < length; i++) {
    const index = i % gradientColors.length;
    colors.push(gradientColors[index]);
  }

  return colors;
}

// genearte background colors
function generateBackgroundColors(length) {
  const colors = [];

  // Define the colors in the gradient
  const gradientColors = [
    "#a6b2bf", // lighter shade of #34495e
    "#e9d7f0", // lighter shade of #9b59b6
    "#bce4fc", // lighter shade of #3498db
    "#c4f2ad", // lighter shade of #62cb31
    "#ffe78a", // lighter shade of #ffb606
  ];

  for (let i = 0; i < length; i++) {
    const index = i % gradientColors.length;
    colors.push(gradientColors[index]);
  }

  return colors;
}

const ChannelTypes = ({ token }) => {
  // redux state
  const { filteredChannelTypesLoading, channelTypesData, channelTypesError } =
    useSelector((state) => state.channelTypes);

  // calculate total amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (channelTypesData && channelTypesData.values.length > 0) {
      const calculatedAmounts = channelTypesData.values.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0
      );
      const roundedSum = calculatedAmounts.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [channelTypesData]);

  // Calculate the percentage
  const [percentageData, setPercentageData] = useState([]);
  useEffect(() => {
    if (channelTypesData && channelTypesData.values.length > 0) {
      const totalAmount = channelTypesData.values.reduce(
        (total, obj) => total + obj.amount,
        0
      );
      const newData = channelTypesData.values.map((data) => ({
        ...data,
        percentage: parseFloat(((data.amount / totalAmount) * 100).toFixed(2)),
      }));
      setPercentageData(newData);
    } else {
      setPercentageData([]);
    }
  }, [channelTypesData]);

  // border colors
  const [borderColors, setBorderColors] = useState([]);
  useEffect(() => {
    if (channelTypesData && channelTypesData.values.length > 0) {
      const colors = generateBorderColors(channelTypesData.values.length);
      setBorderColors(colors);
    } else {
      setBorderColors([]);
    }
  }, [channelTypesData]);

  // background colors
  const [backgroundColors, setBackgroundColors] = useState([]);
  useEffect(() => {
    if (channelTypesData && channelTypesData.values.length > 0) {
      const colors = generateBackgroundColors(channelTypesData.values.length);
      setBackgroundColors(colors);
    } else {
      setBackgroundColors([]);
    }
  }, [channelTypesData]);

  // popup state
  const [popup, setPopup] = useState(false);

  // year state
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [selectedYear, setSelectedYear] = useState(year);

  // month states
  const [month, setMonth] = useState({
    value: "",
    label: "",
  });
  const [selectedMonth, setSelectedMonth] = useState(month);

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // month options array
  const monthOptions = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle filter
  const handleMonthAndYearFilter = () => {
    setPopup(false);
    setSelectedMonth(month);
    setSelectedYear(year);
    const singleObj = {
      token,
      month: month.value,
      year,
    };
    dispatch(getFilteredChannelTypes(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  // custom styles
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#e1e1e9",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#b1b1b9",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
      boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
      "&:hover": {
        border: "1px solid #007C16",
        boxShadow: "0px 0px 1px #007C16",
      },
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#007C16"
        : isFocused
        ? "rgba(0, 124, 22, 0.2)"
        : base.backgroundColor,
      color: isSelected ? "white" : base.color,
    }),
  };

  return (
    <>
      <div className="text-and-filter-div">
        <span>
          Total Amount in{" "}
          {selectedMonth.label !== "" && <>{selectedMonth.label}</>}{" "}
          {selectedYear}{" "}
          <span style={{ color: "#007C16" }}>
            {channelTypesData && <>{channelTypesData.currencySymbol}</>}
            {sum}
          </span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Year and Month</h4>
          <div className="month-summary-filter-form">
            <div className="filter-form-col">
              <label>Month</label>
              <Select
                value={monthOptions.find(
                  (option) => option.value === month.value
                )}
                onChange={(option) => {
                  if (option) {
                    setMonth(option);
                  } else {
                    // If the selected option is null, setMonth with an empty value and label
                    setMonth({
                      value: "",
                      label: "",
                    });
                  }
                }}
                isClearable
                options={monthOptions}
                styles={styles}
              />
            </div>
            <div className="filter-form-col">
              <label>Year</label>
              <Select
                value={yearOptions.find((option) => option.value === year)}
                onChange={(option) => setYear(option.value)}
                options={yearOptions}
                styles={styles}
              />
            </div>
            <div className="popup-filter-btn-div">
              <button type="button" onClick={handleMonthAndYearFilter}>
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row-content">
        <div className="icon-with-text">
          <Tippy content="Payment Sources">
            <span>Channel Types</span>
          </Tippy>
          <Icon
            icon={ecommerce_banknotes}
            size={36}
            style={{ marginRight: 10 + "px" }}
          />
        </div>
        {filteredChannelTypesLoading ? (
          <div className="absoluted-box">
            <MetroSpinner
              size={30}
              color="#007C16"
              loading={filteredChannelTypesLoading}
            />
          </div>
        ) : (
          <>
            {channelTypesError ? (
              <div className="absoluted-box">
                <div className="error-msg" style={{ marginTop: 0 }}>
                  {channelTypesError}
                </div>
              </div>
            ) : (
              <>
                {percentageData.length > 0 ? (
                  percentageData.map((data, index) => (
                    <div className="progress-container" key={index}>
                      <Tippy
                        content={`Transactions: ${data.numberOfTransaction}`}
                      >
                        <label
                          style={{
                            fontSize: 12 + "px",
                            fontWeight: 600,
                            marginBottom: 1 + "px",
                            color: "#007C16",
                          }}
                        >
                          {data.text}
                        </label>
                      </Tippy>

                      <Tippy
                        content={`Amount: ${channelTypesData.currencySymbol}${data.amount}`}
                      >
                        <div className="progress-and-percentage">
                          <div className="channel-progress-bar">
                            <div
                              className="progress-bar-filled"
                              style={{
                                width: `${data.percentage}%`,
                                backgroundColor: borderColors[index],
                              }}
                            ></div>
                          </div>

                          <div
                            className="percentage-div"
                            style={{
                              backgroundColor: backgroundColors[index],
                              color: borderColors[index],
                              fontSize: 13 + "px",
                              padding: 10 + "px",
                            }}
                          >
                            {data.percentage}%
                          </div>
                        </div>
                      </Tippy>
                    </div>
                  ))
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Data Found">
                      <img src={noDataFound} alt="No Data Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ChannelTypes;
