import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../../config";

// get eTransfer configuration
export const getETransferConfiguration = createAsyncThunk(
  "eTransfer/getETransferConfiguration",
  async (token) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/eTransfer`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// delete eTransfer configuration
export const deleteETransferConfiguration = createAsyncThunk(
  "eTransfer/deleteETransferConfiguration",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/eTransfer/${singleObj.tagNumber}`,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// add eTransfer configuration
export const addETransferConfiguration = createAsyncThunk(
  "eTransfer/addETransferConfiguration",
  async (singleObj) => {
    try {
      const data = {
        campaignTag: singleObj.campaignTag,
        startDate: singleObj.startDate,
        username: singleObj.username,
        password: singleObj.password,
        type: singleObj.selectedTransferType,
      };

      if (singleObj.status) {
        data.status = "A";
      } else {
        data.status = "I";
      }

      if (singleObj.alertEmail !== "") {
        data.alertEmail = singleObj.alertEmail;
      }

      if (singleObj.archiveFolder !== "") {
        data.archiveFolder = singleObj.archiveFolder;
      }

      if (singleObj.emailAlias !== "") {
        data.emailAlias = singleObj.emailAlias;
      }

      if (singleObj.selectedTransferType === "IMAP") {
        data.host = singleObj.host;
        data.port = singleObj.port;
        data.encryption = singleObj.encryption;
        data.validateCert = singleObj.validateCert;
        data.protocol = singleObj.protocol;
      }

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/eTransfer`,
        data,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
      };
    }
  }
);

// edit eTransfer configuration
export const editETransferConfiguration = createAsyncThunk(
  "eTransfer/editETransferConfiguration",
  async (singleObj) => {
    try {
      const data = {
        campaignTag: singleObj.campaignTag,
        startDate: singleObj.startDate,
        username: singleObj.username,
        type: singleObj.selectedTransferType,
      };

      if (!singleObj.password || singleObj.password === "") {
        data.password = null;
      } else {
        data.password = singleObj.password;
      }

      if (singleObj.status) {
        data.status = "A";
      } else {
        data.status = "I";
      }

      if (singleObj.alertEmail !== "") {
        data.alertEmail = singleObj.alertEmail;
      }

      if (singleObj.emailAlias !== "") {
        data.emailAlias = singleObj.emailAlias;
      }

      if (singleObj.archiveFolder !== "") {
        data.archiveFolder = singleObj.archiveFolder;
      }

      if (singleObj.selectedTransferType === "IMAP") {
        data.host = singleObj.host;
        data.port = singleObj.port;
        data.encryption = singleObj.encryption;
        data.validateCert = singleObj.validateCert;
        data.protocol = singleObj.protocol;
      }

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.put(
        `${hostName}api/admin/v1/eTransfer/${singleObj.tagNumber}`,
        data,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

const eTransferSlice = createSlice({
  name: "eTransfer",
  initialState: {
    // get eTransfer configuration
    eTransferConfigurationLoading: false,
    eTransferConfigurationData: null,
    eTransferConfigurationError: null,
    // delete eTransfer configuration
    deleteETransferLoading: false,
    deleteETransferData: null,
    // add eTransfer configuration
    addETransferLoading: false,
    addETransferData: null,
    // add eTransfer configuration
    editETransferLoading: false,
    editETransferData: null,
  },
  extraReducers: (builder) => {
    builder
      // eTransfer configuration
      .addCase(getETransferConfiguration.pending, (state) => {
        state.eTransferConfigurationLoading = true;
        state.eTransferConfigurationData = null;
        state.eTransferConfigurationError = null;
      })
      .addCase(getETransferConfiguration.fulfilled, (state, action) => {
        state.eTransferConfigurationLoading = false;
        state.eTransferConfigurationData = action.payload;
        state.eTransferConfigurationError = null;
      })
      .addCase(getETransferConfiguration.rejected, (state, action) => {
        state.eTransferConfigurationLoading = false;
        state.eTransferConfigurationData = null;
        state.eTransferConfigurationError = action.error.message;
      })
      // delete eTransfer configuration
      .addCase(deleteETransferConfiguration.pending, (state) => {
        state.deleteETransferLoading = true;
        state.deleteETransferData = null;
      })
      .addCase(deleteETransferConfiguration.fulfilled, (state, action) => {
        state.deleteETransferLoading = false;
        state.deleteETransferData = action.payload;
        const { axiosError, tagNumber } = action.payload;
        if (!axiosError) {
          state.eTransferConfigurationData =
            state.eTransferConfigurationData.filter(
              (data) => data.tagNumber !== tagNumber
            );
        }
      })
      // add eTransfer configuration
      .addCase(addETransferConfiguration.pending, (state) => {
        state.addETransferLoading = true;
        state.addETransferData = null;
      })
      .addCase(addETransferConfiguration.fulfilled, (state, action) => {
        state.addETransferLoading = false;
        state.addETransferData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.eTransferConfigurationData.unshift(data);
        }
      })
      // edit eTransfer configuration
      .addCase(editETransferConfiguration.pending, (state) => {
        state.editETransferLoading = true;
        state.editETransferData = null;
      })
      .addCase(editETransferConfiguration.fulfilled, (state, action) => {
        state.editETransferLoading = false;
        state.editETransferData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const index = state.eTransferConfigurationData.findIndex(
            (data) => data.tagNumber === tagNumber
          );
          if (index !== -1) {
            state.eTransferConfigurationData[index] = data;
          }
        }
      });
  },
});

export default eTransferSlice.reducer;
