import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import React from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { software_pencil } from "react-icons-kit/linea/software_pencil";

const ConfigurationTable = ({ handleEditConfiguration }) => {
  // redux state
  const { configurationData, configurationError } = useSelector(
    (state) => state.notifications
  );

  return (
    <>
      {configurationError ? (
        <div className="error-msg" style={{ width: "fit-content" }}>
          {configurationError}
        </div>
      ) : (
        <>
          {configurationData?.data?.length > 0 ? (
            <div className="transactions-table">
              <MDBTable align="middle" className="configuration-table">
                <MDBTableHead className="thead">
                  <tr>
                    <th scope="col">Notification</th>
                    <th scope="col">Description</th>
                    <th scope="col">Status</th>
                    <th scope="col">Emails</th>
                    <th scope="col">Actions</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {configurationData.data.map((data, index) => (
                    <tr
                      key={data.tagNumber}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                      }}
                    >
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {data.shortDescriptionEn}
                        </p>
                      </td>
                      <td className="long-description">
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {data.longDescriptionEn}
                        </p>
                      </td>
                      <td>
                        <Tippy content={data.statusText} zIndex={10004}>
                          <img
                            src={data.statusImage}
                            alt="type"
                            style={{
                              width: 30 + "px",
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                          />
                        </Tippy>
                      </td>
                      <td className="emails-cell">
                        {data.emails.map((email, emailIndex) => (
                          <p key={emailIndex} className="email-item">
                            {email}
                          </p>
                        ))}
                      </td>
                      <td className="icons-td">
                        <div className="actions-icon-container">
                          {/* edit */}
                          <Tippy content="Edit" zIndex={10004}>
                            <span
                              onClick={() => {
                                handleEditConfiguration(data);
                              }}
                            >
                              <Icon icon={software_pencil} size={20} />
                            </span>
                          </Tippy>
                        </div>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Configuration Found">
                <img src={noDataFound} alt="No Configuration Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ConfigurationTable;
