/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/Import.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  filterDataRecords,
  getImports,
} from "../../Store/Slices/Sidebar/Data/DataSlice";
import { MetroSpinner } from "react-spinners-kit";
import { Toaster } from "react-hot-toast";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Icon from "react-icons-kit";
import { filter } from "react-icons-kit/feather/filter";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import ImportsCards from "../DataManagment/ImportsCards";
import ImportDetailsPopup from "../DataManagment/ImportDetailsPopup";
import NewImportPopup from "../DataManagment/NewImportPopup";
import DeleteImportPopup from "../DataManagment/DeleteImportPopup";
import DownloadTemplatePopup from "../DataManagment/DownloadTemplatePopup";
import FilterDataModal from "../DataManagment/FilterDataModal";
import axios from "axios";
import hostName from "../../config";

const DataComponent = ({ token }) => {
  // redux state
  const { importsLoading } = useSelector((state) => state.dataManagement);

  // states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("10");

  // page size is used in filter modal
  const [pageSize, setPageSize] = useState("10");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // entity options
  const [entityOptions, setEntityOptions] = useState([]);
  const [entityOptionsLoading, setEntityOptionsLoading] = useState(true);
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${hostName}api/admin/v1/lov?listName=dataFilter`, axiosConfig)
      .then((res) => {
        setEntityOptions(res.data.data);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setEntityOptionsLoading(false));
  }, []);

  // filtered params
  const [filteredParams, setFilteredParams] = useState(null);

  // filter modal
  const [filterDataModal, setFilterDataModal] = useState(false);

  // status
  const [status, setStatus] = useState([]);
  const handleStatusChange = (item) => {
    if (status.includes(item)) {
      setStatus(status.filter((checkedItem) => checkedItem !== item));
    } else {
      setStatus([...status, item]);
    }
  };

  // type
  const [type, setType] = useState([]);
  const handleTypeChange = (item) => {
    if (type.includes(item)) {
      setType(type.filter((checkedItem) => checkedItem !== item));
    } else {
      setType([...type, item]);
    }
  };

  // status and type options
  const [statusOptions, setStatusOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [routeLoading, setRouteLoading] = useState(true);

  // getting options
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${hostName}api/admin/v1/routeLov?routeName=data`, axiosConfig)
      .then((res) => {
        setStatusOptions(res.data.data.status);
        setTypeOptions(res.data.data.type);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setRouteLoading(false));
  }, []);

  // sort by
  const [sortBy, setSortBy] = useState(null);

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // state to check if any loading from redux is true
  const [loadings, setLoadings] = useState(true);

  // checking if any loading is true
  const allLoadingStates = [importsLoading, entityOptionsLoading, routeLoading];
  useEffect(() => {
    const isAnyChildLoading = allLoadingStates.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadingStates]);

  // reset filter
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilteredParams(null);
      setFilterDataModal(false);
    }
    setEntitySelected([]);
    setType([]);
    setStatus([]);
    setPageSize(perPage);
    setSortBy(null);
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get imports
  useEffect(() => {
    if (filteredParams) {
      dispatch(
        filterDataRecords({
          token,
          page: 1,
          pageSize: filteredParams.pageSize,
          order: filteredParams.order,
          apiData: filteredParams.apiData,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    } else {
      dispatch(
        getImports({
          token,
          page: 1,
          pageSize: "10",
          order: "asc",
          by: "type",
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    }
  }, [filteredParams]);

  // import details popup
  const [importDetailsPopup, setImportDetailsPopup] = useState(false);
  const [importDetailsObj, setImportDetailsObj] = useState(null);

  const handleImportDetailsPopup = (importObj) => {
    setImportDetailsPopup(true);
    setImportDetailsObj(importObj);
  };

  // import popup
  const [newImportPopup, setNewImportPopup] = useState(false);

  // download template popup
  const [downloadTemplatePopup, setDownloadTemplatePopup] = useState(false);

  // delete import popup
  const [deleteImportPopup, setDeleteImportPopup] = useState(false);
  const [deleteImportObj, setDeleteImportObj] = useState(null);

  const handleDeleteImportPopup = (obj) => {
    setDeleteImportPopup(true);
    setDeleteImportObj(obj);
  };

  return (
    <>
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              <Toaster />
              <div className="media-heading-button-container">
                <h6>Data Management</h6>
                <div className="add-and-filter-btns-div">
                  <div className="action-btns-div">
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setNewImportPopup(true)}
                    >
                      New Import
                    </button>
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setDownloadTemplatePopup(true)}
                    >
                      Download Template
                    </button>
                  </div>
                  <div className="filter-and-reset-btns-div">
                    <Tippy content="Filter Records">
                      <button
                        className="filter-media-modal-btn"
                        type="button"
                        style={{ marginRight: 5 + "px" }}
                        onClick={() => setFilterDataModal(true)}
                      >
                        <Icon icon={filter} size={24} />
                      </button>
                    </Tippy>
                    {filteredParams && (
                      <Tippy content="Reset Records">
                        <button
                          className="filter-media-modal-btn reset"
                          type="button"
                          onClick={handleResetFilter}
                        >
                          <Icon icon={refreshCw} size={24} />
                        </button>
                      </Tippy>
                    )}
                  </div>
                </div>
              </div>
              <div className="media-row">
                <ImportsCards
                  token={token}
                  currentPage={currentPage}
                  perPage={perPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  setPerPage={setPerPage}
                  setPageSize={setPageSize}
                  setTotalPages={setTotalPages}
                  handleImportDetailsPopup={handleImportDetailsPopup}
                  handleDeleteImportPopup={handleDeleteImportPopup}
                  filteredParams={filteredParams}
                  setLoadings={setLoadings}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {/* import details */}
      {importDetailsPopup && (
        <ImportDetailsPopup
          token={token}
          setImportDetailsPopup={setImportDetailsPopup}
          importDetailsObj={importDetailsObj}
        />
      )}
      {/* new import popup */}
      {newImportPopup && (
        <NewImportPopup token={token} setNewImportPopup={setNewImportPopup} />
      )}
      {/* download template popup */}
      {downloadTemplatePopup && (
        <DownloadTemplatePopup
          token={token}
          setDownloadTemplatePopup={setDownloadTemplatePopup}
        />
      )}
      {/* delete import popup */}
      {deleteImportPopup && (
        <DeleteImportPopup
          token={token}
          setDeleteImportPopup={setDeleteImportPopup}
          deleteImportObj={deleteImportObj}
        />
      )}
      {/* filter data modal */}
      {filterDataModal && (
        <FilterDataModal
          setFilterDataModal={setFilterDataModal}
          entityOptions={entityOptions}
          entitySelected={entitySelected}
          handleSelectChange={handleSelectChange}
          type={type}
          handleTypeChange={handleTypeChange}
          typeOptions={typeOptions}
          status={status}
          handleStatusChange={handleStatusChange}
          statusOptions={statusOptions}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          pageSize={pageSize}
          handlePerPage={handlePerPage}
          setFilteredParams={setFilteredParams}
          setLoadings={setLoadings}
          handleResetFilter={handleResetFilter}
        />
      )}
    </>
  );
};

export default DataComponent;
