import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../config";

// get cards in wallet
export const getWallets = createAsyncThunk(
  "wallet/getWallet",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/payment/wallet?email=${singleObj.email}`,
      axiosConfig
    );
    const response = await request.data;
    return {
      wallets: response.data,
      email: singleObj.email,
    };
  }
);

// add wallet object
export const addWallet = createAsyncThunk(
  "wallet/addWallet",
  async (singleObj) => {
    try {
      const data = {
        email: singleObj.email,
        nameOnCard: singleObj.cardHolderName,
        number: singleObj.cardNumber,
        expiryYear: singleObj.expiryYear,
        expiryMonth: singleObj.expiryMonth,
        cvd: singleObj.cvc,
        function: "DEF",
      };

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/donor/payment/wallet`,
        data,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (err) {
      return {
        data: null,
        successMsg: null,
        apiError: err.response.data.messageDetails,
        axiosError: err.message,
      };
    }
  }
);

// delete wallet
export const deleteWallet = createAsyncThunk(
  "wallet/deleteWallet",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        params: {
          email: singleObj.email,
        },
        ...axiosConfig,
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/donor/payment/wallet/${singleObj.cardId}`,
        requestedData
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        cardId: singleObj.cardId,
      };
    } catch (error) {
      return {
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        cardId: singleObj.cardId,
      };
    }
  }
);

// edit wallet object
export const editWallet = createAsyncThunk(
  "wallet/editWallet",
  async (singleObj) => {
    try {
      const data = {
        email: singleObj.email,
        nameOnCard: singleObj.cardHolderName,
        number: singleObj.cardNumber,
        expiryYear: singleObj.expiryYear,
        expiryMonth: singleObj.expiryMonth,
        cvd: singleObj.cvc,
      };

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.put(
        `${hostName}api/admin/v1/donor/payment/wallet/${singleObj.cardId}`,
        data,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (err) {
      return {
        data: null,
        successMsg: null,
        apiError: err.response.data.messageDetails,
        axiosError: err.message,
      };
    }
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    // get wallet
    walletLoading: false,
    walletData: null,
    walletError: null,
    selectedWallet: null,
    walletEmail: "",
    // add wallet
    addWalletLoading: false,
    addWalletData: null,
    // delete wallet
    deleteWalletLoading: false,
    deleteWalletData: null,
    // edit wallet
    editWalletLoading: false,
    editWalletData: null,
  },
  reducers: {
    setSelectedWallet: (state, action) => {
      state.selectedWallet = action.payload;
    },
    updateWallets: (state, action) => {
      if (!state.walletData && !action.payload) {
        return;
      }

      let updatedWalletData = [...state.walletData.wallets];

      const selectedIndex = updatedWalletData.findIndex(
        (wallet) => wallet.cardId === action.payload.cardId
      );

      if (selectedIndex !== -1) {
        // If the selectedWallet is SEC, make it DEF and move it to index 0
        if (action.payload.function === "SEC") {
          const selectedWallet = { ...updatedWalletData[selectedIndex] };
          selectedWallet.function = "DEF";
          updatedWalletData.splice(selectedIndex, 1);
          updatedWalletData.unshift(selectedWallet);
        }
        // Set the function to "SEC" for all other wallets except the above one
        updatedWalletData = updatedWalletData.map((wallet, index) => ({
          ...wallet,
          function: index === 0 ? "DEF" : "SEC",
        }));
      }

      // Update the walletData with the updated array
      state.walletData.wallets = updatedWalletData;
    },
  },
  extraReducers: (builder) => {
    builder
      // get wallet
      .addCase(getWallets.pending, (state) => {
        state.walletLoading = true;
        state.walletData = null;
        state.walletError = null;
      })
      .addCase(getWallets.fulfilled, (state, action) => {
        state.walletLoading = false;
        state.walletData = action.payload;
        state.walletError = null;
        state.walletEmail = action.payload.email;
        state.selectedWallet = null;
      })
      .addCase(getWallets.rejected, (state, action) => {
        state.walletLoading = false;
        state.walletData = null;
        state.walletError = action.error.message;
        state.selectedWallet = null;
        state.walletEmail = "";
      })
      // add wallet
      .addCase(addWallet.pending, (state) => {
        state.addWalletLoading = true;
        state.addWalletData = null;
      })
      .addCase(addWallet.fulfilled, (state, action) => {
        state.addWalletLoading = false;
        state.addWalletData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.walletData.wallets.forEach((wallet) => {
            if (wallet.cardId !== data.cardId) {
              wallet.function = "SEC";
            }
          });
          state.walletData.wallets.unshift(data);
        }
      })
      // delete wallet
      .addCase(deleteWallet.pending, (state) => {
        state.deleteWalletLoading = true;
        state.deleteWalletData = null;
      })
      .addCase(deleteWallet.fulfilled, (state, action) => {
        state.deleteWalletLoading = false;
        state.deleteWalletData = action.payload;
      })
      // edit wallet
      .addCase(editWallet.pending, (state) => {
        state.editWalletLoading = true;
        state.editWalletData = null;
      })
      .addCase(editWallet.fulfilled, (state, action) => {
        state.editWalletLoading = false;
        state.editWalletData = action.payload;
        const { data } = action.payload;
        if (data) {
          const { cardId } = data;
          const index = state.walletData.wallets.findIndex(
            (wallet) => wallet.cardId === cardId
          );
          if (index !== -1) {
            state.walletData.wallets[index] = data;
          }
        }
      });
  },
});

export default walletSlice.reducer;
export const { setSelectedWallet, updateWallets } = walletSlice.actions;
