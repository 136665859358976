import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDonorFilteredRecentTransactions } from "../../../../Store/Slices/Sidebar/Donors/DonorHighlightsSlice";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import Tippy from "@tippyjs/react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { MetroSpinner } from "react-spinners-kit";
import noDataFound from "../../../../Images/noDataFound.png";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

const RecentTransactions = ({ token, email }) => {
  // redux state
  const {
    filteredRecentTransactionsLoading,
    recentTransactionsData,
    recentTransactionsError,
  } = useSelector((state) => state.donorHighlights);

  // states
  const [numberInput, setNumberInput] = useState(5);
  const [selectedNumber, setSelectedNumber] = useState(numberInput);
  const [popup, setPopup] = useState(false);

  // number change
  const handleNumberChange = (event) => {
    let newValue = parseInt(event.target.value, 10);
    if (newValue < 1) {
      newValue = 1;
    } else if (newValue > 20) {
      newValue = 20;
    }
    setNumberInput(newValue);
  };

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle filter
  const handleNumberFilter = () => {
    setPopup(false);

    // Check if numberInput is NaN, and if it is, set it to 1
    let filteredNumber = isNaN(numberInput) ? 1 : numberInput;

    setSelectedNumber(filteredNumber);
    setNumberInput(filteredNumber);

    const singleObj = {
      token,
      n: filteredNumber,
      email,
    };

    dispatch(getDonorFilteredRecentTransactions(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  return (
    <>
      <div className="box-header">
        <span>
          Recent Transactions -{" "}
          <span style={{ color: "#007c16" }}>{selectedNumber}</span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span
            style={{ cursor: "pointer", color: "#6a6c6f" }}
            onClick={() => setPopup(!popup)}
          >
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Number of transactions</h4>
          <CustomTextField
            type="number"
            variant="outlined"
            fullWidth
            autoComplete="off"
            value={numberInput}
            onChange={handleNumberChange}
            min={1}
            max={20}
            size="small"
          />
          <div className="popup-filter-btn-div">
            <button type="button" onClick={handleNumberFilter}>
              Filter
            </button>
          </div>
        </div>
      </div>
      <div className="lifetime-content-wrapper">
        {filteredRecentTransactionsLoading ? (
          <div className="content-wrappr-loader">
            <MetroSpinner
              loading={filteredRecentTransactionsLoading}
              size={30}
              color="#007c16"
            />
          </div>
        ) : (
          <>
            {recentTransactionsError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {recentTransactionsError}
              </div>
            ) : (
              <>
                {recentTransactionsData &&
                recentTransactionsData.transactions.length > 0 ? (
                  <MDBTable align="middle" responsive>
                    <MDBTableHead
                      className="thead"
                      style={{ background: "#fafafa" }}
                    >
                      <tr>
                        <th scope="col">Campaign</th>
                        <th scope="col">Channel</th>
                        <th scope="col">Amount</th>
                        <th scope="col" style={{ width: "25%" }}>
                          Tender Type
                        </th>
                        <th scope="col">Date</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {recentTransactionsData.transactions.map(
                        (transaction, index) => (
                          <tr
                            key={transaction.tagNumber}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fff" : "#fafafa",
                            }}
                          >
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={`${transaction.campaignLogo}`}
                                  alt="Campaign Img"
                                  style={{ width: "45px", height: "45px" }}
                                  className="rounded-circle"
                                />
                                <div className="ms-3">
                                  <p className="fw-bold mb-1">
                                    {transaction.campaign}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p
                                className="fw-normal mb-1"
                                style={{ fontSize: "16px" }}
                              >
                                {transaction.nodeName}
                              </p>
                            </td>
                            <td>
                              <p
                                className="fw-normal mb-1"
                                style={{ fontSize: "16px" }}
                              >
                                {recentTransactionsData.currencySymbol}
                                {transaction.approvedAmount}
                              </p>
                            </td>
                            <td>
                              <p
                                className="fw-normal mb-1"
                                style={{ fontSize: "16px" }}
                              >
                                {transaction.cardType}
                              </p>
                            </td>
                            <td>
                              <p
                                className="fw-normal mb-1"
                                style={{ fontSize: "16px" }}
                              >
                                {transaction.transactionDate}
                              </p>
                            </td>
                          </tr>
                        )
                      )}
                    </MDBTableBody>
                  </MDBTable>
                ) : (
                  <div className="content-wrappr-loader">
                    <Tippy content="No Data Found">
                      <img
                        src={noDataFound}
                        alt="No Data Found"
                        width={`100px`}
                        height={`auto`}
                      />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RecentTransactions;
