/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/AppExplorer.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllApps } from "../../Store/Slices/Sidebar/AppExplorer/AppExplorerSlice";
import { MetroSpinner } from "react-spinners-kit";
import { Toaster } from "react-hot-toast";
import Apps from "../AppExplorer/Apps";
import CallLogPopup from "../AppExplorer/CallLogPopup";
import DisconnectPopup from "../AppExplorer/DisconnectPopup";
import SharePopup from "../AppExplorer/SharePopup";
import SwitchPopup from "../AppExplorer/SwitchPopup";
import TestPopup from "../AppExplorer/TestPopup";
import SyncPopup from "../AppExplorer/SyncPopup";

// get user
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

// get organization
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const AppExplorerComponent = ({ token }) => {
  // states
  const [organizations] = useState(getUser()?.organizations || []);
  const [selectedOrganization] = useState(getOrganization());

  // redux state
  const { appsLoading, appsError, postLoading } = useSelector(
    (state) => state.appExplorer
  );

  // main loadings state
  const [loadings, setLoadings] = useState(true);

  const allLoadings = [appsLoading, postLoading];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get all apps
  useEffect(() => {
    dispatch(getAllApps(token))
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // handle call log popup
  const [callLogPopup, setCallLogPopup] = useState(false);
  const [callLogObj, setCallLogObj] = useState(null);

  const handleCallLogPopup = (app) => {
    setCallLogPopup(true);
    setCallLogObj(app);
  };

  // handle disconnect popup
  const [disconnectPopup, setDisconnectPopup] = useState(false);
  const [disconnectObj, setDisconnectObj] = useState(null);

  const handleDisconnectPopup = (app) => {
    setDisconnectPopup(true);
    setDisconnectObj(app);
  };

  // handle share popup
  const [sharePopup, setSharePopup] = useState(false);
  const [shareObj, setShareObj] = useState(null);

  const handleSharePopup = (app) => {
    setSharePopup(true);
    setShareObj(app);
  };

  // handle switch popup
  const [switchPopup, setSwitchPopup] = useState(false);
  const [switchObj, setSwitchObj] = useState(null);

  const handleSwitchPopup = (app) => {
    setSwitchPopup(true);
    setSwitchObj(app);
  };

  // handle test popup
  const [testPopup, setTestPopup] = useState(false);
  const [testObj, setTestObj] = useState(null);

  const handleTestPopup = (app) => {
    setTestPopup(true);
    setTestObj(app);
  };

  // handle sync popup
  const [syncPopup, setSyncPopup] = useState(false);
  const [syncObj, setSyncObj] = useState(null);

  const handleSyncPopup = (app) => {
    setSyncPopup(true);
    setSyncObj(app);
  };

  return (
    <>
      <Toaster />
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              {appsError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {appsError}
                </div>
              ) : (
                <Apps
                  token={token}
                  organizations={organizations}
                  handleCallLogPopup={handleCallLogPopup}
                  handleDisconnectPopup={handleDisconnectPopup}
                  handleSharePopup={handleSharePopup}
                  handleSwitchPopup={handleSwitchPopup}
                  handleTestPopup={handleTestPopup}
                  handleSyncPopup={handleSyncPopup}
                />
              )}
            </>
          )}
        </div>
      </div>

      {/* call log */}
      {callLogPopup && (
        <CallLogPopup
          token={token}
          setCallLogPopup={setCallLogPopup}
          callLogObj={callLogObj}
        />
      )}
      {/* disconnect */}
      {disconnectPopup && (
        <DisconnectPopup
          token={token}
          setDisconnectPopup={setDisconnectPopup}
          disconnectObj={disconnectObj}
        />
      )}
      {/* share */}
      {sharePopup && (
        <SharePopup
          token={token}
          setSharePopup={setSharePopup}
          shareObj={shareObj}
          organizations={organizations}
          selectedOrganization={selectedOrganization}
        />
      )}
      {/* switch */}
      {switchPopup && (
        <SwitchPopup
          token={token}
          setSwitchPopup={setSwitchPopup}
          switchObj={switchObj}
        />
      )}
      {/* test */}
      {testPopup && (
        <TestPopup
          token={token}
          setTestPopup={setTestPopup}
          testObj={testObj}
        />
      )}
      {/* sync */}
      {syncPopup && (
        <SyncPopup
          token={token}
          setSyncPopup={setSyncPopup}
          syncObj={syncObj}
        />
      )}
    </>
  );
};

export default AppExplorerComponent;
