/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CustomTextField } from "../Common/CustomTextField";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, resetLoginStates } from "../../Store/Slices/Auth/UserSlice";
import { getUnreadNotifications } from "../../Store/Slices/Sidebar/Notifications/NotificationsSlice";

const LoginForm = () => {
  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Redux state
  const { loading, error } = useSelector((state) => state.user);

  // navigate
  const navigate = useNavigate();

  // login form submit event
  const dispatch = useDispatch();
  const handleLogin = (e) => {
    e.preventDefault();
    const userObj = {
      email,
      password,
    };
    dispatch(loginUser(userObj)).then((result) => {
      if (result.payload) {
        setEmail("");
        setPassword("");
        navigate("/dashboard");

        // get unread notifications
        dispatch(getUnreadNotifications({ token: result.payload.token }))
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      }
    });
  };

  // prevent link click if login loading is true
  const handleLinkClick = (e) => {
    if (loading) {
      e.preventDefault();
    }
  };

  // reset login states
  useEffect(() => {
    dispatch(resetLoginStates());
  }, []);

  return (
    <form className="custom-form" onSubmit={handleLogin}>
      <CustomTextField
        label="Email"
        type="email"
        variant="standard"
        fullWidth
        required
        autoComplete="off"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <br />
      <CustomTextField
        label="Password"
        type="password"
        variant="standard"
        fullWidth
        required
        autoComplete="off"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button type="submit" className="login-submit-btn" disabled={loading}>
        {loading ? "LOADING..." : "LOGIN"}
      </button>
      {error && <div className="error-msg">{error}</div>}
      <div className="forgot-password-div">
        <Link
          to="/forgotpassword"
          className="forgot-password-link"
          onClick={handleLinkClick}
        >
          Forgot Password?
        </Link>
      </div>
    </form>
  );
};

export default LoginForm;
