/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// css
import "../../CSS/Sidebar/Analytics.css";
// react spinners kit
import { MetroSpinner } from "react-spinners-kit";
// react router dom
import { useNavigate } from "react-router-dom";
// redux useDispatch, useSelector
import { useDispatch, useSelector } from "react-redux";
// analytics actions
import { getYearOverYears } from "../../Store/Slices/Sidebar/Analytics/YearOverYearsSlice";
import { getTenderTypes } from "../../Store/Slices/Sidebar/Analytics/TenderTypesSlice";
import { getChannelTypes } from "../../Store/Slices/Sidebar/Analytics/ChannelTypesSlice";
import { getDonationsPyramid } from "../../Store/Slices/Sidebar/Analytics/DonationsPyramidSlice";
import { getCampaignByDay } from "../../Store/Slices/Sidebar/Analytics/CampaignByDaySlice";
import { getTopDonor } from "../../Store/Slices/Sidebar/Analytics/TopDonorSlice";
import { getTopDonations } from "../../Store/Slices/Sidebar/Analytics/TopDonationsSlice";
import { getReceiptedDonations } from "../../Store/Slices/Sidebar/Analytics/ReceiptedDonationsSlice";
import { getFeeSummary } from "../../Store/Slices/Sidebar/Analytics/FeeSummarySlice";
// analytics components
import YearOverYears from "../Analytics/YearOverYears";
import TenderTypes from "../Analytics/TenderTypes";
import ChannelTypes from "../Analytics/ChannelTypes";
import DonationsPyramid from "../Analytics/DonationsPyramid";
import CampaignByDay from "../Analytics/CampaignByDay";
import TopDonor from "../Analytics/TopDonor";
import TopDonations from "../Analytics/TopDonations";
import ReceiptedDonations from "../Analytics/ReceiptedDonations";
import FeeSummary from "../Analytics/FeeSummary";

const AnalyticsComponent = ({ token }) => {
  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // current date obj
  let currentDate = new Date();

  // dispatching actions to get data
  useEffect(() => {
    // yearOverYears
    dispatch(
      getYearOverYears({
        token,
        numberOfYears: "2",
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // tenderTypes
    dispatch(
      getTenderTypes({
        token,
        year: currentDate.getFullYear().toString(),
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // channelTypes
    dispatch(
      getChannelTypes({
        token,
        year: currentDate.getFullYear().toString(),
        month: "",
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // donationsPyramid
    dispatch(
      getDonationsPyramid({
        token,
        year: currentDate.getFullYear().toString(),
        values: "20,120,200",
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // campaignByDay
    dispatch(
      getCampaignByDay({
        token,
        year: String(currentDate.getFullYear().toString()),
        month: String((currentDate.getMonth() + 1).toString().padStart(2, "0")),
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // topDonor
    dispatch(
      getTopDonor({
        token,
        n: "5",
        month: String((currentDate.getMonth() + 1).toString().padStart(2, "0")),
        year: String(currentDate.getFullYear().toString()),
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // topDonations
    dispatch(
      getTopDonations({
        token,
        n: "5",
        month: String((currentDate.getMonth() + 1).toString().padStart(2, "0")),
        year: String(currentDate.getFullYear().toString()),
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // receiptedDonations
    dispatch(
      getReceiptedDonations({
        token,
        year: currentDate.getFullYear().toString(),
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // fee summary
    dispatch(
      getFeeSummary({
        token,
        year: "",
        month: "",
        day: "",
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // redux loading states
  const { yearOverYearsLoading } = useSelector((state) => state.yearOverYears);
  const { tenderTypesLoading } = useSelector((state) => state.tenderTypes);
  const { channelTypesLoading } = useSelector((state) => state.channelTypes);
  const { donationsPyramidLoading } = useSelector(
    (state) => state.donationsPyramid
  );
  const { campaignByDayLoading } = useSelector((state) => state.campaignByDay);
  const { topDonorLoading } = useSelector((state) => state.topDonor);
  const { topDonationsLoading } = useSelector((state) => state.topDonations);
  const { receiptedDonationsLoading } = useSelector(
    (state) => state.receiptedDonations
  );
  const { feeSummaryLoading } = useSelector(
    (state) => state.feeSummary
  );

  // state to check if any loading from redux is true
  const [loadings, setLoadings] = useState(true);

  // checking if any loading is true
  const allLoadingStates = [
    yearOverYearsLoading,
    tenderTypesLoading,
    channelTypesLoading,
    donationsPyramidLoading,
    campaignByDayLoading,
    topDonorLoading,
    topDonationsLoading,
    receiptedDonationsLoading,
    feeSummaryLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allLoadingStates.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadingStates]);

  return (
    <div className="middle-area">
      <div
        className={
          loadings
            ? "middle-content flex-start height"
            : "middle-content flex-start"
        }
      >
        {loadings ? (
          <div className="full-loading-screen">
            <MetroSpinner size={30} color="#007C16" loading={loadings} />
          </div>
        ) : (
          <>
            <h6>Analytics information and statistics</h6>
            {/* yearOverYears */}
            <div className="dashboard-row">
              <div className="dashboard-row-left full-width">
                <YearOverYears token={token} />
              </div>
            </div>

            {/* tenderTypes, channelTypes */}
            <div className="analytics-row">
              <div className="analytics-row-box">
                <TenderTypes token={token} />
              </div>
              <div className="analytics-row-box right-most">
                <ChannelTypes token={token} />
              </div>
            </div>

            {/* donations pyramid */}
            <div className="dashboard-row">
              <div className="dashboard-row-left full-width flex">
                <DonationsPyramid token={token} />
              </div>
            </div>

            {/* campaignByDay */}
            <div className="dashboard-row">
              <div className="dashboard-row-left full-width">
                <CampaignByDay token={token} />
              </div>
            </div>

            {/* topDonor and topDonations */}
            <div className="dashboard-row">
              <div className="dashboard-row-left flex-2">
                <TopDonor token={token} />
              </div>
              <div className="dashboard-row-right background-white flex-1">
                <TopDonations token={token} />
              </div>
            </div>

            {/* receiptedDonations */}
            <div className="dashboard-row">
              <div className="dashboard-row-left full-width special-height-case">
                <ReceiptedDonations token={token} />
              </div>
            </div>

            {/* feeSummary */}
            <div className="dashboard-row">
              <div className="dashboard-row-left full-width special-height-case">
                <FeeSummary token={token} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AnalyticsComponent;
