import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getDonorFilterCampaignsSummary } from "../../../../Store/Slices/Sidebar/Donors/DonorHighlightsSlice";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import Tippy from "@tippyjs/react";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import noDataFound from "../../../../Images/noDataFound.png";
import GaugeChart from "react-gauge-chart";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const CampaignsSummary = ({ token, email }) => {
  // redux state
  const {
    filterCampaignsSummaryLoading,
    campaignsSummaryData,
    campaignsSummaryError,
  } = useSelector((state) => state.donorHighlights);

  // calculate total amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (campaignsSummaryData && campaignsSummaryData.values.length > 0) {
      const calculatedAmounts = campaignsSummaryData.values.map((item) => {
        const { amount } = item;
        const parsedAmount = parseFloat(amount.replace(",", ""));
        return parsedAmount;
      });

      const totalAmount = calculatedAmounts.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const roundedSum = totalAmount.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [campaignsSummaryData]);

  // Calculate the percentage
  const [percentageData, setPercentageData] = useState([]);
  useEffect(() => {
    if (campaignsSummaryData && campaignsSummaryData.values.length > 0) {
      const totalAmount = campaignsSummaryData.values.reduce(
        (total, obj) => total + parseFloat(obj.amount.replace(",", "")),
        0
      );
      const newData = campaignsSummaryData.values.map((data) => ({
        ...data,
        percentage: parseFloat(
          (
            (parseFloat(data.amount.replace(",", "")) / totalAmount) *
            100
          ).toFixed(2)
        ),
      }));
      setPercentageData(newData);
    } else {
      setPercentageData([]);
    }
  }, [campaignsSummaryData]);

  // popup
  const [popup, setPopup] = useState(false);

  // year state
  const [year, setYear] = useState(new Date().getFullYear().toString());

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle year change
  const handleYearChange = (value) => {
    if (year !== value) {
      setPopup(false);
      setYear(value);
      const singleObj = {
        token,
        year: value,
        email,
      };
      dispatch(getDonorFilterCampaignsSummary(singleObj))
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    }
  };

  const chartStyle = {
    height: 150,
  };

  return (
    <>
      <div className="box-header">
        <span>
          Campaigns Distribution -{" "}
          <span style={{ color: "#007c16" }}>
            {campaignsSummaryData && <>{campaignsSummaryData.currencySymbol}</>}
            {sum}
          </span>{" "}
          - <span style={{ color: "#007c16" }}>{year}</span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span
            style={{ cursor: "pointer", color: "#6a6c6f" }}
            onClick={() => setPopup(!popup)}
          >
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Year</h4>
          <div style={{ width: 100 + "%" }}>
            <Select
              value={yearOptions.find((option) => option.value === year)}
              onChange={(option) => handleYearChange(option.value)}
              options={yearOptions}
              styles={styles}
            />
          </div>
        </div>
      </div>
      <div className="lifetime-content-wrapper">
        {filterCampaignsSummaryLoading ? (
          <div className="content-wrappr-loader">
            <MetroSpinner
              loading={filterCampaignsSummaryLoading}
              size={30}
              color="#007c16"
            />
          </div>
        ) : (
          <>
            {campaignsSummaryError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {campaignsSummaryError}
              </div>
            ) : (
              <>
                {percentageData.length > 0 ? (
                  <div className="gauge-container">
                    {percentageData.map((campaign, index) => (
                      <div key={index} className="gauge">
                        <h2>{campaign.campaign}</h2>
                        <GaugeChart
                          className="rgc-circle"
                          id={`gauge-${index}`}
                          nrOfLevels={30}
                          colors={[`#007c16`, `#ff3f00`, `#ff8c00`]}
                          percent={campaign.percentage / 100}
                          textColor="#000"
                          style={chartStyle}
                        />
                        <p>
                          {campaignsSummaryData && (
                            <>{campaignsSummaryData.currencySymbol}</>
                          )}
                          {campaign.amount}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="content-wrappr-loader">
                    <Tippy content="No Data Found">
                      <img
                        src={noDataFound}
                        alt="No Data Found"
                        width={`100px`}
                        height={`auto`}
                      />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CampaignsSummary;
