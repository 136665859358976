import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";

const TotalTransactions = () => {
  // redux state
  const { totalTransactionsData, totalTransactionsError } = useSelector(
    (state) => state.totalTransactions
  );

  return (
    <div className="row-box left-most">
      <div className="icon-with-text">
        <span>Total Transactions</span>
        <FontAwesomeIcon icon={faArrowRightArrowLeft} size="2xl" />
      </div>
      {totalTransactionsError ? (
        <div className="error-msg" style={{ marginTop: 0 }}>
          {totalTransactionsError}
        </div>
      ) : (
        <>
          {totalTransactionsData !== null ? (
            <CountUp
              className="h1"
              start={0}
              end={totalTransactionsData}
              duration={2.5}
              decimals={0}
              decimal="."
              prefix={""}
              suffix=""
            />
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Data Found">
                <img src={noDataFound} alt="No Data Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TotalTransactions;
