import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredTotalRecurringAmount } from "../../Store/Slices/Sidebar/Dashboard/TotalRecurringAmountSlice";
import { MetroSpinner } from "react-spinners-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import DatePicker from "react-date-picker";
import CountUp from "react-countup";
import noDataFound from "../../Images/noDataFound.png";

const TotalRecurringAmount = ({ token }) => {
  // redux state
  const {
    totalRecurringAmountData,
    filteredTotalRecurringAmountLoading,
    totalRecurringAmountError,
  } = useSelector((state) => state.totalRecurringAmount);

  // calculate total recurring amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (
      totalRecurringAmountData &&
      totalRecurringAmountData.values.length > 0
    ) {
      const calculatedSum = totalRecurringAmountData.values.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0
      );
      setSum(calculatedSum);
    } else {
      setSum("0.00");
    }
  }, [totalRecurringAmountData]);

  // states
  const [startDate, setStartDate] = useState(new Date());
  const [year, setYear] = useState(null);
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [popup, setPopup] = useState(false);

  // setting up year month and day
  useEffect(() => {
    if (startDate instanceof Date && !isNaN(startDate)) {
      setYear(startDate.getFullYear().toString());
      setDay(startDate.getDate().toString().padStart(2, "0"));
      setMonth((startDate.getMonth() + 1).toString().padStart(2, "0"));
    } else {
      console.log("get full year error in the useEffect");
    }
  }, [startDate]);

  // dispatch function
  const dispatch = useDispatch();

  // navigate function
  const navigate = useNavigate();

  // handle date change
  const handleDateChange = (value) => {
    if (value instanceof Date && !isNaN(value)) {
      if (value.toDateString() !== startDate.toDateString()) {
        setPopup(false);
        setStartDate(value);
        const singleObj = {
          token,
          year: value.getFullYear().toString(),
          month: (value.getMonth() + 1).toString().padStart(2, "0"),
          day: value.getDate().toString().padStart(2, "0"),
        };
        dispatch(getFilteredTotalRecurringAmount(singleObj))
          .unwrap()
          .catch((err) => {
            if (err.message === "Request failed with status code 401") {
              localStorage.removeItem("user");
              navigate("/login");
            }
          });
      }
    } else {
      console.log("get full year error in the handleDateChange");
    }
  };

  return (
    <div className="col-box">
      <div className="text-and-filter-div">
        <span style={{ color: "#007C16" }}>
          {year}-{month}-{day}
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Date</h4>
          <DatePicker
            value={startDate}
            onChange={(date) => handleDateChange(date)}
            clearIcon={false}
          />
        </div>
      </div>
      <div className="icon-with-text">
        <span>Recurring Amount</span>
        <FontAwesomeIcon icon={faMoneyBill} size="2xl" />
      </div>
      {filteredTotalRecurringAmountLoading ? (
        <MetroSpinner
          size={30}
          color="#007C16"
          loading={filteredTotalRecurringAmountLoading}
        />
      ) : (
        <>
          {totalRecurringAmountError ? (
            <div className="error-msg" style={{ marginTop: 0 }}>
              {totalRecurringAmountError}
            </div>
          ) : (
            <>
              {totalRecurringAmountData !== null ? (
                <CountUp
                  className="h1"
                  start={0}
                  end={sum}
                  duration={2.5}
                  decimals={2}
                  decimal="."
                  prefix={totalRecurringAmountData.currencySymbol + " "}
                  suffix=""
                />
              ) : (
                <div className="no-data-found-div">
                  <Tippy content="No Data Found">
                    <img src={noDataFound} alt="No Data Found" />
                  </Tippy>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TotalRecurringAmount;
