import { MetroSpinner } from "react-spinners-kit";

const CacheLoading = () => {
  return (
    <div className="cache-loading">
      <MetroSpinner loading={true} size={30} />
    </div>
  );
};

export default CacheLoading;
