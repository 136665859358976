import React from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { info } from "react-icons-kit/feather/info";

const RecurringSummary = ({
  filteredFlag,
  setFilteredFlag,
  layout,
  setLoadings,
  setFilteredParams,
  resetEntities,
}) => {
  // redux state
  const { recurringSummaryData } = useSelector(
    (state) => state.recurringPayments
  );

  // handle card click
  const handleFilteredFlag = (flag) => {
    if (filteredFlag !== flag) {
      setLoadings(true);
      resetEntities();
      setFilteredFlag(flag);
      setFilteredParams({
        apiData: {
          by: "status",
          status: [flag],
        },
        order: "asc",
        pageSize: "10",
      });
    }
  };

  return (
    <div className="recurring-summary-row">
      {recurringSummaryData && recurringSummaryData.length > 0 ? (
        <>
          {recurringSummaryData.map((data, index) => (
            <div
              className="recurring-summary-box"
              key={index}
              onClick={() => handleFilteredFlag(data.filteredFlag)}
              style={{
                border:
                  filteredFlag === data.filteredFlag &&
                  layout === "grid" &&
                  "2px solid #007c16",
              }}
            >
              <h4>
                {data.label}
                <Tippy content={data.description}>
                  <span>
                    <Icon icon={info} size={20} />
                  </span>
                </Tippy>
              </h4>
              <p>{data.value}</p>
              <div className="summary-progress-bar-div">
                <div
                  className="summary-progress-bar"
                  style={{
                    width: data.percentage + "%",
                  }}
                ></div>
              </div>
              <img src={data.statusImage} alt="icon" />
            </div>
          ))}
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No Recurring Summary Found">
            <img src={noDataFound} alt="No Recurring Summary Found" />
          </Tippy>
        </div>
      )}
    </div>
  );
};

export default RecurringSummary;
