import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-date-picker";
import { MDBCheckbox } from "mdb-react-ui-kit";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// multi select animated components
const animatedComponents = makeAnimated();

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const FilterDonorModal = ({
  setFilterDonorModal,
  setFilteredParams,
  setLoadings,
  handleResetFilter,
  entityOptions,
  entitySelected,
  handleSelectChange,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  streetAddress,
  setStreetAddress,
  city,
  setCity,
  provinceState,
  setProvinceState,
  postalZipCode,
  setPostalZipCode,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  accountType,
  handleAccountTypeChange,
  businessName,
  setBusinessName,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
  pageSize,
  handlePerPage,
  perPageOptions,
}) => {
  // close modal
  const handleCloseModal = () => {
    setFilterDonorModal(false);
  };

  // Check if 'name' or 'other options' is in the selected options
  const shouldRenderFirstNameField = entitySelected.some(
    (option) => option.value === "first_name"
  );

  const shouldRenderLastNameField = entitySelected.some(
    (option) => option.value === "last_name"
  );

  const shouldRenderEmailField = entitySelected.some(
    (option) => option.value === "email"
  );

  const shouldRenderStreetAddressField = entitySelected.some(
    (option) => option.value === "street_address"
  );

  const shouldRenderCityField = entitySelected.some(
    (option) => option.value === "city"
  );

  const shouldRenderProvinceStateField = entitySelected.some(
    (option) => option.value === "province_state"
  );

  const shouldRenderPostalZipCodeField = entitySelected.some(
    (option) => option.value === "postal_zip_code"
  );

  const shouldRenderFromDateField = entitySelected.some(
    (option) => option.value === "fromDate"
  );

  const shouldRenderToDateField = entitySelected.some(
    (option) => option.value === "toDate"
  );

  const shouldRenderAccountTypeField = entitySelected.some(
    (option) => option.value === "account_type"
  );

  const shouldRenderBusinessNameField = entitySelected.some(
    (option) => option.value === "business_name"
  );

  // from date change
  const handleFromDateChange = (value) => {
    if (value) {
      setFromDate(value);
    } else {
      setFromDate(null);
    }
  };

  // to date change
  const handleToDateChange = (value) => {
    if (value) {
      setToDate(value);
    } else {
      setToDate(null);
    }
  };

  // form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Prepare the data to be sent as API parameters based on the selected options
    const apiData = {};

    if (shouldRenderFirstNameField) {
      apiData.first_name = firstName;
    }

    if (shouldRenderLastNameField) {
      apiData.last_name = lastName;
    }

    if (shouldRenderEmailField) {
      apiData.email = email;
    }

    if (shouldRenderStreetAddressField) {
      apiData.street_address = streetAddress;
    }

    if (shouldRenderCityField) {
      apiData.city = city;
    }

    if (shouldRenderProvinceStateField) {
      apiData.province_state = provinceState;
    }

    if (shouldRenderPostalZipCodeField) {
      apiData.postal_zip_code = postalZipCode;
    }

    if (shouldRenderFromDateField && fromDate) {
      const formattedFromDate = formatDate(fromDate);
      apiData.fromDate = formattedFromDate;
    }

    if (shouldRenderToDateField && toDate) {
      const formattedToDate = formatDate(toDate);
      apiData.toDate = formattedToDate;
    }

    if (shouldRenderAccountTypeField) {
      apiData.account_type = accountType;
    }

    if (shouldRenderBusinessNameField) {
      apiData.business_name = businessName;
    }

    if (sortBy) {
      apiData.by = sortBy.value;
    }

    setLoadings(true);
    setFilterDonorModal(false);
    const filteredParams = {
      apiData,
      pageSize,
      order: orderBy.value,
    };
    setFilteredParams(filteredParams);
    localStorage.setItem("filteredParams", JSON.stringify(filteredParams));
  };

  // format date
  const formatDate = (dateObj) => {
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // account type options
  const accountTypeOptions = [
    { value: "I", label: "Individual" },
    { value: "B", label: "Business" },
  ];

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Filter Donors</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <h6
              style={{
                borderBottom: 1 + "px solid #eaeaea",
                paddingBottom: 5 + "px",
              }}
            >
              Select Fields
            </h6>
            {entityOptions && (
              <>
                <Select
                  isMulti
                  components={animatedComponents}
                  styles={styles}
                  options={entityOptions}
                  onChange={handleSelectChange}
                  value={entitySelected}
                />
                <form
                  style={{ marginTop: 10 + "px" }}
                  className="update-media-form"
                  onSubmit={handleFormSubmit}
                >
                  {shouldRenderFirstNameField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "first_name"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "first_name"
                          )?.label
                        }
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  )}
                  {shouldRenderLastNameField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "last_name"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "last_name"
                          )?.label
                        }
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  )}
                  {shouldRenderEmailField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "email"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "email"
                          )?.label
                        }
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  )}
                  {shouldRenderStreetAddressField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "street_address"
                          )?.label
                        }
                      </label>
                      <input
                        type="address"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "street_address"
                          )?.label
                        }
                        required
                        value={streetAddress}
                        onChange={(e) => setStreetAddress(e.target.value)}
                      />
                    </div>
                  )}
                  {shouldRenderCityField && (
                    <div className="input-margins-div">
                      <h6>
                        {
                          entitySelected.find(
                            (option) => option.value === "city"
                          )?.label
                        }
                      </h6>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "city"
                          )?.label
                        }
                        required
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  )}
                  {shouldRenderProvinceStateField && (
                    <div className="input-margins-div">
                      <h6>
                        {
                          entitySelected.find(
                            (option) => option.value === "province_state"
                          )?.label
                        }
                      </h6>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "province_state"
                          )?.label
                        }
                        required
                        value={provinceState}
                        onChange={(e) => setProvinceState(e.target.value)}
                      />
                    </div>
                  )}
                  {shouldRenderPostalZipCodeField && (
                    <div className="input-margins-div">
                      <h6>
                        {
                          entitySelected.find(
                            (option) => option.value === "postal_zip_code"
                          )?.label
                        }
                      </h6>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "postal_zip_code"
                          )?.label
                        }
                        required
                        value={postalZipCode}
                        onChange={(e) => setPostalZipCode(e.target.value)}
                      />
                    </div>
                  )}
                  {shouldRenderFromDateField && (
                    <div className="input-margins-div">
                      <h6>
                        {
                          entitySelected.find(
                            (option) => option.value === "fromDate"
                          )?.label
                        }
                      </h6>
                      <DatePicker
                        value={fromDate}
                        onChange={handleFromDateChange}
                        clearIcon={false}
                        required
                      />
                    </div>
                  )}
                  {shouldRenderToDateField && (
                    <div className="input-margins-div">
                      <h6>
                        {
                          entitySelected.find(
                            (option) => option.value === "toDate"
                          )?.label
                        }
                      </h6>
                      <DatePicker
                        value={toDate}
                        onChange={handleToDateChange}
                        clearIcon={false}
                        required
                      />
                    </div>
                  )}
                  {shouldRenderAccountTypeField && (
                    <div className="input-margins-div">
                      <h6>
                        {
                          entitySelected.find(
                            (option) => option.value === "account_type"
                          )?.label
                        }
                      </h6>
                      <div className="checkbox-container">
                        {accountTypeOptions.map((item) => (
                          <div className="checkbox-div" key={item.value}>
                            <MDBCheckbox
                              label={item.label}
                              checked={accountType.includes(item.value)}
                              onChange={() =>
                                handleAccountTypeChange(item.value)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {shouldRenderBusinessNameField && (
                    <div className="input-margins-div">
                      <h6>
                        {
                          entitySelected.find(
                            (option) => option.value === "business_name"
                          )?.label
                        }
                      </h6>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "business_name"
                          )?.label
                        }
                        required
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="input-margins-div">
                    <label>Records per page</label>
                    <Select
                      value={perPageOptions.find(
                        (option) => option.value === pageSize
                      )}
                      onChange={(option) => handlePerPage(option.value)}
                      options={perPageOptions}
                      styles={styles}
                    />
                  </div>
                  <div className="input-margins-div">
                    <label>Sort by</label>
                    <Select
                      value={sortBy}
                      onChange={(option) => setSortBy(option)}
                      options={entityOptions}
                      styles={styles}
                      isClearable
                    />
                  </div>
                  <div className="input-margins-div">
                    <label>Order by</label>
                    <Select
                      value={orderBy}
                      onChange={(option) => setOrderBy(option)}
                      options={[
                        { value: "asc", label: "Ascending" },
                        { value: "desc", label: "Descending" },
                      ]}
                      styles={styles}
                    />
                  </div>
                  <div className="reset-and-filter-div">
                    <button
                      type="button"
                      className="reset-fields-btn"
                      onClick={handleResetFilter}
                    >
                      RESET
                    </button>
                    <button type="submit" className="filter-records-btn">
                      FILTER
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterDonorModal;
