/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getETransferConfiguration } from "../../Store/Slices/Sidebar/ETransfer/ETransferSlice";
import { getNodes } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { Toaster } from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import ETransferConfiguration from "../ETransfer/ETransferConfiguration";
import DeleteETransferModal from "../ETransfer/DeleteETransferModal";
import AddNewETransferConfigurationModal from "../ETransfer/AddNewETransferConfigurationModal";
import axios from "axios";
import hostName from "../../config";
import EditETransferModal from "../ETransfer/EditETransferModal";

const ETransferComponent = ({ token }) => {
  // redux state
  const {
    eTransferConfigurationLoading,
    eTransferConfigurationError,
  } = useSelector((state) => state.eTransfer);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // schedule summary
  useEffect(() => {
    dispatch(getETransferConfiguration(token))
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // all api options data states
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [eTransferTypeOptions, setETransferTypeOptions] = useState([]);
  const [encryptionOptions, setEncryptionOptions] = useState([]);
  const [protocolOptions, setProtocolOptions] = useState([]);
  const [allOptionsLoading, setAllOptionsLoading] = useState(true);

  // getting all options at once
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    Promise.all([
      dispatch(getNodes(token)),
      axios.get(
        `${hostName}api/admin/v1/routeLov?routeName=eTransfer`,
        axiosConfig
      ),
    ])
      .then(([campaignRes, routeRes]) => {
        // Handle active campaign options
        setCampaignOptions(campaignRes.payload.active_campaign);
        setETransferTypeOptions(routeRes.data.data.eTransferType);
        setEncryptionOptions(routeRes.data.data.eTransferEncryption);
        setProtocolOptions(routeRes.data.data.eTransferProtocol);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setAllOptionsLoading(false));
  }, []);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [eTransferConfigurationLoading, allOptionsLoading];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // delete eTransfer modal
  const [deleteETransferModal, setDeleteETransferModal] = useState(false);

  // data to be deleted
  const [deleteETransferObj, setDeleteETransferObj] = useState(null);

  // delete eTransfer modal
  const handleDeleteETransferModal = (data) => {
    setDeleteETransferModal(true);
    setDeleteETransferObj(data);
  };

  // add new eTransfer configuration modal
  const [addNewModal, setAddNewModal] = useState(false);

  // edit eTransfer modal
  const [editETransferModal, setEditETransferModal] = useState(false);

  // data to be edited
  const [editETransferObj, setEditETransferObj] = useState(null);

  // edit eTransfer modal
  const handleEditETransferModal = (data) => {
    setEditETransferModal(true);
    setEditETransferObj(data);
  };

  return (
    <>
      <Toaster />
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              <div className="media-heading-button-container">
                <h6>eTransfer Summary</h6>
                <div className="add-and-filter-btns-div">
                  <div className="action-btns-div">
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setAddNewModal(true)}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>

              {eTransferConfigurationError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {eTransferConfigurationError}
                </div>
              ) : (
                <div className="media-row">
                  <ETransferConfiguration
                    handleDeleteETransferModal={handleDeleteETransferModal}
                    handleEditETransferModal={handleEditETransferModal}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/* delete eTransfer modal */}
      {deleteETransferModal && (
        <DeleteETransferModal
          token={token}
          setDeleteETransferModal={setDeleteETransferModal}
          deleteETransferObj={deleteETransferObj}
        />
      )}
      {/* add new eTransfer configuration modal */}
      {addNewModal && (
        <AddNewETransferConfigurationModal
          token={token}
          setAddNewModal={setAddNewModal}
          campaignOptions={campaignOptions}
          eTransferTypeOptions={eTransferTypeOptions}
          encryptionOptions={encryptionOptions}
          protocolOptions={protocolOptions}
        />
      )}
      {/* edit eTransfer modal */}
      {editETransferModal && (
        <EditETransferModal
          token={token}
          setEditETransferModal={setEditETransferModal}
          editETransferObj={editETransferObj}
          campaignOptions={campaignOptions}
          eTransferTypeOptions={eTransferTypeOptions}
          encryptionOptions={encryptionOptions}
          protocolOptions={protocolOptions}
        />
      )}
    </>
  );
};

export default ETransferComponent;
