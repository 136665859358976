import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBBtn } from "mdb-react-ui-kit";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearUploadBannerModalStates,
  uploadBanner,
} from "../../Store/Slices/Sidebar/Campaigns/CampaignSlice";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const ConfirmBannerPopup = ({
  token,
  setConfirmBannerPopup,
  photoURL,
  bannerFile,
  bannerTag,
}) => {
  // redux state
  const { uploadBannerLoading, uploadBannerData } = useSelector(
    (state) => state.campaign
  );

  // close modal
  const handleCloseModal = () => {
    if (!uploadBannerLoading) {
      setConfirmBannerPopup(false);
    }
  };

  // isLoaded
  const [isLoaded, setIsLoaded] = useState(false);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // upload banner function
  const handleBannerUpload = () => {
    dispatch(
      uploadBanner({
        token,
        tagNumber: bannerTag,
        bannerImage: bannerFile,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setConfirmBannerPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        dispatch(clearUploadBannerModalStates());
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Update Banner Image</div>
            <div
              className="update-widgets-cancel-icon"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="modal-body">
            <div className="banner-img-container">
              <img
                src={photoURL}
                alt="banner"
                onLoad={() => {
                  setTimeout(() => {
                    setIsLoaded(true);
                  }, 500);
                }}
                style={{
                  display: isLoaded ? "block" : "none",
                }}
              />
            </div>

            {uploadBannerData &&
              uploadBannerData.tagNumber === bannerTag &&
              (uploadBannerData.apiError || uploadBannerData.axiosError) && (
                <div className="error-msg">
                  {uploadBannerData.apiError || uploadBannerData.axiosError}
                </div>
              )}

            <MDBBtn
              type="button"
              className="save-btn"
              onClick={handleBannerUpload}
              disabled={!isLoaded || uploadBannerLoading}
            >
              {uploadBannerLoading ? "Saving..." : "Save"}
            </MDBBtn>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ConfirmBannerPopup;
