import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getChannelTypes = createAsyncThunk(
  "channelTypes/getChannelTypes",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/channelTypeSummary?year=${singleObj.year}&month=${singleObj.month}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredChannelTypes = createAsyncThunk(
  "filteredChannelTypes/getFilteredChannelTypes",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/channelTypeSummary?year=${singleObj.year}&month=${singleObj.month}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const channelTypesSlice = createSlice({
  name: "channelTypes",
  initialState: {
    channelTypesLoading: false,
    channelTypesData: null,
    channelTypesError: null,
    filteredChannelTypesLoading: false,
  },
  extraReducers: (builder) => {
    builder
      // first render
      .addCase(getChannelTypes.pending, (state) => {
        state.channelTypesLoading = true;
        state.channelTypesData = null;
        state.channelTypesError = null;
      })
      .addCase(getChannelTypes.fulfilled, (state, action) => {
        state.channelTypesLoading = false;
        state.channelTypesData = action.payload;
        state.channelTypesError = null;
      })
      .addCase(getChannelTypes.rejected, (state, action) => {
        state.channelTypesLoading = false;
        state.channelTypesData = null;
        state.channelTypesError = action.error.message;
      })
      // filter render
      .addCase(getFilteredChannelTypes.pending, (state) => {
        state.filteredChannelTypesLoading = true;
        state.channelTypesData = null;
        state.channelTypesError = null;
      })
      .addCase(getFilteredChannelTypes.fulfilled, (state, action) => {
        state.filteredChannelTypesLoading = false;
        state.channelTypesData = action.payload;
        state.channelTypesError = null;
      })
      .addCase(getFilteredChannelTypes.rejected, (state, action) => {
        state.filteredChannelTypesLoading = false;
        state.channelTypesData = null;
        state.channelTypesError = action.error.message;
      });
  },
});

export default channelTypesSlice.reducer;
