/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/Donors.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDonors,
  filterDonorRecords,
  exportDonors,
} from "../../Store/Slices/Sidebar/Donors/DonorsCrudSlice";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { grid } from "react-icons-kit/feather/grid";
import { list } from "react-icons-kit/feather/list";
import { filter } from "react-icons-kit/feather/filter";
import { arrowUp } from "react-icons-kit/feather/arrowUp";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import axios from "axios";
import hostName from "../../config";
import { Toaster, toast } from "react-hot-toast";
import ResponsivePagination from "react-responsive-pagination";
import Select from "react-select";
import Donors from "../Donors/Donors";
import AddDonorModal from "../Donors/AddDonorModal";
import EditDonorModal from "../Donors/EditDonorModal";
import DeleteDonorModal from "../Donors/DeleteDonorModal";
import FilterDonorModal from "../Donors/FilterDonorModal";
import DonorsTable from "../Donors/DonorsTable";
import AddressDrawer from "../Donors/AddressDrawer";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const DonorsComponent = ({ token }) => {
  // redux loading states
  const { donorsLoading, donorsData, donorsError } = useSelector(
    (state) => state.donors
  );

  // states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter donar modal
  const [perPage, setPerPage] = useState("10");

  // page size is used in filterDonorModal
  const [pageSize, setPageSize] = useState("10");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "25",
      label: "25",
    },
    {
      value: "50",
      label: "50",
    },
    {
      value: "75",
      label: "75",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // layout
  const [layout, setLayout] = useState("list");
  useEffect(() => {
    const view = localStorage.getItem("view");
    if (view) {
      setLayout(view);
    } else {
      setLayout("grid");
    }
  }, []);

  // filtered params
  const [filteredParams, setFilteredParams] = useState(null);

  // filter modal
  const [filterDonorModal, setFilterDonorModal] = useState(false);

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // entity options
  const [entityOptions, setEntityOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [allOptionsLoading, setAllOptionsLoading] = useState(true);

  // getting all options at once
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    Promise.all([
      axios.get(
        `${hostName}api/admin/v1/lov?listName=donorFilter`,
        axiosConfig
      ),
      axios.get(`${hostName}api/v1/public/country`),
      axios.get(`${hostName}api/v1/public/state?countryCode=US`),
      axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
    ])
      .then(([res, countriesRes, usaStatesRes, provincesRes]) => {
        // Handle donorFilter
        setEntityOptions(res.data.data);

        // Handle countries
        const countryOptions = countriesRes.data.map((country) => ({
          value: country.code,
          label: country.name,
        }));
        setCountries(countryOptions);

        // Handle USA states
        const usaStatesOptions = usaStatesRes.data.map((state) => ({
          value: state.code,
          label: state.name,
        }));
        setUsaStates(usaStatesOptions);

        // Handle Canada provinces
        const provincesOptions = provincesRes.data.map((province) => ({
          value: province.code,
          label: province.name,
        }));
        setProvinces(provincesOptions);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setAllOptionsLoading(false));
  }, []);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [donorsLoading, allOptionsLoading];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // input field states for filter
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [provinceState, setProvinceState] = useState("");
  const [postalZipCode, setPostalZipCode] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [accountType, setAccountType] = useState([]);
  const handleAccountTypeChange = (item) => {
    if (accountType.includes(item)) {
      setAccountType(accountType.filter((checkedItem) => checkedItem !== item));
    } else {
      setAccountType([...accountType, item]);
    }
  };
  const [businessName, setBusinessName] = useState("");

  // sort by
  const [sortBy, setSortBy] = useState({
    value: "first_name",
    label: "First Name",
  });

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // get filtered params
  useEffect(() => {
    const savedParams = localStorage.getItem("filteredParams");

    if (savedParams) {
      const parsedParams = JSON.parse(savedParams);
      setFilteredParams(parsedParams);
      setPageSize(parsedParams.pageSize);
      setPerPage(parsedParams.pageSize);

      if (parsedParams.order === "asc") {
        setOrderBy({
          value: "asc",
          label: "Ascending",
        });
      } else if (parsedParams.order === "desc") {
        setOrderBy({
          value: "desc",
          label: "Descending",
        });
      }

      // Check if parsedParams.apiData exists and contains desired keys
      if (parsedParams.apiData && typeof parsedParams.apiData === "object") {
        const apiDataKeys = Object.keys(parsedParams.apiData);
        const desiredKeys = [
          "first_name",
          "last_name",
          "email",
          "street_address",
          "city",
          "province_state",
          "postal_zip_code",
          "fromDate",
          "toDate",
          "account_type",
          "business_name",
        ];

        // Find the intersection of available keys and desired keys
        const selectedKeys = apiDataKeys.filter((key) =>
          desiredKeys.includes(key)
        );

        // Define the value-label mappings for the selected keys
        const selectedControls = [
          { value: "first_name", label: "First Name", control: "textbox" },
          { value: "last_name", label: "Last Name", control: "textbox" },
          { value: "email", label: "Email", control: "textbox" },
          {
            value: "street_address",
            label: "Street Address",
            control: "textbox",
          },
          { value: "city", label: "City", control: "textbox" },
          {
            value: "province_state",
            label: "Province/State",
            control: "textbox",
          },
          {
            value: "postal_zip_code",
            label: "Postal Code/Zip Code",
            control: "textbox",
          },
          { value: "fromDate", label: "From", control: "date" },
          { value: "toDate", label: "To", control: "date" },
          {
            value: "account_type",
            label: "Account Type",
            control: "checkboxList",
          },
          {
            value: "business_name",
            label: "Business Name",
            control: "textbox",
          },
        ];

        // Update entitySelected state with the selectedControls array
        setEntitySelected(
          selectedControls.filter((control) =>
            selectedKeys.includes(control.value)
          )
        );

        // Update sortBy state based on apiData.by
        const sortByMapping = {
          first_name: { value: "first_name", label: "First Name" },
          last_name: { value: "last_name", label: "Last Name" },
          email: { value: "email", label: "Email" },
          street_address: { value: "street_address", label: "Street Address" },
          city: { value: "city", label: "City" },
          province_state: { value: "province_state", label: "Province/State" },
          postal_zip_code: {
            value: "postal_zip_code",
            label: "Postal Code/Zip Code",
          },
          fromDate: { value: "fromDate", label: "From" },
          toDate: { value: "toDate", label: "To" },
          account_type: { value: "account_type", label: "Account Type" },
          business_name: { value: "business_name", label: "Business Name" },
        };

        const sortByValue = parsedParams.apiData.by;
        if (sortByValue && sortByMapping.hasOwnProperty(sortByValue)) {
          setSortBy(sortByMapping[sortByValue]);
        }

        // Populate input field states based on apiData
        for (const selectedKey of selectedKeys) {
          if (parsedParams.apiData[selectedKey] !== undefined) {
            switch (selectedKey) {
              case "first_name":
                setFirstName(parsedParams.apiData[selectedKey]);
                break;
              case "last_name":
                setLastName(parsedParams.apiData[selectedKey]);
                break;
              case "email":
                setEmail(parsedParams.apiData[selectedKey]);
                break;
              case "street_address":
                setStreetAddress(parsedParams.apiData[selectedKey]);
                break;
              case "city":
                setCity(parsedParams.apiData[selectedKey]);
                break;
              case "province_state":
                setProvinceState(parsedParams.apiData[selectedKey]);
                break;
              case "postal_zip_code":
                setPostalZipCode(parsedParams.apiData[selectedKey]);
                break;
              case "fromDate":
                setFromDate(parsedParams.apiData[selectedKey]);
                break;
              case "toDate":
                setToDate(parsedParams.apiData[selectedKey]);
                break;
              case "account_type":
                setAccountType(parsedParams.apiData[selectedKey]);
                break;
              case "business_name":
                setBusinessName(parsedParams.apiData[selectedKey]);
                break;
              // Handle additional cases as needed
              default:
                break;
            }
          }
        }
      }
    } else {
      setFilteredParams(null);
    }
  }, []);

  // diapatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get donors
  useEffect(() => {
    const savedParams = localStorage.getItem("filteredParams");

    if (savedParams) {
      const parsedParams = JSON.parse(savedParams);
      dispatch(
        filterDonorRecords({
          token,
          page: 1,
          pageSize: parsedParams.pageSize,
          order: parsedParams.order,
          apiData: parsedParams.apiData,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    } else {
      dispatch(
        getDonors({
          token,
          page: 1,
          pageSize: "10",
          order: "asc",
          by: "first_name",
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    }
  }, [filteredParams]);

  // add donor modal
  const [addDonorModal, setAddDonorModal] = useState(false);

  // edit donor modal
  const [editDonorModal, setEditDonorModal] = useState(false);
  const [editDonorData, setEditDonorData] = useState(null);
  const handleEditDonor = (data) => {
    setEditDonorModal(true);
    setEditDonorData(data);
  };

  // delete donor modal
  const [deleteDonorModal, setDeleteDonorModal] = useState(false);
  const [deleteDonorObj, setDeleteDonorObj] = useState(null);
  const handleDeleteDonor = (data) => {
    setDeleteDonorModal(true);
    setDeleteDonorObj(data);
  };

  // address drawer
  const [addressDrawer, setAddressDrawer] = useState(false);
  const [addressDonorObj, setAddressDonorObj] = useState(null);
  const handleAddressDrawer = (data) => {
    setAddressDrawer(true);
    setAddressDonorObj(data);
  };

  // redux state
  const { exportDonorsLoading } = useSelector((state) => state.donors);
  const handleExportDonors = () => {
    const params = filteredParams || {
      apiData: {
        by: "first_name",
      },
    };
    dispatch(
      exportDonors({
        token,
        filteredParams: params,
      })
    ).then((res) => {
      if (res.payload) {
        // download xlsx
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `donors.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else if (res.error.message === "Request failed with status code 401") {
        localStorage.removeItem("user");
        navigate("/login");
      } else if (res.error.message && typeof res.error.message === "string") {
        toast.error(`${res.error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // reset filters
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilteredParams(null);
      localStorage.removeItem("filteredParams");
      setFilterDonorModal(false);
    }
    setEntitySelected([]);
    setFirstName("");
    setLastName("");
    setEmail("");
    setStreetAddress("");
    setCity("");
    setProvinceState("");
    setPostalZipCode("");
    setFromDate(null);
    setToDate(null);
    setAccountType([]);
    setBusinessName("");
    setPageSize(perPage);
    setSortBy({
      value: "first_name",
      label: "First Name",
    });
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterDonorRecords({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getDonors({
            token,
            page: value,
            pageSize: perPage,
            by: "first_name",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // select change
  const handleSelectPageSize = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterDonorRecords({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getDonors({
            token,
            page: 1,
            pageSize: value,
            by: "first_name",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // go to specific donor
  const goToSpecificDonor = (donor) => {
    localStorage.setItem("view", layout);
    navigate(
      `/donors/${donor.firstName.toLowerCase()}_${donor.lastName.toLowerCase()}_${donor.tagNumber.toString()}`
    );
  };

  // loaded images
  const [loadedImages, setLoadedImages] = useState({});

  // load image
  useEffect(() => {
    if (donorsData?.data.length > 0) {
      const loadImage = (tagNumber, profileImage) => {
        if (!profileImage) {
          // Set the image as loaded if profileImage is null
          setLoadedImages((previousImages) => ({
            ...previousImages,
            [tagNumber]: true,
          }));
          return;
        }
        const img = new Image();
        img.src = profileImage;
        img.onload = () => {
          setLoadedImages((previousImages) => ({
            ...previousImages,
            [tagNumber]: true,
          }));
        };
      };
      donorsData.data.forEach((donor) =>
        loadImage(donor.tagNumber, donor.profileImage)
      );
    }
  }, [donorsData]);

  return (
    <>
      <Toaster />
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              <div className="media-heading-button-container">
                <h6>Donors</h6>
                <div className="add-and-filter-btns-div">
                  <div className="action-btns-div">
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setAddDonorModal(true)}
                    >
                      Add Donor
                    </button>
                  </div>
                  <div
                    className="filter-and-reset-btns-div"
                    style={{ marginRight: "10px" }}
                  >
                    {/* grid btn */}
                    <Tippy content="Tile View">
                      <button
                        className={`filter-media-modal-btn ${
                          layout === "grid" ? "selected" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          if (layout !== "grid") {
                            setLayout("grid");
                            localStorage.setItem("view", "grid");
                          }
                        }}
                      >
                        <Icon icon={grid} size={24} />
                      </button>
                    </Tippy>

                    {/* list btn */}
                    <Tippy content="List View">
                      <button
                        className={`filter-media-modal-btn reset ${
                          layout === "list" ? "selected" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          if (layout !== "list") {
                            setLayout("list");
                            localStorage.setItem("view", "list");
                          }
                        }}
                      >
                        <Icon icon={list} size={24} />
                      </button>
                    </Tippy>

                    {/* filter btn */}
                    <Tippy content="Filter Records">
                      <button
                        className="filter-media-modal-btn reset"
                        type="button"
                        onClick={() => setFilterDonorModal(true)}
                      >
                        <Icon icon={filter} size={24} />
                      </button>
                    </Tippy>

                    {/* reset btn */}
                    {filteredParams && (
                      <Tippy content="Reset Records">
                        <button
                          className="filter-media-modal-btn reset"
                          type="button"
                          onClick={handleResetFilter}
                        >
                          <Icon icon={refreshCw} size={24} />
                        </button>
                      </Tippy>
                    )}

                    {/* export btn */}
                    <Tippy
                      content={
                        exportDonorsLoading ? "Please Wait" : "Export Donors"
                      }
                    >
                      <button
                        className="filter-media-modal-btn reset"
                        type="button"
                        onClick={handleExportDonors}
                      >
                        {exportDonorsLoading ? (
                          <MetroSpinner
                            loading={exportDonorsLoading}
                            size={24}
                            color="#6a6c6f"
                          />
                        ) : (
                          <Icon icon={arrowUp} size={24} />
                        )}
                      </button>
                    </Tippy>
                  </div>
                </div>
              </div>

              {/* render donors grid or list view if !error && data */}
              {donorsError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {donorsError}
                </div>
              ) : (
                <>
                  {donorsData && donorsData.data.length > 0 ? (
                    <>
                      {/* donors */}
                      {layout === "grid" ? (
                        <div className="media-row">
                          <Donors
                            handleEditDonor={handleEditDonor}
                            handleDeleteDonor={handleDeleteDonor}
                            donorsData={donorsData}
                            goToSpecificDonor={goToSpecificDonor}
                            loadedImages={loadedImages}
                          />
                        </div>
                      ) : (
                        <DonorsTable
                          handleEditDonor={handleEditDonor}
                          handleDeleteDonor={handleDeleteDonor}
                          donorsData={donorsData}
                          goToSpecificDonor={goToSpecificDonor}
                          handleAddressDrawer={handleAddressDrawer}
                          loadedImages={loadedImages}
                        />
                      )}

                      {/* a single pagination */}
                      <div className="transactions-pagination">
                        <div className="per-page-and-pagination">
                          <div className="per-page">
                            <label>Records per page</label>
                            <Select
                              value={perPageOptions.find(
                                (option) => option.value === perPage
                              )}
                              onChange={(option) =>
                                handleSelectPageSize(option.value)
                              }
                              options={perPageOptions}
                              styles={styles}
                              menuPlacement="top"
                            />
                          </div>
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="no-data-found-div">
                      <Tippy content="No Donors Found">
                        <img src={noDataFound} alt="No Donors Found" />
                      </Tippy>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {addDonorModal && (
        <AddDonorModal
          token={token}
          setAddDonorModal={setAddDonorModal}
          countries={countries}
          provinces={provinces}
          usaStates={usaStates}
        />
      )}
      {editDonorModal && (
        <EditDonorModal
          token={token}
          setEditDonorModal={setEditDonorModal}
          editDonorData={editDonorData}
          countries={countries}
          provinces={provinces}
          usaStates={usaStates}
        />
      )}
      {deleteDonorModal && (
        <DeleteDonorModal
          token={token}
          setDeleteDonorModal={setDeleteDonorModal}
          deleteDonorObj={deleteDonorObj}
        />
      )}
      {addressDrawer && (
        <AddressDrawer
          setAddressDrawer={setAddressDrawer}
          addressDonorObj={addressDonorObj}
        />
      )}
      {filterDonorModal && (
        <FilterDonorModal
          setFilterDonorModal={setFilterDonorModal}
          setFilteredParams={setFilteredParams}
          setLoadings={setLoadings}
          handleResetFilter={handleResetFilter}
          entityOptions={entityOptions}
          entitySelected={entitySelected}
          handleSelectChange={handleSelectChange}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          streetAddress={streetAddress}
          setStreetAddress={setStreetAddress}
          city={city}
          setCity={setCity}
          provinceState={provinceState}
          setProvinceState={setProvinceState}
          postalZipCode={postalZipCode}
          setPostalZipCode={setPostalZipCode}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          accountType={accountType}
          handleAccountTypeChange={handleAccountTypeChange}
          businessName={businessName}
          setBusinessName={setBusinessName}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          pageSize={pageSize}
          handlePerPage={handlePerPage}
          perPageOptions={perPageOptions}
        />
      )}
    </>
  );
};

export default DonorsComponent;
