/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { testConnection } from "../../Store/Slices/Sidebar/AppExplorer/AppExplorerSlice";
import toast from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const TestPopup = ({ token, setTestPopup, testObj }) => {
  // redux state
  const { testLoading } = useSelector((state) => state.appExplorer);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // test connection
  useEffect(() => {
    dispatch(
      testConnection({
        token,
        integrationTag: testObj.tagNumber,
      })
    ).then((res) => {
      setTestPopup(false);
      if (res.payload?.messageDetails) {
        toast.success(`${res.payload.messageDetails}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (res.error?.message === "Request failed with status code 401") {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(`${res.error?.message}`, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-body">
            {testLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MetroSpinner size={30} color="#007C16" loading={testLoading} />
              </div>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default TestPopup;
