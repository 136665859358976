/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/Donors.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  deletePhoto,
  getSpecificDonor,
} from "../../Store/Slices/Sidebar/Donors/DonorsCrudSlice";
import { MetroSpinner } from "react-spinners-kit";
import { Toaster, toast } from "react-hot-toast";
import {
  MDBBtn,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { software_pencil } from "react-icons-kit/linea/software_pencil";
import { arrows_plus } from "react-icons-kit/linea/arrows_plus";
import { chevronLeft } from "react-icons-kit/feather/chevronLeft";
import { arrowUp } from "react-icons-kit/feather/arrowUp";
import { mail } from "react-icons-kit/feather/mail";
import { mapPin } from "react-icons-kit/feather/mapPin";
import { phone } from "react-icons-kit/feather/phone";
import axios from "axios";
import hostName from "../../config";
// common
import Header from "../../Components/UI/Header";
import Sidebar from "../../Components/UI/Sidebar";
// update photo popup
import UpdatePhotoPopup from "./UpdatePhotoPopup";
// highlights
import {
  getDonorKeyDates,
  getDonorLastNMonthSummary,
  getDonorLifetime,
  getDonorRecentTransactions,
  getDonorYearly,
  getDonorYearOverYears,
  getDonorCampaignsSummary,
} from "../../Store/Slices/Sidebar/Donors/DonorHighlightsSlice";
import Lifetime from "../../Components/Donors/DonorDetails/Highlights/Lifetime";
import ThisYear from "../../Components/Donors/DonorDetails/Highlights/ThisYear";
import KeyDates from "../../Components/Donors/DonorDetails/Highlights/KeyDates";
import NMonthSummary from "../../Components/Donors/DonorDetails/Highlights/NMonthSummary";
import RecentTransactions from "../../Components/Donors/DonorDetails/Highlights/RecentTransactions";
import YearOverYears from "../../Components/Donors/DonorDetails/Highlights/YearOverYears";
import CampaignsSummary from "../../Components/Donors/DonorDetails/Highlights/CampaignsSummary";
// payments
import { getWallets } from "../../Store/Slices/Wallet/WalletSlice";
import { filterRecurringRecords } from "../../Store/Slices/Sidebar/RecurringPayments/RecurringPaymentsSlice";
import Wallets from "../../Components/Donors/DonorDetails/Payments/Wallets";
import AddWalletPopus from "../../Components/Donors/DonorDetails/Payments/AddWalletPopus";
import DeleteWalletPopup from "../../Components/Donors/DonorDetails/Payments/DeleteWalletPopup";
import EditWalletPopup from "../../Components/Donors/DonorDetails/Payments/EditWalletPopup";
import WalletChargePopup from "../../Components/Donors/DonorDetails/Payments/WalletChargePopup";
import RecurringContainer from "../../Components/Recurring/RecurringContainer";
import RecurringDetailsPopup from "../../Components/Recurring/RecurringDetailsPopup";
import ChangeAmountPopup from "../../Components/Recurring/ChangeAmountPopup";
import CloseRecurringPopup from "../../Components/Recurring/CloseRecurringPopup";
import HistoryNotesPopup from "../../Components/Recurring/HistoryNotesPopup";
import DeclinedTransactionsPopup from "../../Components/Recurring/DeclinedTransactionsPopup";
import SwitchToActivePopup from "../../Components/Recurring/SwitchToActivePopup";
import SwitchToHoldPopup from "../../Components/Recurring/SwitchToHoldPopup";
import UpdateCardPopup from "../../Components/Recurring/UpdateCardPopup";
// transactions
import {
  exportTransactionRecords,
  filterTransactionRecords,
  getNodes,
} from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import TransactionsTable from "../../Components/Transactions/TransactionsTable";
import TransactionDetailsPopup from "../../Components/Transactions/TransactionDetailsPopup";
import CardTransactionsPopup from "../../Components/Transactions/CardTransactionsPopup";
import ManualEditTransactionPopup from "../../Components/Transactions/ManualEditTransactionPopup";
import DeleteTransactionPopup from "../../Components/Transactions/DeleteTransactionPopup";
import ManualTransactionsPopup from "../../Components/Transactions/ManualTransactionsPopup";
import RefundPopup from "../../Components/Transactions/RefundPopup";
import UpdateCampaignPopup from "../../Components/Transactions/UpdateCampaignPopup";
// tax receipts
import { filterTaxReceiptsRecords } from "../../Store/Slices/Sidebar/TaxReceipts/TaxReceiptsSlice";
import TaxReceiptTable from "../../Components/TaxReceipts/TaxReceiptTable";
import NewReceiptPopup from "../../Components/TaxReceipts/NewReceiptPopup";

// get user from LS
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

// get organization
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const DonorDetails = ({
  sidebarToggled,
  setSidebarToggled,
  quickLinksDropdown,
  setQuickLinksDropdown,
}) => {
  // state
  const [user] = useState(getUser());

  // redirect user to login if no user found
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, []);

  // selected org
  const [selectedOrganization] = useState(getOrganization());

  // getting donor first and last name
  const { name } = useParams();

  const [firstName, lastName, tagNumber] = name.split("_");

  // redux state -- getting specific donor
  const { specificDonorData, specificDonorLoading, specificDonorError } =
    useSelector((state) => state.donors);

  // redux state -- getting highlights loadings
  const {
    donorLifetimeLoading,
    donorYearlyLoading,
    donorKeyDatesLoading,
    donorLastNMonthSummaryLoading,
    recentTransactionsLoading,
    yearOverYearsLoading,
    campaignsSummaryLoading,
  } = useSelector((state) => state.donorHighlights);

  // redux stare -- getting wallets loading
  const { walletLoading, walletData } = useSelector((state) => state.wallet);

  // redux state -- get recurrings
  const { recurringPaymentsLoading } = useSelector(
    (state) => state.recurringPayments
  );

  // redux state -- get transactions
  const { transactionsLoading } = useSelector((state) => state.transactions);

  // redux state -- tax receipts
  const { allLoading } = useSelector((state) => state.taxReceipts);

  // get all options with a single loading state
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      axios.get(`${hostName}api/v1/public/country`),
      axios.get(`${hostName}api/v1/public/state?countryCode=US`),
      axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
      dispatch(getNodes(user.token)),
    ])
      .then(([countriesRes, usaStatesRes, provincesRes, nodesRes]) => {
        // handle countries
        const countriesOptions = countriesRes.data.map((country) => ({
          value: country.code,
          label: country.name,
        }));
        setCountries(countriesOptions);

        // handle usa states
        const usaStatesOptions = usaStatesRes.data.map((state) => ({
          value: state.code,
          label: state.name,
        }));
        setUsaStates(usaStatesOptions);

        // handle provinces
        const provincesOptions = provincesRes.data.map((province) => ({
          value: province.code,
          label: province.name,
        }));
        setProvinces(provincesOptions);

        // handle campaign options
        setCampaignOptions(nodesRes.payload.active_campaign);
        setAllCampaigns(nodesRes.payload.campaign_tag);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setOptionsLoading(false));
  }, []);

  // state to check if any loading from redux is true
  const [loadings, setLoadings] = useState(true);

  // checking if any loading is true
  const allLoadingStates = [
    donorLifetimeLoading,
    donorYearlyLoading,
    donorKeyDatesLoading,
    donorLastNMonthSummaryLoading,
    recentTransactionsLoading,
    yearOverYearsLoading,
    campaignsSummaryLoading,
    walletLoading,
    recurringPaymentsLoading,
    transactionsLoading,
    allLoading,
    optionsLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allLoadingStates.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadingStates]);

  // states
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTransactions, setCurrentPageTransactions] = useState(1);
  const [currentPageTaxReceipts, setCurrentPageTaxReceipts] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPagesTransactions, setTotalPagesTransactions] = useState(1);
  const [totalPagesTaxReceipts, setTotalPagesTaxReceipts] = useState(1);
  const [perPage, setPerPage] = useState("10");
  const [perPageTransactions, setPerPageTransactions] = useState("20");
  const [perPageTaxReceipts, setPerPageTaxReceipts] = useState("20");

  // tabs
  const [activeTab, setActiveTab] = useState("Highlights");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get specific donor
  useEffect(() => {
    if (user) {
      dispatch(
        getSpecificDonor({
          token: user.token,
          firstName,
          lastName,
          tagNumber,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    } else {
      navigate("/login");
    }
  }, []);

  // current date obj
  let currentDate = new Date();

  // get all donors tab data at once
  useEffect(() => {
    if (specificDonorData) {
      // liftime
      dispatch(
        getDonorLifetime({
          token: user.token,
          email: specificDonorData.email,
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // yearly
      dispatch(
        getDonorYearly({
          token: user.token,
          email: specificDonorData.email,
          year: currentDate.getFullYear().toString(),
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // key dates
      dispatch(
        getDonorKeyDates({
          token: user.token,
          email: specificDonorData.email,
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // last n month summary
      dispatch(
        getDonorLastNMonthSummary({
          token: user.token,
          email: specificDonorData.email,
          n: "20",
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // recent transaction
      dispatch(
        getDonorRecentTransactions({
          token: user.token,
          email: specificDonorData.email,
          n: "5",
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // year over years
      dispatch(
        getDonorYearOverYears({
          token: user.token,
          email: specificDonorData.email,
          numberOfYears: "2",
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // campaigns summary
      dispatch(
        getDonorCampaignsSummary({
          token: user.token,
          email: specificDonorData.email,
          year: currentDate.getFullYear().toString(),
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // get wallets
      dispatch(
        getWallets({
          token: user.token,
          email: specificDonorData.email,
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // get user specific recurrings
      dispatch(
        filterRecurringRecords({
          token: user.token,
          page: 1,
          pageSize: "10",
          order: "asc",
          apiData: {
            email: specificDonorData.email,
            by: "email",
          },
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setTotalPages(res.payload.links.lastPage);
          setPerPage(res.payload.links.perPage);
        }
      });

      // get donor transactions
      dispatch(
        filterTransactionRecords({
          token: user.token,
          page: 1,
          pageSize: "20",
          order: "asc",
          apiData: {
            email: specificDonorData.email,
            by: "email",
          },
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPageTransactions(res.payload.links.currentPage);
          setTotalPagesTransactions(res.payload.links.lastPage);
          setPerPageTransactions(res.payload.links.perPage);
        }
      });

      // get donor specific tax receipts data
      dispatch(
        filterTaxReceiptsRecords({
          token: user.token,
          page: 1,
          pageSize: "20",
          order: "desc",
          apiData: {
            email: specificDonorData.email,
            by: "email",
          },
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPageTaxReceipts(res.payload.links.currentPage);
          setPerPageTaxReceipts(res.payload.links.perPage);
          setTotalPagesTaxReceipts(res.payload.links.lastPage);
        }
      });
    }
  }, [specificDonorData?.tagNumber]);

  // add wallet popup
  const [addWalletPopup, setAddWalletPopup] = useState(false);

  // delete wallet popup
  const [deleteWalletPopup, setDeleteWalletPopup] = useState(false);
  const [deleteWalletObj, setDeleteWalletObj] = useState(null);

  const handleDeleteWalletPopup = (wallet) => {
    setDeleteWalletPopup(true);
    setDeleteWalletObj(wallet);
  };

  // edit wallet popup
  const [editWalletPopup, setEditWalletPopup] = useState(false);
  const [editWalletObj, setEditWalletObj] = useState(null);

  const handleEditWalletPopup = (wallet) => {
    setEditWalletPopup(true);
    setEditWalletObj(wallet);
  };

  // wallet charge popup
  const [walletChargePopup, setWalletChargePopup] = useState(false);
  const [walletObj, setWalletObj] = useState(null);

  const handleWalletChargePopup = (wallet) => {
    setWalletChargePopup(true);
    setWalletObj(wallet);
  };

  // recurring details popup
  const [recurringDetailsPopup, setRecurringDetailsPopup] = useState(false);
  const [recurringObj, setRecurringObj] = useState(null);
  const handleRecurringDetailsPopup = (recurring) => {
    setRecurringDetailsPopup(true);
    setRecurringObj(recurring);
  };

  // declined transactions popup
  const [declinedTransactionsPopup, setDeclinedTransactionsPopup] =
    useState(false);
  const [declinedTransactionsObj, setDeclinedTransactionsObj] = useState(null);
  const handleDeclinedTransactionsPopup = (recurring) => {
    setDeclinedTransactionsPopup(true);
    setDeclinedTransactionsObj(recurring);
  };

  // history notes popup
  const [historyNotesPopup, setHistoryNotesPopup] = useState(false);
  const [historyNotesObj, setHistoryNotesObj] = useState(null);
  const handleHistoryNotesPopup = (recurring) => {
    setHistoryNotesPopup(true);
    setHistoryNotesObj(recurring);
  };

  // update card popup
  const [updateCardPopup, setUpdateCardPopup] = useState(false);
  const [updateCardObj, setUpdateCardObj] = useState(null);
  const handleUpdateCardPopup = (recurring) => {
    setUpdateCardPopup(true);
    setUpdateCardObj(recurring);
  };

  // change amount popup
  const [changeAmountPopup, setChangeAmountPopup] = useState(false);
  const [changeAmountObj, setChangeAmountObj] = useState(null);
  const handleChangeAmountPopup = (recurring) => {
    setChangeAmountPopup(true);
    setChangeAmountObj(recurring);
  };

  // close recurring popup
  const [closeRecurringPopup, setCloseRecurringPopup] = useState(false);
  const [closeRecurringObj, setCloseRecurringObj] = useState(null);
  const handleCloseRecurringPopup = (recurring) => {
    setCloseRecurringPopup(true);
    setCloseRecurringObj(recurring);
  };

  // switch to active popup
  const [switchToActivePopup, setSwitchToActivePopup] = useState(false);
  const [switchToActiveObj, setSwitchToActiveObj] = useState(null);
  const handleSwitchToActivePopup = (recurring) => {
    setSwitchToActivePopup(true);
    setSwitchToActiveObj(recurring);
  };

  // switch to hold popup
  const [switchToHoldPopup, setSwitchToHoldPopup] = useState(false);
  const [switchToHoldObj, setSwitchToHoldObj] = useState(null);
  const handleSwitchToHoldPopup = (recurring) => {
    setSwitchToHoldPopup(true);
    setSwitchToHoldObj(recurring);
  };

  // new recurring modal
  const [newRecurringPopup, setNewRecurringPopup] = useState(false);
  const handleNewRecurringPopup = () => {
    setNewRecurringPopup(true);
  };

  // one time
  const [oneTime] = useState(false);

  // donor specific
  const [donorSpecific] = useState(true);

  // scroll down
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    if (shouldScroll) {
      // Scroll to the desired section using the scrollIntoView method
      const element = document.getElementById("recurringSection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }

      // Reset the scroll flag
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  // Function to trigger scrolling from outside ComponentA
  const triggerScroll = () => {
    setShouldScroll(true);
  };

  // donor specific transactions

  // manual transaction modal
  const [manualTransactionsPopup, setManualTransactionsPopup] = useState(false);

  const handleManualTransactionsPopup = (e) => {
    e.preventDefault();
    setManualTransactionsPopup(true);
  };

  // card transactions modal
  const [cardTransactionsPopup, setCardTransactionsPopup] = useState(false);

  const handleCardTransactionsPopup = (e) => {
    e.preventDefault();
    setCardTransactionsPopup(true);
  };

  // edit transaction popup
  const [editTransactionPopup, setEditTransactionPopup] = useState(false);
  const [editTransactionObj, setEditTransactionObj] = useState(null);

  const handleEditTransactionPopup = (transaction) => {
    setEditTransactionPopup(true);
    setEditTransactionObj(transaction);
  };

  // transaction details popup
  const [transactionDetailsPopup, setTransactionDetailsPopup] = useState(false);
  const [transactionObj, setTarnsactionObj] = useState(null);

  const handleTransactionDetailsPopup = (transaction) => {
    setTransactionDetailsPopup(true);
    setTarnsactionObj(transaction);
  };

  // update campaign popup
  const [updateCampaignPopup, setUpdateCampaignPopup] = useState(false);
  const [updateCampaignObj, setUpdateCampaignObj] = useState(null);

  const handleUpdateCampaignPopup = (transaction) => {
    setUpdateCampaignPopup(true);
    setUpdateCampaignObj(transaction);
  };

  // delete transaction popup
  const [deleteTransactionPopup, setDeleteTransactionPopup] = useState(false);
  const [deleteTransactionObj, setDeleteTransactionObj] = useState(null);

  const handleDeleteTransactionsPopup = (transaction) => {
    setDeleteTransactionPopup(true);
    setDeleteTransactionObj(transaction);
  };

  // transaction type popup (refund)
  const [transactionTypePopup, setTransactionTypePopup] = useState(false);
  const [transactionTypeObj, setTransactionTypeObj] = useState(null);

  const handleTransactionTypePopup = (transaction) => {
    setTransactionTypePopup(true);
    setTransactionTypeObj(transaction);
  };

  // redux state
  const { exportTransactionLoading } = useSelector(
    (state) => state.transactions
  );

  // export records
  const handleExportRecords = () => {
    dispatch(
      exportTransactionRecords({
        token: user.token,
        filteredParams: {
          apiData: {
            by: "email",
            email: specificDonorData.email,
          },
        },
      })
    ).then((res) => {
      if (res.payload) {
        // download xlsx
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transactions.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else if (res.error.message === "Request failed with status code 401") {
        localStorage.removeItem("user");
        navigate("/login");
      } else if (res.error.message && typeof res.error.message === "string") {
        toast.error(`${res.error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // new receipt popup
  const [newReceiptPopup, setNewReceiptPopup] = useState(false);

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // is user dp loaded
  const [isLoaded, setIsLoaded] = useState(false);

  // set is loaded to false again when user change his dp
  useEffect(() => {
    setIsLoaded(false);
  }, [specificDonorData?.profileImage]);

  // profile photo change
  const [photoFile, setPhotoFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [updatePhotoPopup, setUpdatePhotoPopup] = useState(false);

  const handleProfilePhoto = (file) => {
    if (file) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      const fileType = file.type;

      // Maximum file size in KB
      const maxFileSizeKB = 1024;

      // Check file type
      if (!allowedFileTypes.includes(fileType)) {
        setPhotoFile(null);
        setPhotoURL(null);
        setFileError("Invalid file type. Only PNG, JPEG, and JPG.");
        return;
      }

      // Check file size
      if (file.size > maxFileSizeKB * 1024) {
        setPhotoFile(null);
        setPhotoURL(null);
        setFileError(`File size should not exceed ${maxFileSizeKB}KB.`);
        return;
      }

      // File is valid
      setPhotoFile(file);
      setFileError(null);

      // Create object URL and save it in the state
      const url = URL.createObjectURL(file);
      setPhotoURL(url);

      // open popup
      setUpdatePhotoPopup(true);
    } else {
      setFileError(null);
    }
  };

  // delete photo
  const { deletePhotoLoading } = useSelector((state) => state.donors);

  // delete photo function
  const handleDeletePhoto = () => {
    setFileError(null);
    dispatch(
      deletePhoto({
        token: user.token,
        tagNumber: specificDonorData.tagNumber,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/signin");
      } else {
        toast.error(`${res.payload.apiError}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <>
      {updatePhotoPopup && (
        <UpdatePhotoPopup
          token={user.token}
          specificDonorData={specificDonorData}
          setUpdatePhotoPopup={setUpdatePhotoPopup}
          photoURL={photoURL}
          photoFile={photoFile}
        />
      )}
      <Toaster />
      {user && (
        <div className={sidebarToggled ? "dashboard" : "dashboard toggled"}>
          <Header
            sidebarToggled={sidebarToggled}
            setSidebarToggled={setSidebarToggled}
            quickLinksDropdown={quickLinksDropdown}
            setQuickLinksDropdown={setQuickLinksDropdown}
          />
          <div className="content-wrapper">
            <Sidebar sidebarToggled={sidebarToggled} />
            <div className="middle-area">
              <div
                className={`middle-content flex-start ${
                  specificDonorLoading ||
                  specificDonorError ||
                  !specificDonorData
                    ? "height"
                    : ""
                }`}
              >
                {specificDonorLoading ? (
                  <div className="full-loading-screen">
                    <MetroSpinner
                      size={30}
                      color="#007C16"
                      loading={specificDonorLoading}
                    />
                  </div>
                ) : (
                  <>
                    {specificDonorError ? (
                      <div className="error-msg" style={{ marginTop: 0 }}>
                        {specificDonorError}
                      </div>
                    ) : (
                      <>
                        {specificDonorData ? (
                          <div className="donors-details-body">
                            <h6>
                              <Icon icon={chevronLeft} size={24} />
                              <Link
                                to="/donors"
                                style={{
                                  color: "#007c16",
                                  marginBottom: 2 + "px",
                                }}
                              >
                                BACK TO DONORS
                              </Link>
                            </h6>
                            <header className="donor-details-header">
                              <div className="crm-users-info">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="donor-circle"
                                    style={{
                                      backgroundColor:
                                        specificDonorData.status === 1
                                          ? specificDonorData &&
                                            specificDonorData.profileImage &&
                                            isLoaded
                                            ? "#f7f8fa"
                                            : "#007c16"
                                          : "#f2b92c",
                                      border: "1px solid #007c16",
                                    }}
                                  >
                                    {specificDonorData &&
                                    !specificDonorData.profileImage ? (
                                      <h3
                                        style={{
                                          color:
                                            specificDonorData.status === 1
                                              ? "#fff"
                                              : "#000",
                                        }}
                                      >
                                        {specificDonorData.firstName.charAt(0)}{" "}
                                        {specificDonorData.lastName.charAt(0)}
                                      </h3>
                                    ) : (
                                      <>
                                        {!isLoaded && (
                                          <h3
                                            style={{
                                              color:
                                                specificDonorData.status === 1
                                                  ? "#fff"
                                                  : "#000",
                                            }}
                                          >
                                            ...
                                          </h3>
                                        )}
                                        <img
                                          src={specificDonorData.profileImage}
                                          alt="dp"
                                          onLoad={() => setIsLoaded(true)}
                                          style={{
                                            display: !isLoaded
                                              ? "none"
                                              : "block",
                                          }}
                                        />
                                      </>
                                    )}

                                    <div className="upload-or-edit-icon-cirle">
                                      <input
                                        type="file"
                                        id="profileImageUpload"
                                        className="input-file"
                                        accept=".jpeg,.jpg,.png"
                                        onChange={(e) => {
                                          handleProfilePhoto(e.target.files[0]);
                                        }}
                                        onClick={(e) => (e.target.value = null)}
                                      />
                                      <label htmlFor="profileImageUpload">
                                        {!specificDonorData.profileImage ? (
                                          <Icon icon={arrows_plus} size={20} />
                                        ) : (
                                          <Icon
                                            icon={software_pencil}
                                            size={20}
                                          />
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  {fileError && (
                                    <div
                                      className="error-msg"
                                      style={{ width: "fit-content" }}
                                    >
                                      {fileError}
                                    </div>
                                  )}
                                </div>

                                <div className="user-name-email">
                                  <h1 className="media-name">
                                    {specificDonorData.firstName}{" "}
                                    {specificDonorData.middleName !== "" && (
                                      <>{specificDonorData.middleName}</>
                                    )}{" "}
                                    {specificDonorData.lastName}
                                  </h1>
                                  <h4>
                                    <Icon icon={mail} size={18} />{" "}
                                    {specificDonorData.email}
                                    <Icon
                                      icon={phone}
                                      size={18}
                                      style={{ marginLeft: 25 + "px" }}
                                    />{" "}
                                    {specificDonorData.phone}
                                  </h4>
                                  <h4 className="h4-last">
                                    <Icon icon={mapPin} size={18} />{" "}
                                    {specificDonorData.streetAddress},{" "}
                                    {specificDonorData.city},{" "}
                                    {specificDonorData.provinceState},{" "}
                                    {specificDonorData.postalZipCode},{" "}
                                    {specificDonorData.country}
                                  </h4>
                                  {specificDonorData.profileImage && (
                                    <MDBBtn
                                      type="button"
                                      className="delete-photo-btn"
                                      onClick={handleDeletePhoto}
                                      disabled={!isLoaded || deletePhotoLoading}
                                    >
                                      {deletePhotoLoading
                                        ? "Removing"
                                        : "Remove Photo"}
                                    </MDBBtn>
                                  )}
                                </div>
                              </div>
                            </header>
                            <div className="tabs-container">
                              <div
                                className={`tab ${
                                  activeTab === "Highlights" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("Highlights")}
                                style={{
                                  opacity: loadings ? 0.5 : 1,
                                  pointerEvents: loadings ? "none" : "auto",
                                }}
                              >
                                Highlights
                              </div>
                              <div
                                className={`tab ${
                                  activeTab === "Payments" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("Payments")}
                                style={{
                                  opacity:
                                    loadings ||
                                    selectedOrganization.gatewayNodeTag === 0
                                      ? 0.5
                                      : 1,
                                  pointerEvents:
                                    loadings ||
                                    selectedOrganization.gatewayNodeTag === 0
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                Payments
                              </div>
                              <div
                                className={`tab ${
                                  activeTab === "Transactions" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("Transactions")}
                                style={{
                                  opacity: loadings ? 0.5 : 1,
                                  pointerEvents: loadings ? "none" : "auto",
                                }}
                              >
                                Transactions
                              </div>
                              <div
                                className={`tab ${
                                  activeTab === "Year End Reports"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleTabClick("Year End Reports")
                                }
                                style={{
                                  opacity: loadings ? 0.5 : 1,
                                  pointerEvents: loadings ? "none" : "auto",
                                }}
                              >
                                Tax Receipts
                              </div>
                            </div>
                            <>
                              {loadings ? (
                                <div className="highlights-loadings">
                                  <MetroSpinner
                                    size={30}
                                    color="#007c16"
                                    loading={loadings}
                                  />
                                </div>
                              ) : (
                                <>
                                  {activeTab === "Highlights" && (
                                    <div className="tab-content">
                                      <div className="parent-container">
                                        <div className="box">
                                          <Lifetime />
                                        </div>
                                        <div className="box">
                                          <ThisYear
                                            token={user.token}
                                            email={specificDonorData.email}
                                          />
                                        </div>
                                        <div className="box">
                                          <KeyDates />
                                        </div>
                                        <div className="box">
                                          <NMonthSummary
                                            token={user.token}
                                            email={specificDonorData.email}
                                          />
                                        </div>
                                      </div>
                                      {/* recent transaction */}
                                      <div className="table-container">
                                        <div className="box">
                                          <RecentTransactions
                                            token={user.token}
                                            email={specificDonorData.email}
                                          />
                                        </div>
                                      </div>
                                      {/* year over years */}
                                      <div className="year-over-years-container">
                                        <div className="box">
                                          <YearOverYears
                                            token={user.token}
                                            email={specificDonorData.email}
                                          />
                                        </div>
                                      </div>
                                      {/* campaigns summary */}
                                      <div className="campaigns-summary-container">
                                        <div className="box">
                                          <CampaignsSummary
                                            token={user.token}
                                            email={specificDonorData.email}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {activeTab === "Payments" && (
                                    <div className="tab-content">
                                      <div
                                        className="media-heading-button-container"
                                        style={{ marginTop: 35 + "px" }}
                                      >
                                        <h6>Wallet</h6>
                                        <div className="add-and-filter-btns-div">
                                          <div className="action-btns-div">
                                            {walletData &&
                                            walletData.wallets &&
                                            walletData.wallets.length >= 5 ? (
                                              <Tippy content="Max cards limit reached">
                                                <div
                                                  style={{
                                                    background: "#007c17",
                                                    color: "#fff",
                                                    padding: 8 + "px",
                                                    width: "fit-content",
                                                    margin: 0,
                                                    opacity: 0.5,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  Add New Card
                                                </div>
                                              </Tippy>
                                            ) : (
                                              <button
                                                type="button"
                                                className="action-buttons"
                                                onClick={() =>
                                                  setAddWalletPopup(true)
                                                }
                                              >
                                                Add New Card
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <Wallets
                                        handleDeleteWalletPopup={
                                          handleDeleteWalletPopup
                                        }
                                        handleEditWalletPopup={
                                          handleEditWalletPopup
                                        }
                                        handleWalletChargePopup={
                                          handleWalletChargePopup
                                        }
                                      />
                                      {/* recurrings of donor */}
                                      <div
                                        className="media-heading-button-container"
                                        style={{ marginTop: 35 + "px" }}
                                        id="recurringSection"
                                      >
                                        <h6>Recurring Payments</h6>
                                        <div className="add-and-filter-btns-div">
                                          <div className="action-btns-div">
                                            <button
                                              type="button"
                                              className="action-buttons"
                                              onClick={handleNewRecurringPopup}
                                            >
                                              New Recurring
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="media-row">
                                        <RecurringContainer
                                          token={user.token}
                                          currentPage={currentPage}
                                          perPage={perPage}
                                          totalPages={totalPages}
                                          setCurrentPage={setCurrentPage}
                                          setPerPage={setPerPage}
                                          setTotalPages={setTotalPages}
                                          handleRecurringDetailsPopup={
                                            handleRecurringDetailsPopup
                                          }
                                          handleUpdateCardPopup={
                                            handleUpdateCardPopup
                                          }
                                          handleChangeAmountPopup={
                                            handleChangeAmountPopup
                                          }
                                          handleCloseRecurringPopup={
                                            handleCloseRecurringPopup
                                          }
                                          handleSwitchToActivePopup={
                                            handleSwitchToActivePopup
                                          }
                                          handleSwitchToHoldPopup={
                                            handleSwitchToHoldPopup
                                          }
                                          handleDeclinedTransactionsPopup={
                                            handleDeclinedTransactionsPopup
                                          }
                                          handleHistoryNotesPopup={
                                            handleHistoryNotesPopup
                                          }
                                          donorSpecific={donorSpecific}
                                          email={specificDonorData.email}
                                          triggerScroll={triggerScroll}
                                          setLoadings={setLoadings}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {activeTab === "Transactions" && (
                                    <div className="tab-content">
                                      <div
                                        className="media-heading-button-container"
                                        style={{ marginTop: 35 + "px" }}
                                      >
                                        <h6>Transactions</h6>
                                        <div className="add-and-filter-btns-div">
                                          <div className="action-btns-div">
                                            <MDBDropdown>
                                              <MDBDropdownToggle className="organizations-dropdown-button">
                                                NEW
                                              </MDBDropdownToggle>
                                              <MDBDropdownMenu>
                                                <MDBDropdownItem
                                                  link
                                                  onClick={
                                                    handleManualTransactionsPopup
                                                  }
                                                >
                                                  <span>
                                                    Manual Transaction
                                                  </span>
                                                </MDBDropdownItem>
                                                <MDBDropdownItem
                                                  link
                                                  disabled={
                                                    selectedOrganization.gatewayNodeTag ===
                                                    0
                                                  }
                                                  onClick={
                                                    handleCardTransactionsPopup
                                                  }
                                                >
                                                  <span>Card Transaction</span>
                                                </MDBDropdownItem>
                                              </MDBDropdownMenu>
                                            </MDBDropdown>
                                          </div>
                                          <div className="filter-and-reset-btns-div">
                                            {/* export records */}
                                            <Tippy
                                              content={
                                                exportTransactionLoading
                                                  ? "Please Wait"
                                                  : "Export Transactions"
                                              }
                                            >
                                              <button
                                                className="filter-media-modal-btn"
                                                type="button"
                                                onClick={handleExportRecords}
                                              >
                                                {exportTransactionLoading ? (
                                                  <MetroSpinner
                                                    loading={
                                                      exportTransactionLoading
                                                    }
                                                    size={24}
                                                    color="#6a6c6f"
                                                  />
                                                ) : (
                                                  <Icon
                                                    icon={arrowUp}
                                                    size={24}
                                                  />
                                                )}
                                              </button>
                                            </Tippy>
                                          </div>
                                        </div>
                                      </div>
                                      {/* transaction table */}
                                      <TransactionsTable
                                        token={user.token}
                                        setLoadings={setLoadings}
                                        currentPage={currentPageTransactions}
                                        perPage={perPageTransactions}
                                        totalPages={totalPagesTransactions}
                                        setCurrentPage={
                                          setCurrentPageTransactions
                                        }
                                        setPerPage={setPerPageTransactions}
                                        setTotalPages={
                                          setTotalPagesTransactions
                                        }
                                        handleTransactionDetailsPopup={
                                          handleTransactionDetailsPopup
                                        }
                                        handleUpdateCampaignPopup={
                                          handleUpdateCampaignPopup
                                        }
                                        handleDeleteTransactionsPopup={
                                          handleDeleteTransactionsPopup
                                        }
                                        handleEditTransactionPopup={
                                          handleEditTransactionPopup
                                        }
                                        handleTransactionTypePopup={
                                          handleTransactionTypePopup
                                        }
                                        transactionTypePopup={
                                          transactionTypePopup
                                        }
                                        donorSpecific={donorSpecific}
                                        email={specificDonorData.email}
                                      />
                                    </div>
                                  )}
                                  {activeTab === "Year End Reports" && (
                                    <div className="tab-content">
                                      <div
                                        className="media-heading-button-container"
                                        style={{ marginTop: 35 + "px" }}
                                      >
                                        <h6>Records</h6>
                                        <div className="add-and-filter-btns-div">
                                          <div className="action-btns-div">
                                            <button
                                              type="button"
                                              className="action-buttons"
                                              onClick={() =>
                                                setNewReceiptPopup(true)
                                              }
                                            >
                                              New Receipt
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <TaxReceiptTable
                                        token={user.token}
                                        perPage={perPageTaxReceipts}
                                        setPerPage={setPerPageTaxReceipts}
                                        currentPage={currentPageTaxReceipts}
                                        setCurrentPage={
                                          setCurrentPageTaxReceipts
                                        }
                                        totalPages={totalPagesTaxReceipts}
                                        setTotalPages={setTotalPagesTaxReceipts}
                                        setLoadings={setLoadings}
                                        donorSpecific={donorSpecific}
                                        donorSpecificEmail={
                                          specificDonorData.email
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          </div>
                        ) : (
                          <div className="full-page-center">
                            <Tippy content="No Donor Found">
                              <img
                                src={noDataFound}
                                alt="No Donor Found"
                                width={`100px`}
                                height={`auto`}
                              />
                            </Tippy>

                            <Link
                              to="/donors"
                              style={{ marginTop: 15 + "px", color: "#007c16" }}
                            >
                              Return to donors
                            </Link>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* add wallet */}
      {addWalletPopup && (
        <AddWalletPopus
          token={user.token}
          setAddWalletPopup={setAddWalletPopup}
          email={specificDonorData.email}
        />
      )}
      {/* delete wallet */}
      {deleteWalletPopup && (
        <DeleteWalletPopup
          token={user.token}
          setDeleteWalletPopup={setDeleteWalletPopup}
          email={specificDonorData.email}
          deleteWalletObj={deleteWalletObj}
        />
      )}
      {/* edit wallet */}
      {editWalletPopup && (
        <EditWalletPopup
          token={user.token}
          setEditWalletPopup={setEditWalletPopup}
          editWalletObj={editWalletObj}
          email={specificDonorData.email}
        />
      )}
      {/* wallet charge popup */}
      {walletChargePopup && (
        <WalletChargePopup
          token={user.token}
          setWalletChargePopup={setWalletChargePopup}
          walletObj={walletObj}
          email={specificDonorData.email}
          campaignOptions={campaignOptions}
          setActiveTab={setActiveTab}
        />
      )}
      {/* new recurring */}
      {newRecurringPopup && (
        <CardTransactionsPopup
          token={user.token}
          emailAvailable={specificDonorData.email}
          setCardTransactionsPopup={setNewRecurringPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
          oneTime={oneTime}
          triggerScroll={triggerScroll}
        />
      )}
      {/* recurring details */}
      {recurringDetailsPopup && (
        <RecurringDetailsPopup
          token={user.token}
          recurringObj={recurringObj}
          setRecurringDetailsPopup={setRecurringDetailsPopup}
        />
      )}
      {/* declined transactions */}
      {declinedTransactionsPopup && (
        <DeclinedTransactionsPopup
          setDeclinedTransactionsPopup={setDeclinedTransactionsPopup}
          declinedTransactionsObj={declinedTransactionsObj}
        />
      )}
      {/* history notes */}
      {historyNotesPopup && (
        <HistoryNotesPopup
          setHistoryNotesPopup={setHistoryNotesPopup}
          historyNotesObj={historyNotesObj}
        />
      )}
      {/* update card */}
      {updateCardPopup && (
        <UpdateCardPopup
          token={user.token}
          setUpdateCardPopup={setUpdateCardPopup}
          updateCardObj={updateCardObj}
        />
      )}
      {/* change amount */}
      {changeAmountPopup && (
        <ChangeAmountPopup
          token={user.token}
          setChangeAmountPopup={setChangeAmountPopup}
          changeAmountObj={changeAmountObj}
        />
      )}
      {/* close recurring popup */}
      {closeRecurringPopup && (
        <CloseRecurringPopup
          token={user.token}
          setCloseRecurringPopup={setCloseRecurringPopup}
          closeRecurringObj={closeRecurringObj}
        />
      )}
      {/* switch to active popup */}
      {switchToActivePopup && (
        <SwitchToActivePopup
          token={user.token}
          setSwitchToActivePopup={setSwitchToActivePopup}
          switchToActiveObj={switchToActiveObj}
        />
      )}
      {/* switch to hold popup */}
      {switchToHoldPopup && (
        <SwitchToHoldPopup
          token={user.token}
          setSwitchToHoldPopup={setSwitchToHoldPopup}
          switchToHoldObj={switchToHoldObj}
        />
      )}
      {manualTransactionsPopup && (
        <ManualTransactionsPopup
          token={user.token}
          emailAvailable={specificDonorData.email}
          setManualTransactionsPopup={setManualTransactionsPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
          donorSpecific={donorSpecific}
        />
      )}
      {cardTransactionsPopup && (
        <CardTransactionsPopup
          token={user.token}
          emailAvailable={specificDonorData.email}
          setCardTransactionsPopup={setCardTransactionsPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
          oneTime={true}
        />
      )}
      {transactionDetailsPopup && (
        <TransactionDetailsPopup
          token={user.token}
          setTransactionDetailsPopup={setTransactionDetailsPopup}
          transactionObj={transactionObj}
        />
      )}
      {updateCampaignPopup && (
        <UpdateCampaignPopup
          token={user.token}
          setUpdateCampaignPopup={setUpdateCampaignPopup}
          updateCampaignObj={updateCampaignObj}
          allCampaigns={allCampaigns}
        />
      )}
      {deleteTransactionPopup && (
        <DeleteTransactionPopup
          setDeleteTransactionPopup={setDeleteTransactionPopup}
          deleteTransactionObj={deleteTransactionObj}
          token={user.token}
          perPage={perPageTransactions}
          setCurrentPage={setCurrentPageTransactions}
          setPerPage={setPerPageTransactions}
          setTotalPages={setTotalPagesTransactions}
          currentPage={currentPageTransactions}
        />
      )}
      {/* transaction type refund */}
      {transactionTypePopup && (
        <RefundPopup
          token={user.token}
          setTransactionTypePopup={setTransactionTypePopup}
          transactionTypeObj={transactionTypeObj}
        />
      )}
      {/* manual transaction edit */}
      {editTransactionPopup && (
        <ManualEditTransactionPopup
          token={user.token}
          setEditTransactionPopup={setEditTransactionPopup}
          editTransactionObj={editTransactionObj}
          campaignOptions={campaignOptions}
          donorSpecific={donorSpecific}
        />
      )}
      {/* new receipt */}
      {newReceiptPopup && (
        <NewReceiptPopup
          token={user.token}
          setNewReceiptPopup={setNewReceiptPopup}
          yearOptions={yearOptions}
          donorSpecific={donorSpecific}
          donorSpecificEmail={specificDonorData.email}
        />
      )}
    </>
  );
};

export default DonorDetails;
