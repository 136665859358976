import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../../config";

// get schedule summary
export const getScheduleSummaryData = createAsyncThunk(
  "eCheck/getScheduleSummaryData",
  async (token) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/electronicPaymentScheduleSummary`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// get echeck data
export const getECheckData = createAsyncThunk(
  "eCheck/getECheckData",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/electronicPayment?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter echeck records
export const filterECheckRecords = createAsyncThunk(
  "eCheck/filterECheckRecords",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "amount",
      "institute_number",
      "campaign_tag",
      "email",
      "invoice",
      "transit_number",
      "status",
      "billing_period",
      "created_by",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/electronicPayment?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// get echeckData list view
export const readListViewData = createAsyncThunk(
  "eCheck/readListViewData",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/electronicPaymentSchedule?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter list view records
export const filterListViewRecords = createAsyncThunk(
  "eCheck/filterListViewRecords",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "campaign_tag",
      "charged_amount",
      "invoice",
      "email",
      "batch_id",
      "billing_period",
      "institute_number",
      "transit_number",
      "created_by",
      "status",
      "schedule_status",
      "process_date",
      "return_date",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/electronicPaymentSchedule?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// get banking details
export const getBankingDetails = createAsyncThunk(
  "eCheck/getBankingDetails",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
        params: {
          email: singleObj.email,
        },
      };

      const request = await axios.get(
        `${hostName}api/admin/v1/electronicPaymentBanking`,
        axiosConfig
      );

      const response = request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }

      return {
        data: null,
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
      };
    }
  }
);

// add echeck transaction
export const addECheckTransaction = createAsyncThunk(
  "eCheck/addECheckTransaction",
  async (singleObj) => {
    try {
      const data = {
        accountHolderName: singleObj.accountHolderName,
        campaignTag: singleObj.campaignTag,
        email: singleObj.email,
        amount: singleObj.amount,
        billingPeriod: singleObj.billingPeriod,
        totalPayments: singleObj.totalPayments,
        startDate: singleObj.startDate,
        instituteNumber: singleObj.instituteNumber,
        transitNumber: singleObj.transitNumber,
        accountNumber: singleObj.accountNumber,
        accountCode: singleObj.accountCode,
      };

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/electronicPayment`,
        data,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
      };
    }
  }
);

// edit echeck transaction
export const editECheckTransaction = createAsyncThunk(
  "eCheck/editECheckTransaction",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const data = {
        accountHolderName: singleObj.accountHolderName,
        campaignTag: singleObj.campaignTag,
        email: singleObj.email,
        amount: singleObj.amount,
        billingPeriod: singleObj.billingPeriod,
        totalPayments: singleObj.totalPayments,
        startDate: singleObj.startDate,
        instituteNumber: singleObj.instituteNumber,
        transitNumber: singleObj.transitNumber,
        accountNumber: singleObj.accountNumber,
        accountCode: singleObj.accountCode,
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/electronicPayment/${singleObj.tagNumber}`,
        data,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// close eCheck Transaction
export const closeECheckTransaction = createAsyncThunk(
  "eCheck/closeECheckTransaction",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.patch(
        `${hostName}api/admin/v1/electronicPayment/${singleObj.tagNumber}`,
        null,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// cancel payment
export const cancelPayment = createAsyncThunk(
  "eCheck/cancelPayment",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/electronicPaymentSchedule`,
        {
          scheduleTag: singleObj.scheduleTag,
        },
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        scheduleTag: singleObj.scheduleTag,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: null,
        axiosError: error.message,
        scheduleTag: singleObj.scheduleTag,
      };
    }
  }
);

// read schedule
export const readSchedule = createAsyncThunk(
  "eCheck/readSchedule",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };

    const request = await axios.get(
      `${hostName}api/admin/v1/electronicPayment/${singleObj.tagNumber}?page=${singleObj.page}&pageSize=${singleObj.pageSize}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// send email in eCheck
export const sendEmailInECheck = createAsyncThunk(
  "eCheck/sendEmailInECheck",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        tagNumber: singleObj.tagNumber,
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/transaction/sendInvoice`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// update amount
export const updateAmount = createAsyncThunk(
  "eCheck/updateAmount",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/electronicPaymentScheduleAmount`,
        {
          scheduleTag: singleObj.scheduleTag,
          amount: singleObj.amount,
        },
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        scheduleTag: singleObj.scheduleTag,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        scheduleTag: singleObj.scheduleTag,
      };
    }
  }
);

const eCheckSlice = createSlice({
  name: "echeck",
  initialState: {
    // schedule summary
    scheduleSummaryLoading: false,
    scheduleSummaryData: null,
    scheduleSummaryError: null,
    // get echeck data
    eCheckLoading: false,
    eCheckData: null,
    eCheckError: null,
    // list view
    listViewLoading: false,
    listViewData: null,
    listViewError: null,
    // banking details
    bankingDetailsLoading: false,
    bankingDetailsData: null,
    // add echeck transaction
    addETransactionLoading: false,
    addETransactionData: null,
    // edit echeck transaction
    editETransactionLoading: false,
    editETransactionData: null,
    // close eCheck Transaction
    closeECheckLoading: false,
    closeECheckData: null,
    // cancel payment
    cancelPaymentLoading: false,
    cancelPaymentData: null,
    // schedule
    scheduleLoading: false,
    scheduleData: null,
    scheduleError: null,
    // send email in echeck
    sendEmailInECheckLoading: false,
    sendEmailInECheckData: null,
    // update amount
    updateAmountLoading: false,
    updateAmountData: null,
  },
  reducers: {
    clearAddETransferModalStates: (state) => {
      state.addETransactionData = null;
    },
    clearBankingDetailsStates: (state) => {
      state.bankingDetailsData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get schedule summary
      .addCase(getScheduleSummaryData.pending, (state) => {
        state.scheduleSummaryLoading = true;
        state.scheduleSummaryData = null;
        state.scheduleSummaryError = null;
      })
      .addCase(getScheduleSummaryData.fulfilled, (state, action) => {
        state.scheduleSummaryLoading = false;
        state.scheduleSummaryData = action.payload;
        state.scheduleSummaryError = null;
      })
      .addCase(getScheduleSummaryData.rejected, (state, action) => {
        state.scheduleSummaryLoading = false;
        state.scheduleSummaryData = null;
        state.scheduleSummaryError = action.error.message;
      })
      // get eCheck data
      .addCase(getECheckData.pending, (state) => {
        state.eCheckLoading = true;
        state.eCheckData = null;
        state.eCheckError = null;
      })
      .addCase(getECheckData.fulfilled, (state, action) => {
        state.eCheckLoading = false;
        state.eCheckData = action.payload;
        state.eCheckError = null;
      })
      .addCase(getECheckData.rejected, (state, action) => {
        state.eCheckLoading = false;
        state.eCheckData = null;
        state.eCheckError = action.error.message;
      })
      // filter eCheck records
      .addCase(filterECheckRecords.pending, (state) => {
        state.eCheckLoading = true;
        state.eCheckData = null;
        state.eCheckError = null;
      })
      .addCase(filterECheckRecords.fulfilled, (state, action) => {
        state.eCheckLoading = false;
        state.eCheckData = action.payload;
        state.eCheckError = null;
      })
      .addCase(filterECheckRecords.rejected, (state, action) => {
        state.eCheckLoading = false;
        state.eCheckData = null;
        state.eCheckError = action.error.message;
      })
      // read list view data
      .addCase(readListViewData.pending, (state) => {
        state.listViewLoading = true;
        state.listViewData = null;
        state.listViewError = null;
      })
      .addCase(readListViewData.fulfilled, (state, action) => {
        state.listViewLoading = false;
        state.listViewData = action.payload;
        state.listViewError = null;
      })
      .addCase(readListViewData.rejected, (state, action) => {
        state.listViewLoading = false;
        state.listViewData = null;
        state.listViewError = action.error.message;
      })
      // filter listView records
      .addCase(filterListViewRecords.pending, (state) => {
        state.listViewLoading = true;
        state.listViewData = null;
        state.listViewError = null;
      })
      .addCase(filterListViewRecords.fulfilled, (state, action) => {
        state.listViewLoading = false;
        state.listViewData = action.payload;
        state.listViewError = null;
      })
      .addCase(filterListViewRecords.rejected, (state, action) => {
        state.listViewLoading = false;
        state.listViewData = null;
        state.listViewError = action.error.message;
      })
      // banking details
      .addCase(getBankingDetails.pending, (state) => {
        state.bankingDetailsLoading = true;
        state.bankingDetailsData = null;
      })
      .addCase(getBankingDetails.fulfilled, (state, action) => {
        state.bankingDetailsLoading = false;
        state.bankingDetailsData = action.payload;
      })
      // add echeck transaction
      .addCase(addECheckTransaction.pending, (state) => {
        state.addETransactionLoading = true;
        state.addETransactionData = null;
      })
      .addCase(addECheckTransaction.fulfilled, (state, action) => {
        state.addETransactionLoading = false;
        state.addETransactionData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.eCheckData.data.unshift(data);
        }
      })
      // edit echeck transaction
      .addCase(editECheckTransaction.pending, (state) => {
        state.editETransactionLoading = true;
        state.editETransactionData = null;
      })
      .addCase(editECheckTransaction.fulfilled, (state, action) => {
        state.editETransactionLoading = false;
        state.editETransactionData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const index = state.eCheckData.data.findIndex(
            (echeck) => echeck.tagNumber === tagNumber
          );
          if (index !== -1) {
            state.eCheckData.data[index] = data;
          }
        }
      })
      // close eCheck Transaction
      .addCase(closeECheckTransaction.pending, (state) => {
        state.closeECheckLoading = true;
        state.closeECheckData = null;
      })
      .addCase(closeECheckTransaction.fulfilled, (state, action) => {
        state.closeECheckLoading = false;
        state.closeECheckData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const index = state.eCheckData.data.findIndex(
            (data) => data.tagNumber === tagNumber
          );
          if (index !== -1) {
            state.eCheckData.data[index] = data;
          }
        }
      })
      // cancel payment
      .addCase(cancelPayment.pending, (state) => {
        state.cancelPaymentLoading = true;
        state.cancelPaymentData = null;
      })
      .addCase(cancelPayment.fulfilled, (state, action) => {
        state.cancelPaymentLoading = false;
        state.cancelPaymentData = action.payload;
        const { data, scheduleTag } = action.payload;
        if (data) {
          const index = state.listViewData.data.findIndex(
            (data) => data.scheduleTag === scheduleTag
          );
          if (index !== -1) {
            state.listViewData.data[index] = data;
          }
        }
      })
      // get schedule data
      .addCase(readSchedule.pending, (state) => {
        state.scheduleLoading = true;
        state.scheduleData = null;
        state.scheduleError = null;
      })
      .addCase(readSchedule.fulfilled, (state, action) => {
        state.scheduleLoading = false;
        state.scheduleData = action.payload;
        state.scheduleError = null;
      })
      .addCase(readSchedule.rejected, (state, action) => {
        state.scheduleLoading = false;
        state.scheduleData = null;
        state.scheduleError = action.error.message;
      })
      // send email in eCheck
      .addCase(sendEmailInECheck.pending, (state, action) => {
        state.sendEmailInECheckLoading = true;
        state.sendEmailInECheckData = action.meta.arg;
      })
      .addCase(sendEmailInECheck.fulfilled, (state, action) => {
        state.sendEmailInECheckLoading = false;
        state.sendEmailInECheckData = action.payload;
      })
      // update amount
      .addCase(updateAmount.pending, (state) => {
        state.updateAmountLoading = true;
        state.updateAmountData = null;
      })
      .addCase(updateAmount.fulfilled, (state, action) => {
        state.updateAmountLoading = false;
        state.updateAmountData = action.payload;
        const { data, scheduleTag } = action.payload;
        if (data) {
          const objIndex = state.listViewData.data.findIndex(
            (obj) => obj.scheduleTag === scheduleTag
          );

          if (objIndex !== -1) {
            state.listViewData.data[objIndex] = data;
          }
        }
      });
  },
});

export default eCheckSlice.reducer;
export const { clearAddETransferModalStates, clearBankingDetailsStates } =
  eCheckSlice.actions;
