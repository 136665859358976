import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredTopDonor } from "../../Store/Slices/Sidebar/Analytics/TopDonorSlice";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import noDataFound from "../../Images/noDataFound.png";
import {
  MDBBadge,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

function generateColors(length) {
  const colors = [];

  // Define the colors in the gradient
  const gradientColors = [
    "#34495e",
    "#9b59b6",
    "#3498db",
    "#62cb31",
    "#ffb606",
    "#e67e22",
    "#e74c3c",
    "#c0392b",
    "#175935",
    "#1f9d61",
    "#cc2473",
    "#d828c9",
    "#453095",
    "#9100ff",
    "#884a39",
    "#c38154",
  ];

  // Generate new colors after the eighth position
  const gradientLength = gradientColors.length;
  for (let i = 0; i < length; i++) {
    let color;
    if (i < gradientLength) {
      color = gradientColors[i];
    } else {
      // Generate a new color based on a pattern
      const colorIndex = i % gradientLength;
      const patternIndex = Math.floor(i / gradientLength) - 1;
      const patternColor = gradientColors[colorIndex];

      const patternColors = [
        "#212e48",
        "#800080",
        "#2d86ff",
        "#00d327",
        "#ffcb2b",
        "#ffa800",
        "#f25410",
        "#cd4c3a",
      ];

      const newColorIndex = patternIndex % patternColors.length;
      const newColor = patternColors[newColorIndex];

      // Combine the pattern color and new color
      color = mixColors(patternColor, newColor);
    }
    colors.push(color);
  }
  return colors;
}

// Helper function to mix two colors
function mixColors(color1, color2) {
  // Perform your logic to mix the colors
  // Here's a simple example that mixes the colors by averaging their RGB values
  const rgb1 = hexToRGB(color1);
  const rgb2 = hexToRGB(color2);

  const mixedColor = {
    r: Math.floor((rgb1.r + rgb2.r) / 2),
    g: Math.floor((rgb1.g + rgb2.g) / 2),
    b: Math.floor((rgb1.b + rgb2.b) / 2),
  };
  return rgbToHex(mixedColor);
}

// Helper function to convert hex color to RGB object
function hexToRGB(hex) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
}

// Helper function to convert RGB object to hex color
function rgbToHex(rgb) {
  const { r, g, b } = rgb;
  const hexR = r.toString(16).padStart(2, "0");
  const hexG = g.toString(16).padStart(2, "0");
  const hexB = b.toString(16).padStart(2, "0");
  return `#${hexR}${hexG}${hexB}`;
}

const TopDonor = ({ token }) => {
  // redux state
  const { filteredTopDonorLoading, topDonorData, topDonorError } = useSelector(
    (state) => state.topDonor
  );

  // Calculate the total sum of amounts
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (topDonorData && topDonorData.values.length > 0) {
      const totalSum = topDonorData.values.reduce(
        (sum, obj) => sum + obj.amount,
        0
      );
      const roundedSum = totalSum.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [topDonorData]);

  // progress bars colors
  const [progressColors, setProgressColors] = useState([]);
  useEffect(() => {
    if (topDonorData !== null && topDonorData.values.length > 0) {
      const colors = generateColors(topDonorData.values.length);
      setProgressColors(colors);
    } else {
      setProgressColors([]);
    }
  }, [topDonorData]);

  // month options array
  const monthOptions = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];

  // get month label
  const getMonthLabel = (monthValue) => {
    const monthObj = monthOptions.find((option) => option.value === monthValue);
    return monthObj ? monthObj.label : "";
  };

  // current date
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = currentDate.getFullYear();

  // getting year options
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // states
  const [month, setMonth] = useState({
    value: currentMonth,
    label: getMonthLabel(currentMonth),
  });
  const [year, setYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [selectedYear, setSelectedYear] = useState(year);
  const [popup, setPopup] = useState(false);

  const [numberInput, setNumberInput] = useState(5);

  const handleNumberChange = (event) => {
    let newValue = parseInt(event.target.value, 10);
    if (newValue < 1) {
      newValue = 1;
    } else if (newValue > 20) {
      newValue = 20;
    }
    setNumberInput(newValue);
  };

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle filter
  const handleMonthAndYearFilter = () => {
    setPopup(false);
    setSelectedMonth(month);
    setSelectedYear(year);
    // Check if numberInput is NaN, and if it is, set it to 1
    let filteredNumber = isNaN(numberInput) ? 1 : numberInput;
    const singleObj = {
      token,
      n: filteredNumber,
      year,
      month: month.value,
    };
    dispatch(getFilteredTopDonor(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  // custom styles
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#e1e1e9",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#b1b1b9",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
      boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
      "&:hover": {
        border: "1px solid #007C16",
        boxShadow: "0px 0px 1px #007C16",
      },
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#007C16"
        : isFocused
        ? "rgba(0, 124, 22, 0.2)"
        : base.backgroundColor,
      color: isSelected ? "white" : base.color,
      "&:active": {
        backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
      },
    }),
  };

  return (
    <>
      <div className="text-and-filter-div">
        <span>
          Top Donors in {selectedMonth.label} {selectedYear}:{" "}
          <span style={{ color: "#007C16" }}>
            {topDonorData && <>{topDonorData.currencySymbol}</>}
            {sum}
          </span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Month, Year and Top Records</h4>
          <div className="month-summary-filter-form">
            <div className="filter-form-col">
              <label>Month</label>
              <Select
                value={monthOptions.find(
                  (option) => option.value === month.value
                )}
                onChange={(option) => setMonth(option)}
                options={monthOptions}
                styles={styles}
              />
            </div>
            <div className="filter-form-col">
              <label>Year</label>
              <Select
                value={yearOptions.find((option) => option.value === year)}
                onChange={(option) => setYear(option.value)}
                options={yearOptions}
                styles={styles}
              />
            </div>
            <div className="filter-form-col">
              <label>Top Records</label>
              <CustomTextField
                type="number"
                variant="outlined"
                fullWidth
                autoComplete="off"
                value={numberInput}
                onChange={handleNumberChange}
                min={1}
                max={20}
                size="small"
              />
            </div>
            <div className="popup-filter-btn-div">
              <button type="button" onClick={handleMonthAndYearFilter}>
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`custom-table-container${
          topDonorData && topDonorData.values.length > 0 ? "" : " flex"
        }`}
      >
        {filteredTopDonorLoading ? (
          <MetroSpinner
            size={30}
            color="#007C16"
            loading={filteredTopDonorLoading}
          />
        ) : (
          <>
            {topDonorError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {topDonorError}
              </div>
            ) : (
              <>
                {topDonorData && topDonorData.values.length > 0 ? (
                  <MDBTable align="middle">
                    <MDBTableHead className="thead">
                      <tr>
                        <th scope="col">Donors</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Transactions</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {topDonorData.values.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div
                                  className="rounded-circle"
                                  style={{
                                    padding: "7px",
                                    width: "50px",
                                    height: "50px",
                                    backgroundColor: progressColors[index],
                                    color: "#fff",
                                    letterSpacing: "0.09em",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {data.firstName.charAt(0)}
                                  {data.lastName.charAt(0)}
                                </div>
                                <div className="ms-3">
                                  <p className="fw-bold mb-1">
                                    {data.firstName} {data.lastName}
                                  </p>
                                  <p className="text-muted mb-0">
                                    {data.email}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p
                                className="fw-normal mb-1"
                                style={{ fontSize: "16px" }}
                              >
                                {topDonorData.currencySymbol}
                                {data.amount}
                              </p>
                            </td>
                            <td>
                              <MDBBadge color="success" pill>
                                {data.numberOfTransaction}
                              </MDBBadge>
                            </td>
                          </tr>
                        );
                      })}
                    </MDBTableBody>
                  </MDBTable>
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Data Found">
                      <img src={noDataFound} alt="No Data Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TopDonor;
