import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredMonthSummary } from "../../Store/Slices/Sidebar/Dashboard/MonthSummarySlice";
import { MetroSpinner } from "react-spinners-kit";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import noDataFound from "../../Images/noDataFound.png";

// Register the plugin to all charts:
ChartJS.register(ChartDataLabels);

const MonthSummary = ({ token }) => {
  // redux state
  const { filteredMonthSummaryLoading, monthSummaryData, monthSummaryError } =
    useSelector((state) => state.monthSummary);

  // calculate total amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (
      monthSummaryData &&
      monthSummaryData.datasets &&
      monthSummaryData.datasets[0] &&
      monthSummaryData.datasets[0].data
    ) {
      if (monthSummaryData.datasets[0].data.length > 0) {
        const calculatedSum = monthSummaryData.datasets[0].data.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        const roundedSum = calculatedSum.toFixed(2);
        setSum(roundedSum);
      } else {
        setSum("0.00");
      }
    } else {
      setSum("0.00");
    }
  }, [monthSummaryData]);

  // month options array
  const monthOptions = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];

  // get month label
  const getMonthLabel = (monthValue) => {
    const monthObj = monthOptions.find((option) => option.value === monthValue);
    return monthObj ? monthObj.label : "";
  };

  // current date
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = currentDate.getFullYear();

   // getting year options
   const yearOptions = [];
   for (let i = 0; i < 5; i++) {
     const year = currentYear - i;
     yearOptions.push({ value: year.toString(), label: year.toString() });
   }

  // states
  const [month, setMonth] = useState({
    value: currentMonth,
    label: getMonthLabel(currentMonth),
  });
  const [year, setYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [selectedYear, setSelectedYear] = useState(year);
  const [popup, setPopup] = useState(false);

  // custom styles
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#e1e1e9",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#b1b1b9",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
      boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
      "&:hover": {
        border: "1px solid #007C16",
        boxShadow: "0px 0px 1px #007C16",
      },
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#007C16"
        : isFocused
        ? "rgba(0, 124, 22, 0.2)"
        : base.backgroundColor,
      color: isSelected ? "white" : base.color,
      "&:active": {
        backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
      },
    }),
  };

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle filter
  const handleMonthAndYearFilter = () => {
    setPopup(false);
    setSelectedMonth(month);
    setSelectedYear(year);
    const singleObj = {
      token,
      month: month.value,
      year,
    };
    dispatch(getFilteredMonthSummary(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  return (
    <>
      <div className="text-and-filter-div">
        <span>
          Donations in {selectedMonth.label} {selectedYear}:{" "}
          <span style={{ color: "#007C16" }}>
            {monthSummaryData && (
              <>{monthSummaryData.currencySymbol}</>
            )}
            {sum}
          </span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Month and Year</h4>
          <div className="month-summary-filter-form">
            <div className="filter-form-col">
              <label>Month</label>
              <Select
                value={monthOptions.find(
                  (option) => option.value === month.value
                )}
                onChange={(option) => setMonth(option)}
                options={monthOptions}
                styles={styles}
              />
            </div>
            <div className="filter-form-col">
              <label>Year</label>
              <Select
                value={yearOptions.find((option) => option.value === year)}
                onChange={(option) => setYear(option.value)}
                options={yearOptions}
                styles={styles}
              />
            </div>
            <div className="popup-filter-btn-div">
              <button type="button" onClick={handleMonthAndYearFilter}>
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="graph-container">
        {filteredMonthSummaryLoading ? (
          <MetroSpinner
            size={30}
            color="#007C16"
            loading={filteredMonthSummaryLoading}
          />
        ) : (
          <>
            {monthSummaryError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {monthSummaryError}
              </div>
            ) : (
              <>
                {monthSummaryData &&
                monthSummaryData.datasets &&
                monthSummaryData.datasets[0] &&
                monthSummaryData.datasets[0].data &&
                monthSummaryData.datasets[0].data.length > 0 ? (
                  <Line
                    data={monthSummaryData}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            callback: (value) => {
                              if (value >= 1000) {
                                return value
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              } else {
                                return value.toString();
                              }
                            },
                          },
                        },
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          backgroundColor: "#fff",
                          titleColor: "#000",
                          bodyColor: "#000",
                          callbacks: {
                            label: (context) =>
                              `${monthSummaryData.currencySymbol}` +
                              context.formattedValue,
                          },
                        },
                        datalabels: {
                          display: false,
                        },
                      },
                    }}
                  />
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Data Found">
                      <img src={noDataFound} alt="No Data Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MonthSummary;
