import React, { useState } from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { basic_calendar } from "react-icons-kit/linea/basic_calendar";
import { ecommerce_graph2 } from "react-icons-kit/linea/ecommerce_graph2";
import { weather_cloud } from "react-icons-kit/linea/weather_cloud";
import { basic_alarm } from "react-icons-kit/linea/basic_alarm";
import { x } from "react-icons-kit/feather/x";

const Widgets = ({ handleUpdateWidgetsModal }) => {
  // redux state
  const { widgetsData, widgetsError } = useSelector((state) => state.widgets);

  const icons = {
    Weather: weather_cloud,
    "Date Time": basic_calendar,
    Schedule: basic_alarm,
    "Target Chart": ecommerce_graph2,
  };

  const [popupStates, setPopupStates] = useState({});

  const togglePopup = (tagNumber) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [tagNumber]: !prevState[tagNumber],
    }));
  };

  return (
    <>
      {widgetsError ? (
        <div className="error-msg" style={{ width: "fit-content" }}>
          {widgetsError}
        </div>
      ) : (
        <>
          {widgetsData && widgetsData.length > 0 ? (
            <>
              {widgetsData.map((data) => {
                const icon = icons[data.name];
                const tagNumber = data.tagNumber;
                const isPopupOpen = popupStates[tagNumber];

                return (
                  <div key={tagNumber} className="widgets-box">
                    <div className="background">
                      {data.valueRequired && (
                        <>
                          <Tippy
                            content={
                              isPopupOpen
                                ? "Cancel"
                                : data.name === "Schedule"
                                ? "See Schedule"
                                : "More"
                            }
                          >
                            <span
                              className="schedule-icon"
                              onClick={() => togglePopup(tagNumber)}
                            >
                              {isPopupOpen ? (
                                <Icon icon={x} size={16} />
                              ) : (
                                <Icon icon={moreVertical} size={16} />
                              )}
                            </span>
                          </Tippy>
                          <div className={isPopupOpen ? "popup open" : "popup"}>
                            <div className="widget-value-div">
                              {data.widgetValue
                                .split("\n")
                                .map((line, index) => (
                                  <p
                                    key={index}
                                    style={{ marginBottom: 4 + "px" }}
                                  >
                                    {line}
                                  </p>
                                ))}
                            </div>
                          </div>
                        </>
                      )}
                      {icon && <Icon icon={icon} size={72} />}
                      <h4>{data.name}</h4>
                    </div>
                    <div className="status-box">
                      <span>Location</span>
                      <span>{data.location}</span>
                    </div>
                    <div className="status-box">
                      <span>Status</span>
                      <span>
                        <span>{data.status ? "Active" : "Inactive"}</span>{" "}
                        <span>/</span>{" "}
                        <span
                          style={{ color: "#00A727", cursor: "pointer" }}
                          onClick={() => handleUpdateWidgetsModal(data)}
                        >
                          Edit
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Widgets Found">
                <img src={noDataFound} alt="No Widgets Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Widgets;
