import React from 'react';
import authLogo from '../../Images/auth-logo.png';

export const AuthLogo = () => {
  return (
    <div className='mervice-logo'>
        <img src={authLogo} alt='mervice' />
    </div>
  )
}
export default AuthLogo;
