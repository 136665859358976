import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useSelector } from "react-redux";
import LazyLoad from "react-lazy-load";
import { FixedSizeGrid as Grid } from "react-window";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const IconDrawer = ({ setStep, iconTag, changeIconFunction }) => {
  // reducer data
  const { iconsData, iconsError } = useSelector(
    (state) => state.getIconsAndNodes
  );

  // body
  const bodyRef = useRef(null);
  const [bodyHeight, setBodyHeight] = useState(0);
  const [bodyWidth, setBodyWidth] = useState(0);

  // get body dimension
  useEffect(() => {
    const updateDimension = () => {
      if (bodyRef.current) {
        setBodyHeight(bodyRef.current.offsetHeight);
        const newBodyWidth = bodyRef.current.offsetWidth - 10;
        setBodyWidth(newBodyWidth);
      }
    };

    updateDimension();
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Select an icon</div>
            <div
              className="cancel-widget-update-icon"
              onClick={() => setStep("campaign-details")}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div
            className="update-widgets-modal-body"
            style={{
              width: "100%",
              padding: "4px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            ref={bodyRef}
          >
            {iconsError ? (
              <div className="error-msg mb-3 mt-0">{iconsError}</div>
            ) : (
              <>
                {iconsData?.length > 0 ? (
                  <Grid
                    className="list-container"
                    height={bodyHeight - 100}
                    width={bodyWidth >= 490 ? 480 : 360}
                    columnCount={6}
                    columnWidth={bodyWidth >= 490 ? 78 : 58}
                    rowCount={Math.ceil(iconsData.length / 6)}
                    rowHeight={100}
                  >
                    {({ columnIndex, rowIndex, style }) => {
                      const index = rowIndex * 6 + columnIndex;
                      if (index >= iconsData.length) return null; // Avoid rendering items that don't exist

                      return (
                        <div style={style} className="cursor-pointer">
                          <label
                            key={iconsData[index].tagNumber}
                            className={
                              iconTag === iconsData[index].tagNumber
                                ? "selected"
                                : ""
                            }
                          >
                            <input
                              className="d-none"
                              type="radio"
                              name="iconsgroupt"
                              value={iconsData[index].tagNumber}
                              checked={iconTag === iconsData[index].tagNumber}
                              onChange={() =>
                                changeIconFunction(
                                  iconsData[index].tagNumber,
                                  iconsData[index].filename
                                )
                              }
                            />

                            <LazyLoad offset={200}>
                              <img
                                className="radio-icon"
                                src={iconsData[index].filename}
                                alt={iconsData[index].tag}
                              />
                            </LazyLoad>
                          </label>
                        </div>
                      );
                    }}
                  </Grid>
                ) : (
                  <div className="error-msg mb-3 mt-0">No Icons Found</div>
                )}
              </>
            )}

            <div
              className="reset-and-filter-div"
              style={{ width: "100%", padding: "10px" }}
            >
              <button
                type="button"
                className="reset-fields-btn"
                onClick={() => setStep("campaign-details")}
                style={{ width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default IconDrawer;
