/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFilteredCampaignSummary,
  getFilteredCampaignYearSummary,
  getFilteredCampaignYearMonthDaySummary,
  getFilteredCampaignYearMonthSummary,
} from "../../Store/Slices/Sidebar/Dashboard/CampaignSummarySlice";
import { MetroSpinner } from "react-spinners-kit";
import Select from "react-select";
import DatePicker from "react-date-picker";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { minus } from "react-icons-kit/feather/minus";
import noDataFound from "../../Images/noDataFound.png";
import {
  MDBBadge,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBCheckbox,
} from "mdb-react-ui-kit";

function generateColors(length) {
  const colors = [];

  // Define the colors in the gradient
  const gradientColors = [
    "#34495e",
    "#9b59b6",
    "#3498db",
    "#62cb31",
    "#ffb606",
    "#e67e22",
    "#e74c3c",
    "#c0392b",
    "#175935",
    "#1f9d61",
    "#cc2473",
    "#d828c9",
    "#453095",
    "#9100ff",
    "#884a39",
    "#c38154",
  ];

  // Generate new colors after the eighth position
  const gradientLength = gradientColors.length;
  for (let i = 0; i < length; i++) {
    let color;
    if (i < gradientLength) {
      color = gradientColors[i];
    } else {
      // Generate a new color based on a pattern
      const colorIndex = i % gradientLength;
      const patternIndex = Math.floor(i / gradientLength) - 1;
      const patternColor = gradientColors[colorIndex];
      const patternColors = [
        "#212e48",
        "#800080",
        "#2d86ff",
        "#00d327",
        "#ffcb2b",
        "#ffa800",
        "#f25410",
        "#cd4c3a",
      ];

      const newColorIndex = patternIndex % patternColors.length;
      const newColor = patternColors[newColorIndex];

      // Combine the pattern color and new color
      color = mixColors(patternColor, newColor);
    }
    colors.push(color);
  }
  return colors;
}

// Helper function to mix two colors
function mixColors(color1, color2) {
  // Perform your logic to mix the colors
  // Here's a simple example that mixes the colors by averaging their RGB values
  const rgb1 = hexToRGB(color1);
  const rgb2 = hexToRGB(color2);

  const mixedColor = {
    r: Math.floor((rgb1.r + rgb2.r) / 2),
    g: Math.floor((rgb1.g + rgb2.g) / 2),
    b: Math.floor((rgb1.b + rgb2.b) / 2),
  };
  return rgbToHex(mixedColor);
}

// Helper function to convert hex color to RGB object
function hexToRGB(hex) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
}

// Helper function to convert RGB object to hex color
function rgbToHex(rgb) {
  const { r, g, b } = rgb;
  const hexR = r.toString(16).padStart(2, "0");
  const hexG = g.toString(16).padStart(2, "0");
  const hexB = b.toString(16).padStart(2, "0");
  return `#${hexR}${hexG}${hexB}`;
}

const CampaignTable = ({ token }) => {
  // redux state
  const {
    filteredCampaignSummaryLoading,
    campaignSummaryData,
    campaignSummaryError,
  } = useSelector((state) => state.campaignSummary);

  // Calculate the total sum of amounts
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (campaignSummaryData && campaignSummaryData.values.length > 0) {
      const totalSum = campaignSummaryData.values.reduce(
        (sum, obj) => sum + parseFloat(obj.amount.replace(/,/g, "")),
        0
      );
      const roundedSum = totalSum.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [campaignSummaryData]);

  // Generate colors for progress bars
  const [progressColors, setProgressColors] = useState([]);
  useEffect(() => {
    if (campaignSummaryData && campaignSummaryData.values.length > 0) {
      const colors = generateColors(campaignSummaryData.values.length);
      setProgressColors(colors);
    } else {
      setProgressColors([]);
    }
  }, [campaignSummaryData]);

  // custom styles
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#e1e1e9",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#b1b1b9",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
      boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
      "&:hover": {
        border: "1px solid #007C16",
        boxShadow: "0px 0px 1px #007C16",
      },
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#007C16"
        : isFocused
        ? "rgba(0, 124, 22, 0.2)"
        : base.backgroundColor,
      color: isSelected ? "white" : base.color,
      "&:active": {
        backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
      },
    }),
  };

  // dispatch function
  const dispatch = useDispatch();

  // navigate function
  const navigate = useNavigate();

  // active filter state
  const [activeFilter, setActiveFilter] = useState("channels");

  // popup states
  const [channelPopup, setChannelPopup] = useState(false);
  const [yearPopup, setYearPopup] = useState(false);
  const [monthPopup, setMonthPopup] = useState(false);
  const [dayPopup, setDayPopup] = useState(false);

  // popups change
  const handleChannelPopup = () => {
    setChannelPopup(!channelPopup);
    setYearPopup(false);
    setMonthPopup(false);
    setDayPopup(false);
  };

  const handleYearPopup = () => {
    setChannelPopup(false);
    setYearPopup(!yearPopup);
    setMonthPopup(false);
    setDayPopup(false);
  };

  const handleMonthPopup = () => {
    setChannelPopup(false);
    setYearPopup(false);
    setMonthPopup(!monthPopup);
    setDayPopup(false);
  };

  const handleDayPopup = () => {
    setChannelPopup(false);
    setYearPopup(false);
    setMonthPopup(false);
    setDayPopup(!dayPopup);
  };

  // redux nodes
  const { nodesData } = useSelector((state) => state.transactions);

  // items state
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  // populate checked items with nodesData.node_type values
  useEffect(() => {
    if (nodesData) {
      const values = nodesData.node_type.map((node) => node.value);
      setCheckedItems(values);
      setSelectedItems(values);
    }
  }, []);

  // handle checkboxes change
  const handleCheckboxChange = (item) => {
    if (checkedItems.includes(item)) {
      setCheckedItems(
        checkedItems.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setCheckedItems([...checkedItems, item]);
    }
  };

  // handle channels filter
  const handleChannelsFilter = () => {
    setActiveFilter("channels");
    setChannelPopup(false);
    setSelectedItems(checkedItems);
    const singleObj = {
      token,
      checkedItems,
    };
    dispatch(getFilteredCampaignSummary(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  // current date
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = currentDate.getFullYear();

  // campaignYear states
  const [campaignYearYear, setCampaignYearYear] = useState(
    currentYear.toString()
  );
  const [campainYearSelectedYear, setCampainYearSelectedYear] =
    useState(campaignYearYear);

  // year options array for campaignYear and campiagnMonth
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // handle campaignYear filter
  const handleCampaignYearFilter = () => {
    setActiveFilter("year");
    setYearPopup(false);
    setSelectedItems(checkedItems);
    setCampainYearSelectedYear(campaignYearYear);
    const singleObj = {
      token,
      checkedItems,
      year: campaignYearYear,
    };
    dispatch(getFilteredCampaignYearSummary(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  // campaignMonth month options array
  const campaignMonthMonthOptions = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];

  // get month label
  const getMonthLabel = (monthValue) => {
    const monthObj = campaignMonthMonthOptions.find(
      (option) => option.value === monthValue
    );
    return monthObj ? monthObj.label : "";
  };

  // campaign year month states
  const [campaignMonthYear, setCampaignMonthYear] = useState(
    currentYear.toString()
  );
  const [campaignMonthSelectedYear, setCampaignMonthSelectedYear] =
    useState(campaignMonthYear);
  const [campaignMonthMonth, setCampaignMonthMonth] = useState({
    value: currentMonth,
    label: getMonthLabel(currentMonth),
  });
  const [campaignMonthSelectedMonth, setCampaignMonthSelectedMonth] =
    useState(campaignMonthMonth);

  // handle month filter
  const handleMonthFilter = () => {
    setActiveFilter("month");
    setMonthPopup(false);
    setSelectedItems(checkedItems);
    setCampaignMonthSelectedYear(campaignMonthYear);
    setCampaignMonthSelectedMonth(campaignMonthMonth);
    const singleObj = {
      token,
      checkedItems,
      year: campaignMonthYear,
      month: campaignMonthMonth.value,
    };
    dispatch(getFilteredCampaignYearMonthSummary(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  // campaignDay summary states
  const [unformattedDate, setUnformattedDate] = useState(currentDate);
  const [campaignDayMonth, setCampaignDayMonth] = useState(null);
  const [campaignDayYear, setCampaignDayYear] = useState(null);
  const [campaignDayDay, setCampaignDayDay] = useState(null);

  // formatting date in yyyy-mm-dd
  useEffect(() => {
    const dateObj = new Date(unformattedDate);
    setCampaignDayYear(dateObj.getFullYear().toString());
    setCampaignDayMonth((dateObj.getMonth() + 1).toString().padStart(2, "0"));
    setCampaignDayDay(dateObj.getDate().toString().padStart(2, "0"));
  }, [unformattedDate]);

  // handle day filter
  const handleDayFilter = () => {
    setActiveFilter("day");
    setDayPopup(false);
    setSelectedItems(checkedItems);
    const singleObj = {
      token,
      checkedItems,
      year: campaignDayYear,
      month: campaignDayMonth,
      day: campaignDayDay,
    };
    dispatch(getFilteredCampaignYearMonthDaySummary(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };
  return (
    <>
      <div className="text-and-filter-div responsive">
        <span>
          Campaign Summary <Icon icon={minus} size={12} />{" "}
          {activeFilter === "channels" && <>[{selectedItems.join(", ")}]</>}
          {activeFilter === "year" && (
            <>
              [{selectedItems.join(", ")}] <Icon icon={minus} size={12} />{" "}
              {campainYearSelectedYear}
            </>
          )}
          {activeFilter === "month" && (
            <>
              [{selectedItems.join(", ")}] <Icon icon={minus} size={12} />{" "}
              {campaignMonthSelectedMonth.label} {campaignMonthSelectedYear}
            </>
          )}
          {activeFilter === "day" && (
            <>
              [{selectedItems.join(", ")}] <Icon icon={minus} size={12} />{" "}
              {campaignDayYear}-{campaignDayMonth}-{campaignDayDay}
            </>
          )}{" "}
          <Icon icon={minus} size={12} />{" "}
          <span style={{ color: "#007C16" }}>
            {campaignSummaryData && <>{campaignSummaryData.currencySymbol}</>}
            {sum}
          </span>
        </span>
        <div className="spans-container">
          <Tippy content={channelPopup ? "Cancel" : "Filter by Channels"}>
            <span
              style={{ cursor: "pointer" }}
              className={activeFilter === "channels" ? "channels" : ""}
              onClick={handleChannelPopup}
            >
              {channelPopup ? (
                <span>
                  <Icon icon={x} size={16} />
                </span>
              ) : (
                <span>Channels</span>
              )}
            </span>
          </Tippy>
          <Tippy content={yearPopup ? "Cancel" : "Filter by Year"}>
            <span
              style={{ cursor: "pointer" }}
              className={activeFilter === "year" ? "year" : ""}
              onClick={handleYearPopup}
            >
              {yearPopup ? (
                <span>
                  <Icon icon={x} size={16} />
                </span>
              ) : (
                <span>Year</span>
              )}
            </span>
          </Tippy>
          <Tippy content={monthPopup ? "Cancel" : "Filter by Month"}>
            <span
              style={{ cursor: "pointer" }}
              className={activeFilter === "month" ? "month" : ""}
              onClick={handleMonthPopup}
            >
              {monthPopup ? (
                <span>
                  <Icon icon={x} size={16} />
                </span>
              ) : (
                <span>Month</span>
              )}
            </span>
          </Tippy>
          <Tippy content={dayPopup ? "Cancel" : "Filter by Day"}>
            <span
              style={{ cursor: "pointer" }}
              className={activeFilter === "day" ? "day" : ""}
              onClick={handleDayPopup}
            >
              {dayPopup ? (
                <span>
                  <Icon icon={x} size={16} />
                </span>
              ) : (
                <span>Day</span>
              )}
            </span>
          </Tippy>
        </div>
        {/* channel popup */}
        <div
          className={
            channelPopup ? "popup responsive open" : "popup responsive"
          }
        >
          <h4>Filter by Channels</h4>
          <div className="checkboxes-div">
            {nodesData &&
              nodesData.node_type.map((item) => (
                <MDBCheckbox
                  key={item.value}
                  label={item.label}
                  checked={checkedItems.includes(item.value)}
                  onChange={() => handleCheckboxChange(item.value)}
                />
              ))}
          </div>
          <div className="popup-filter-btn-div">
            <button type="button" onClick={handleChannelsFilter}>
              Filter
            </button>
          </div>
        </div>

        {/* year popup */}
        <div
          className={yearPopup ? "popup responsive open" : "popup responsive"}
        >
          <h4>Filter by Year</h4>
          <div className="checkboxes-div">
            {nodesData &&
              nodesData.node_type.map((item) => (
                <MDBCheckbox
                  key={item.value}
                  label={item.label}
                  checked={checkedItems.includes(item.value)}
                  onChange={() => handleCheckboxChange(item.value)}
                />
              ))}
          </div>

          <div style={{ width: 100 + "%" }}>
            <label>Year</label>
            <Select
              value={yearOptions.find(
                (option) => option.value === campaignYearYear
              )}
              onChange={(option) => setCampaignYearYear(option.value)}
              options={yearOptions}
              styles={styles}
            />
          </div>

          <div className="popup-filter-btn-div">
            <button type="button" onClick={handleCampaignYearFilter}>
              Filter
            </button>
          </div>
        </div>

        {/* month popup */}
        <div
          className={monthPopup ? "popup responsive open" : "popup responsive"}
        >
          <h4>Filter by Month</h4>
          <div className="checkboxes-div">
            {nodesData &&
              nodesData.node_type.map((item) => (
                <MDBCheckbox
                  key={item.value}
                  label={item.label}
                  checked={checkedItems.includes(item.value)}
                  onChange={() => handleCheckboxChange(item.value)}
                />
              ))}
          </div>
          <div className="select-div">
            <div className="select-col">
              <label>Month</label>
              <Select
                value={campaignMonthMonthOptions.find(
                  (option) => option.value === campaignMonthMonth.value
                )}
                onChange={(option) => setCampaignMonthMonth(option)}
                options={campaignMonthMonthOptions}
                styles={styles}
              />
            </div>
            <div className="select-col">
              <label>Year</label>
              <Select
                value={yearOptions.find(
                  (option) => option.value === campaignMonthYear
                )}
                onChange={(option) => setCampaignMonthYear(option.value)}
                options={yearOptions}
                styles={styles}
              />
            </div>
          </div>
          <div className="popup-filter-btn-div">
            <button type="button" onClick={handleMonthFilter}>
              Filter
            </button>
          </div>
        </div>

        {/* day popup */}
        <div
          className={dayPopup ? "popup responsive open" : "popup responsive"}
        >
          <h4>Filter by Day</h4>
          <div className="checkboxes-div">
            {nodesData &&
              nodesData.node_type.map((item) => (
                <MDBCheckbox
                  key={item.value}
                  label={item.label}
                  checked={checkedItems.includes(item.value)}
                  onChange={() => handleCheckboxChange(item.value)}
                />
              ))}
          </div>
          <DatePicker
            value={unformattedDate}
            onChange={setUnformattedDate}
            clearIcon={false}
          />
          <div className="popup-filter-btn-div">
            <button type="button" onClick={handleDayFilter}>
              Filter
            </button>
          </div>
        </div>
      </div>
      <div
        className={`custom-table-container${
          campaignSummaryData && campaignSummaryData.values.length > 0
            ? ""
            : " flex"
        }`}
      >
        {filteredCampaignSummaryLoading ? (
          <MetroSpinner
            size={30}
            color="#007C16"
            loading={filteredCampaignSummaryLoading}
          />
        ) : (
          <>
            {campaignSummaryError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {campaignSummaryError}
              </div>
            ) : (
              <>
                {campaignSummaryData &&
                campaignSummaryData.values.length > 0 ? (
                  <MDBTable align="middle">
                    <MDBTableHead className="thead">
                      <tr>
                        <th scope="col">Campaign</th>
                        <th scope="col">Amount</th>
                        <th scope="col">No. of transactions</th>
                        <th scope="col" style={{ width: "25%" }}>
                          Progress
                        </th>
                        <th scope="col">Percentage</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {campaignSummaryData.values.map((data, index) => {
                        // Calculate the percentage for each object
                        const percentage = (
                          (parseFloat(data.amount.replace(/,/g, "")) / sum) *
                          100
                        ).toFixed(2);
                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={`${campaignSummaryData.themeHome}${data.icon}`}
                                  alt="Campaign Img"
                                  style={{ width: "45px", height: "45px" }}
                                  className="rounded-circle"
                                />
                                <div className="ms-3">
                                  <p className="fw-bold mb-1">
                                    {data.campaign}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p
                                className="fw-normal mb-1"
                                style={{ fontSize: "16px" }}
                              >
                                {campaignSummaryData.currencySymbol}
                                {data.amount}
                              </p>
                            </td>
                            <td>
                              <MDBBadge color="success" pill>
                                {data.numberOfTransaction}
                              </MDBBadge>
                            </td>
                            <td>
                              <div className="progress-trail">
                                <div
                                  className="table-progressbar"
                                  style={{
                                    width: `${percentage}%`,
                                    backgroundColor: progressColors[index],
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <MDBBtn
                                color="link"
                                rounded
                                size="sm"
                                style={{
                                  fontSize: "16px",
                                  whiteSpace: "nowrap",
                                  backgroundColor: progressColors[index],
                                  color: "#fff",
                                  width: "85px",
                                }}
                              >
                                {percentage} %
                              </MDBBtn>
                            </td>
                          </tr>
                        );
                      })}
                    </MDBTableBody>
                  </MDBTable>
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Data Found">
                      <img src={noDataFound} alt="No Data Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CampaignTable;
