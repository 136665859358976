import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../../config";

// get organization data of all 3 sections
export const getOrganizationData = createAsyncThunk(
  "organization/getOrganizationData",
  async (token) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/organization`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// update organization
export const updateOrganization = createAsyncThunk(
  "organization/updateOrganization",
  async (singleObj) => {
    try {
      const requestData = {
        // organization
        name: singleObj.name,
        phone: singleObj.phone,
        email: singleObj.email,
        website: singleObj.website,
        streetAddress: singleObj.streetAddress,
        unitNumber: singleObj.unitNumber,
        city: singleObj.city,
        country: singleObj.country,
        provinceState: singleObj.provinceState,
        postalZipCode: singleObj.postalZipCode,
        locationName: singleObj.locationName,
        receiptAlertEmail: singleObj.receiptAlertEmail,
        receiptFooter: singleObj.receiptFooter,
        footerNote: singleObj.footerNote,
        forceRegistration: singleObj.forceRegistration,
        // charity
        charityOrganization: singleObj.charityOrganization,
        charityStreetAddress: singleObj.charityStreetAddress,
        charityUnitNumber: singleObj.charityUnitNumber,
        charityCity: singleObj.charityCity,
        charityProvinceState: singleObj.charityProvinceState,
        charityPostalZipCode: singleObj.charityPostalZipCode,
        charityRegistrationNumber: singleObj.charityRegisterationNumber,
        taxReceiptFooter: singleObj.taxReceiptFooter,
        taxReceiptStart: singleObj.taxReceiptStartDate,
        // contact
        contactName: singleObj.contactName,
        contactEmail: singleObj.contactEmail,
        contactPhone: singleObj.contactPhone,
      };

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.put(
        `${hostName}api/admin/v1/organization/${singleObj.tagNumber}`,
        requestData,
        axiosConfig
      );
      const response = await request.data;

      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }

      return {
        data: null,
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
      };
    }
  }
);

// update signature
export const updateSignature = createAsyncThunk(
  "organization/updateSignature",
  async (singleObj) => {
    try {
      const requestedData = {
        signatureFile: singleObj.file,
      };
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/signature?_method=PUT`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
      };
    }
  }
);

const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    // organization data
    organizationLoading: false,
    organizationData: null,
    organizationError: null,
    // update organization
    updateOrganizationLoading: false,
    updateOrganizationData: null,
    // update signature
    updateSignatureLoading: false,
    updateSignatureData: null,
  },
  extraReducers: (builder) => {
    builder
      // get organization data
      .addCase(getOrganizationData.pending, (state) => {
        state.organizationLoading = true;
        state.organizationData = null;
        state.organizationError = null;
      })
      .addCase(getOrganizationData.fulfilled, (state, action) => {
        state.organizationLoading = false;
        state.organizationData = action.payload;
        state.organizationError = null;
      })
      .addCase(getOrganizationData.rejected, (state, action) => {
        state.organizationLoading = false;
        state.organizationData = null;
        state.organizationError = action.error.message;
      })
      // update organization
      .addCase(updateOrganization.pending, (state) => {
        state.updateOrganizationLoading = true;
        state.updateOrganizationData = null;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.updateOrganizationLoading = false;
        state.updateOrganizationData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.organizationData = data;
        }
      })
      // update signature
      .addCase(updateSignature.pending, (state) => {
        state.updateSignatureLoading = true;
        state.updateSignatureData = null;
      })
      .addCase(updateSignature.fulfilled, (state, action) => {
        state.updateSignatureLoading = false;
        state.updateSignatureData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.organizationData.signatureImage = data.signatureImage;
          state.organizationData.signatureImageType = data.signatureImageType;
          state.organizationData.signatureImageBytes = data.signatureImageBytes;
        }
      });
  },
});

export default organizationSlice.reducer;
