import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// multi select animated components
const animatedComponents = makeAnimated();

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const FilterMediaModal = ({
  setFilterMediaModal,
  setFilteredParams,
  entitySelected,
  entityOptions,
  handleSelectChange,
  name,
  setName,
  description,
  setDescription,
  url,
  setUrl,
  mediaType,
  setMediaType,
  fullScreen,
  muted,
  status,
  handleFullScreenChange,
  handleMutedChange,
  handleStatusChange,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
  pageSize,
  handlePerPage,
  setLoadings,
  handleResetFilter,
}) => {
  // close modal
  const handleCloseModal = () => {
    setFilterMediaModal(false);
  };

  // Check if 'name' or 'other options' is in the selected options
  const shouldRenderNameField = entitySelected.some(
    (option) => option.value === "name"
  );

  const shouldRenderDescriptionField = entitySelected.some(
    (option) => option.value === "description"
  );

  const shouldRenderUrlField = entitySelected.some(
    (option) => option.value === "url"
  );

  const shouldRenderMediaTypeField = entitySelected.some(
    (option) => option.value === "media_type"
  );

  const shouldRenderIsMutedSwitch = entitySelected.some(
    (option) => option.value === "is_muted"
  );

  const shouldRenderFullScreenSwitch = entitySelected.some(
    (option) => option.value === "fullscreen"
  );

  const shouldRenderStatusSwitch = entitySelected.some(
    (option) => option.value === "status"
  );

  // per page options array
  const perPageOptions = [
    {
      value: "02",
      label: "02",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "06",
      label: "06",
    },
    {
      value: "08",
      label: "08",
    },
    {
      value: "10",
      label: "10",
    },
  ];

  // form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Prepare the data to be sent as API parameters based on the selected options
    const apiData = {};

    // Add 'name' to the apiData if it's selected
    if (shouldRenderNameField) {
      apiData.name = name;
    }

    // Add 'description' to the apiData if it's selected
    if (shouldRenderDescriptionField) {
      apiData.description = description;
    }

    // Add 'url' to the apiData if it's selected
    if (shouldRenderUrlField) {
      apiData.url = url;
    }

    // Add 'media_type' to the apiData if it's selected
    if (shouldRenderMediaTypeField) {
      apiData.media_type = mediaType.value;
    }

    if (shouldRenderFullScreenSwitch) {
      apiData.fullscreen = fullScreen;
    }

    if (shouldRenderIsMutedSwitch) {
      apiData.is_muted = muted;
    }

    if (shouldRenderStatusSwitch) {
      apiData.status = status;
    }

    if (sortBy) {
      apiData.by = sortBy.value;
    }
    setLoadings(true);
    setFilterMediaModal(false);
    setFilteredParams({
      apiData,
      pageSize,
      order: orderBy.value,
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Filter Media</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <h6
              style={{
                borderBottom: 1 + "px solid #eaeaea",
                paddingBottom: 5 + "px",
              }}
            >
              Select Fields
            </h6>

            <Select
              isMulti
              components={animatedComponents}
              styles={styles}
              options={entityOptions}
              onChange={handleSelectChange}
              value={entitySelected}
            />
            <form
              style={{ marginTop: 10 + "px" }}
              className="update-media-form"
              onSubmit={handleFormSubmit}
            >
              {shouldRenderNameField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "name")
                        ?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelected.find((option) => option.value === "name")
                        ?.label
                    }
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              )}
              {shouldRenderDescriptionField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "description"
                      )?.label
                    }
                  </label>
                  <textarea
                    className="edit-media-inputs textarea"
                    placeholder={
                      entitySelected.find(
                        (option) => option.value === "description"
                      )?.label
                    }
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              )}
              {shouldRenderUrlField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "url")
                        ?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelected.find((option) => option.value === "url")
                        ?.label
                    }
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
              )}
              {shouldRenderMediaTypeField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "media_type"
                      )?.label
                    }
                  </label>
                  <Select
                    options={[
                      { value: "image", label: "Image" },
                      { value: "video", label: "Video" },
                    ]}
                    styles={styles}
                    value={mediaType}
                    onChange={(option) => setMediaType(option)}
                  />
                </div>
              )}
              {shouldRenderIsMutedSwitch && (
                <div className="input-margins-div">
                  <h6>
                    {
                      entitySelected.find(
                        (option) => option.value === "is_muted"
                      )?.label
                    }
                  </h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={muted === 1}
                      onChange={handleMutedChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              )}
              {shouldRenderStatusSwitch && (
                <div className="input-margins-div">
                  <h6>
                    {
                      entitySelected.find((option) => option.value === "status")
                        ?.label
                    }
                  </h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={status === 1}
                      onChange={handleStatusChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              )}
              {shouldRenderFullScreenSwitch && (
                <div className="input-margins-div">
                  <h6>
                    {
                      entitySelected.find(
                        (option) => option.value === "fullscreen"
                      )?.label
                    }
                  </h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={fullScreen === 1}
                      onChange={handleFullScreenChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              )}
              <div className="input-margins-div">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === pageSize
                  )}
                  onChange={(option) => handlePerPage(option.value)}
                  options={perPageOptions}
                  styles={styles}
                />
              </div>
              <div className="input-margins-div">
                <label>Sort by</label>
                <Select
                  value={sortBy}
                  onChange={(option) => setSortBy(option)}
                  options={entityOptions}
                  styles={styles}
                  isClearable
                />
              </div>
              <div className="input-margins-div">
                <label>Order by</label>
                <Select
                  value={orderBy}
                  onChange={(option) => setOrderBy(option)}
                  options={[
                    { value: "asc", label: "Ascending" },
                    { value: "desc", label: "Descending" },
                  ]}
                  styles={styles}
                />
              </div>
              <div className="reset-and-filter-div">
                <button
                  type="button"
                  className="reset-fields-btn"
                  onClick={handleResetFilter}
                >
                  RESET
                </button>
                <button type="submit" className="filter-records-btn">
                  FILTER
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterMediaModal;
