import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    fundraisingDropdown: false,
    fundraisingDropdownHeight: "0px",
    manageDropdown: false,
    manageDropdownHeight: "0px",
    integrationHubDropdown: false,
    integrationHubDropdownHeight: "0px",
  },
  reducers: {
    setFundraisingDropdown: (state, action) => {
      return {
        ...state,
        fundraisingDropdown: action.payload,
      };
    },
    setManageDropdown: (state, action) => {
      return {
        ...state,
        manageDropdown: action.payload,
      };
    },
    setIntegrationHubDropdown: (state, action) => {
      return {
        ...state,
        integrationHubDropdown: action.payload,
      };
    },
    setFundraisingDropdownHeight: (state, action) => {
      return {
        ...state,
        fundraisingDropdownHeight: action.payload,
      };
    },
    setManageDropdownHeight: (state, action) => {
      return {
        ...state,
        manageDropdownHeight: action.payload,
      };
    },
    setIntegrationHubDropdownHeight: (state, action) => {
      return {
        ...state,
        integrationHubDropdownHeight: action.payload,
      };
    },
    resetStates: () => {
      return {
        fundraisingDropdown: false,
        fundraisingDropdownHeight: "0px",
        manageDropdown: false,
        manageDropdownHeight: "0px",
        integrationHubDropdown: false,
        integrationHubDropdownHeight: "0px",
      };
    },
    resetFundraisingStates: (state) => {
      return {
        ...state,
        fundraisingDropdown: false,
        fundraisingDropdownHeight: "0px",
      };
    },
    resetManageStates: (state) => {
      return {
        ...state,
        manageDropdown: false,
        manageDropdownHeight: "0px",
      };
    },
    resetIntegrationHubStates: (state) => {
      return {
        ...state,
        integrationHubDropdown: false,
        integrationHubDropdownHeight: "0px",
      };
    },
  },
});

export default sidebarSlice.reducer;
export const {
  setFundraisingDropdown,
  setManageDropdown,
  setIntegrationHubDropdown,
  setFundraisingDropdownHeight,
  setManageDropdownHeight,
  setIntegrationHubDropdownHeight,
  resetStates,
  resetFundraisingStates,
  resetManageStates,
  resetIntegrationHubStates,
} = sidebarSlice.actions;
