import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  filterDataRecords,
  getImports,
} from "../../Store/Slices/Sidebar/Data/DataSlice";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { users } from "react-icons-kit/feather/users";
import { creditCard } from "react-icons-kit/feather/creditCard";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const ImportsCards = ({
  token,
  currentPage,
  totalPages,
  perPage,
  setCurrentPage,
  setTotalPages,
  setPerPage,
  setPageSize,
  handleImportDetailsPopup,
  handleDeleteImportPopup,
  filteredParams,
  setLoadings,
}) => {
  const { importsData, importsError } = useSelector(
    (state) => state.dataManagement
  );

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "50",
      label: "50",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterDataRecords({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getImports({
            token,
            page: 1,
            pageSize: value,
            order: "asc",
            by: "type",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterDataRecords({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getImports({
            token,
            page: value,
            pageSize: perPage,
            order: "asc",
            by: "type",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  return (
    <>
      {importsError ? (
        <div className="error-msg" style={{ width: "fit-content" }}>
          {importsError}
        </div>
      ) : (
        <>
          {importsData && importsData.data.length > 0 ? (
            <>
              {importsData.data.map((data) => {
                let borderTopColor = "";

                if (data.status === "Deleted") {
                  borderTopColor = "grey";
                } else if (data.failedErrors > 0) {
                  borderTopColor = "#dc3545";
                } else if (data.validationErrors > 0) {
                  borderTopColor = "#f2b92c";
                } else if (data.validationErrors === 0) {
                  borderTopColor = "#007c16";
                }
                return (
                  <div
                    key={data.tagNumber}
                    className="media-box"
                    style={{
                      borderTop: `3px solid ${borderTopColor}`,
                      background: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImportDetailsPopup(data);
                    }}
                  >
                    <div className="recurring-row">
                      <div className="recurring-leftside">
                        <>
                          {data.type === "Donors" ? (
                            <Icon
                              icon={users}
                              size={80}
                              style={{ color: "#007c16" }}
                            />
                          ) : (
                            <Icon
                              icon={creditCard}
                              size={80}
                              style={{ color: "#007c16" }}
                            />
                          )}
                        </>
                        <div className="recurring-campaign-details">
                          <h1
                            style={{
                              color: "#6a6a6a",
                              fontWeight: 600,
                              marginBottom: 5 + "px",
                            }}
                          >
                            {data.type}
                          </h1>
                          <h5>{data.clientFile}</h5>
                          <h5 style={{ color: "#007c16" }}>{data.userEmail}</h5>
                        </div>
                      </div>
                      <div className="recurring-rightside">
                        <h1
                          style={{
                            color: "#6a6a6a",
                            fontWeight: 600,
                          }}
                        >
                          {data.tagNumber}
                        </h1>
                      </div>
                    </div>
                    <div className="media-table-container">
                      <MDBTable className="recurring-table">
                        <MDBTableHead className="media-thead">
                          <tr>
                            <th scope="col">Status</th>
                            <th scope="col">Submit Date</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody className="media-body">
                          <tr>
                            <td>
                              <p className="text-muted mb-0">{data.status}</p>
                            </td>
                            <td>
                              <p className="text-muted mb-0">
                                {data.submitDateTime ? data.submitDateTime : ""}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0">
                                {data.startDateTime ? data.startDateTime : ""}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0">
                                {data.endDateTime ? data.endDateTime : ""}
                              </p>
                            </td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                    <div className="media-table-container">
                      <MDBTable className="recurring-table">
                        <MDBTableHead className="media-thead">
                          <tr>
                            <th scope="col">Affected Rows</th>
                            <th scope="col">Validation Rows</th>
                            <th scope="col">Validation Errors</th>
                            <th scope="col">Failed Rows</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody className="media-body">
                          <tr>
                            <td>
                              <p className="text-muted mb-0">
                                {data.affectedRows}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0">
                                {data.validationRows}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0">
                                {data.validationErrors}
                              </p>
                            </td>

                            <td>
                              <p className="text-muted mb-0">
                                {data.failedErrors}
                              </p>
                            </td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                    <div className="edit-and-delete-media-div">
                      <button
                        className="delete-btn"
                        style={{
                          width: "fit-content",
                          pointerEvents:
                            data.status === "Deleted" ? "none" : "auto",
                          opacity: data.status === "Deleted" ? 0.5 : 1,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteImportPopup(data);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })}
              <div className="per-page-and-pagination">
                <div className="per-page">
                  <label>Records per page</label>
                  <Select
                    value={perPageOptions.find(
                      (option) => option.value === String(perPage)
                    )}
                    onChange={(option) => handlePerPage(option.value)}
                    options={perPageOptions}
                    styles={styles}
                    menuPlacement="top"
                  />
                </div>
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Data Found">
                <img src={noDataFound} alt="No Data Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ImportsCards;
