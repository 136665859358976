/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/Users.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getCrmUsers } from "../../Store/Slices/Sidebar/Users/CrmUsersSlice";
import { MetroSpinner } from "react-spinners-kit";
import { Toaster } from "react-hot-toast";
import CrmUsers from "../Users/CrmUsers";
import UpdateUserModal from "../Users/UpdateUserModal";
import DeleteUserModal from "../Users/DeleteUserModal";
import AddUserModal from "../Users/AddUserModal";

const UsersComponent = ({ token }) => {
  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get users
  useEffect(() => {
    dispatch(getCrmUsers({ token }))
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // redux loading states
  const { usersLoading } = useSelector((state) => state.crmUsers);

  // update user modal
  const [updateUserModal, setUpdateUserModal] = useState(false);

  // user to be updated
  const [updateUserObj, setUpdateUserObj] = useState(null);

  // handle update modal
  const handleUpdateModal = (obj) => {
    setUpdateUserModal(true);
    setUpdateUserObj(obj);
  };

  // delete user modal
  const [deleteUserModal, setDeleteUserModal] = useState(false);

  // user to be deleted
  const [deleteUserObj, setDeleteUserObj] = useState(null);

  // handle delete modal
  const handleDeleteModal = (obj) => {
    setDeleteUserModal(true);
    setDeleteUserObj(obj);
  };

  // add user modal
  const [addUserModal, setAddUserModal] = useState(false);

  return (
    <>
      <div className="middle-area">
        <div
          className={`middle-content flex-start${
            usersLoading ? " height" : ""
          }`}
        >
          {usersLoading ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={usersLoading} />
            </div>
          ) : (
            <>
              <Toaster />
              <div className="media-heading-button-container">
                <h6>Users</h6>
                <div className="add-and-filter-btns-div">
                  <div className="action-btns-div">
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setAddUserModal(true)}
                    >
                      Add User
                    </button>
                  </div>
                </div>
              </div>
              <div className="media-row">
                <CrmUsers
                  handleUpdateModal={handleUpdateModal}
                  handleDeleteModal={handleDeleteModal}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {updateUserModal && (
        <UpdateUserModal
          token={token}
          setUpdateUserModal={setUpdateUserModal}
          updateUserObj={updateUserObj}
        />
      )}
      {deleteUserModal && (
        <DeleteUserModal
          token={token}
          setDeleteUserModal={setDeleteUserModal}
          deleteUserObj={deleteUserObj}
        />
      )}
      {addUserModal && (
        <AddUserModal token={token} setAddUserModal={setAddUserModal} />
      )}
    </>
  );
};

export default UsersComponent;
