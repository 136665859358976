import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredDonationsPyramid } from "../../Store/Slices/Sidebar/Analytics/DonationsPyramidSlice";
import { MetroSpinner } from "react-spinners-kit";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import noDataFound from "../../Images/noDataFound.png";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root:hover fieldset {
      border-color: #007c16;
    }
  }
`;

// generate colors
function generateColors(length) {
  const colors = [];

  // Define the colors in the gradient
  const gradientColors = [
    "#34495e",
    "#9b59b6",
    "#3498db",
    "#62cb31",
    "#ffb606",
    "#e67e22",
    "#e74c3c",
    "#c0392b",
    "#175935",
    "#1f9d61",
    "#cc2473",
    "#d828c9",
    "#453095",
    "#9100ff",
    "#884a39",
    "#c38154",
  ];

  for (let i = 0; i < length; i++) {
    const index = i % gradientColors.length;
    colors.push(gradientColors[index]);
  }

  return colors;
}

// Register the plugin to all charts:
ChartJS.register(ChartDataLabels);

const DonationsPyramid = ({ token }) => {
  // redux state
  const {
    filteredDonationsPyramidLoading,
    donationsPyramidData,
    donationsPyramidError,
  } = useSelector((state) => state.donationsPyramid);

  // calculate total amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (donationsPyramidData && donationsPyramidData.values.length > 0) {
      const calculatedAmounts = donationsPyramidData.values.map((item) => {
        const { amount } = item;
        return amount;
      });

      const totalAmount = calculatedAmounts.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const roundedSum = totalAmount.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [donationsPyramidData]);

  // calculate total transactions
  const [transactions, setTransactions] = useState("0.00");
  useEffect(() => {
    if (donationsPyramidData && donationsPyramidData.values.length > 0) {
      const calculatedTransactions = donationsPyramidData.values.map((item) => {
        const { numberOfTransaction } = item;
        return numberOfTransaction;
      });

      const totalTransactions = calculatedTransactions.reduce(
        (acc, curr) => acc + curr,
        0
      );
      setTransactions(totalTransactions);
    } else {
      setTransactions("0.00");
    }
  }, [donationsPyramidData]);

  // generate chart data
  const generateChartData = (data) => {
    // Extracting values from the data
    const titles = data.map((item) => item.title).reverse();
    const transactionPercentages = data
      .map((item) => item.transactionPercentage)
      .reverse();

    // Chart data
    const chartData = {
      labels: titles,
      datasets: [
        {
          label: "Transactions",
          data: transactionPercentages,
          backgroundColor: generateColors(data.length),
          barThickness: "flex",
          barPercentage: 1,
          categoryPercentage: 0.95,
        },
      ],
    };

    return chartData;
  };

  // bars colors
  const [barsColors, setBarsColors] = useState([]);
  useEffect(() => {
    if (donationsPyramidData && donationsPyramidData.values.length > 0) {
      const colors = generateColors(donationsPyramidData.values.length);
      setBarsColors(colors);
    } else {
      setBarsColors([]);
    }
  }, [donationsPyramidData]);

  // popup
  const [popup, setPopup] = useState(false);

  // year state
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [selectedYear, setSelectedYear] = useState(year);

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // min mid and max values
  const [field1Value, setField1Value] = useState(20);
  const [field2Value, setField2Value] = useState(120);
  const [field3Value, setField3Value] = useState(200);
  const [combinedState, setCombinedState] = useState("");

  // validate and combine values in string formate
  useEffect(() => {
    const validateAndCombineValues = () => {
      const validatedField1Value = Math.max(field1Value, 1);
      const validatedField2Value = Math.max(field2Value, 1);
      const validatedField3Value = Math.max(field3Value, 1);
      // Check if numberInput is NaN, and if it is, set it to 1
      let filteredNumber1 = isNaN(validatedField1Value) ? 1 : validatedField1Value;

      // Check if numberInput is NaN, and if it is, set it to 1
      let filteredNumber2 = isNaN(validatedField2Value) ? 1 : validatedField2Value;

      // Check if numberInput is NaN, and if it is, set it to 1
      let filteredNumber3 = isNaN(validatedField3Value) ? 1 : validatedField3Value;

      const combinedValues = `${filteredNumber1},${filteredNumber2},${filteredNumber3}`;
      setCombinedState(combinedValues);
    };

    validateAndCombineValues();
  }, [field1Value, field2Value, field3Value]);

  // handle range fields change
  const handleField1Change = (event) => {
    event.preventDefault();
    let newValue = parseInt(event.target.value, 10);
    if (newValue < 1) {
      newValue = 1;
    }
    setField1Value(newValue);
  };

  const handleField2Change = (event) => {
    event.preventDefault();
    let newValue = parseInt(event.target.value, 10);
    if (newValue < 1) {
      newValue = 1;
    }
    setField2Value(newValue);
  };

  const handleField3Change = (event) => {
    event.preventDefault();
    let newValue = parseInt(event.target.value, 10);
    if (newValue < 1) {
      newValue = 1;
    }
    setField3Value(newValue);
  };

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle filter
  const handleYearAndValuesFilter = () => {
    setPopup(false);
    setSelectedYear(year);
    const singleObj = {
      token,
      values: combinedState,
      year,
    };
    dispatch(getFilteredDonationsPyramid(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  // custom styles
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#e1e1e9",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#b1b1b9",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
      boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
      "&:hover": {
        border: "1px solid #007C16",
        boxShadow: "0px 0px 1px #007C16",
      },
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#007C16"
        : isFocused
        ? "rgba(0, 124, 22, 0.2)"
        : base.backgroundColor,
      color: isSelected ? "white" : base.color,
    }),
  };

  return (
    <>
      <div className="text-and-filter-div">
        <span style={{ color: "#007C16" }}>{selectedYear}</span>

        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Year and Values</h4>
          <div style={{ width: 100 + "%" }}>
            <label>Year</label>
            <Select
              value={yearOptions.find((option) => option.value === year)}
              onChange={(option) => setYear(option.value)}
              options={yearOptions}
              styles={styles}
            />
          </div>
          <div className="textbox-values-div" style={{ width: 250 + "px" }}>
            <label>Range</label>
            <div className="textfields-div">
              <CustomTextField
                type="number"
                variant="outlined"
                fullWidth
                autoComplete="off"
                min={1}
                size="small"
                className="custom-field"
                value={field1Value}
                onChange={handleField1Change}
              />

              <CustomTextField
                type="number"
                variant="outlined"
                fullWidth
                autoComplete="off"
                min={1}
                size="small"
                className="custom-field"
                value={field2Value}
                onChange={handleField2Change}
              />

              <CustomTextField
                type="number"
                variant="outlined"
                fullWidth
                autoComplete="off"
                min={1}
                size="small"
                className="custom-field"
                value={field3Value}
                onChange={handleField3Change}
              />
            </div>
          </div>
          <div className="popup-filter-btn-div">
            <button type="button" onClick={handleYearAndValuesFilter}>
              Filter
            </button>
          </div>
        </div>
      </div>
      <div className="pyramid-graph-container">
        {filteredDonationsPyramidLoading ? (
          <MetroSpinner
            size={30}
            color="#007C16"
            loading={filteredDonationsPyramidLoading}
          />
        ) : (
          <>
            {donationsPyramidError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {donationsPyramidError}
              </div>
            ) : (
              <>
                {donationsPyramidData &&
                donationsPyramidData.values.length > 0 ? (
                  <>
                    <div className="pyramid-container">
                      <Bar
                        data={generateChartData(donationsPyramidData.values)}
                        options={{
                          indexAxis: "y",
                          scales: {
                            x: {
                              stacked: true,
                              beginAtZero: true,
                              max: 100,
                            },
                            y: {
                              beginAtZero: true,
                              stacked: true,
                            },
                          },
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              display: false,
                            },
                            tooltip: {
                              backgroundColor: "#fff",
                              titleColor: "#000",
                              bodyColor: "#000",
                              callbacks: {
                                label: (context) => {
                                  return context.formattedValue;
                                },
                              },
                            },
                            datalabels: {
                              display: false,
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="graph-info">
                      <MDBTable align="middle">
                        <MDBTableHead className="thead">
                          <tr>
                            <th scope="col">Range</th>
                            <th scope="col">Transactions</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Transactions %</th>
                            <th scope="col">Amount %</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {[...donationsPyramidData.values]
                            .reverse()
                            .map((data, index) => (
                              <tr key={index}>
                                <td>
                                  <p className="fw-bold mb-1">{data.title}</p>
                                </td>
                                <td>
                                  <p className="fw-bold mb-1">
                                    {data.numberOfTransaction}
                                  </p>
                                </td>
                                <td>
                                  <p className="fw-bold mb-1">
                                    {donationsPyramidData.currencySymbol}
                                    {data.amount}
                                  </p>
                                </td>
                                <td>
                                  <div
                                    className="custom-badge"
                                    style={{
                                      backgroundColor: barsColors[index],
                                    }}
                                  >
                                    {data.transactionPercentage}%
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="custom-badge"
                                    style={{
                                      backgroundColor: barsColors[index],
                                    }}
                                  >
                                    {data.amountPercentage}%
                                  </div>
                                </td>
                              </tr>
                            ))}
                          <tr style={{ borderTop: `1px solid #eaeaea` }}></tr>
                          <tr>
                            <td>
                              <p
                                className="fw-bold mb-1"
                                style={{ color: "#007C16" }}
                              >
                                Total
                              </p>
                            </td>
                            <td>
                              <p className="fw-bold mb-1">{transactions}</p>
                            </td>
                            <td>
                              <p className="fw-bold mb-1">
                                {donationsPyramidData.currencySymbol}
                                {sum}
                              </p>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                  </>
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Data Found">
                      <img src={noDataFound} alt="No Data Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DonationsPyramid;
