import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getYearOverYears = createAsyncThunk(
  "yearOverYears/getYearOverYears",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/yearOverYears?groupData=false&numberOfYears=${singleObj.numberOfYears}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredYearOverYears = createAsyncThunk(
  "filteredYearOverYears/getFilteredYearOverYears",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/yearOverYears?groupData=false&numberOfYears=${singleObj.numberOfYears}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const yearOverYearsSlice = createSlice({
  name: "yearOverYears",
  initialState: {
    yearOverYearsLoading: false,
    yearOverYearsData: null,
    yearOverYearsError: null,
    filteredYearOverYearsLoading: false,
  },
  extraReducers: (builder) => {
    builder
      // first render
      .addCase(getYearOverYears.pending, (state) => {
        state.yearOverYearsLoading = true;
        state.yearOverYearsData = null;
        state.yearOverYearsError = null;
      })
      .addCase(getYearOverYears.fulfilled, (state, action) => {
        state.yearOverYearsLoading = false;
        state.yearOverYearsData = action.payload;
        state.yearOverYearsError = null;
      })
      .addCase(getYearOverYears.rejected, (state, action) => {
        state.yearOverYearsLoading = false;
        state.yearOverYearsData = null;
        state.yearOverYearsError = action.error.message;
      })
      // filter render
      .addCase(getFilteredYearOverYears.pending, (state) => {
        state.filteredYearOverYearsLoading = true;
        state.yearOverYearsData = null;
        state.yearOverYearsError = null;
      })
      .addCase(getFilteredYearOverYears.fulfilled, (state, action) => {
        state.filteredYearOverYearsLoading = false;
        state.yearOverYearsData = action.payload;
        state.yearOverYearsError = null;
      })
      .addCase(getFilteredYearOverYears.rejected, (state, action) => {
        state.filteredYearOverYearsLoading = false;
        state.yearOverYearsData = null;
        state.yearOverYearsError = action.error.message;
      });
  },
});

export default yearOverYearsSlice.reducer;
