import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../../config";

// get unread notifications (number)
export const getUnreadNotifications = createAsyncThunk(
  "notifications/getUnreadNotifications",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/notifications/numberOfUnread`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// get all notifications data
export const getAllNotifications = createAsyncThunk(
  "notifications/getAllNotifications",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/notifications?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter notifications
export const filterNotifications = createAsyncThunk(
  "notifications/filterNotifications",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "type",
      "transaction_type",
      "status",
      "state",
      "invoice",
      "local_create_datetime",
      "last_modified_datetime",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/notifications?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// mark as read
export const markAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (obj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${obj.token}`,
      },
    };
    try {
      const request = await axios.put(
        `${hostName}api/admin/v1/notifications/markAsRead`,
        { tagNumber: obj.tagNumber },
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        tagNumber: obj.tagNumber,
        error: null,
      };
    } catch (err) {
      return {
        data: null,
        tagNumber: obj.tagNumber,
        error: err.message,
      };
    }
  }
);

// update notification
export const updateNotification = createAsyncThunk(
  "notification/updateNotification",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        tagNumber: singleObj.tagNumber,
        state: singleObj.state,
      };
      if (singleObj.notes) {
        requestedData.notes = singleObj.notes;
      }
      const request = await axios.put(
        `${hostName}api/admin/v1/notifications`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// get configuration data
export const getConfigurationData = createAsyncThunk(
  "notifications/getConfigurationData",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/organizationNotification`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data };
  }
);

// update configuration
export const updateConfiguration = createAsyncThunk(
  "notification/updateConfiguration",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        status: singleObj.status,
        emails: singleObj.emails,
      };

      const request = await axios.put(
        `${hostName}api/admin/v1/organizationNotification/${singleObj.tagNumber}`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    // get unread notifications (number)
    unreadNotificationsLoading: false,
    totalUnreadNotifications: null,
    unreadNotificationsError: null,
    // get all notifications data
    notificationsLoading: false,
    notificationsData: null,
    notificationsError: null,
    // mark as read
    markAsReadLoading: false,
    markAsReadData: null,
    // update notification
    updateNotificationLoading: false,
    updateNotificationData: null,
    // get configuration data
    configurationLoading: false,
    configurationData: null,
    configurationError: null,
    // update configuration
    updateConfigurationLoading: false,
    updateConfigurationData: null,
  },
  extraReducers: (builder) => {
    builder
      // get unread notifications
      .addCase(getUnreadNotifications.pending, (state) => {
        state.unreadNotificationsLoading = true;
        state.totalUnreadNotifications = null;
        state.unreadNotificationsError = null;
      })
      .addCase(getUnreadNotifications.fulfilled, (state, action) => {
        state.unreadNotificationsLoading = false;
        state.totalUnreadNotifications = action.payload;
        state.unreadNotificationsError = null;
      })
      .addCase(getUnreadNotifications.rejected, (state, action) => {
        state.unreadNotificationsLoading = false;
        state.totalUnreadNotifications = null;
        state.unreadNotificationsError = action.error.message;
      })
      // get all notifications data
      .addCase(getAllNotifications.pending, (state) => {
        state.notificationsLoading = true;
        state.notificationsData = null;
        state.notificationsError = null;
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.notificationsLoading = false;
        state.notificationsData = action.payload;
        state.notificationsError = null;
      })
      .addCase(getAllNotifications.rejected, (state, action) => {
        state.notificationsLoading = false;
        state.notificationsData = null;
        state.notificationsError = action.error.message;
      })
      // filter notifications
      .addCase(filterNotifications.pending, (state) => {
        state.notificationsLoading = true;
        state.notificationsData = null;
        state.notificationsError = null;
      })
      .addCase(filterNotifications.fulfilled, (state, action) => {
        state.notificationsLoading = false;
        state.notificationsData = action.payload;
        state.notificationsError = null;
      })
      .addCase(filterNotifications.rejected, (state, action) => {
        state.notificationsLoading = false;
        state.notificationsData = null;
        state.notificationsError = action.error.message;
      })
      // mark as read
      .addCase(markAsRead.pending, (state) => {
        state.markAsReadLoading = true;
        state.markAsReadData = null;
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        state.markAsReadLoading = false;
        state.markAsReadData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const notificationIndex = state.notificationsData.data.findIndex(
            (notification) => notification.tagNumber === tagNumber
          );

          if (notificationIndex !== -1) {
            state.notificationsData.data[notificationIndex] = data;
          }
        }
      })
      // update notification
      .addCase(updateNotification.pending, (state) => {
        state.updateNotificationLoading = true;
        state.updateNotificationData = null;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.updateNotificationLoading = false;
        state.updateNotificationData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const notificationIndex = state.notificationsData.data.findIndex(
            (notification) => notification.tagNumber === tagNumber
          );

          if (notificationIndex !== -1) {
            state.notificationsData.data[notificationIndex] = data;
          }
        }
      })
      // get configuration data
      .addCase(getConfigurationData.pending, (state) => {
        state.configurationLoading = true;
        state.configurationData = null;
        state.configurationError = null;
      })
      .addCase(getConfigurationData.fulfilled, (state, action) => {
        state.configurationLoading = false;
        state.configurationData = action.payload;
        state.configurationError = null;
      })
      .addCase(getConfigurationData.rejected, (state, action) => {
        state.configurationLoading = false;
        state.configurationData = null;
        state.configurationError = action.error.message;
      })
      // update configuration
      .addCase(updateConfiguration.pending, (state) => {
        state.updateConfigurationLoading = true;
        state.updateConfigurationData = null;
      })
      .addCase(updateConfiguration.fulfilled, (state, action) => {
        state.updateConfigurationLoading = false;
        state.updateConfigurationData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const configurationIndex = state.configurationData.data.findIndex(
            (configuration) => configuration.tagNumber === tagNumber
          );

          if (configurationIndex !== -1) {
            state.configurationData.data[configurationIndex] = data;
          }
        }
      });
  },
});

export default notificationsSlice.reducer;
