import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../config";

export const handleResetPassword = createAsyncThunk(
  "resetPassword/handleResetPassword",
  async (resetObj) => {
    const request = await axios.put(
      `${hostName}api/admin/v1/auth/resetPassword`,
      resetObj
    );
    const result = await request.data.message;
    localStorage.removeItem("userEmailForOtp");
    return result;
  }
);

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    loading: false,
    successMsg: null,
    error: null,
  },
  reducers: {
    resetResetPasswordStates: () => {
      return {
        loading: false,
        successMsg: null,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleResetPassword.pending, (state) => {
        state.loading = true;
        state.successMsg = null;
        state.error = null;
      })
      .addCase(handleResetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.successMsg = action.payload;
        state.error = null;
      })
      .addCase(handleResetPassword.rejected, (state, action) => {
        state.loading = false;
        state.successMsg = null;
        if (action.error.message === "Request failed with status code 400") {
          state.error = "Invalid reset code";
        } else if (
          action.error.message === "Request failed with status code 422"
        ) {
          state.error = "The email field is required";
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export default resetPasswordSlice.reducer;
export const { resetResetPasswordStates } = resetPasswordSlice.actions;
