import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const AddressDrawer = ({ setAddressDrawer, addressDonorObj }) => {
  // close modal
  const handleCloseModal = () => {
    setAddressDrawer(false);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              {addressDonorObj.firstName} {addressDonorObj.lastName} -{" "}
              {addressDonorObj.tagNumber}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="transaction-details">
              <div className="content">
                {/* business name */}
                {addressDonorObj.businessName && (
                  <div className="detail">
                    <h6 className="key">Business Name</h6>
                    <h6>{addressDonorObj.businessName}</h6>
                  </div>
                )}
                {/* street address */}
                <div className="detail">
                  <h6 className="key">Street Address</h6>
                  <h6>{addressDonorObj.streetAddress}</h6>
                </div>

                {/* unit number */}
                {addressDonorObj.unitNumber && (
                  <div className="detail">
                    <h6 className="key">Unit Number</h6>
                    <h6>{addressDonorObj.unitNumber}</h6>
                  </div>
                )}

                {/* city */}
                <div className="detail">
                  <h6 className="key">City</h6>
                  <h6>{addressDonorObj.city}</h6>
                </div>

                {/* provinceState */}
                <div className="detail">
                  <h6 className="key">Province / State</h6>
                  <h6>{addressDonorObj.provinceState}</h6>
                </div>

                {/* country */}
                <div className="detail">
                  <h6 className="key">Country</h6>
                  <h6>{addressDonorObj.country}</h6>
                </div>
              </div>
            </div>
            <div
              className="reset-and-filter-div"
              style={{ padding: 10 + "px" }}
            >
              <button
                type="button"
                className="reset-fields-btn"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddressDrawer;
