/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { alertCircle } from "react-icons-kit/feather/alertCircle";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getImportDetails } from "../../Store/Slices/Sidebar/Data/DataSlice";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const ImportDetailsPopup = ({
  token,
  setImportDetailsPopup,
  importDetailsObj,
}) => {
  // redux state
  const { importDetailsLoading, importDetailsData, importDetailsError } =
    useSelector((state) => state.dataManagement);

  // close modal
  const handleCloseModal = () => {
    if (!importDetailsLoading) {
      setImportDetailsPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // call api
  useEffect(() => {
    dispatch(
      getImportDetails({
        token,
        tagNumber: importDetailsObj.tagNumber,
      })
    )
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media extra-width"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Import Details</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div
            className="update-widgets-modal-body transactions-popup"
            style={{ padding: 5 + "px" }}
          >
            {importDetailsLoading ? (
              <div
                style={{
                  padding: 50 + "px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MetroSpinner
                  size={30}
                  color="#007C16"
                  loading={importDetailsLoading}
                />
              </div>
            ) : (
              <>
                {importDetailsError ? (
                  <div
                    style={{
                      padding: 50 + "px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="error-msg">{importDetailsError}</div>
                  </div>
                ) : (
                  <>
                    {importDetailsData && importDetailsData.length > 0 ? (
                      <div className="transactions-table">
                        <MDBTable align="middle">
                          <MDBTableHead className="thead">
                            <tr>
                              <th scope="col" style={{ textAlign: "right" }}>
                                Row
                              </th>
                              <th scope="col">Field Name</th>
                              <th scope="col">Message</th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Type
                              </th>
                            </tr>
                          </MDBTableHead>
                          <MDBTableBody>
                            {importDetailsData &&
                              importDetailsData.map((data, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#fff" : "#fafafa",
                                  }}
                                  key={data.tagNumber}
                                >
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {data.rowNumber}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {data.fieldName}
                                    </p>
                                  </td>

                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {data.message}
                                    </p>
                                  </td>

                                  <td className="icons-td">
                                    <div className="actions-icon-container">
                                      <Tippy
                                        content={data.type}
                                        zIndex={1000001}
                                      >
                                        <div
                                          style={{
                                            color:
                                              data.type === "Validation Errors"
                                                ? "#f2b92c"
                                                : "#dc3545",
                                            textAlign: "center",
                                            width: 100 + "%",
                                          }}
                                        >
                                          <Icon icon={alertCircle} size={20} />
                                        </div>
                                      </Tippy>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </MDBTableBody>
                        </MDBTable>
                      </div>
                    ) : (
                      <div
                        className="no-data-found-div"
                        style={{ padding: 50 + "px" }}
                      >
                        <Tippy content="No Data Found" zIndex={100005}>
                          <img src={noDataFound} alt="No Data Found" />
                        </Tippy>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            <div className="submit-and-cancel-div" style={{ borderTop: 0 }}>
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ImportDetailsPopup;
