import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getTopDonor = createAsyncThunk(
  "topDonor/getTopDonor",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/topDonorYearMonthSummary?n=${singleObj.n}&year=${singleObj.year}&month=${singleObj.month}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// firlter render
export const getFilteredTopDonor = createAsyncThunk(
  "filteredTopDonor/getFilteredTopDonor",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/topDonorYearMonthSummary?n=${singleObj.n}&year=${singleObj.year}&month=${singleObj.month}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const topDonorSlice = createSlice({
  name: "topDonor",
  initialState: {
    topDonorLoading: false,
    topDonorData: null,
    topDonorError: null,
    filteredTopDonorLoading: false,
  },
  extraReducers: (builder) => {
    builder
    // first render
      .addCase(getTopDonor.pending, (state) => {
        state.topDonorLoading = true;
        state.topDonorData = null;
        state.topDonorError = null;
      })
      .addCase(getTopDonor.fulfilled, (state, action) => {
        state.topDonorLoading = false;
        state.topDonorData = action.payload;
        state.topDonorError = null;
      })
      .addCase(getTopDonor.rejected, (state, action) => {
        state.topDonorLoading = false;
        state.topDonorData = null;
        state.topDonorError = action.error.message;
      })
      // filter render
      .addCase(getFilteredTopDonor.pending, (state) => {
        state.filteredTopDonorLoading = true;
        state.topDonorData = null;
        state.topDonorError = null;
      })
      .addCase(getFilteredTopDonor.fulfilled, (state, action) => {
        state.filteredTopDonorLoading = false;
        state.topDonorData = action.payload;
        state.topDonorError = null;
      })
      .addCase(getFilteredTopDonor.rejected, (state, action) => {
        state.filteredTopDonorLoading = false;
        state.topDonorData = null;
        state.topDonorError = action.error.message;
      });
  },
});

export default topDonorSlice.reducer;
