import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getTotalRecurringAmount = createAsyncThunk(
  "totalRecurringAmount/getTotalRecurringAmount",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/recurring?year=${singleObj.year}&month=${singleObj.month}&day=${singleObj.day}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredTotalRecurringAmount = createAsyncThunk(
  "filteredTotalRecurringAmount/getFilteredTotalRecurringAmount",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/recurring?year=${singleObj.year}&month=${singleObj.month}&day=${singleObj.day}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const totalRecurringAmountSlice = createSlice({
  name: "totalRecurringAmount",
  initialState: {
    totalRecurringAmountLoading: false,
    totalRecurringAmountData: null,
    totalRecurringAmountError: null,
    filteredTotalRecurringAmountLoading: false,
  },
  extraReducers: (builder) => {
    // first render
    builder
      .addCase(getTotalRecurringAmount.pending, (state) => {
        state.totalRecurringAmountLoading = true;
        state.totalRecurringAmountData = null;
        state.totalRecurringAmountError = null;
      })
      .addCase(getTotalRecurringAmount.fulfilled, (state, action) => {
        state.totalRecurringAmountLoading = false;
        state.totalRecurringAmountData = action.payload;
        state.totalRecurringAmountError = null;
      })
      .addCase(getTotalRecurringAmount.rejected, (state, action) => {
        state.totalRecurringAmountLoading = false;
        state.totalRecurringAmountData = null;
        state.totalRecurringAmountError = action.error.message;
      })
      // filter render
      .addCase(getFilteredTotalRecurringAmount.pending, (state) => {
        state.filteredTotalRecurringAmountLoading = true;
        state.totalRecurringAmountData = null;
        state.totalRecurringAmountError = null;
      })
      .addCase(getFilteredTotalRecurringAmount.fulfilled, (state, action) => {
        state.filteredTotalRecurringAmountLoading = false;
        state.totalRecurringAmountData = action.payload;
        state.totalRecurringAmountError = null;
      })
      .addCase(getFilteredTotalRecurringAmount.rejected, (state, action) => {
        state.filteredTotalRecurringAmountLoading = false;
        state.totalRecurringAmountData = null;
        state.totalRecurringAmountError = action.error.message;
      });
  },
});

export default totalRecurringAmountSlice.reducer;
