import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getCurrentYearDonations = createAsyncThunk(
  "currentYearDonations/getCurrentYearDonations",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/totalDonationOfYear?year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredYearDonations = createAsyncThunk(
  "filteredYearDonations/getFilteredYearDonations",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/totalDonationOfYear?year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const currentYearDonationsSlice = createSlice({
  name: "currentYearDonations",
  initialState: {
    currentYearDonationsLoading: false,
    currentYearDonationsData: null,
    currentYearDonationsError: null,
    filteredYearDonationsLoading: false,
  },
  extraReducers: (builder) => {
    // first render
    builder
      .addCase(getCurrentYearDonations.pending, (state) => {
        state.currentYearDonationsLoading = true;
        state.currentYearDonationsData = null;
        state.currentYearDonationsError = null;
      })
      .addCase(getCurrentYearDonations.fulfilled, (state, action) => {
        state.currentYearDonationsLoading = false;
        state.currentYearDonationsData = action.payload;
        state.currentYearDonationsError = null;
      })
      .addCase(getCurrentYearDonations.rejected, (state, action) => {
        state.currentYearDonationsLoading = false;
        state.currentYearDonationsData = null;
        state.currentYearDonationsError = action.error.message;
      })
      // filter render
      .addCase(getFilteredYearDonations.pending, (state) => {
        state.filteredYearDonationsLoading = true;
        state.currentYearDonationsData = null;
        state.currentYearDonationsError = null;
      })
      .addCase(getFilteredYearDonations.fulfilled, (state, action) => {
        state.filteredYearDonationsLoading = false;
        state.currentYearDonationsData = action.payload;
        state.currentYearDonationsError = null;
      })
      .addCase(getFilteredYearDonations.rejected, (state, action) => {
        state.filteredYearDonationsLoading = false;
        state.currentYearDonationsData = null;
        state.currentYearDonationsError = action.error.message;
      });
  },
});

export default currentYearDonationsSlice.reducer;
