import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBBtn } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteBanner } from "../../Store/Slices/Sidebar/Campaigns/CampaignSlice";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const BannerModal = ({ token, setBannerModal, bannerObj }) => {
  // close modal
  const handleCloseModal = () => {
    if (isLoaded && !deleteBannerLoading) setBannerModal(false);
  };

  // isLoaded
  const [isLoaded, setIsLoaded] = useState(false);

  // delete banner
  const { deleteBannerLoading } = useSelector((state) => state.campaign);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // delete banner function
  const handleDeleteBanner = () => {
    dispatch(
      deleteBanner({
        token,
        campaignTag: bannerObj.tagNumber,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setBannerModal(false);

        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/signin");
      } else {
        toast.error(`${res.payload.apiError}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Banner</div>
            <div
              className="update-widgets-cancel-icon"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body" style={{ padding: "8px" }}>
            <div className="banner-img-container">
              <img
                src={bannerObj.banner}
                alt="banner"
                onLoad={() => {
                  setTimeout(() => {
                    setIsLoaded(true);
                  }, 500);
                }}
                style={{
                  display: isLoaded ? "block" : "none",
                }}
              />
            </div>

            <MDBBtn
              type="button"
              className="mdb-delete-btn"
              onClick={handleDeleteBanner}
              disabled={!isLoaded || deleteBannerLoading}
            >
              {deleteBannerLoading ? "Removing" : "Remove Banner"}
            </MDBBtn>
            <MDBBtn
              type="button"
              className="mdb-cancel-btn"
              onClick={handleCloseModal}
              disabled={!isLoaded || deleteBannerLoading}
            >
              CLOSE
            </MDBBtn>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default BannerModal;
