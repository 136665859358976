import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredTenderTypes } from "../../Store/Slices/Sidebar/Analytics/TenderTypesSlice";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import { ecommerce_creditcard } from "react-icons-kit/linea/ecommerce_creditcard";
import Tippy from "@tippyjs/react";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import noDataFound from "../../Images/noDataFound.png";

const TenderTypes = ({ token }) => {
  // redux state
  const { filteredTenderTypesLoading, tenderTypesData, tenderTypesError } =
    useSelector((state) => state.tenderTypes);

  // calculate total amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (tenderTypesData && tenderTypesData.values.length > 0) {
      const calculatedAmounts = tenderTypesData.values.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0
      );
      const roundedSum = calculatedAmounts.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [tenderTypesData]);

  // popup state
  const [popup, setPopup] = useState(false);

  // year state
  const [year, setYear] = useState(new Date().getFullYear().toString());

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle year change
  const handleYearChange = (value) => {
    if (value !== year) {
      setPopup(false);
      setYear(value);
      const singleObj = {
        token,
        year: value,
      };
      dispatch(getFilteredTenderTypes(singleObj))
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    }
  };

  // custom styles
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#e1e1e9",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#b1b1b9",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
      boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
      "&:hover": {
        border: "1px solid #007C16",
        boxShadow: "0px 0px 1px #007C16",
      },
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#007C16"
        : isFocused
        ? "rgba(0, 124, 22, 0.2)"
        : base.backgroundColor,
      color: isSelected ? "white" : base.color,
    }),
  };

  return (
    <>
      <div className="text-and-filter-div">
        <span>
          <span>{year}: </span>
          <span style={{ color: "#007C16" }}>
            {tenderTypesData && <>{tenderTypesData.currencySymbol}</>}
            {sum}
          </span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Year</h4>
          <Select
            value={yearOptions.find((option) => option.value === year)}
            onChange={(option) => handleYearChange(option.value)}
            options={yearOptions}
            styles={styles}
          />
        </div>
      </div>
      <div className="row-content">
        <div className="icon-with-text">
          <Tippy content="Method of payment">
            <span>Tender Types</span>
          </Tippy>
          <Icon
            icon={ecommerce_creditcard}
            size={36}
            style={{ marginRight: 10 + "px" }}
          />
        </div>
        {filteredTenderTypesLoading ? (
          <div className="absoluted-box">
            <MetroSpinner
              size={30}
              color="#007C16"
              loading={filteredTenderTypesLoading}
            />
          </div>
        ) : (
          <>
            {tenderTypesError ? (
              <div className="absoluted-box">
                <div className="error-msg" style={{ marginTop: 0 }}>
                  {tenderTypesError}
                </div>
              </div>
            ) : (
              <>
                {tenderTypesData &&
                tenderTypesData.values.length > 0 ? (
                  <div className="tenders-data-row">
                    {tenderTypesData.values.map((data, index) => (
                      <div
                        className="tenders-data-box"
                        key={index}
                        style={{
                          borderTop: `2px solid`,
                          borderTopColor: "#007C16",
                        }}
                      >
                        <h4>
                          {tenderTypesData.currencySymbol}
                          {data.amount}
                        </h4>
                        <div className="text-muted mb-0">{data.text}</div>
                        <div className="text-muted mb-0">
                          Transaction: {data.numberOfTransaction}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Tippy content="No Data Found">
                    <div className="no-data-found-div">
                      <img src={noDataFound} alt="No Data Found" />
                    </div>
                  </Tippy>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TenderTypes;
