/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { basic_paperplane } from "react-icons-kit/linea/basic_paperplane";
import { basic_download } from "react-icons-kit/linea/basic_download";
import { alertCircle } from "react-icons-kit/feather/alertCircle";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { readSchedule } from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import { sendEmailInECheck } from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import toast from "react-hot-toast";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import axios from "axios";
import hostName from "../../config";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const SchedulePopup = ({ token, setSchedulePopup, scheduleTag }) => {
  // redux state
  const { scheduleLoading, scheduleData, scheduleError, eCheckData } =
    useSelector((state) => state.eCheck);

  // redux state
  const { sendEmailInECheckLoading, sendEmailInECheckData } = useSelector(
    (state) => state.eCheck
  );

  // download receipt states
  const [downloadReceiptLoading, setDownloadReceiptLoading] = useState(false);
  const [downloadReceiptTransactionTag, setDownloadReceiptTransactionTag] =
    useState(null);

  // close modal
  const handleCloseModal = () => {
    if (
      !scheduleLoading &&
      !sendEmailInECheckLoading &&
      !downloadReceiptLoading
    ) {
      setSchedulePopup(false);
    }
  };

  // states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState("10");

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "50",
      label: "50",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // read schedule
  useEffect(() => {
    dispatch(
      readSchedule({
        token,
        tagNumber: scheduleTag,
        page: currentPage,
        pageSize: perPage,
      })
    ).then((res) => {
      // 401 works fine here
      if (
        res.error &&
        res.error.message === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        setCurrentPage(res.payload.links.currentPage);
        setPerPage(res.payload.links.perPage);
        setTotalPages(res.payload.links.lastPage);
      }
    });
  }, [currentPage, perPage]);

  // email send event
  const handleSendEmail = (data) => {
    dispatch(
      sendEmailInECheck({
        token,
        tagNumber: data.transactionTag,
      })
    ).then((res) => {
      if (!res.payload.axiosError) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(`${res.payload.apiError}`, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // handle download receipt
  const handleDownloadReceipt = (data) => {
    setDownloadReceiptLoading(true);
    setDownloadReceiptTransactionTag(data.transactionTag);

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    };

    axios
      .get(
        `${hostName}api/admin/v1/transaction/reports/receipt?pTransactionTag=${data.transactionTag}`,
        axiosConfig
      )
      .then((response) => {
        // download pdf
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${data.transactionTag}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          toast.error(`${error.message}`, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .finally(() => setDownloadReceiptLoading(false));
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media extra-width w-90"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Schedule Details</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body ">
            {scheduleLoading ? (
              <div
                style={{
                  padding: 50 + "px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MetroSpinner
                  size={30}
                  color="#007C16"
                  loading={scheduleLoading}
                />
              </div>
            ) : (
              <>
                {scheduleError ? (
                  <div
                    style={{
                      padding: 50 + "px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="error-msg">{scheduleError}</div>
                  </div>
                ) : (
                  <>
                    {scheduleData && scheduleData.data.length > 0 ? (
                      <>
                        <div className="transactions-table">
                          <MDBTable align="middle">
                            <MDBTableHead
                              className="thead"
                              style={{ zIndex: 0 }}
                            >
                              <tr>
                                <th scope="col">Create Date Time</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Process Date</th>
                                <th scope="col">Last Status Date</th>
                                <th scope="col">Return Date</th>
                                <th scope="col">Settlement Date</th>
                                <th scope="col">Transaction ID</th>
                                <th scope="col">Status</th>
                                <th scope="col">Notes</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </MDBTableHead>

                            <MDBTableBody>
                              {scheduleData.data.map((data, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#fff" : "#fafafa",
                                  }}
                                  key={data.tagNumber}
                                >
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{
                                        fontSize: "16px",
                                      }}
                                    >
                                      {data.createDateTime && (
                                        <>{data.createDateTime}</>
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {eCheckData &&
                                        eCheckData.data[0].currencySymbol}
                                      {data.amount}
                                    </p>
                                  </td>

                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{
                                        fontSize: "16px",
                                      }}
                                    >
                                      {data.processDate && (
                                        <>{data.processDate}</>
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{
                                        fontSize: "16px",
                                      }}
                                    >
                                      {data.lastStatusDate && (
                                        <>{data.lastStatusDate}</>
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {data.returnDate && (
                                        <>{data.returnDate}</>
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {data.settlementDate && (
                                        <>{data.settlementDate}</>
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {data.transactionId && (
                                        <>{data.transactionId}</>
                                      )}
                                    </p>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <Tippy
                                      content={data.statusText}
                                      zIndex={1000001}
                                    >
                                      <img
                                        src={data.statusImage}
                                        alt="status"
                                        style={{
                                          width: 30 + "px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Tippy>
                                  </td>
                                  <td className="icons-td">
                                    <div className="actions-icon-container">
                                      <Tippy
                                        content={
                                          <div>
                                            {data.notes
                                              .split("<BR/>")
                                              .map((line, index) => (
                                                <p
                                                  key={index}
                                                  style={{
                                                    marginBottom: 4 + "px",
                                                  }}
                                                >
                                                  {line}
                                                </p>
                                              ))}
                                          </div>
                                        }
                                        zIndex={1000001}
                                      >
                                        <div
                                          style={{
                                            color: "#85b73b",
                                            textAlign: "center",
                                            width: 100 + "%",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Icon icon={alertCircle} size={20} />
                                        </div>
                                      </Tippy>
                                    </div>
                                  </td>
                                  <td className="icons-td">
                                    <div className="actions-icon-container">
                                      {/* send email */}
                                      {sendEmailInECheckData &&
                                      sendEmailInECheckData.tagNumber ===
                                        data.transactionTag &&
                                      sendEmailInECheckLoading ? (
                                        <span className="loader-span">
                                          <MetroSpinner
                                            loading={sendEmailInECheckLoading}
                                            color="#007c16"
                                            size={20}
                                          />
                                        </span>
                                      ) : (
                                        <Tippy
                                          content="Send Email"
                                          zIndex={1000001}
                                        >
                                          <span
                                            onClick={() =>
                                              handleSendEmail(data)
                                            }
                                            style={{
                                              opacity: data.transactionTag
                                                ? 1
                                                : 0.5,
                                              pointerEvents: data.transactionTag
                                                ? "auto"
                                                : "none",
                                            }}
                                          >
                                            <Icon
                                              icon={basic_paperplane}
                                              size={20}
                                            />
                                          </span>
                                        </Tippy>
                                      )}
                                      {/* download receipt */}
                                      {downloadReceiptLoading &&
                                      downloadReceiptTransactionTag ===
                                        data.transactionTag &&
                                      downloadReceiptLoading ? (
                                        <span className="loader-span">
                                          <MetroSpinner
                                            loading={downloadReceiptLoading}
                                            color="#007c16"
                                            size={20}
                                          />
                                        </span>
                                      ) : (
                                        <Tippy
                                          content="Download Receipt"
                                          zIndex={1000001}
                                        >
                                          <span
                                            onClick={() =>
                                              handleDownloadReceipt(data)
                                            }
                                            style={{
                                              opacity: data.transactionTag
                                                ? 1
                                                : 0.5,
                                              pointerEvents: data.transactionTag
                                                ? "auto"
                                                : "none",
                                            }}
                                          >
                                            <Icon
                                              icon={basic_download}
                                              size={20}
                                            />
                                          </span>
                                        </Tippy>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </MDBTableBody>
                          </MDBTable>
                        </div>
                        <div
                          className="per-page-and-pagination"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="per-page">
                            <label>Records per page</label>
                            <Select
                              value={perPageOptions.find(
                                (option) => option.value === String(perPage)
                              )}
                              onChange={(option) => {
                                setCurrentPage(1);
                                setPerPage(option.value);
                              }}
                              options={perPageOptions}
                              styles={styles}
                              menuPlacement="top"
                              menuPosition={"fixed"}
                            />
                          </div>
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(value) => setCurrentPage(value)}
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        className="no-data-found-div"
                        style={{ padding: 50 + "px" }}
                      >
                        <Tippy content="No Data Found" zIndex={100005}>
                          <img src={noDataFound} alt="No Data Found" />
                        </Tippy>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default SchedulePopup;
