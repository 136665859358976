import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getDaySummaryByCampaign = createAsyncThunk(
  "daySummaryByCampaign/getDaySummaryByCampaign",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/daySummary?date=${singleObj.date}`,
      axiosConfig
    );
    const response = await request.data.data;
    if (response.values.length > 0) {
      function generateColors(length) {
        const colors = [];

        // Define the colors in the gradient
        const gradientColors = [
          "#34495e",
          "#9b59b6",
          "#3498db",
          "#62cb31",
          "#ffb606",
          "#e67e22",
          "#e74c3c",
          "#c0392b",
          "#175935",
          "#1f9d61",
          "#cc2473",
          "#d828c9",
          "#453095",
          "#9100ff",
          "#884a39",
          "#c38154",
        ];

        // Generate new colors after the eighth position
        const gradientLength = gradientColors.length;
        for (let i = 0; i < length; i++) {
          let color;
          if (i < gradientLength) {
            color = gradientColors[i];
          } else {
            // Generate a new color based on a pattern
            const colorIndex = i % gradientLength;
            const patternIndex = Math.floor(i / gradientLength) - 1;
            const patternColor = gradientColors[colorIndex];
            const patternColors = [
              "#212e48",
              "#800080",
              "#2d86ff",
              "#00d327",
              "#ffcb2b",
              "#ffa800",
              "#f25410",
              "#cd4c3a",
            ];
            const newColorIndex = patternIndex % patternColors.length;
            const newColor = patternColors[newColorIndex];
            // Combine the pattern color and new color
            color = mixColors(patternColor, newColor);
          }
          colors.push(color);
        }
        return colors;
      }

      // Helper function to mix two colors
      function mixColors(color1, color2) {
        // Perform your logic to mix the colors
        // Here's a simple example that mixes the colors by averaging their RGB values
        const rgb1 = hexToRGB(color1);
        const rgb2 = hexToRGB(color2);
        const mixedColor = {
          r: Math.floor((rgb1.r + rgb2.r) / 2),
          g: Math.floor((rgb1.g + rgb2.g) / 2),
          b: Math.floor((rgb1.b + rgb2.b) / 2),
        };
        return rgbToHex(mixedColor);
      }

      // Helper function to convert hex color to RGB object
      function hexToRGB(hex) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return { r, g, b };
      }

      // Helper function to convert RGB object to hex color
      function rgbToHex(rgb) {
        const { r, g, b } = rgb;
        const hexR = r.toString(16).padStart(2, "0");
        const hexG = g.toString(16).padStart(2, "0");
        const hexB = b.toString(16).padStart(2, "0");
        return `#${hexR}${hexG}${hexB}`;
      }

      // Usage example
      const responseLength = response.values.length;
      const colors = generateColors(responseLength);

      return {
        currencySymbol: response.currencySymbol,
        labels: response.values.map(
          (individualData) => individualData.campaign
        ),
        datasets: [
          {
            label: "Amount",
            data: response.values.map((individualData) =>
              parseFloat(individualData.amount.replace(/,/g, ""))
            ),
            backgroundColor: colors,
            borderColor: colors,
          },
          {
            label: "Number of Transactions",
            data: response.values.map(
              (individualData) => individualData.numberOfTransaction
            ),
            backgroundColor: "#08a5a5",
            borderColor: "#08a5a5",
          },
        ],
      };
    } else {
      return {
        currencySymbol: response.currencySymbol,
      };
    }
  }
);

// filter render
export const getFilteredDaySummaryByCampaign = createAsyncThunk(
  "filteredDaySummaryByCampaign/getFilteredDaySummaryByCampaign",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/daySummary?date=${singleObj.date}`,
      axiosConfig
    );
    const response = await request.data.data;
    if (response.values.length > 0) {
      function generateColors(length) {
        const colors = [];

        // Define the colors in the gradient
        const gradientColors = [
          "#34495e",
          "#9b59b6",
          "#3498db",
          "#62cb31",
          "#ffb606",
          "#e67e22",
          "#e74c3c",
          "#c0392b",
          "#175935",
          "#1f9d61",
          "#cc2473",
          "#d828c9",
          "#453095",
          "#9100ff",
          "#884a39",
          "#c38154",
        ];

        // Generate new colors after the eighth position
        const gradientLength = gradientColors.length;
        for (let i = 0; i < length; i++) {
          let color;
          if (i < gradientLength) {
            color = gradientColors[i];
          } else {
            // Generate a new color based on a pattern
            const colorIndex = i % gradientLength;
            const patternIndex = Math.floor(i / gradientLength) - 1;
            const patternColor = gradientColors[colorIndex];
            const patternColors = [
              "#212e48",
              "#800080",
              "#2d86ff",
              "#00d327",
              "#ffcb2b",
              "#ffa800",
              "#f25410",
              "#cd4c3a",
            ];
            const newColorIndex = patternIndex % patternColors.length;
            const newColor = patternColors[newColorIndex];
            // Combine the pattern color and new color
            color = mixColors(patternColor, newColor);
          }
          colors.push(color);
        }
        return colors;
      }

      // Helper function to mix two colors
      function mixColors(color1, color2) {
        // Perform your logic to mix the colors
        // Here's a simple example that mixes the colors by averaging their RGB values
        const rgb1 = hexToRGB(color1);
        const rgb2 = hexToRGB(color2);
        const mixedColor = {
          r: Math.floor((rgb1.r + rgb2.r) / 2),
          g: Math.floor((rgb1.g + rgb2.g) / 2),
          b: Math.floor((rgb1.b + rgb2.b) / 2),
        };
        return rgbToHex(mixedColor);
      }

      // Helper function to convert hex color to RGB object
      function hexToRGB(hex) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return { r, g, b };
      }

      // Helper function to convert RGB object to hex color
      function rgbToHex(rgb) {
        const { r, g, b } = rgb;
        const hexR = r.toString(16).padStart(2, "0");
        const hexG = g.toString(16).padStart(2, "0");
        const hexB = b.toString(16).padStart(2, "0");
        return `#${hexR}${hexG}${hexB}`;
      }

      // Usage example
      const responseLength = response.values.length;
      const colors = generateColors(responseLength);

      return {
        currencySymbol: response.currencySymbol,
        labels: response.values.map(
          (individualData) => individualData.campaign
        ),
        datasets: [
          {
            label: "Amount",
            data: response.values.map((individualData) =>
              parseFloat(individualData.amount.replace(/,/g, ""))
            ),
            backgroundColor: colors,
            borderColor: colors,
          },
          {
            label: "Number of Transactions",
            data: response.values.map(
              (individualData) => individualData.numberOfTransaction
            ),
            backgroundColor: "#08a5a5",
            borderColor: "#08a5a5",
          },
        ],
      };
    } else {
      return {
        currencySymbol: response.currencySymbol,
      };
    }
  }
);

const daySummaryByCampaignSlice = createSlice({
  name: "daySummaryByCampaign",
  initialState: {
    daySummaryByCampaignLoading: false,
    daySummaryByCampaignData: null,
    daySummaryByCampaignError: null,
    filteredDaySummaryByCampaignLoading: false,
  },
  extraReducers: (builder) => {
    builder
      // first render
      .addCase(getDaySummaryByCampaign.pending, (state) => {
        state.daySummaryByCampaignLoading = true;
        state.daySummaryByCampaignData = null;
        state.daySummaryByCampaignError = null;
      })
      .addCase(getDaySummaryByCampaign.fulfilled, (state, action) => {
        state.daySummaryByCampaignLoading = false;
        state.daySummaryByCampaignData = action.payload;
        state.daySummaryByCampaignError = null;
      })
      .addCase(getDaySummaryByCampaign.rejected, (state, action) => {
        state.daySummaryByCampaignLoading = false;
        state.daySummaryByCampaignData = null;
        state.daySummaryByCampaignError = action.error.message;
      })
      // filter render
      .addCase(getFilteredDaySummaryByCampaign.pending, (state) => {
        state.filteredDaySummaryByCampaignLoading = true;
        state.daySummaryByCampaignData = null;
        state.daySummaryByCampaignError = null;
      })
      .addCase(getFilteredDaySummaryByCampaign.fulfilled, (state, action) => {
        state.filteredDaySummaryByCampaignLoading = false;
        state.daySummaryByCampaignData = action.payload;
        state.daySummaryByCampaignError = null;
      })
      .addCase(getFilteredDaySummaryByCampaign.rejected, (state, action) => {
        state.filteredDaySummaryByCampaignLoading = false;
        state.daySummaryByCampaignData = null;
        state.daySummaryByCampaignError = action.error.message;
      });
  },
});

export default daySummaryByCampaignSlice.reducer;
