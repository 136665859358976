import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editWallet } from "../../../../Store/Slices/Wallet/WalletSlice";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import { toast } from "react-hot-toast";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "180px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const EditWalletPopup = ({
  token,
  editWalletObj,
  setEditWalletPopup,
  email,
}) => {
  // redux state
  const { editWalletLoading, editWalletData } = useSelector(
    (state) => state.wallet
  );

  // close modal
  const handleCloseModal = () => {
    if (!editWalletLoading) {
      setEditWalletPopup(false);
    }
  };

  // card details state
  const [cardHolderName] = useState(editWalletObj.name);
  const [cardNumber] = useState(editWalletObj.number);
  const [expiryYear, setExpiryYear] = useState(editWalletObj.expiryYear);
  const [expiryMonth, setExpiryMonth] = useState(editWalletObj.expiryMonth);
  const [cvc, setCvc] = useState("");

  // Function to generate the month options
  const generateMonthOptions = () => {
    const monthOptions = [];

    for (let i = 1; i <= 12; i++) {
      const monthValue = i < 10 ? `0${i}` : `${i}`;
      monthOptions.push({
        value: monthValue,
        label: monthValue,
      });
    }

    return monthOptions;
  };

  // Generate the month options
  const expiryMonthOptions = generateMonthOptions();

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Function to generate the expiry year options
  const generateExpiryYearOptions = (startYear, numberOfYears) => {
    const expiryYearOptions = [];

    for (let i = 0; i < numberOfYears; i++) {
      const year = startYear + i;
      expiryYearOptions.push({
        value: String(year).slice(-2),
        label: String(year),
      });
    }

    return expiryYearOptions;
  };

  // Generate the expiry year options for the next 15 years
  const expiryYearOptions = generateExpiryYearOptions(currentYear, 15);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // edit card submit
  const handleEditCardSubmit = (e) => {
    e.preventDefault();
    dispatch(
      editWallet({
        token,
        expiryYear,
        expiryMonth,
        cvc,
        email,
        cardId: editWalletObj.cardId,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setEditWalletPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Edit Card - {editWalletObj.number}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form className="update-media-form" onSubmit={handleEditCardSubmit}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Cardholder Name</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={cardHolderName}
                    readOnly
                    required
                    placeholder="Cardholder Name"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Card Number</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={cardNumber}
                    readOnly
                    required
                    placeholder="Card Number"
                    pattern=".{15,}"
                    title="Please enter a card number with at least 15 characters"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Expiry Month</label>
                  <Select
                    styles={styles}
                    required
                    menuPlacement="bottom"
                    value={expiryMonthOptions.find(
                      (option) => option.value === expiryMonth
                    )}
                    onChange={(option) => setExpiryMonth(option.value)}
                    options={expiryMonthOptions}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Expiry Year</label>
                  <Select
                    styles={styles}
                    required
                    menuPlacement="bottom"
                    value={expiryYearOptions.find(
                      (option) => option.value === expiryYear
                    )}
                    onChange={(option) => setExpiryYear(option.value)}
                    options={expiryYearOptions}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>CVC</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={cvc}
                    onChange={(e) => setCvc(e.target.value)}
                    required
                    placeholder="CVC"
                  />
                </MDBCol>
              </MDBRow>
              {/* edit wallet error */}
              {editWalletData && editWalletData.apiError ? (
                <div className="error-msg">{editWalletData.apiError}</div>
              ) : (
                <>
                  {editWalletData && editWalletData.axiosError && (
                    <div className="error-msg">{editWalletData.axiosError}</div>
                  )}
                </>
              )}
              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CLOSE
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={editWalletLoading}
                >
                  {editWalletLoading ? (
                    <MetroSpinner
                      loading={editWalletLoading}
                      color="#fff"
                      size={22}
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default EditWalletPopup;
