import React from "react";
import { useSelector } from "react-redux";
import Icon from "react-icons-kit";
import { userCheck } from "react-icons-kit/feather/userCheck";
import CountUp from "react-countup";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";

const NoOfFollowers = () => {
  // redux state
  const { noOfFollowersData, noOfFollowersError } = useSelector(
    (state) => state.noOfFollowers
  );

  return (
    <div className="row-box">
      <div className="icon-with-text">
        <span>Followers</span>
        <Icon icon={userCheck} size={36} />
      </div>
      {noOfFollowersError ? (
        <div className="error-msg" style={{ marginTop: 0 }}>
          {noOfFollowersError}
        </div>
      ) : (
        <>
          {noOfFollowersData !== null ? (
            <>
              <CountUp
                className="h1"
                start={0}
                end={noOfFollowersData.followers}
                duration={2.5}
                decimals={0}
                decimal="."
                prefix=""
                suffix=""
              />
              <div className="social-users-div">
                <span className="social-users-heading">Donors</span>
                <div className="progress-bar-div">
                  <div
                    className="progress-bar-colored"
                    style={{
                      width: `${
                        (noOfFollowersData.donors /
                          noOfFollowersData.followers) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
            </>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Data Found">
                <img src={noDataFound} alt="No Data Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NoOfFollowers;
