import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isBlurhashValid } from "blurhash";
import { Blurhash } from "react-blurhash";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { software_pencil } from "react-icons-kit/linea/software_pencil";
import { basic_trashcan_remove } from "react-icons-kit/linea/basic_trashcan_remove";
import { basic_todo_txt } from "react-icons-kit/linea/basic_todo_txt";

const DonorRow = React.memo(
  ({
    donor,
    index,
    loadedImages,
    handleEditDonor,
    handleDeleteDonor,
    goToSpecificDonor,
    handleAddressDrawer,
  }) => {
    const hasProfileImage = !!donor.profileImage;

    const circleStyle = {
      width: "50px",
      height: "50px",
      backgroundColor: donor.profileImage
        ? "#f0f7f9"
        : donor.status === 1
        ? "#007C16"
        : "#f2b92c",
      color: donor.status === 1 ? "#fff" : "#000",
      letterSpacing: "0.09em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "18px",
      fontWeight: "bold",
      padding: hasProfileImage ? "0px" : "7px",
      overflow: "hidden",
    };

    const validRes = isBlurhashValid(donor.blurHash);

    return (
      <tr
        style={{
          backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
        }}
      >
        <td>
          <div className="d-flex align-items-center">
            <div className="rounded-circle" style={circleStyle}>
              {donor.profileImage ? (
                loadedImages[donor.tagNumber] ? (
                  <LazyLoadImage
                    src={donor.profileImage}
                    alt="dp"
                    effect="blur"
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                      overflowClipMargin: "unset",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    {validRes.result && (
                      <Blurhash
                        hash={donor.blurHash}
                        width="100%"
                        height="100%"
                        resolutionX={32}
                        resolutionY={32}
                        punch={1}
                      />
                    )}
                  </div>
                )
              ) : (
                <>
                  {donor.firstName.charAt(0)}
                  {donor.lastName.charAt(0)}
                </>
              )}
            </div>
            <div className="ms-3">
              <span
                style={{ color: "#757575", cursor:"pointer" }}
                className="donor-link"
                onClick={() => goToSpecificDonor(donor)}
              >
                <p className="fw-bold mb-1">
                  {donor.firstName} {donor.lastName}
                </p>
              </span>
              <p className="text-muted mb-0">{donor.email}</p>
            </div>
          </div>
        </td>
        <td>
          <p className="fw-normal mb-1" style={{ fontSize: "16px" }}>
            {donor.formattedAddress}
          </p>
        </td>
        <td>
          <p className="fw-normal mb-1" style={{ fontSize: "16px" }}>
            {donor.phone}
          </p>
        </td>
        <td style={{ textAlign: "center" }}>
          <Tippy content={donor.accountTypeText} zIndex={10004}>
            <img
              src={donor.accountTypeImage}
              alt="accountType"
              style={{ width: "30px", cursor: "pointer" }}
            />
          </Tippy>
        </td>
        <td className="icons-td">
          <div className="actions-icon-container">
            <Tippy content="More Details" zIndex={10004}>
              <span onClick={() => handleAddressDrawer(donor)}>
                <Icon icon={basic_todo_txt} size={20} />
              </span>
            </Tippy>

            <Tippy content="Edit Donor" zIndex={10004}>
              <span onClick={() => handleEditDonor(donor)}>
                <Icon icon={software_pencil} size={20} />
              </span>
            </Tippy>

            <Tippy content="Delete Donor" zIndex={10004}>
              <span onClick={() => handleDeleteDonor(donor)}>
                <Icon icon={basic_trashcan_remove} size={20} />
              </span>
            </Tippy>
          </div>
        </td>
      </tr>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.donor === nextProps.donor &&
      prevProps.loadedImages[nextProps.donor.tagNumber] ===
        nextProps.loadedImages[nextProps.donor.tagNumber]
    );
  }
);

export default DonorRow;
