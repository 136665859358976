import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeAmount } from "../../Store/Slices/Sidebar/RecurringPayments/RecurringPaymentsSlice";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { MetroSpinner } from "react-spinners-kit";
import { toast } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const ChangeAmountPopup = ({
  token,
  setChangeAmountPopup,
  changeAmountObj,
}) => {
  // redux state
  const { recurringPaymentsData, changeAmountLoading, changeAmountData } =
    useSelector((state) => state.recurringPayments);

  // close modal
  const handleCloseModal = () => {
    if (!changeAmountLoading) {
      setChangeAmountPopup(false);
    }
  };

  // amount
  const [amount, setAmount] = useState(changeAmountObj.amount);

  // amount change
  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue);
  };

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // amount change event
  const handleAmountSubmit = (e) => {
    e.preventDefault();
    if (amount >= changeAmountObj.campaignMinimumAmount) {
      dispatch(
        changeAmount({
          token,
          tagNumber: changeAmountObj.tagNumber,
          amount: parseFloat(amount),
        })
      ).then((res) => {
        if (res.payload.successMsg) {
          setChangeAmountPopup(false);
          toast.success(`${res.payload.successMsg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          res.payload.axiosError === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Change Amount</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form onSubmit={handleAmountSubmit}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Amount</label>
                  {changeAmountObj.campaignMinimumAmount !== "" && (
                    <span
                      style={{
                        marginLeft: 10 + "px",
                        fontSize: 13 + "px",
                        fontWeight: 600,
                        color: "#6a6c6f",
                      }}
                    >
                      Minimum Amount:
                      {recurringPaymentsData && (
                        <>{recurringPaymentsData.data.currencySymbol}</>
                      )}
                      {changeAmountObj.campaignMinimumAmount}
                    </span>
                  )}
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={amount}
                    onChange={handleAmountChange}
                    inputMode="decimal"
                    title=""
                    required
                    placeholder="Amount"
                    helperText={
                      amount !== "" &&
                      amount < changeAmountObj.campaignMinimumAmount
                        ? `Please enter an amount greater than or equal to ${changeAmountObj.campaignMinimumAmount}`
                        : ""
                    }
                    error={
                      amount !== "" &&
                      amount < changeAmountObj.campaignMinimumAmount
                    }
                  />
                </MDBCol>
              </MDBRow>

              {changeAmountData &&
              changeAmountData.apiError &&
              changeAmountData.tagNumber === changeAmountObj.tagNumber ? (
                <div className="error-msg">{changeAmountData.apiError}</div>
              ) : (
                <>
                  {changeAmountData &&
                    changeAmountData.axiosError &&
                    changeAmountData.tagNumber ===
                      changeAmountObj.tagNumber && (
                      <div className="error-msg">
                        {changeAmountData.axiosError}
                      </div>
                    )}
                </>
              )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={changeAmountLoading}
                  style={{
                    opacity:
                      amount < changeAmountObj.campaignMinimumAmount ? 0.5 : 1,
                  }}
                >
                  {changeAmountLoading ? (
                    <MetroSpinner
                      loading={changeAmountLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "CHANGE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ChangeAmountPopup;
