import React, { useState } from "react";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Icon from "react-icons-kit";
import { basic_download } from "react-icons-kit/linea/basic_download";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MetroSpinner } from "react-spinners-kit";
import axios from "axios";
import hostName from "../../config";

// get organization
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const OrganizationDetailsForm = ({
  token,
  organization,
  setOrganization,
  countries,
  usaStates,
  provinces,
  handleOrganizationDetailsSubmit,
}) => {
  // selected org
  const [selectedOrganization] = useState(getOrganization());

  // redux state
  const { updateOrganizationLoading, updateOrganizationData } = useSelector(
    (state) => state.organization
  );

  // handle country change
  const handleCountryChange = (label) => {
    setOrganization((prevOrganization) => ({
      ...prevOrganization,
      country: label,
      city: "",
      charityCity: "",
      provinceState: "",
      charityProvinceState: "",
      postalZipCode: "",
      charityPostalZipCode: "",
    }));
  };

  // generate qr code

  // states
  const [qrCodeLoading, setQrCodeLoading] = useState(false);
  const [qrCodeError, setQrCodeError] = useState(null);

  // navigate
  const navigate = useNavigate();

  // event
  const handleGenerateQrCode = (e) => {
    e.preventDefault();

    setQrCodeLoading(true);
    setQrCodeError(null);

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    };

    axios
      .get(
        `${hostName}api/admin/v1/qrCode?data=${organization.portalUrl}`,
        axiosConfig
      )
      .then((response) => {
        // download png
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "qrcode.png");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setQrCodeError(error.message);
        }
      })
      .finally(() => setQrCodeLoading(false));
  };

  // handle status change
  const handleStatusChange = () => {
    setOrganization((prevOrganization) => ({
      ...prevOrganization,
      forceRegistration: prevOrganization.forceRegistration === "Y" ? "N" : "Y",
    }));
  };

  return (
    <form
      onSubmit={handleOrganizationDetailsSubmit}
      className="update-media-form"
    >
      <h4
        style={{
          borderBottom: 1 + "px solid #e4e4e4",
          paddingBottom: 5 + "px",
          marginBottom: 15 + "px",
        }}
      >
        Profile
      </h4>
      <MDBRow className="mb-3 logo-row">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Name</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.name}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                name: e.target.value,
              }))
            }
            required
            placeholder="Name"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Phone</label>
          <input
            type="tel"
            className="edit-media-inputs"
            value={organization.phone}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                phone: e.target.value,
              }))
            }
            placeholder="Phone"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0 logo-col">
          <img src={organization.logo} alt="logo" />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
          <label>Email</label>
          <input
            type="email"
            className="edit-media-inputs"
            value={organization.email}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                email: e.target.value,
              }))
            }
            placeholder="Email"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
          <label>Website</label>
          <input
            type="url"
            className="edit-media-inputs"
            value={organization.website}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                website: e.target.value,
              }))
            }
            placeholder="Website"
          />
        </MDBCol>
      </MDBRow>
      <br />
      <h4
        style={{
          borderBottom: 1 + "px solid #e4e4e4",
          paddingBottom: 5 + "px",
          marginBottom: 15 + "px",
        }}
      >
        Address
      </h4>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Street Address</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.streetAddress}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                streetAddress: e.target.value,
              }))
            }
            required
            placeholder="Street Address"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Unit Number</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.unitNumber}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                unitNumber: e.target.value,
              }))
            }
            placeholder="Unit Number"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>City</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.city}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                city: e.target.value,
              }))
            }
            required
            placeholder="City"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Country</label>
          <Select
            value={countries.find(
              (option) => option.label === organization.country
            )}
            onChange={(option) => handleCountryChange(option.label)}
            options={countries}
            styles={styles}
            required
            menuPlacement="top"
            menuPosition="fixed"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          {organization.country === "Canada" ? (
            <>
              <label>Province</label>
              <Select
                value={provinces.find(
                  (option) => option.value === organization.provinceState
                )}
                onChange={(option) =>
                  setOrganization((prevOrganization) => ({
                    ...prevOrganization,
                    provinceState: option.value,
                  }))
                }
                options={provinces}
                styles={styles}
                required
                menuPlacement="top"
                menuPosition="fixed"
              />
            </>
          ) : (
            <>
              {organization.country === "United States" ? (
                <>
                  <label>State</label>
                  <Select
                    value={usaStates.find(
                      (option) => option.value === organization.provinceState
                    )}
                    onChange={(option) =>
                      setOrganization((prevOrganization) => ({
                        ...prevOrganization,
                        provinceState: option.value,
                      }))
                    }
                    options={usaStates}
                    styles={styles}
                    required
                    menuPlacement="top"
                    menuPosition="fixed"
                  />
                </>
              ) : (
                <>
                  <label>Province</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={organization.provinceState}
                    onChange={(e) =>
                      setOrganization((prevOrganization) => ({
                        ...prevOrganization,
                        provinceState: e.target.value,
                      }))
                    }
                    required
                    placeholder="Province"
                  />
                </>
              )}
            </>
          )}
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>
            {organization.country === "United States"
              ? "Zip Code"
              : "Postal Code"}
          </label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.postalZipCode}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                postalZipCode: e.target.value,
              }))
            }
            required
            placeholder={
              organization.country === "United States"
                ? "Zip Code"
                : "Postal Code"
            }
          />
        </MDBCol>
      </MDBRow>
      <br />
      <h4
        style={{
          borderBottom: 1 + "px solid #e4e4e4",
          paddingBottom: 5 + "px",
          marginBottom: 15 + "px",
        }}
      >
        Supplementary Data
      </h4>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Location Name</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.locationName}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                locationName: e.target.value,
              }))
            }
            placeholder="Location Name"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Time Zone</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.timezone}
            readOnly
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Short Name</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.shortName}
            readOnly
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Receipt Alert Email</label>
          <input
            type="email"
            className="edit-media-inputs"
            value={organization.receiptAlertEmail}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                receiptAlertEmail: e.target.value,
              }))
            }
            placeholder="Receipt Alert Email"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Sign Up Date</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.signUpDate}
            readOnly
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Last Update</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.updateDateTime}
            readOnly
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
          <label>Portal URL</label>
          <div className="qr-input-container">
            <input
              type="url"
              className="edit-media-inputs"
              value={organization.portalUrl}
              readOnly
            />
            {/* qr code button */}
            <Tippy content="QR Code" zIndex={10000}>
              <button
                type="button"
                className="qr-button"
                onClick={handleGenerateQrCode}
                disabled={qrCodeLoading && updateOrganizationLoading}
              >
                {qrCodeLoading ? (
                  <MetroSpinner
                    loading={qrCodeLoading}
                    size={22}
                    color="#fff"
                  />
                ) : (
                  <>
                    <Icon
                      icon={basic_download}
                      size={20}
                      style={{ marginRight: 10 + "px" }}
                    />
                    QR Code
                  </>
                )}
              </button>
            </Tippy>
          </div>
        </MDBCol>
        <MDBCol className="col-12 col-md-2 mb-2 mb-md-0"></MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Force Registration</label>
          <br />
          <label className="switch">
            <input
              type="checkbox"
              checked={organization.forceRegistration === "Y"}
              onChange={handleStatusChange}
              disabled={selectedOrganization.gatewayNodeTag === 0}
            />
            <span className="slider round"></span>
          </label>
        </MDBCol>
        {qrCodeError && (
          <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
            <div className="error-msg">{qrCodeError}</div>
          </MDBCol>
        )}
      </MDBRow>
      <br />
      <h4
        style={{
          borderBottom: 1 + "px solid #e4e4e4",
          paddingBottom: 5 + "px",
          marginBottom: 15 + "px",
        }}
      >
        Footer Notes
      </h4>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
          <label>Receipt Footer</label>
          <textarea
            style={{ height: 84 + "px" }}
            className="custom-textarea"
            maxLength={65000}
            value={organization.receiptFooter}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                receiptFooter: e.target.value,
              }))
            }
            placeholder="Receipt Footer"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
          <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
            <label>Footer Note</label>
            <input
              type="text"
              className="edit-media-inputs"
              value={organization.footerNote}
              onChange={(e) =>
                setOrganization((prevOrganization) => ({
                  ...prevOrganization,
                  footerNote: e.target.value,
                }))
              }
              placeholder="Footer Note"
            />
          </MDBCol>
        </MDBCol>
      </MDBRow>

      {updateOrganizationData &&
        (updateOrganizationData.apiError ? (
          <div className="error-msg" style={{ width: "fit-content" }}>
            {typeof updateOrganizationData.apiError === "object" ? (
              // Case 1: If apiError is an object
              Object.keys(updateOrganizationData.apiError).map(
                (field, index) => (
                  <p key={index} style={{ marginBottom: 0 }}>
                    {updateOrganizationData.apiError[field][0]}
                  </p>
                )
              )
            ) : (
              // Case 2: If apiError is a simple string
              <>{updateOrganizationData.apiError}</>
            )}
          </div>
        ) : (
          // Case 3: If apiError does not exist, render axiosError
          <>
            {updateOrganizationData && updateOrganizationData.axiosError && (
              <div className="error-msg" style={{ width: "fit-content" }}>
                {updateOrganizationData.axiosError}
              </div>
            )}
          </>
        ))}

      <div className="submit-and-cancel-div">
        <button
          type="submit"
          className="submit addModal"
          disabled={updateOrganizationLoading && qrCodeLoading}
        >
          {updateOrganizationLoading ? (
            <MetroSpinner
              loading={updateOrganizationLoading}
              size={22}
              color="#fff"
            />
          ) : (
            "UPDATE"
          )}
        </button>
      </div>
    </form>
  );
};

export default OrganizationDetailsForm;
