import React from "react";
import LeftSide from "../../Components/Common/LeftSide";
import ForgotForm from "../../Components/Auth/ForgotForm";
import AuthLogo from "../../Components/Common/AuthLogo";
import AppAndPlayStore from "../../Components/Auth/AppAndPlayStore";

const ForgotPassword = () => (
  <div className="login-background">
    <div className="login-box">
      <LeftSide />
      <div className="rightside">
        <AuthLogo />
        <h4>
          <span className="green">FORGOTTEN</span> YOUR PASSWORD?
        </h4>
        <ForgotForm />
      </div>
    </div>
    <AppAndPlayStore />
  </div>
);

export default ForgotPassword;
