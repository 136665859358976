import React, { useEffect, useState } from "react";
import LoginForm from "../../Components/Auth/LoginForm";
import LeftSide from "../../Components/Common/LeftSide";
import AuthLogo from "../../Components/Common/AuthLogo";
import AppAndPlayStore from "../../Components/Auth/AppAndPlayStore";
import NewsPopup from "../../Components/News/NewsPopup";
import { useDispatch } from "react-redux";
import { getNews } from "../../Store/Slices/News/NewsSlice";

const Login = () => {
  // state
  const [newsPopup, setNewsPopup] = useState(false);
  const [shouldMute] = useState(true);

  // dispatch
  const dispatch = useDispatch();

  // if api returns slides length > 0
  useEffect(() => {
    dispatch(getNews()).then((res) => {
      if (res.payload?.length > 0) {
        setNewsPopup(true);
      }
    });
  }, [dispatch]);

  return (
    <>
      <div className="login-background">
        <div className="login-box">
          <LeftSide />
          <div className="rightside">
            <AuthLogo />
            <h4>
              <span className="green">LOGIN</span> YOUR ACCOUNT
            </h4>
            <LoginForm />
          </div>
        </div>
        <AppAndPlayStore />
      </div>

      {newsPopup && (
        <NewsPopup setNewsPopup={setNewsPopup} shouldMute={shouldMute} />
      )}
    </>
  );
};

export default Login;
