/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { CustomTextField } from '../Common/CustomTextField';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleResetPassword, resetResetPasswordStates } from '../../Store/Slices/Auth/ResetPasswordSlice';

export const ResetForm = () => {
  // states
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [componentError, setComponentError] = useState(null);

  // Redux state
  const { successMsg, loading, error } = useSelector((state)=>state.resetPassword);

  // forgor password event
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleResetPasswordEvent=(e)=>{
    e.preventDefault();
    setComponentError(null);
    if(newPassword === confirmPassword){
      if(newPassword.length >= 8){
        let email = localStorage.getItem('userEmailForOtp');
        let resetObj = {
            email, newPassword, confirmPassword, resetCode,
        }
        dispatch(handleResetPassword(resetObj)).then((result)=>{
          if(result.payload){
            setNewPassword('');
            setConfirmPassword('');
            setResetCode('');
            setTimeout(()=>{
              navigate('/login');
            },5000);
          };
        });
      }
      else{
        setComponentError('Password must be atleast 8 characters long');
      }
    }
    else{
      setComponentError('Confirm password does not match with the new password');
    }
  }

  // prevent link clicks when loading is true
  const handleLinkClick=(e)=>{
    if(loading){
      e.preventDefault();
    }
  }

  // empty the redux state messags when the component loads
  useEffect(()=>{
    dispatch(resetResetPasswordStates());
  },[])

  return (
    <form className="custom-form" onSubmit={handleResetPasswordEvent}>
      {successMsg&&(<div className="success-msg">
        {successMsg} You will be automatically redirected to the login page in 5 seconds.
      </div>)}
      <CustomTextField
        label="New Password"
        type="password"
        variant="standard"
        fullWidth
        required
        autoComplete="off"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <br />
      <br />
      <CustomTextField
        label="Confirm Password"
        type="password"
        variant="standard"
        fullWidth
        required
        autoComplete="off"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <br />
      <br />
      <CustomTextField
        label="Reset Code"
        type="text"
        variant="standard"
        fullWidth
        required
        autoComplete="off"
        value={resetCode}
        onChange={(e) => setResetCode(e.target.value)}
      />
      <button type="submit" className="login-submit-btn" disabled={loading}>
        {loading ? 'LOADING...' : 'RESET'}
      </button>
      {componentError?(
        <div className='error-msg'>{componentError}</div>
      ):(
        <>
          {error && <div className="error-msg">{error}</div>}
        </>
      )}
      <div className="forgot-password-div">
        <Link to="/login" className="forgot-password-link" onClick={handleLinkClick}>LOGIN</Link>
      </div>
    </form>
  )
}

export default ResetForm;
