import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredCampaignByDay } from "../../Store/Slices/Sidebar/Analytics/CampaignByDaySlice";
import { MetroSpinner } from "react-spinners-kit";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import noDataFound from "../../Images/noDataFound.png";

// Register the plugin to all charts:
ChartJS.register(ChartDataLabels);

const CampaignByDay = ({ token }) => {
  // redux state
  const {
    filteredCampaignByDayLoading,
    campaignByDayData,
    campaignByDayError,
  } = useSelector((state) => state.campaignByDay);

  // calculate total amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (campaignByDayData && campaignByDayData.values.length > 0) {
      const calculatedAmounts = campaignByDayData.values.map((item) => {
        const { amounts } = item;
        return amounts.reduce((acc, curr) => acc + curr, 0);
      });

      const totalAmount = calculatedAmounts.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const roundedSum = totalAmount.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [campaignByDayData]);

  // month options array
  const monthOptions = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];

  // get month label
  const getMonthLabel = (monthValue) => {
    const monthObj = monthOptions.find((option) => option.value === monthValue);
    return monthObj ? monthObj.label : "";
  };

  // current date
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = currentDate.getFullYear();

  // states
  const [month, setMonth] = useState({
    value: currentMonth,
    label: getMonthLabel(currentMonth),
  });
  const [year, setYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [selectedYear, setSelectedYear] = useState(year);
  const [popup, setPopup] = useState(false);

  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // parse data for line chart
  const parseData = (data) => {
    // Get the unique days across all campaigns
    const allDays = data.reduce((days, item) => {
      item.days.forEach((day) => {
        if (!days.includes(day)) {
          days.push(day);
        }
      });
      return days;
    }, []);

    // Sort the days in ascending order
    const sortedDays = allDays.sort((a, b) => a - b);

    // Create datasets for each campaign
    const datasets = data.map((item, index) => ({
      label: item.campaign,
      data: sortedDays.map((day) => {
        if (item.days.includes(day)) {
          const amountIndex = item.days.indexOf(day);
          return item.amounts[amountIndex];
        } else {
          return 0;
        }
      }),
      backgroundColor: generateBackgroundColors(data.length)[index],
      borderColor: generateBorderColors(data.length)[index],
      lineTension: 0.4,
      borderWidth: 2,
    }));

    return {
      labels: sortedDays,
      datasets: datasets,
    };
  };

  // genearte border colors
  function generateBorderColors(length) {
    const colors = [];

    // Define the colors in the gradient
    const gradientColors = [
      "#34495e",
      "#9b59b6",
      "#3498db",
      "#62cb31",
      "#ffb606",
      "#e67e22",
      "#e74c3c",
      "#c0392b",
      "#175935",
      "#1f9d61",
      "#cc2473",
      "#d828c9",
      "#453095",
      "#9100ff",
      "#884a39",
      "#c38154",
    ];

    for (let i = 0; i < length; i++) {
      const index = i % gradientColors.length;
      colors.push(gradientColors[index]);
    }

    return colors;
  }

  // genearte background colors
  function generateBackgroundColors(length) {
    const colors = [];

    // Define the colors in the gradient
    const gradientColors = [
      "#a6b2bf",
      "#e9d7f0",
      "#bce4fc",
      "#c4f2ad",
      "#ffe78a",
      "#f8b763",
      "#f69b8b",
      "#d8655a",
      "#47b597",
      "#60d898",
      "#e868a7",
      "#ea61d9",
      "#7469b8",
      "#ae62ff",
      "#b9816a",
      "#ddb792",
    ];

    for (let i = 0; i < length; i++) {
      const index = i % gradientColors.length;
      colors.push(gradientColors[index]);
    }

    return colors;
  }

  // setting up chart data
  const formattedData = parseData(campaignByDayData?.values ?? []);

  // custom styles
  const styles = {
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#e1e1e9",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#b1b1b9",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
      boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
      "&:hover": {
        border: "1px solid #007C16",
        boxShadow: "0px 0px 1px #007C16",
      },
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#007C16"
        : isFocused
        ? "rgba(0, 124, 22, 0.2)"
        : base.backgroundColor,
      color: isSelected ? "white" : base.color,
      "&:active": {
        backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
      },
    }),
  };

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle filter
  const handleMonthAndYearFilter = () => {
    setPopup(false);
    setSelectedMonth(month);
    setSelectedYear(year);
    const singleObj = {
      token,
      month: month.value,
      year,
    };
    dispatch(getFilteredCampaignByDay(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  // hide legends on 768 and lesser screens
  const [screenReaches768, setScreenReaches768] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setScreenReaches768(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="text-and-filter-div">
        <span>
          Donations in {selectedMonth.label} {selectedYear}:{" "}
          <span style={{ color: "#007C16" }}>
            {campaignByDayData && <>{campaignByDayData.currencySymbol}</>}
            {sum}
          </span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Month and Year</h4>
          <div className="month-summary-filter-form">
            <div className="filter-form-col">
              <label>Month</label>
              <Select
                value={monthOptions.find(
                  (option) => option.value === month.value
                )}
                onChange={(option) => setMonth(option)}
                options={monthOptions}
                styles={styles}
              />
            </div>
            <div className="filter-form-col">
              <label>Year</label>
              <Select
                value={yearOptions.find((option) => option.value === year)}
                onChange={(option) => setYear(option.value)}
                options={yearOptions}
                styles={styles}
              />
            </div>
            <div className="popup-filter-btn-div">
              <button type="button" onClick={handleMonthAndYearFilter}>
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="graph-container">
        {filteredCampaignByDayLoading ? (
          <MetroSpinner
            size={30}
            color="#007C16"
            loading={filteredCampaignByDayLoading}
          />
        ) : (
          <>
            {campaignByDayError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {campaignByDayError}
              </div>
            ) : (
              <>
                {formattedData.datasets.length > 0 ? (
                  <Line
                    data={formattedData}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            callback: (value) => {
                              if (value >= 1000) {
                                return value
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              } else {
                                return value.toString();
                              }
                            },
                          },
                        },
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: screenReaches768,
                          position: "bottom",
                          align: "center",
                          labels: {
                            usePointStyle: true,
                            padding: 15,
                          },
                        },
                        tooltip: {
                          backgroundColor: "#fff",
                          titleColor: "#000",
                          bodyColor: "#000",
                          callbacks: {
                            label: (context) =>
                              `${campaignByDayData.currencySymbol}` +
                              context.formattedValue,
                          },
                        },
                        datalabels: {
                          display: false,
                        },
                      },
                    }}
                  />
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Data Found">
                      <img src={noDataFound} alt="No Data Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CampaignByDay;
