import React from "react";
import Tippy from "@tippyjs/react";

const AppAndPlayStore = () => {
  return (
    <div className="store-container">
      <div className="store">
        <Tippy
          content={
            <img
              src={"https://donorapi.mervice.ca/public/applestore.png"}
              alt="app-store-qrcode"
            />
          }
          zIndex={100001}
          placement="top-end"
          maxWidth={100 + "%"}
          interactive
        >
          <a
            href="https://apps.apple.com/ca/app/donor-choice-manager/id6505115869"
            target="blank"
          >
            <img
              src="https://donorapi.mervice.ca/public/applestorelogo.svg"
              alt="app-store"
            />
          </a>
        </Tippy>
      </div>
      <div className="store">
        <Tippy
          content={
            <img
              src={"https://donorapi.mervice.ca/public/playstore.png"}
              alt="play-store-qrcode"
            />
          }
          zIndex={100001}
          placement="top-end"
          maxWidth={100 + "%"}
          interactive
        >
          <a
            href="https://play.google.com/store/apps/details?id=ca.donorchoice.crm"
            target="blank"
          >
            <img
              src="https://donorapi.mervice.ca/public/playstorelogo.png"
              alt="play-store"
            />
          </a>
        </Tippy>
      </div>
    </div>
  );
};

export default AppAndPlayStore;
