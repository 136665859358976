import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../../config";

// get recurring summary
export const getRecurringSummaryData = createAsyncThunk(
  "recurringPayments/getRecurringSummaryData",
  async (token) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/recurringSummary`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// get all data
export const getAllData = createAsyncThunk(
  "recurringPayments/getAllData",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/recurring?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter records
export const filterRecurringRecords = createAsyncThunk(
  "recurringPayments/filterRecurringRecords",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "amount",
      "card_number",
      "campaign_tag",
      "email",
      "invoice",
      "account_id",
      "status",
      "billing_period",
      "notes",
      "created_by",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/recurring?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// get declined recurring payments list view
export const readDeclinedTransactions = createAsyncThunk(
  "recurringPayments/readDeclinedTransactions",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/recurringDeclined?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return {
      data: response.data.declinedRecurring,
      links: response.links,
      currencySymbol: response.data.currencySymbol,
    };
  }
);

// filter list view records
export const filterDeclinedRecords = createAsyncThunk(
  "recurringPayments/filterDeclinedRecords",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "amount",
      "campaign_tag",
      "account_id",
      "invoice",
      "email",
      "card_number",
      "cardholder_name",
      "transaction_date",
      "message",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/recurringDeclined?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// update card
export const updateCard = createAsyncThunk(
  "recurringPayments/updateCard",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        recurringTag: singleObj.tagNumber,
        cardHolderName: singleObj.cardHolderName,
        cardNumber: singleObj.cardNumber,
        expiryMonth: singleObj.expiryMonth,
        expiryYear: singleObj.expiryYear,
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/recurring/updateCard`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// change amount
export const changeAmount = createAsyncThunk(
  "recurringPayments/changeAmount",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        recurringTag: singleObj.tagNumber,
        amount: singleObj.amount,
        type: "update",
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/recurring?status=active`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// close recurring
export const closeRecurring = createAsyncThunk(
  "recurringPayments/closeRecurring",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        recurringTag: singleObj.tagNumber,
        type: "close",
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/recurring`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// switch to active
export const switchToActive = createAsyncThunk(
  "recurringPayments/switchToActive",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        recurringTag: singleObj.recurringTag,
        type: "active",
        processBackPayments: singleObj.processBackPayments,
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/switchRecurringStatus`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        recurringTag: singleObj.recurringTag,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        recurringTag: singleObj.recurringTag,
      };
    }
  }
);

// switch to hold
export const switchToHold = createAsyncThunk(
  "recurringPayments/switchToHold",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        recurringTag: singleObj.recurringTag,
        type: "hold",
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/switchRecurringStatus`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        recurringTag: singleObj.recurringTag,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        recurringTag: singleObj.recurringTag,
      };
    }
  }
);

// add new Recurring object
export const addNewRecurring = createAsyncThunk(
  "recurringPayments/addNewRecurring",
  async (singleObj) => {
    try {
      const data = {
        email: singleObj.email,
        campaignTag: singleObj.campaignTag,
        amount: singleObj.amount,
        source: singleObj.source,
        cardHolderName: singleObj.cardHolderName,
        cardNumber: singleObj.cardNumber,
        expiryYear: singleObj.expiryYear,
        expiryMonth: singleObj.expiryMonth,
        billingPeriod: singleObj.billingPeriod,
        noOfRecurring: singleObj.noOfRecurrings,
        fee: singleObj.fee,
      };
      if (singleObj.userNotes) {
        data.userNotes = singleObj.userNotes;
      }
      if (singleObj.startDate) {
        data.startDate = singleObj.startDate;
      }
      if (singleObj.source === "WALLET") {
        data.cardId = singleObj.cardId;
        data.function = singleObj.function;
      } else if (singleObj.source === "CARD") {
        data.ccv = singleObj.cvc;
        data.saveCard = singleObj.saveCard;
      }

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/transaction/cardTransaction`,
        data,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
      };
    }
  }
);

const recurringPaymentSlice = createSlice({
  name: "recurringPayments",
  initialState: {
    // recurring summary
    recurringSummaryLoading: false,
    recurringSummaryData: null,
    recurringSummaryError: null,
    // all data
    recurringPaymentsLoading: false,
    recurringPaymentsData: null,
    recurringPaymentsError: null,
    // declined recurrings list view
    declinedRecurringsLoading: false,
    declinedRecurringsData: null,
    declinedRecurringsError: null,
    // update card
    updateCardLoading: false,
    updateCardData: null,
    // change amount
    changeAmountLoading: false,
    changeAmountData: null,
    // close recurring
    closeRecurringLoading: false,
    closeRecurringData: null,
    // switch to active
    switchToActiveLoading: false,
    switchToActiveData: null,
    // switch to hold
    switchToHoldLoading: false,
    switchToHoldData: null,
    // card transaction / new recurring
    newRecurringLoading: false,
    newRecurringData: null,
  },
  reducers: {
    clearPrevRecurringTransactionData: (state) => {
      state.newRecurringData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get recurring summary
      .addCase(getRecurringSummaryData.pending, (state) => {
        state.recurringSummaryLoading = true;
        state.recurringSummaryData = null;
        state.recurringSummaryError = null;
      })
      .addCase(getRecurringSummaryData.fulfilled, (state, action) => {
        state.recurringSummaryLoading = false;
        state.recurringSummaryData = action.payload;
        state.recurringSummaryError = null;
      })
      .addCase(getRecurringSummaryData.rejected, (state, action) => {
        state.recurringSummaryLoading = false;
        state.recurringSummaryData = null;
        state.recurringSummaryError = action.error.message;
      })
      // get all data
      .addCase(getAllData.pending, (state) => {
        state.recurringPaymentsLoading = true;
        state.recurringPaymentsData = null;
        state.recurringPaymentsError = null;
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.recurringPaymentsLoading = false;
        state.recurringPaymentsData = action.payload;
        state.recurringPaymentsError = null;
      })
      .addCase(getAllData.rejected, (state, action) => {
        state.recurringPaymentsLoading = false;
        state.recurringPaymentsData = null;
        state.recurringPaymentsError = action.error.message;
      })
      // filter recurring records
      .addCase(filterRecurringRecords.pending, (state) => {
        state.recurringPaymentsLoading = true;
        state.recurringPaymentsData = null;
        state.recurringPaymentsError = null;
      })
      .addCase(filterRecurringRecords.fulfilled, (state, action) => {
        state.recurringPaymentsLoading = false;
        state.recurringPaymentsData = action.payload;
        state.recurringPaymentsError = null;
      })
      .addCase(filterRecurringRecords.rejected, (state, action) => {
        state.recurringPaymentsLoading = false;
        state.recurringPaymentsData = null;
        state.recurringPaymentsError = action.error.message;
      })
      // read declined transactions
      .addCase(readDeclinedTransactions.pending, (state) => {
        state.declinedRecurringsLoading = true;
        state.declinedRecurringsData = null;
        state.declinedRecurringsError = null;
      })
      .addCase(readDeclinedTransactions.fulfilled, (state, action) => {
        state.declinedRecurringsLoading = false;
        state.declinedRecurringsData = action.payload;
        state.declinedRecurringsError = null;
      })
      .addCase(readDeclinedTransactions.rejected, (state, action) => {
        state.declinedRecurringsLoading = false;
        state.declinedRecurringsData = null;
        state.declinedRecurringsError = action.error.message;
      })
      // filter declined recurrings records
      .addCase(filterDeclinedRecords.pending, (state) => {
        state.declinedRecurringsLoading = true;
        state.declinedRecurringsData = null;
        state.declinedRecurringsError = null;
      })
      .addCase(filterDeclinedRecords.fulfilled, (state, action) => {
        state.declinedRecurringsLoading = false;
        state.declinedRecurringsData = action.payload;
        state.declinedRecurringsError = null;
      })
      .addCase(filterDeclinedRecords.rejected, (state, action) => {
        state.declinedRecurringsLoading = false;
        state.declinedRecurringsData = null;
        state.declinedRecurringsError = action.error.message;
      })
      // update card
      .addCase(updateCard.pending, (state) => {
        state.updateCardLoading = true;
        state.updateCardData = null;
      })
      .addCase(updateCard.fulfilled, (state, action) => {
        state.updateCardLoading = false;
        state.updateCardData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const recurringIndex =
            state.recurringPaymentsData.data.recurring.findIndex(
              (recurring) => recurring.tagNumber === tagNumber
            );

          if (recurringIndex !== -1) {
            state.recurringPaymentsData.data.recurring[recurringIndex] = data;
          }
        }
      })
      // change amount
      .addCase(changeAmount.pending, (state) => {
        state.changeAmountLoading = true;
        state.changeAmountData = null;
      })
      .addCase(changeAmount.fulfilled, (state, action) => {
        state.changeAmountLoading = false;
        state.changeAmountData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const recurringIndex =
            state.recurringPaymentsData.data.recurring.findIndex(
              (recurring) => recurring.tagNumber === tagNumber
            );

          if (recurringIndex !== -1) {
            state.recurringPaymentsData.data.recurring[recurringIndex] = data;
          }
        }
      })
      // close Recurring
      .addCase(closeRecurring.pending, (state) => {
        state.closeRecurringLoading = true;
        state.closeRecurringData = null;
      })
      .addCase(closeRecurring.fulfilled, (state, action) => {
        state.closeRecurringLoading = false;
        state.closeRecurringData = action.payload;
        const { data, tagNumber } = action.payload;

        if (data) {
          const recurringIndex =
            state.recurringPaymentsData.data.recurring.findIndex(
              (recurring) => recurring.tagNumber === tagNumber
            );

          if (recurringIndex !== -1) {
            state.recurringPaymentsData.data.recurring[recurringIndex] = data;
          }
        }
      })
      // switch to active
      .addCase(switchToActive.pending, (state) => {
        state.switchToActiveLoading = true;
        state.switchToActiveData = null;
      })
      .addCase(switchToActive.fulfilled, (state, action) => {
        state.switchToActiveLoading = false;
        state.switchToActiveData = action.payload;
        const { data, recurringTag } = action.payload;

        if (data) {
          const recurringIndex =
            state.recurringPaymentsData.data.recurring.findIndex(
              (recurring) => recurring.tagNumber === recurringTag
            );

          if (recurringIndex !== -1) {
            state.recurringPaymentsData.data.recurring[recurringIndex] = data;
          }
        }
      })
      // switch to hold
      .addCase(switchToHold.pending, (state) => {
        state.switchToHoldLoading = true;
        state.switchToHoldData = null;
      })
      .addCase(switchToHold.fulfilled, (state, action) => {
        state.switchToHoldLoading = false;
        state.switchToHoldData = action.payload;
        const { data, recurringTag } = action.payload;

        if (data) {
          const recurringIndex =
            state.recurringPaymentsData.data.recurring.findIndex(
              (recurring) => recurring.tagNumber === recurringTag
            );

          if (recurringIndex !== -1) {
            state.recurringPaymentsData.data.recurring[recurringIndex] = data;
          }
        }
      })
      // add card transaction
      .addCase(addNewRecurring.pending, (state) => {
        state.newRecurringLoading = true;
        state.newRecurringData = null;
      })
      .addCase(addNewRecurring.fulfilled, (state, action) => {
        state.newRecurringLoading = false;
        state.newRecurringData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.recurringPaymentsData.data.recurring.unshift(
            data.recurringPayment
          );
        }
      });
  },
});

export default recurringPaymentSlice.reducer;
export const { clearPrevRecurringTransactionData } =
  recurringPaymentSlice.actions;
