import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredYearDonations } from "../../Store/Slices/Sidebar/Dashboard/CurrentYearDonationsSlice";
import { MetroSpinner } from "react-spinners-kit";
import Icon from "react-icons-kit";
import { gift } from "react-icons-kit/feather/gift";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import Tippy from "@tippyjs/react";
import Select from "react-select";
import CountUp from "react-countup";
import noDataFound from "../../Images/noDataFound.png";

const CurrentYearDonations = ({ token }) => {
  // redux state
  const {
    filteredYearDonationsLoading,
    currentYearDonationsData,
    currentYearDonationsError,
  } = useSelector((state) => state.currentYearDonations);

  // formattion of data
  const formattedDonation =
    currentYearDonationsData !== null
      ? parseFloat(currentYearDonationsData.donation.replace(/,/g, ""))
      : 0;

  // popup state
  const [popup, setPopup] = useState(false);

  // year state
  const [year, setYear] = useState(new Date().getFullYear().toString()); // current year

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle year change
  const handleYearChange = (value) => {
    if (value !== year) {
      setPopup(false);
      setYear(value);
      const singleObj = {
        token,
        year: value,
      };
      dispatch(getFilteredYearDonations(singleObj))
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    }
  };

  // custom styles
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#e1e1e9",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#b1b1b9",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
      boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
      "&:hover": {
        border: "1px solid #007C16",
        boxShadow: "0px 0px 1px #007C16",
      },
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#007C16"
        : isFocused
        ? "rgba(0, 124, 22, 0.2)"
        : base.backgroundColor,
      color: isSelected ? "white" : base.color,
      "&:active": {
        backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
      },
    }),
  };

  return (
    <div className="col-box">
      <div className="text-and-filter-div">
        <span style={{ color: "#007C16" }}>{year}</span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Year</h4>
          <Select
            value={yearOptions.find((option) => option.value === year)}
            onChange={(option) => handleYearChange(option.value)}
            options={yearOptions}
            styles={styles}
          />
        </div>
      </div>
      <div className="icon-with-text">
        <span>Donations</span>
        <Icon icon={gift} size={36} />
      </div>
      {filteredYearDonationsLoading ? (
        <MetroSpinner
          size={30}
          color="#007C16"
          loading={filteredYearDonationsLoading}
        />
      ) : (
        <>
          {currentYearDonationsError ? (
            <div className="error-msg" style={{ marginTop: 0 }}>
              {currentYearDonationsError}
            </div>
          ) : (
            <>
              {currentYearDonationsData !== null ? (
                <CountUp
                  className="h1"
                  start={0}
                  end={formattedDonation}
                  duration={2.5}
                  decimals={2}
                  decimal="."
                  prefix={currentYearDonationsData.currencySymbol + " "}
                  suffix=""
                />
              ) : (
                <div className="no-data-found-div">
                  <Tippy content="No Data Found">
                    <img src={noDataFound} alt="No Data Found" />
                  </Tippy>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CurrentYearDonations;
