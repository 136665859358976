import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../config";
import axios from "axios";

export const handleForgotPassword = createAsyncThunk(
  "forgotPassword/handleForgotPassword",
  async (email) => {
    const request = await axios.put(
      `${hostName}api/admin/v1/auth/forgetPassword`,
      { email }
    );
    const result = await request.data.message;
    localStorage.setItem("userEmailForOtp", email);
    return result;
  }
);

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: {
    loading: false,
    successMessage: null,
    error: null,
  },
  reducers: {
    resetForgotPasswordStates: () => {
      return {
        loading: false,
        successMessage: null,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleForgotPassword.pending, (state) => {
        state.loading = true;
        state.successMessage = null;
        state.error = null;
      })
      .addCase(handleForgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload;
        state.error = null;
      })
      .addCase(handleForgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.successMessage = null;
        localStorage.removeItem("userEmailForOtp");
        if (action.error.message === "Request failed with status code 400") {
          state.error = "No account is associated with the given email address";
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export default forgotPasswordSlice.reducer;
export const { resetForgotPasswordStates } = forgotPasswordSlice.actions;
