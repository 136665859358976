import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import ReactPlayer from "react-player/youtube";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const VideoModal = ({ setVideoModal, videoObj }) => {
  // close modal
  const handleCloseModal = () => {
    setVideoModal(false);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>{videoObj.name}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            {videoObj.mediaType === "image" && (
              <div style={{ width: 100 + "%", height: "auto" }}>
                <img
                  src={videoObj.path}
                  alt={videoObj.name}
                  style={{ width: 100 + "%", height: 100 + "%" }}
                />
              </div>
            )}
            {videoObj.mediaType === "video" && (
              <ReactPlayer
                url={videoObj.path}
                controls
                width="100%"
                height="auto"
              />
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default VideoModal;
