import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// get media --- array of objects
export const getMedia = createAsyncThunk(
  "media/getMedia",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/media?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter media records
export const filterMediaRecords = createAsyncThunk(
  "media/filterMediaRecords",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "name",
      "description",
      "url",
      "media_type",
      "status",
      "is_muted",
      "fullscreen",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/media?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// update media object
export const updateMedia = createAsyncThunk(
  "media/updateMedia",
  async (singleObj) => {
    try {
      const formData = new FormData();
      formData.append("name", singleObj.name);
      formData.append("description", singleObj.description);
      formData.append("duration", singleObj.duration);
      formData.append("sortOrder", singleObj.sortOrder);
      formData.append("fullScreen", singleObj.fullScreen);
      formData.append("status", singleObj.status);
      formData.append("isMuted", singleObj.isMuted);
      if (singleObj.mediaFile) {
        formData.append("mediaFile", singleObj.mediaFile);
      }

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
          "Content-Type": "multipart/form-data", // Set the Content-Type header to 'multipart/form-data'
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/media/${singleObj.tagNumber}?_method=PUT`,
        formData,
        axiosConfig
      );
      const response = await request.data;

      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// delete media
export const deleteMedia = createAsyncThunk(
  "media/deleteMedia",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/media/${singleObj.tagNumber}`,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// add media object
export const addMedia = createAsyncThunk(
  "media/addMedia",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
          "Content-Type": "multipart/form-data", // Set the Content-Type header to 'multipart/form-data'
        },
      };

      const formData = new FormData();
      formData.append("name", singleObj.name);
      formData.append("description", singleObj.description);
      formData.append("duration", singleObj.duration);
      formData.append("sortOrder", singleObj.sortOrder);
      formData.append("fullScreen", singleObj.fullScreen);
      formData.append("status", singleObj.status);
      formData.append("isMuted", singleObj.isMuted);
      formData.append("mediaFile", singleObj.mediaFile);

      const request = await axios.post(
        `${hostName}api/admin/v1/media`,
        formData,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
      };
    }
  }
);

const getMediaSlice = createSlice({
  name: "media",
  initialState: {
    mediaLoading: false,
    mediaData: null,
    mediaError: null,
    updateMediaLoading: false,
    updateMediaData: null,
    deleteMediaLoading: false,
    deleteMediaData: null,
    addMediaLoading: false,
    addMediaData: null,
  },
  extraReducers: (builder) => {
    builder
      // get media
      .addCase(getMedia.pending, (state) => {
        state.mediaLoading = true;
        state.mediaData = null;
        state.mediaError = null;
      })
      .addCase(getMedia.fulfilled, (state, action) => {
        state.mediaLoading = false;
        state.mediaData = action.payload;
        state.mediaError = null;
      })
      .addCase(getMedia.rejected, (state, action) => {
        state.mediaLoading = false;
        state.mediaData = null;
        state.mediaError = action.error.message;
      })
      // filter media records
      .addCase(filterMediaRecords.pending, (state) => {
        state.mediaLoading = true;
        state.mediaData = null;
        state.mediaError = null;
      })
      .addCase(filterMediaRecords.fulfilled, (state, action) => {
        state.mediaLoading = false;
        state.mediaData = action.payload;
        state.mediaError = null;
      })
      .addCase(filterMediaRecords.rejected, (state, action) => {
        state.mediaLoading = false;
        state.mediaData = null;
        state.mediaError = action.error.message;
      })
      // update media
      .addCase(updateMedia.pending, (state) => {
        state.updateMediaLoading = true;
        state.updateMediaData = null;
      })
      .addCase(updateMedia.fulfilled, (state, action) => {
        state.updateMediaLoading = false;
        state.updateMediaData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const mediaIndex = state.mediaData.data.findIndex(
            (media) => media.tagNumber === tagNumber
          );
          if (mediaIndex !== -1) {
            state.mediaData.data[mediaIndex] = data;
          }
        }
      })
      // delete media
      .addCase(deleteMedia.pending, (state) => {
        state.deleteMediaLoading = true;
        state.deleteMediaData = null;
      })
      .addCase(deleteMedia.fulfilled, (state, action) => {
        state.deleteMediaLoading = false;
        state.deleteMediaData = action.payload;
        const { axiosError, tagNumber } = action.payload;
        if (!axiosError) {
          state.mediaData.data = state.mediaData.data.filter(
            (data) => data.tagNumber !== tagNumber
          );
        }
      })
      // add media
      .addCase(addMedia.pending, (state) => {
        state.addMediaLoading = true;
        state.addMediaData = null;
      })
      .addCase(addMedia.fulfilled, (state, action) => {
        state.addMediaLoading = false;
        state.addMediaData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.mediaData.data.unshift(data);
        }
      });
  },
});

export default getMediaSlice.reducer;
