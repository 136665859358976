import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { disconnectIntegration } from "../../Store/Slices/Sidebar/AppExplorer/AppExplorerSlice";
import { MetroSpinner } from "react-spinners-kit";
import { toast } from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const DisconnectPopup = ({ token, setDisconnectPopup, disconnectObj }) => {
  // redux state
  const { disconnectIntegrationLoading, disconnectIntegrationData } =
    useSelector((state) => state.appExplorer);

  // close modal
  const handleCloseModal = () => {
    if (!disconnectIntegrationLoading) {
      setDisconnectPopup(false);
    }
  };

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // disconnect integration
  const handleDisconnectIntegration = () => {
    dispatch(
      disconnectIntegration({
        token,
        organizationIntegrationTag: disconnectObj.organizationIntegrationTag,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setDisconnectPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Disconnect Integration - {disconnectObj.applicationName}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="delete-consequences-container">
              <p style={{ color: "#DC3545" }}>
                Are you sure you want to delete this integration?
              </p>
              <p style={{ color: "#6a6c6f", lineHeight: "27px" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    background: "#DC3545",
                    color: "#fff",
                    padding: "4px",
                    marginRight: "8px",
                  }}
                >
                  Please note:
                </span>
                Deleting this integration will also remove all information
                shared with other organizations that is associated with this
                integration. This action cannot be undone.
              </p>
            </div>

            {disconnectIntegrationData &&
              disconnectIntegrationData.organizationIntegrationTag ===
                disconnectObj.organizationIntegrationTag &&
              (disconnectIntegrationData.apiError ||
                disconnectIntegrationData.axiosError) && (
                <div className="error-msg">
                  {disconnectIntegrationData.apiError ? (
                    <>
                      {typeof disconnectIntegrationData.apiError ===
                      "object" ? (
                        // Case 1: If apiError is an object
                        Object.keys(disconnectIntegrationData.apiError).map(
                          (field, index) => (
                            <p key={index} style={{ marginBottom: 0 }}>
                              {disconnectIntegrationData.apiError[field][0]}
                            </p>
                          )
                        )
                      ) : (
                        // Case 2: If apiError is a simple string
                        <>{disconnectIntegrationData.apiError}</>
                      )}
                    </>
                  ) : (
                    <>{disconnectIntegrationData.axiosError}</>
                  )}
                </div>
              )}

            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CANCEL
              </button>
              <button
                type="button"
                className="submit delete"
                disabled={disconnectIntegrationLoading}
                onClick={handleDisconnectIntegration}
              >
                {disconnectIntegrationLoading ? (
                  <MetroSpinner
                    loading={disconnectIntegrationLoading}
                    size={22}
                    color="#fff"
                  />
                ) : (
                  "DISCONNECT"
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DisconnectPopup;
