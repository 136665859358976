import React from "react";
import { useSelector } from "react-redux";
import Icon from "react-icons-kit";
import { users } from "react-icons-kit/feather/users";
import CountUp from "react-countup";
import noDataFound from "../../Images/noDataFound.png";
import Tippy from "@tippyjs/react";

const RegisteredDonors = () => {
  // redux state
  const { registeredDonorsData, registeredDonorsError } = useSelector(
    (state) => state.registeredDonors
  );

  return (
    <div className="row-box left-most">
      <div className="icon-with-text">
        <span>Registered Donors</span>
        <Icon icon={users} size={36} />
      </div>
      {registeredDonorsError ? (
        <div className="error-msg" style={{ marginTop: 0 }}>
          {registeredDonorsError}
        </div>
      ) : (
        <>
          {registeredDonorsData !== null ? (
            <CountUp
              className="h1"
              start={0}
              end={registeredDonorsData}
              duration={2.5}
              decimals={0}
              decimal="."
              prefix=""
              suffix=""
            />
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Data Found">
                <img src={noDataFound} alt="No Data Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RegisteredDonors;
