import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

export const getActiveRecurring = createAsyncThunk(
  "activeRecurring/getActiveRecurring",
  async (adminToken) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${adminToken}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/activeRecurring`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const activeRecurringSlice = createSlice({
  name: "activeRecurring",
  initialState: {
    activeRecurringLoading: false,
    activeRecurringData: null,
    activeRecurringError: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveRecurring.pending, (state) => {
        state.activeRecurringLoading = true;
        state.activeRecurringData = null;
        state.activeRecurringError = null;
      })
      .addCase(getActiveRecurring.fulfilled, (state, action) => {
        state.activeRecurringLoading = false;
        state.activeRecurringData = action.payload;
        state.activeRecurringError = null;
      })
      .addCase(getActiveRecurring.rejected, (state, action) => {
        state.activeRecurringLoading = false;
        state.activeRecurringData = null;
        state.activeRecurringError = action.error.message;
      });
  },
});

export default activeRecurringSlice.reducer;
