import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// get widgets
export const getWidgets = createAsyncThunk(
  "widgets/getWidgets",
  async (token) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/widget`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// update widget
export const updateWidget = createAsyncThunk(
  "widget/updateWidget",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestData = {
        status: singleObj.status,
        widgetValue: singleObj.widgetValue,
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/widget/${singleObj.tagNumber}`,
        requestData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

const getWidgetSlice = createSlice({
  name: "widget",
  initialState: {
    widgetsLoading: false,
    widgetsData: null,
    widgetsError: null,
    updateWidgetLoading: false,
    updateWidgetData: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWidgets.pending, (state) => {
        state.widgetsLoading = true;
        state.widgetsData = null;
        state.widgetsError = null;
      })
      .addCase(getWidgets.fulfilled, (state, action) => {
        state.widgetsLoading = false;
        state.widgetsData = action.payload;
        state.widgetsError = null;
      })
      .addCase(getWidgets.rejected, (state, action) => {
        state.widgetsLoading = false;
        state.widgetsData = null;
        state.widgetsError = action.error.message;
      })
      .addCase(updateWidget.pending, (state) => {
        state.updateWidgetLoading = true;
        state.updateWidgetData = null;
      })
      .addCase(updateWidget.fulfilled, (state, action) => {
        state.updateWidgetLoading = false;
        state.updateWidgetData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const widgetIndex = state.widgetsData.findIndex(
            (widget) => widget.tagNumber === tagNumber
          );
          if (widgetIndex !== -1) {
            state.widgetsData[widgetIndex] = data;
          }
        }
      });
  },
});

export default getWidgetSlice.reducer;
