/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// css
import "../../CSS/Sidebar/Dashboard.css";
// react spinners kit
import { MetroSpinner } from "react-spinners-kit";
// react router dom
import { useNavigate } from "react-router-dom";
// redux useDispatch, useSelector
import { useDispatch, useSelector } from "react-redux";
// redux row 1 actions
import { getRegisteredDonors } from "../../Store/Slices/Sidebar/Dashboard/RegisteredDonorsSlice";
import { getFollowers } from "../../Store/Slices/Sidebar/Dashboard/NoOfFollowersSlice";
import { getTotalTransactions } from "../../Store/Slices/Sidebar/Dashboard/TotalTransactionsSlice";
import { getCurrentYearTransactions } from "../../Store/Slices/Sidebar/Dashboard/CurrentYearTransactionsSlice";
// redux row 2 actions
import { getLast12MonthsData } from "../../Store/Slices/Sidebar/Dashboard/Last12MonthsDataSlice";
import { getCurrentYearDonations } from "../../Store/Slices/Sidebar/Dashboard/CurrentYearDonationsSlice";
import { getTotalDonations } from "../../Store/Slices/Sidebar/Dashboard/TotalDonationsSlice";
import { getTotalRecurringAmount } from "../../Store/Slices/Sidebar/Dashboard/TotalRecurringAmountSlice";
import { getActiveRecurring } from "../../Store/Slices/Sidebar/Dashboard/ActiveRecurringSlice";
// redux row 3 action
import { getMonthSummary } from "../../Store/Slices/Sidebar/Dashboard/MonthSummarySlice";
import { getDaySummaryByCampaign } from "../../Store/Slices/Sidebar/Dashboard/DaySummaryByCampaignSlice";
// redux row 4 actions
import { getCampaignSummary } from "../../Store/Slices/Sidebar/Dashboard/CampaignSummarySlice";
// redux row 5 actions
import { getRecurringDetails } from "../../Store/Slices/Sidebar/Dashboard/RecurringDetailsSlice";
// import { getClosedRecurring } from "../../Store/Slices/Sidebar/Dashboard/ClosedRecurringSlice";
import { getNodes } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";

// dashboard row 1 components
import RegisteredDonors from "../Dashboard/RegisteredDonors";
import NoOfFollowers from "../Dashboard/NoOfFollowers";
import CurrentYearTransactions from "../Dashboard/CurrentYearTransactions";
import TotalTransactions from "../Dashboard/TotalTransactions";
// dashboard row 2 components
import Last12MonthsData from "../Dashboard/Last12MonthsData";
import CurrentYearDonations from "../Dashboard/CurrentYearDonations";
import TotalDonations from "../Dashboard/TotalDonations";
import TotalRecurringAmount from "../Dashboard/TotalRecurringAmount";
import ActiveRecurring from "../Dashboard/ActiveRecurring";
// dashboard row 3 components
import MonthSummary from "../Dashboard/MonthSummary";
import DaySummaryByCampaign from "../Dashboard/DaySummaryByCampaign";
// dashboard row 4 components
import CampaignTable from "../Dashboard/CampaignsTable";
// dashboard row 5 components
import RecurringDetails from "../Dashboard/RecurringDetails";
// import ClosedRecurring from "../Dashboard/ClosedRecurring";

const DashboardComponent = ({ token }) => {
  // dispatch function
  const dispatch = useDispatch();

  // navigate function
  const navigate = useNavigate();

  // current date obj
  let currentDate = new Date();

  // dispatching actions to get data
  useEffect(() => {
    // registered donors
    dispatch(getRegisteredDonors(token))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // no of followers
    dispatch(getFollowers(token))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // total transactions
    dispatch(getTotalTransactions(token))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // current year transactions
    dispatch(
      getCurrentYearTransactions({
        token,
        year: currentDate.getFullYear().toString(),
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // last 12 months data
    dispatch(getLast12MonthsData(token))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // current year donations
    dispatch(
      getCurrentYearDonations({
        token,
        year: currentDate.getFullYear().toString(),
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // total donations
    dispatch(getTotalDonations(token))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // total recurring amount
    dispatch(
      getTotalRecurringAmount({
        token,
        year: String(currentDate.getFullYear().toString()),
        month: String((currentDate.getMonth() + 1).toString().padStart(2, "0")),
        day: String(currentDate.getDate().toString().padStart(2, "0")),
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // active recurring
    dispatch(getActiveRecurring(token))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // month summary
    dispatch(
      getMonthSummary({
        token,
        month: String(currentDate.getMonth() + 1).padStart(2, "0"),
        year: String(currentDate.getFullYear()),
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // day summary by campaign
    const year = String(currentDate.getFullYear().toString());
    const month = String(
      (currentDate.getMonth() + 1).toString().padStart(2, "0")
    );
    const day = String(currentDate.getDate().toString().padStart(2, "0"));

    const formatted = `${year}-${month}-${day}`;
    dispatch(
      getDaySummaryByCampaign({
        token,
        date: formatted,
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // campaign summary
    dispatch(
      getCampaignSummary({
        token,
        checkedItems: ["Kiosk", "Handheld", "Gateway", "Manual", "External", "eCheck", "eTransfer"],
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });

    // recurring details
    dispatch(
      getRecurringDetails({
        token,
        year: String(currentDate.getFullYear().toString()),
        month: String((currentDate.getMonth() + 1).toString().padStart(2, "0")),
        day: String(currentDate.getDate().toString().padStart(2, "0")),
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // get node types
  const [checkedItemsLoading, setCheckedItemsLoading] = useState(true);
  useEffect(() => {
    dispatch(getNodes(token))
      .then(() => {
        setCheckedItemsLoading(false);
      })
      .catch((err) => {
        setCheckedItemsLoading(false);
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // redux loading states
  // row 1
  const { registeredDonorsLoading } = useSelector(
    (state) => state.registeredDonors
  );
  const { noOfFollowersLoading } = useSelector((state) => state.noOfFollowers);
  const { totalTransactionsLoading } = useSelector(
    (state) => state.totalTransactions
  );
  const { currentYearTransactionsLoading } = useSelector(
    (state) => state.currentYearTransactions
  );

  // row 2
  const { last12MonthsLoading } = useSelector(
    (state) => state.last12MonthsData
  );
  const { currentYearDonationsLoading } = useSelector(
    (state) => state.currentYearDonations
  );
  const { totalDonationsLoading } = useSelector(
    (state) => state.totalDonations
  );
  const { totalRecurringAmountLoading } = useSelector(
    (state) => state.totalRecurringAmount
  );
  const { activeRecurringLoading } = useSelector(
    (state) => state.activeRecurring
  );

  // row 3
  const { monthSummaryLoading } = useSelector((state) => state.monthSummary);
  const { daySummaryByCampaignLoading } = useSelector(
    (state) => state.daySummaryByCampaign
  );

  // row 4
  const { campaignSummaryLoading } = useSelector(
    (state) => state.campaignSummary
  );

  // row 5
  const { recurringDetailsLoading } = useSelector(
    (state) => state.recurringDetails
  );

  // state to check if any loading from redux is true
  const [loadings, setLoadings] = useState(true);

  // checking if any loading is true
  const allLoadingStates = [
    registeredDonorsLoading,
    noOfFollowersLoading,
    totalTransactionsLoading,
    currentYearTransactionsLoading,
    last12MonthsLoading,
    currentYearDonationsLoading,
    totalDonationsLoading,
    totalRecurringAmountLoading,
    activeRecurringLoading,
    monthSummaryLoading,
    daySummaryByCampaignLoading,
    campaignSummaryLoading,
    recurringDetailsLoading,
    checkedItemsLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allLoadingStates.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadingStates]);

  return (
    <div className="middle-area">
      <div
        className={
          loadings
            ? "middle-content flex-start height"
            : "middle-content flex-start"
        }
      >
        {loadings ? (
          <div className="full-loading-screen">
            <MetroSpinner size={30} color="#007C16" loading={loadings} />
          </div>
        ) : (
          <>
            <h6>Dashboard information and statistics</h6>
            {/* row 1 */}
            <div className="dashboard-row">
              <div className="row-boxes-left">
                <RegisteredDonors />
                <NoOfFollowers />
              </div>
              <div className="row-boxes-right">
                <TotalTransactions />
                <CurrentYearTransactions />
              </div>
            </div>
            {/* row 2 */}
            <div className="dashboard-row">
              <div className="dashboard-row-left">
                <Last12MonthsData />
              </div>
              <div className="dashboard-row-right">
                <div className="row-right-col">
                  <CurrentYearDonations token={token} />
                  <TotalDonations />
                </div>
                <div className="row-right-col right-most">
                  <TotalRecurringAmount token={token} />
                  <ActiveRecurring />
                </div>
              </div>
            </div>
            {/* row-3 */}
            <div className="dashboard-row">
              <div className="dashboard-row-left">
                <MonthSummary token={token} />
              </div>
              <div className="dashboard-row-right background-white">
                <DaySummaryByCampaign token={token} />
              </div>
            </div>
            {/* row 4 */}
            <div className="dashboard-row">
              <div className="dashboard-row-left full-width">
                <CampaignTable token={token} />
              </div>
            </div>
            {/* row 5 */}
            <div className="dashboard-row">
              <div className="dashboard-row-left full-width">
                <RecurringDetails token={token} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardComponent;
