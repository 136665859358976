import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import DonorRow from "./DonorRow";

const DonorsTable = ({
  handleEditDonor,
  handleDeleteDonor,
  donorsData,
  goToSpecificDonor,
  handleAddressDrawer,
  loadedImages,
}) => {
  return (
    <div className="transactions-table">
      <MDBTable align="middle">
        <MDBTableHead className="thead">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Address</th>
            <th scope="col">Phone</th>
            <th scope="col">Account Type</th>
            <th scope="col">Actions</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {donorsData.data.map((donor, index) => (
            <DonorRow
              key={donor.tagNumber}
              donor={donor}
              index={index}
              loadedImages={loadedImages}
              handleEditDonor={handleEditDonor}
              handleDeleteDonor={handleDeleteDonor}
              goToSpecificDonor={goToSpecificDonor}
              handleAddressDrawer={handleAddressDrawer}
            />
          ))}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
};

export default DonorsTable;
