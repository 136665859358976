import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { MDBCheckbox } from "mdb-react-ui-kit";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// multi select animated components
const animatedComponents = makeAnimated();

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const FilterRecurringModal = ({
  setFilterRecurringModal,
  setFilteredParams,
  setLoadings,
  handleResetFilter,
  setFilteredFlag,
  entityOptions,
  entitySelected,
  handleSelectChange,
  amount,
  handleAmountChange,
  accountId,
  setAccountId,
  email,
  setEmail,
  invoice,
  setInvoice,
  campaignTag,
  handleCampaignTagChange,
  cardNumber,
  setCardNumber,
  notes,
  setNotes,
  createdBy,
  setCreatedBy,
  billingPeriod,
  handleBillingPeriodChange,
  status,
  handleStatusChange,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
  pageSize,
  handlePerPage,
}) => {
  // redux nodes
  const { nodesData } = useSelector((state) => state.transactions);

  // close modal
  const handleCloseModal = () => {
    setFilterRecurringModal(false);
  };

  // should render fields?
  const shouldRenderAmountField = entitySelected.some(
    (option) => option.value === "amount"
  );

  const shouldRenderAccountIdField = entitySelected.some(
    (option) => option.value === "account_id"
  );

  const shouldRenderCampaignTagField = entitySelected.some(
    (option) => option.value === "campaign_tag"
  );

  const shouldRenderEmailField = entitySelected.some(
    (option) => option.value === "email"
  );

  const shouldRenderInvoiceField = entitySelected.some(
    (option) => option.value === "invoice"
  );

  const shouldRenderNotesField = entitySelected.some(
    (option) => option.value === "notes"
  );

  const shouldRenderCreatedByField = entitySelected.some(
    (option) => option.value === "created_by"
  );

  const shouldRenderBillingPeriodField = entitySelected.some(
    (option) => option.value === "billing_period"
  );

  const shouldRenderStatusField = entitySelected.some(
    (option) => option.value === "status"
  );

  const shouldRenderCardNumberField = entitySelected.some(
    (option) => option.value === "card_number"
  );

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "50",
      label: "50",
    },
  ];

  // form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Prepare the data to be sent as API parameters based on the selected options
    const apiData = {};

    // Add 'amount' to the apiData if it's selected
    if (shouldRenderAmountField) {
      apiData.amount = parseFloat(amount);
    }

    // Add 'account_id' to the apiData if it's selected
    if (shouldRenderAccountIdField) {
      apiData.account_id = accountId;
    }

    // Add 'card_number' to the apiData if it's selected
    if (shouldRenderCardNumberField) {
      apiData.card_number = cardNumber;
    }

    // Add 'campaign_tag' to the apiData if it's selected
    if (shouldRenderCampaignTagField) {
      apiData.campaign_tag = campaignTag;
    }

    // add notes
    if (shouldRenderNotesField) {
      apiData.notes = notes;
    }

    // Add 'created_by' to the apiData if it's selected
    if (shouldRenderCreatedByField) {
      apiData.created_by = createdBy;
    }

    // Add 'invoice' to the apiData if it's selected
    if (shouldRenderInvoiceField) {
      apiData.invoice = invoice;
    }

    // Add 'email' to the apiData if it's selected
    if (shouldRenderEmailField) {
      apiData.email = email;
    }

    // Add 'billing_period' to the apiData if it's selected
    if (shouldRenderBillingPeriodField) {
      apiData.billing_period = billingPeriod;
    }

    // add status
    if (shouldRenderStatusField) {
      apiData.status = status;
    }

    // sort by
    if (sortBy) {
      apiData.by = sortBy.value;
    }

    // if amount > 0
    if (shouldRenderAmountField && amount > 0) {
      setLoadings(true);
      setFilterRecurringModal(false);
      setFilteredFlag(null);
      setFilteredParams({
        apiData,
        pageSize,
        order: orderBy.value,
      });
    } else if (!shouldRenderAmountField) {
      setLoadings(true);
      setFilterRecurringModal(false);
      setFilteredFlag(null);
      setFilteredParams({
        apiData,
        pageSize,
        order: orderBy.value,
      });
    }
  };

  // status array
  const statusArray = [
    { value: "ACTIVE", label: "Active" },
    { value: "CLOSED", label: "Closed" },
    { value: "HOLD", label: "Hold" },
  ];

  // billing period array
  const billingPeriodOptions = [
    { value: "DAILY", label: "DAILY" },
    { value: "WEEKLY", label: "WEEKLY" },
    { value: "BIWEEKLY", label: "BIWEEKLY" },
    { value: "MONTHLY", label: "MONTHLY" },
  ];

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Filter Recurring Payments</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <h6
              style={{
                borderBottom: 1 + "px solid #eaeaea",
                paddingBottom: 5 + "px",
              }}
            >
              Select Fields
            </h6>
            {entityOptions && (
              <>
                <Select
                  isMulti
                  components={animatedComponents}
                  styles={styles}
                  options={entityOptions}
                  onChange={handleSelectChange}
                  value={entitySelected}
                />
                <form
                  style={{ marginTop: 10 + "px" }}
                  className="update-media-form"
                  onSubmit={handleFormSubmit}
                >
                  {/* amount */}
                  {shouldRenderAmountField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "amount"
                          )?.label
                        }
                      </label>
                      <CustomTextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        size="small"
                        value={amount}
                        onChange={(e) => handleAmountChange(e)}
                        inputMode="decimal"
                        step="0.01"
                        title=""
                        required
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "amount"
                          )?.label
                        }
                        helperText={
                          amount !== "" && amount <= 0
                            ? "Value must be greater than 0"
                            : ""
                        }
                        error={amount !== "" && amount <= 0}
                      />
                    </div>
                  )}
                  {/* account_id */}
                  {shouldRenderAccountIdField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "account_id"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "account_id"
                          )?.label
                        }
                        required
                        value={accountId}
                        onChange={(e) => setAccountId(e.target.value)}
                      />
                    </div>
                  )}
                  {/* campaign_tag */}
                  {shouldRenderCampaignTagField && nodesData && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "campaign_tag"
                          )?.label
                        }
                      </label>
                      <div className="checkbox-container">
                        {nodesData.campaign_tag.map((item) => (
                          <div className="checkbox-div" key={item.value}>
                            <MDBCheckbox
                              label={item.label}
                              checked={campaignTag.includes(item.value)}
                              onChange={() =>
                                handleCampaignTagChange(item.value)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* card_number */}
                  {shouldRenderCardNumberField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "card_number"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "card_number"
                          )?.label
                        }
                        required
                        value={cardNumber}
                        onChange={(e) => setCardNumber(e.target.value)}
                      />
                    </div>
                  )}
                  {/* email */}
                  {shouldRenderEmailField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "email"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "email"
                          )?.label
                        }
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  )}
                  {/* invoice */}
                  {shouldRenderInvoiceField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "invoice"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "invoice"
                          )?.label
                        }
                        required
                        value={invoice}
                        onChange={(e) => setInvoice(e.target.value)}
                      />
                    </div>
                  )}
                  {/* notes */}
                  {shouldRenderNotesField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "notes"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "notes"
                          )?.label
                        }
                        required
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </div>
                  )}
                  {/* created_by */}
                  {shouldRenderCreatedByField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "created_by"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "created_by"
                          )?.label
                        }
                        required
                        value={createdBy}
                        onChange={(e) => setCreatedBy(e.target.value)}
                      />
                    </div>
                  )}
                  {/* billing_period */}
                  {shouldRenderBillingPeriodField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "billing_period"
                          )?.label
                        }
                      </label>
                      <div className="checkbox-container">
                        {billingPeriodOptions.map((item) => (
                          <div className="checkbox-div" key={item.value}>
                            <MDBCheckbox
                              label={item.label}
                              checked={billingPeriod.includes(item.value)}
                              onChange={() =>
                                handleBillingPeriodChange(item.value)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* status */}
                  {shouldRenderStatusField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "status"
                          )?.label
                        }
                      </label>
                      <div className="checkbox-container">
                        {statusArray.map((item) => (
                          <div className="checkbox-div" key={item.value}>
                            <MDBCheckbox
                              label={item.label}
                              checked={status.includes(item.value)}
                              onChange={() => handleStatusChange(item.value)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* page size */}
                  <div className="input-margins-div">
                    <label>Records per page</label>
                    <Select
                      value={perPageOptions.find(
                        (option) => option.value === pageSize
                      )}
                      onChange={(option) => handlePerPage(option.value)}
                      options={perPageOptions}
                      styles={styles}
                    />
                  </div>
                  {/* sort by */}
                  <div className="input-margins-div">
                    <label>Sort by</label>
                    <Select
                      value={sortBy}
                      onChange={(option) => setSortBy(option)}
                      options={entityOptions}
                      styles={styles}
                      isClearable
                    />
                  </div>
                  {/* order by */}
                  <div className="input-margins-div">
                    <label>Order by</label>
                    <Select
                      value={orderBy}
                      onChange={(option) => setOrderBy(option)}
                      options={[
                        { value: "asc", label: "Ascending" },
                        { value: "desc", label: "Descending" },
                      ]}
                      styles={styles}
                    />
                  </div>
                  <div className="reset-and-filter-div">
                    <button
                      type="button"
                      className="reset-fields-btn"
                      onClick={handleResetFilter}
                    >
                      RESET
                    </button>
                    <button type="submit" className="filter-records-btn">
                      FILTER
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterRecurringModal;
