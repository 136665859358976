/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/Media.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getWidgets } from "../../Store/Slices/Sidebar/Media/GetWidgetsSlice";
import {
  filterMediaRecords,
  getMedia,
} from "../../Store/Slices/Sidebar/Media/GetMediaSlice";
import Widgets from "../../Components/Media/Widgets";
import UpdateWidgetsModal from "../Media/UpdateWidgetsModal";
import Media from "../Media/Media";
import EditMediaModal from "../Media/EditMediaModal";
import AddMediaModal from "../Media/AddMediaModal";
import DeleteMediaModal from "../Media/DeleteMediaModal";
import FilterMediaModal from "../Media/FilterMediaModal";
import VideoModal from "../Media/VideoModal";
import { Toaster } from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import Icon from "react-icons-kit";
import { filter } from "react-icons-kit/feather/filter";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import Tippy from "@tippyjs/react";
import axios from "axios";
import hostName from "../../config";

const MediaComponent = ({ token }) => {
  // redux loading states
  const { widgetsLoading } = useSelector((state) => state.widgets);
  const { mediaLoading } = useSelector((state) => state.media);

  // setting up pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("04");

  // page size
  const [pageSize, setPageSize] = useState("04");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // filtered params
  const [filteredParams, setFilteredParams] = useState(null);

  // filter media modal
  const [filterMediaModal, setFilterMediaModal] = useState(false);

  // get widgets
  useEffect(() => {
    dispatch(getWidgets(token))
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // get media
  useEffect(() => {
    if (filteredParams) {
      // filter media
      dispatch(
        filterMediaRecords({
          token,
          page: 1,
          pageSize: filteredParams.pageSize,
          order: filteredParams.order,
          apiData: filteredParams.apiData,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    } else {
      // get media
      dispatch(
        getMedia({
          token,
          page: 1,
          pageSize: "04",
          order: "asc",
          by: "url",
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
          setPageSize(res.payload.links.perPage);
        }
      });
    }
  }, [filteredParams]);

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);

  // handle multi select change in filter modal
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // entity options
  const [entityOptions, setEntityOptions] = useState([]);
  const [entityOptionsLoading, setEntityOptionsLoading] = useState(true);

  // setting entity options through api call
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${hostName}api/admin/v1/lov?listName=mediaFilter`, axiosConfig)
      .then((res) => {
        setEntityOptions(res.data.data);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setEntityOptionsLoading(false));
  }, []);

  // state to check if any loading from redux is true
  const [loadings, setLoadings] = useState(true);

  // checking if any loading is true
  const allLoadingStates = [widgetsLoading, mediaLoading, entityOptionsLoading];
  useEffect(() => {
    const isAnyChildLoading = allLoadingStates.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadingStates]);

  // input field states
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [mediaType, setMediaType] = useState({
    value: "image",
    label: "Image",
  });

  // boolean states and change events
  const [muted, setMuted] = useState(0);
  const handleMutedChange = () => {
    setMuted((prevStatus) => (prevStatus === 1 ? 0 : 1));
  };

  const [fullScreen, setFullScreen] = useState(0);
  const handleFullScreenChange = () => {
    setFullScreen((prevStatus) => (prevStatus === 1 ? 0 : 1));
  };

  const [status, setStatus] = useState(0);
  const handleStatusChange = () => {
    setStatus((prevStatus) => (prevStatus === 1 ? 0 : 1));
  };

  // sort by
  const [sortBy, setSortBy] = useState(null);

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // reset filter
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilteredParams(null);
      setFilterMediaModal(false);
    }
    setEntitySelected([]);
    setName("");
    setDescription("");
    setUrl("");
    setMediaType({
      value: "image",
      label: "Image",
    });
    setMuted(0);
    setFullScreen(0);
    setStatus(0);
    setPageSize(perPage);
    setSortBy(null);
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // update widget popup
  const [updateWidgetsModal, setUpdateWidgetsModal] = useState(false);

  // onclicked widget data
  const [widgetData, setWidgetData] = useState(null);

  // handle widgets update modal
  const handleUpdateWidgetsModal = (data) => {
    setUpdateWidgetsModal(true);
    setWidgetData(data);
  };

  // edit media modal
  const [editMediaModal, setEditMediaModal] = useState(false);

  // data to be edited
  const [editMediaData, setEditMediaData] = useState(null);

  // img updating
  const [isLoadingImg, setIsLoadingImg] = useState({
    value: false,
    tagNumber: null,
  });

  // handle edit modal
  const handleEditModal = (data) => {
    setEditMediaModal(true);
    setEditMediaData(data);
  };

  // add media modal
  const [addMediaModal, setAddMediaModal] = useState(false);

  // delete media modal
  const [deleteMediaModal, setDeleteMediaModal] = useState(false);

  // data to be deleted
  const [deleteMediaObj, setDeleteMediaObj] = useState(null);

  // delete media modal
  const handleDeleteModal = (data) => {
    setDeleteMediaModal(true);
    setDeleteMediaObj(data);
  };

  // video modal
  const [videoModal, setVideoModal] = useState(false);

  // video data
  const [videoObj, setVideoObj] = useState(null);

  // handle video modal
  const handleVideoModal = (data) => {
    setVideoModal(true);
    setVideoObj(data);
  };

  return (
    <>
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              <Toaster />
              <h6>Widgets</h6>
              <div className="widgets-row">
                <Widgets handleUpdateWidgetsModal={handleUpdateWidgetsModal} />
              </div>
              <div className="media-heading-button-container">
                <h6>Media Center</h6>
                <div className="add-and-filter-btns-div">
                  <div className="action-btns-div">
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setAddMediaModal(true)}
                    >
                      Add Media
                    </button>
                  </div>
                  <div className="filter-and-reset-btns-div">
                    <Tippy content="Filter Records">
                      <button
                        className="filter-media-modal-btn"
                        type="button"
                        onClick={() => setFilterMediaModal(true)}
                      >
                        <Icon icon={filter} size={24} />
                      </button>
                    </Tippy>
                    {filteredParams && (
                      <Tippy content="Reset Records">
                        <button
                          className="filter-media-modal-btn reset"
                          type="button"
                          onClick={handleResetFilter}
                        >
                          <Icon icon={refreshCw} size={24} />
                        </button>
                      </Tippy>
                    )}
                  </div>
                </div>
              </div>
              <div className="media-row">
                <Media
                  token={token}
                  filteredParams={filteredParams}
                  isLoadingImg={isLoadingImg}
                  currentPage={currentPage}
                  perPage={perPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  setPerPage={setPerPage}
                  setTotalPages={setTotalPages}
                  setPageSize={setPageSize}
                  handleEditModal={handleEditModal}
                  handleDeleteModal={handleDeleteModal}
                  handleVideoModal={handleVideoModal}
                  setLoadings={setLoadings}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {updateWidgetsModal && (
        <UpdateWidgetsModal
          token={token}
          setUpdateWidgetsModal={setUpdateWidgetsModal}
          widgetData={widgetData}
        />
      )}
      {editMediaModal && (
        <EditMediaModal
          token={token}
          setEditMediaModal={setEditMediaModal}
          editMediaData={editMediaData}
          setIsLoadingImg={setIsLoadingImg}
        />
      )}
      {addMediaModal && (
        <AddMediaModal token={token} setAddMediaModal={setAddMediaModal} />
      )}
      {deleteMediaModal && (
        <DeleteMediaModal
          token={token}
          setDeleteMediaModal={setDeleteMediaModal}
          deleteMediaObj={deleteMediaObj}
        />
      )}
      {filterMediaModal && (
        <FilterMediaModal
          setFilterMediaModal={setFilterMediaModal}
          setFilteredParams={setFilteredParams}
          entitySelected={entitySelected}
          entityOptions={entityOptions}
          handleSelectChange={handleSelectChange}
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          url={url}
          setUrl={setUrl}
          mediaType={mediaType}
          setMediaType={setMediaType}
          fullScreen={fullScreen}
          muted={muted}
          status={status}
          handleFullScreenChange={handleFullScreenChange}
          handleMutedChange={handleMutedChange}
          handleStatusChange={handleStatusChange}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          pageSize={pageSize}
          handlePerPage={handlePerPage}
          setLoadings={setLoadings}
          handleResetFilter={handleResetFilter}
        />
      )}
      {videoModal && (
        <VideoModal setVideoModal={setVideoModal} videoObj={videoObj} />
      )}
    </>
  );
};

export default MediaComponent;
