/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addCardTransaction,
  clearPrevTransactionData,
  getDonorsByEmail,
} from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import {
  getWallets,
  setSelectedWallet,
  updateWallets,
} from "../../Store/Slices/Wallet/WalletSlice";
import {
  addNewRecurring,
  clearPrevRecurringTransactionData,
} from "../../Store/Slices/Sidebar/RecurringPayments/RecurringPaymentsSlice";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { info } from "react-icons-kit/feather/info";
import { check } from "react-icons-kit/feather/check";
import { userPlus } from "react-icons-kit/feather/userPlus";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import { toast } from "react-hot-toast";
import Tippy from "@tippyjs/react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import chipImg from "../../Images/chip.png";
import noDataFound from "../../Images/noDataFound.png";
import BigNumber from "bignumber.js";
import AddDonorModal from "../Donors/AddDonorModal";
import ReactDatePicker from "react-datepicker";

// custom text field
const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "180px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// get organization for currency symbol
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const CardTransactionsPopup = ({
  token,
  setCardTransactionsPopup,
  countries,
  provinces,
  usaStates,
  campaignOptions,
  oneTime,
  emailAvailable,
  triggerScroll,
}) => {
  // state
  const [selectedOrganization] = useState(getOrganization()); // for currency symbol

  // redux state transactions
  const { cardTransactionLoading, cardTransactionData } = useSelector(
    (state) => state.transactions
  );

  // redux state recurringPayments
  const { newRecurringLoading, newRecurringData } = useSelector(
    (state) => state.recurringPayments
  );

  // redux state wallet
  const { walletLoading, walletData, walletError, selectedWallet } =
    useSelector((state) => state.wallet);

  // close modal
  const handleCloseModal = () => {
    if (!cardTransactionLoading && !newRecurringLoading && !walletLoading) {
      setCardTransactionsPopup(false);
    }
  };

  // to clear error msg from last transaction and set selected wallet null / recurring too
  useEffect(() => {
    dispatch(clearPrevTransactionData());
    dispatch(clearPrevRecurringTransactionData());
    dispatch(setSelectedWallet(null));
  }, []);

  // steps
  // campaign-details
  // add-donor
  // select-wallet
  // add-card
  // confirm-info
  const [step, setStep] = useState("campaign-details");

  // transaction email state
  const [transactionEmail, setTransactionEmail] = useState("");

  useEffect(() => {
    if (emailAvailable) {
      setTransactionEmail(emailAvailable);
    } else {
      setTransactionEmail("");
    }
  }, [emailAvailable]);

  // timer state
  const [timer, setTimer] = useState(null);

  // suggestions state
  const [suggestions, setSuggestions] = useState([]);

  // handle transaction email change event
  const handleTransactionEmail = (value) => {
    setTransactionEmail(value);
    // clear any existing timer
    if (timer) {
      clearTimeout(timer);
    }
    // create a new timer of 1 second
    const newTimer = setTimeout(() => {
      if (value.length > 3) {
        dispatch(
          getDonorsByEmail({
            token,
            email: value,
          })
        ).then((res) => {
          if (res.payload && res.payload.data && res.payload.data.length > 0) {
            setSuggestions(res.payload.data);
          } else if (
            res.payload &&
            res.payload.data &&
            res.payload.data.length < 1
          ) {
            setSuggestions([]);
          } else if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      } else {
        setSuggestions([]);
      }
    }, 1000);
    setTimer(newTimer);
  };

  // campaign
  const [selectedCampaign, setSelectedCampaign] = useState({
    value: "",
    label: "",
    minimumAmount: "",
    fees: "",
    enableQuantity: "",
  });

  // amount
  const [amount, setAmount] = useState("");

  // quantity
  const [quantity, setQuantity] = useState(1);

  // amount change
  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    newValue = newValue.replace(/[^0-9.]/g, "");
    const parts = newValue.split(".");
    if (parts.length > 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue);
  };

  // handle quantity
  const handleQuantity = (value) => {
    // Allow only numbers between 1 and 999
    if (/^\d+$/.test(value) && value >= 1 && value <= 999) {
      setQuantity(value);
    } else if (value === "") {
      setQuantity(""); // Allow clearing the input
    }
  };

  // notes
  const [notes, setNotes] = useState("");

  // frequency
  const [selectedFrequency, setSelectedFrequency] = useState(
    oneTime ? "ONETIME" : "DAILY"
  );

  // change qty and selected frequency
  useEffect(() => {
    if (selectedCampaign.enableQuantity && oneTime) {
      // oneTime means, you are in the cardTransactions and not recurringPayments
      setSelectedFrequency("ONETIME");
      setFees(0.0);
      setQuantity(1);
      setNewAmount(1 * parseFloat(selectedCampaign.minimumAmount));
    }
  }, [selectedCampaign]);

  // frequency options
  const frequencyOptions = [
    { value: "ONETIME", label: "ONE-TIME" },
    { value: "DAILY", label: "DAILY" },
    { value: "WEEKLY", label: "WEEKLY" },
    { value: "BIWEEKLY", label: "BIWEEKLY" },
    { value: "MONTHLY", label: "MONTHLY" },
  ].filter((option) => oneTime || option.value !== "ONETIME");

  // specify no of recurrings
  const [specifyNoOfRecurrings, setSpecifyNoOfRecurrings] = useState(false);
  const [noOfRecurringsError, setNoOfRecurringsError] = useState(false);
  const [noOfRecurrings, setNoOfRecurrings] = useState("");

  // start date
  const [startDate, setStartDate] = useState(new Date());

  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  // hiding the past dates
  const isSelectableDate = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison
    return date >= currentDate;
  };

  // updating no of recurrings
  useEffect(() => {
    let noOfFrequency = 1;

    if (selectedFrequency === "DAILY" && specifyNoOfRecurrings) {
      noOfFrequency = 30;
    } else if (selectedFrequency === "WEEKLY" && specifyNoOfRecurrings) {
      noOfFrequency = 52;
    } else if (selectedFrequency === "BIWEEKLY" && specifyNoOfRecurrings) {
      noOfFrequency = 26;
    } else if (selectedFrequency === "MONTHLY" && specifyNoOfRecurrings) {
      noOfFrequency = 12;
    } else if (selectedFrequency === "ONETIME") {
      noOfFrequency = 1;
      setStartDate(new Date());
    }

    setNoOfRecurrings(noOfFrequency);
    setNoOfRecurringsError(false);
  }, [specifyNoOfRecurrings, selectedFrequency]);

  // radio change event
  const handleRadioChange = (event) => {
    const targetValue = event.target.value;
    if (targetValue === "recurring") {
      setSpecifyNoOfRecurrings(false);
      setNoOfRecurringsError(false);
    } else if (targetValue === "notRecurring") {
      setSpecifyNoOfRecurrings(true);
    }
  };

  // handle input box event for no of recurrings
  const handleNoOfRecurrings = (value) => {
    let newValue = parseInt(value);
    if ((newValue < 2 || isNaN(newValue)) && specifyNoOfRecurrings) {
      setNoOfRecurringsError(true);
    } else if (newValue >= 2 && specifyNoOfRecurrings) {
      setNoOfRecurringsError(false);
    }
    if (newValue < 0) {
      newValue = 0;
    }
    setNoOfRecurrings(newValue);
  };

  // dispatch and use navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // handle next step | go to fee cover if campaign needs else select wallet or add card info
  const handleNextStep = (e) => {
    e.preventDefault();

    const proceedToWallet = () => {
      setStep("select-wallet");
      if (!emailAvailable) {
        dispatch(getWallets({ token, email: transactionEmail })).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      }
    };

    if (selectedCampaign.enableQuantity && oneTime) {
      if (
        parseInt(quantity) >= 1 &&
        parseInt(quantity) <= 999 &&
        quantity !== ""
      ) {
        proceedToWallet();
      }
    } else {
      if (amount >= selectedCampaign.minimumAmount && !noOfRecurringsError) {
        proceedToWallet();
      }
    }
  };

  // source
  const [source, setSource] = useState(null);

  // card details state
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [cvc, setCvc] = useState("");
  const [saveCard, setSaveCard] = useState(true);

  // wallet click
  const handleWalletCardClick = (wallet) => {
    if (!wallet.isExpired) {
      dispatch(setSelectedWallet(wallet));
      setSource("WALLET");
      setCardHolderName(wallet.name);
      setCardNumber(wallet.number);
      setExpiryYear(wallet.expiryYear);
      setExpiryMonth(wallet.expiryMonth);
    }
  };

  // handle new card
  const handleAddCardStep = () => {
    dispatch(setSelectedWallet(null));
    setSource("CARD");
    setStep("add-card");
    setCardHolderName("");
    setCardNumber("");
    setExpiryMonth("");
    setExpiryYear("");
    setCvc("");
    setSaveCard(true);
  };

  // Function to generate the month options
  const generateMonthOptions = () => {
    const monthOptions = [];

    for (let i = 1; i <= 12; i++) {
      const monthValue = i < 10 ? `0${i}` : `${i}`;
      monthOptions.push({
        value: monthValue,
        label: monthValue,
      });
    }

    return monthOptions;
  };

  // Generate the month options
  const expiryMonthOptions = generateMonthOptions();

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Function to generate the expiry year options
  const generateExpiryYearOptions = (startYear, numberOfYears) => {
    const expiryYearOptions = [];

    for (let i = 0; i < numberOfYears; i++) {
      const year = startYear + i;
      expiryYearOptions.push({
        value: String(year).slice(-2),
        label: String(year),
      });
    }

    return expiryYearOptions;
  };

  // Generate the expiry year options for the next 15 years
  const expiryYearOptions = generateExpiryYearOptions(currentYear, 15);

  // confirm info step
  const handleConfirmInfoStep = (e) => {
    e.preventDefault();
    setStep("confirm-info");
  };

  // handle step back from confirm info
  const handleStepBackFromConfirm = () => {
    if (source === "WALLET") {
      setStep("select-wallet");
    } else {
      setStep("add-card");
    }
  };

  // fee cover
  const [isChecked, setIsChecked] = useState(false);
  const [fees, setFees] = useState(0.0);
  const [newAmount, setNewAmount] = useState(
    selectedCampaign.enableQuantity && oneTime
      ? parseInt(quantity) * parseFloat(selectedCampaign.minimumAmount)
      : amount
  );

  // checkbox
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // new amount and fees
  useEffect(() => {
    if (selectedCampaign.enableQuantity && oneTime) {
      setNewAmount(
        parseInt(quantity) * parseFloat(selectedCampaign.minimumAmount)
      );
      setFees(0);
      setSelectedFrequency("ONETIME");
    } else {
      const feesExist = parseFloat(selectedCampaign.fees) > 0;

      if (feesExist && isChecked) {
        const feeAmount = new BigNumber(parseFloat(selectedCampaign.fees))
          .dividedBy(100)
          .times(parseFloat(amount))
          .toFixed(2);

        const updatedAmount = new BigNumber(parseFloat(amount))
          .plus(feeAmount)
          .toFixed(2);

        setFees(feeAmount);
        setNewAmount(updatedAmount);
      } else {
        setNewAmount(amount);
        setFees(0);
      }
    }
  }, [isChecked, selectedCampaign, amount, quantity, oneTime]);

  // card transaction event
  const handleCardTransaction = () => {
    const singleObj = {
      token,
      email: transactionEmail,
      campaignTag: selectedCampaign.value,
      amount: parseFloat(newAmount),
      source,
      cardHolderName,
      cardNumber,
      expiryYear,
      expiryMonth,
      billingPeriod: selectedFrequency,
      noOfRecurrings: parseFloat(noOfRecurrings),
      fee: parseFloat(fees),
      userNotes: notes,
    };
    if (singleObj.source === "WALLET") {
      singleObj.cardId = selectedWallet.cardId;
      singleObj.function = selectedWallet.function;
    } else if (singleObj.source === "CARD") {
      singleObj.cvc = cvc;
      singleObj.saveCard = saveCard;
    }
    if (selectedFrequency !== "ONETIME") {
      singleObj.startDate = formatDate(startDate);
    }
    if (oneTime) {
      dispatch(addCardTransaction(singleObj)).then((res) => {
        if (res.payload.successMsg) {
          setCardTransactionsPopup(false);
          toast.success(`${res.payload.successMsg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // donor specific
          if (emailAvailable) {
            if (selectedFrequency !== "ONETIME") {
              dispatch(updateWallets(selectedWallet));
            } else {
              dispatch(setSelectedWallet(null));
            }
          }
        } else if (
          res.payload.axiosError === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    } else {
      dispatch(addNewRecurring(singleObj)).then((res) => {
        if (!res.payload.axiosError) {
          setCardTransactionsPopup(false);
          toast.success(`${res.payload.successMsg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // donor specific
          if (emailAvailable) {
            if (res.payload.data) {
              triggerScroll();
            }
            dispatch(updateWallets(selectedWallet));
          }
        } else if (
          res.payload.axiosError === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    }
  };

  // filteredCampaigns
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);

  // filter campaign where enable quantity is true in recurring payments only
  useEffect(() => {
    if (!oneTime) {
      const filteredCampaign = campaignOptions.filter(
        (campaign) => !campaign.enableQuantity
      );
      setFilteredCampaigns(filteredCampaign);
    }
  }, [campaignOptions]);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className={`update-widgets-modal ${
            step === "campaign-details" ? "" : "media extra-width"
          }`}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              Card Transaction -{" "}
              {step === "campaign-details" && "Campaign Details"}
              {step === "add-donor" && "Add Donor"}
              {step === "select-wallet" && "Select Wallet"}
              {step === "add-card" && "Add Card"}
              {step === "confirm-info" && "Confirm Information"}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>

          {/* Campaign Details Step */}
          {step === "campaign-details" && (
            <div className="update-widgets-modal-body">
              <form className="update-media-form" onSubmit={handleNextStep}>
                {/* Email */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label>Email</label>
                    <div className="email-input-container">
                      <input
                        type="email"
                        className="edit-media-inputs no-borders-right"
                        placeholder="Email"
                        value={transactionEmail}
                        onChange={(e) => handleTransactionEmail(e.target.value)}
                        required
                        readOnly={emailAvailable}
                      />

                      {/* suggestion popup */}
                      {suggestions.length > 0 && (
                        <div className="suggestions-popup">
                          {suggestions.map((suggestion, index) => (
                            <div
                              key={index}
                              className="suggestion-item"
                              onClick={() => {
                                setTransactionEmail(suggestion.email);
                                setSuggestions([]);
                              }}
                            >
                              <div className="suggestion-email">
                                {suggestion.email}
                              </div>
                              <div className="suggestion-name">
                                {suggestion.firstName} {suggestion.lastName}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {/* add donor button */}
                      {!emailAvailable && (
                        <Tippy content="Add Donor" zIndex={1000001}>
                          <button
                            type="button"
                            className="add-button"
                            onClick={() => setStep("add-donor")}
                          >
                            <Icon icon={userPlus} size={20} />
                          </button>
                        </Tippy>
                      )}
                    </div>
                  </MDBCol>
                </MDBRow>
                {/* Campaign */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label>Campaign</label>
                    <Select
                      styles={styles}
                      required
                      menuPlacement="bottom"
                      value={(oneTime
                        ? campaignOptions
                        : filteredCampaigns
                      ).find(
                        (option) => option.value === selectedCampaign.value
                      )}
                      onChange={(option) => setSelectedCampaign(option)}
                      options={oneTime ? campaignOptions : filteredCampaigns}
                    />
                  </MDBCol>
                </MDBRow>
                {/* check if enableQuantity is enabled */}
                {selectedCampaign.enableQuantity && oneTime ? (
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Unit Cost</label>
                      <CustomTextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        size="small"
                        value={selectedCampaign.minimumAmount}
                        readOnly
                      />
                    </MDBCol>
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Quantity</label>
                      <CustomTextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        size="small"
                        value={quantity}
                        onChange={(e) => handleQuantity(e.target.value)}
                        required
                        placeholder="Quantity"
                        helperText={
                          parseInt(quantity) < 1 ||
                          parseInt(quantity) > 999 ||
                          quantity === ""
                            ? `Please enter a quantity ranging between 1 - 999`
                            : ""
                        }
                        error={
                          parseInt(quantity) < 1 ||
                          parseInt(quantity) > 999 ||
                          quantity === ""
                        }
                      />
                    </MDBCol>
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Total Amount</label>
                      <CustomTextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        size="small"
                        value={isNaN(newAmount) ? 0 : newAmount}
                        readOnly
                      />
                    </MDBCol>
                  </MDBRow>
                ) : (
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Amount</label>
                      {selectedCampaign.minimumAmount !== "" && (
                        <span
                          style={{
                            marginLeft: 10 + "px",
                            fontSize: 13 + "px",
                            fontWeight: 600,
                            color: "#6a6c6f",
                          }}
                        >
                          Minimum Amount: {selectedOrganization?.currencySymbol}
                          {selectedCampaign.minimumAmount}
                        </span>
                      )}
                      <CustomTextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        size="small"
                        value={amount}
                        onChange={handleAmountChange}
                        required
                        placeholder="Amount"
                        helperText={
                          amount !== "" &&
                          amount < selectedCampaign.minimumAmount
                            ? `Please enter an amount greater than or equal to ${selectedCampaign.minimumAmount}`
                            : ""
                        }
                        error={
                          amount !== "" &&
                          amount < selectedCampaign.minimumAmount
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                )}
                {/* Notes */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label>Notes</label>
                    <textarea
                      className="edit-media-inputs text-area"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      maxLength={500}
                    />
                  </MDBCol>
                </MDBRow>
                {/* Frequency */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label>Frequency</label>
                    <Select
                      styles={styles}
                      required
                      menuPlacement="top"
                      value={frequencyOptions.find(
                        (option) => option.value === selectedFrequency
                      )}
                      onChange={(option) => setSelectedFrequency(option.value)}
                      options={frequencyOptions}
                      isDisabled={selectedCampaign.enableQuantity && oneTime}
                    />
                  </MDBCol>
                </MDBRow>
                {/* if selectedFrequency is anything other than one-time */}
                {selectedFrequency !== "ONETIME" && (
                  <>
                    <MDBRow>
                      <MDBCol className="col-12 col-md-12 mb-md-0">
                        <label>No. Of Donations</label>
                        <div className="tender-row card">
                          <div className="custom-radio">
                            <label>
                              <input
                                type="radio"
                                className="input-radio"
                                name="recurring"
                                id="recurring"
                                value="recurring"
                                checked={!specifyNoOfRecurrings}
                                onChange={handleRadioChange}
                              />{" "}
                              Make this a recurring transaction
                            </label>
                          </div>
                          <div className="custom-radio">
                            <label>
                              <input
                                type="radio"
                                className="input-radio"
                                name="notRecurring"
                                id="notRecurring"
                                value="notRecurring"
                                checked={specifyNoOfRecurrings}
                                onChange={handleRadioChange}
                              />{" "}
                              {specifyNoOfRecurrings ? (
                                <>
                                  {" "}
                                  I want to transact{" "}
                                  <input
                                    type="number"
                                    className="edit-media-inputs"
                                    value={noOfRecurrings}
                                    onChange={(e) =>
                                      handleNoOfRecurrings(e.target.value)
                                    }
                                    onFocus={(e) => {
                                      const input = e.target;
                                      const preventScroll = (e) =>
                                        e.preventDefault();
                                      input.addEventListener(
                                        "wheel",
                                        preventScroll,
                                        { passive: false }
                                      );

                                      // Cleanup listener on blur
                                      input.addEventListener(
                                        "blur",
                                        () => {
                                          input.removeEventListener(
                                            "wheel",
                                            preventScroll
                                          );
                                        },
                                        { once: true }
                                      );
                                    }}
                                    required
                                    style={{
                                      width: 65 + "px",
                                      marginLeft: 10 + "px",
                                      marginRight: 10 + "px",
                                      marginTop: -7 + "px",
                                    }}
                                  />{" "}
                                  times
                                </>
                              ) : (
                                <>I want to specify no of recurrings</>
                              )}
                            </label>
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    {specifyNoOfRecurrings && (
                      <div
                        className={
                          noOfRecurringsError ? "error-msg" : "info-msg"
                        }
                        style={{ marginTop: 0 }}
                      >
                        Minimum Recurrings: 02
                      </div>
                    )}
                    <MDBRow className="mb-3">
                      <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                        <label htmlFor="startDate">Start Date</label>
                        <ReactDatePicker
                          style={{
                            width: 100 + "%",
                          }}
                          className="edit-media-inputs"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="yyyy-MM-dd"
                          placeholderText="Start Date"
                          required
                          minDate={new Date()}
                          filterDate={isSelectableDate}
                        />
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
                {/* cancel and next button */}
                <div className="submit-and-cancel-div">
                  <button
                    type="button"
                    className="cancel"
                    onClick={handleCloseModal}
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className="submit addModal"
                    style={{
                      opacity:
                        selectedCampaign.enableQuantity && oneTime
                          ? parseInt(quantity) < 1 ||
                            parseInt(quantity) > 999 ||
                            quantity === ""
                            ? 0.5
                            : 1
                          : noOfRecurringsError ||
                            amount < selectedCampaign.minimumAmount
                          ? 0.5
                          : 1,
                      pointerEvents:
                        selectedCampaign.enableQuantity && oneTime
                          ? parseInt(quantity) < 1 ||
                            parseInt(quantity) > 999 ||
                            quantity === ""
                            ? "none"
                            : "auto"
                          : noOfRecurringsError ||
                            amount < selectedCampaign.minimumAmount
                          ? "none"
                          : "auto",
                    }}
                  >
                    NEXT
                  </button>
                </div>
              </form>
            </div>
          )}

          {/* Add Donor Step */}
          {step === "add-donor" && (
            <>
              <AddDonorModal
                token={token}
                setCardTransactionsPopup={setCardTransactionsPopup}
                countries={countries}
                usaStates={usaStates}
                provinces={provinces}
                addDonorStep={step}
                setAddDonorStep={setStep}
                setTransactionEmail={setTransactionEmail}
                setSuggestions={setSuggestions}
              />
            </>
          )}

          {/* Select Wallet Step */}
          {step === "select-wallet" && (
            <div className="update-widgets-modal-body select-wallet">
              <>
                {walletLoading ? (
                  <div className="wallet-loader">
                    <MetroSpinner
                      loading={walletLoading}
                      size={30}
                      color="#007c17"
                    />
                  </div>
                ) : (
                  <>
                    {walletError ? (
                      <div className="error-msg" style={{ marginTop: 0 }}>
                        {walletError}
                      </div>
                    ) : (
                      <>
                        {walletData &&
                        walletData.wallets &&
                        walletData.wallets.length > 0 ? (
                          <>
                            <div className="wallet-container">
                              {walletData.wallets.map((wallet) => {
                                // Split the name by spaces
                                const nameParts = wallet.name.split(" ");

                                // Determine the display name based on the number of name parts
                                let displayName = wallet.name;
                                if (nameParts.length === 3) {
                                  displayName = `${nameParts[0]} ${
                                    nameParts[nameParts.length - 1]
                                  }`;
                                }
                                return (
                                  <div
                                    className="wallet-card"
                                    key={wallet.cardId}
                                    style={{
                                      pointerEvents: wallet.isExpired
                                        ? "none"
                                        : "auto",
                                      opacity: wallet.isExpired ? 0.5 : 1,
                                      borderTop: wallet.isExpired
                                        ? "3px solid #f2b92c"
                                        : "1px solid #eaeaea",
                                      border:
                                        selectedWallet === wallet
                                          ? "3px solid #007c16"
                                          : "1px solid #eaeaea",
                                    }}
                                    onClick={() =>
                                      handleWalletCardClick(wallet)
                                    }
                                  >
                                    <h6>{wallet.function}</h6>
                                    <div className="chip-img">
                                      <img alt="chip" src={chipImg} />
                                    </div>
                                    <h4>{wallet.number}</h4>
                                    <div className="name-container">
                                      <div className="valid-thru">
                                        <div className="expiry-date">
                                          <h6>
                                            VALID
                                            <br /> THRU
                                          </h6>
                                          <h4>{wallet.expiryDate}</h4>
                                        </div>
                                        <h4>{displayName}</h4>
                                      </div>
                                      <div className="card-brand-image">
                                        <img
                                          alt="card"
                                          src={wallet.cardImage}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            {walletData.wallets.length < 5 && (
                              <div className="add-new-card-div">
                                <button
                                  className="add-new-card-btn"
                                  onClick={handleAddCardStep}
                                >
                                  Use New Card
                                </button>
                              </div>
                            )}

                            <div className="submit-and-cancel-div">
                              <button
                                type="button"
                                className="cancel"
                                onClick={() => setStep("campaign-details")}
                              >
                                BACK
                              </button>
                              <button
                                type="button"
                                className="submit addModal"
                                style={{
                                  pointerEvents: !selectedWallet
                                    ? "none"
                                    : "auto",
                                  opacity: !selectedWallet ? 0.5 : 1,
                                }}
                                onClick={() => setStep("confirm-info")}
                              >
                                NEXT
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="no-data-found-div">
                              <Tippy content="No Cards Found" zIndex={1000001}>
                                <img src={noDataFound} alt="No Cards Found" />
                              </Tippy>
                            </div>

                            <div className="add-new-card-div">
                              <button
                                className="add-new-card-btn"
                                onClick={handleAddCardStep}
                              >
                                Add New Card
                              </button>
                            </div>

                            <div className="submit-and-cancel-div">
                              <button
                                type="button"
                                className="cancel"
                                onClick={() => setStep("campaign-details")}
                              >
                                BACK
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          )}

          {/* Add Card */}
          {step === "add-card" && (
            <div className="update-widgets-modal-body">
              <form
                className="update-media-form"
                onSubmit={handleConfirmInfoStep}
              >
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                    <label>Cardholder Name</label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      value={cardHolderName}
                      onChange={(e) => setCardHolderName(e.target.value)}
                      required
                      placeholder="Cardholder Name"
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                    <label>Card Number</label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      value={cardNumber}
                      onChange={(e) => setCardNumber(e.target.value)}
                      required
                      placeholder="Card Number"
                      pattern=".{15,}"
                      title="Please enter a card number with at least 15 characters"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mdb-3">
                  <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                    <label>Expiry Month</label>
                    <Select
                      styles={styles}
                      required
                      menuPlacement="bottom"
                      value={expiryMonthOptions.find(
                        (option) => option.value === expiryMonth
                      )}
                      onChange={(option) => setExpiryMonth(option.value)}
                      options={expiryMonthOptions}
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                    <label>Expiry Year</label>
                    <Select
                      styles={styles}
                      required
                      menuPlacement="bottom"
                      value={expiryYearOptions.find(
                        (option) => option.value === expiryYear
                      )}
                      onChange={(option) => setExpiryYear(option.value)}
                      options={expiryYearOptions}
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                    <label>CVC</label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      value={cvc}
                      onChange={(e) => setCvc(e.target.value)}
                      required
                      placeholder="CVC"
                    />
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <h6>Save Card</h6>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={saveCard}
                        onChange={() => setSaveCard(!saveCard)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </MDBCol>
                </MDBRow>
                <div className="submit-and-cancel-div">
                  <button
                    type="button"
                    className="cancel"
                    onClick={() => setStep("select-wallet")}
                  >
                    BACK
                  </button>
                  <button type="submit" className="submit addModal">
                    NEXT
                  </button>
                </div>
              </form>
            </div>
          )}

          {/* Confirm Info */}
          {step === "confirm-info" && (
            <div className="update-widgets-modal-body">
              <div className="transaction-details">
                <div className="content">
                  {/* cover transaction costs */}
                  {selectedCampaign.fees > 0 &&
                    !selectedCampaign.enableQuantity && (
                      <div className="detail">
                        <h6 className="key">
                          Cover Transaction Costs
                          <Tippy
                            content={
                              isChecked
                                ? `By covering
                                ${selectedOrganization?.currencySymbol}${fees} in transaction costs, you cover our processing and
                                platform fees.`
                                : "Would you like to cover the transaction costs so that we receive 100% of your gift?"
                            }
                            zIndex={1000001}
                          >
                            <span
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                color: isChecked && "#007c16",
                              }}
                            >
                              <Icon
                                icon={isChecked ? check : info}
                                size={isChecked ? 24 : 20}
                              />
                            </span>
                          </Tippy>
                        </h6>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    )}
                  {/* card holder name */}
                  <div className="detail">
                    <h6 className="key">Cardholder Name</h6>
                    <h6>{cardHolderName}</h6>
                  </div>
                  {/* card number */}
                  <div className="detail">
                    <h6 className="key">Card Number</h6>
                    <h6>{cardNumber}</h6>
                  </div>
                  {/* email */}
                  <div className="detail">
                    <h6 className="key">Donor Email</h6>
                    <h6>{transactionEmail}</h6>
                  </div>
                  {/* campaign */}
                  <div className="detail">
                    <h6 className="key">Campaign</h6>
                    <h6>{selectedCampaign.label}</h6>
                  </div>
                  {/* amount/unit cost */}
                  <div className="detail">
                    <h6 className="key">
                      {selectedCampaign.enableQuantity ? "Unit Cost" : "Amount"}
                    </h6>
                    <h6>
                      {selectedOrganization?.currencySymbol}
                      {selectedCampaign.enableQuantity
                        ? selectedCampaign.minimumAmount
                        : amount}
                    </h6>
                  </div>
                  {selectedCampaign.fees > 0 &&
                    selectedCampaign.enableQuantity === 0 && (
                      <>
                        <div className="detail">
                          <h6 className="key">Fee</h6>
                          <h6>
                            {selectedOrganization?.currencySymbol}
                            {fees}
                          </h6>
                        </div>
                        <div className="detail">
                          <h6 className="key">Total Amount</h6>
                          <h6>
                            {selectedOrganization?.currencySymbol}
                            {newAmount}
                          </h6>
                        </div>
                      </>
                    )}
                  {selectedCampaign.enableQuantity === 1 && (
                    <>
                      <div className="detail">
                        <h6 className="key">Quantity</h6>
                        <h6>{quantity}</h6>
                      </div>
                      <div className="detail">
                        <h6 className="key">Total Amount</h6>
                        <h6>
                          {selectedOrganization?.currencySymbol}
                          {newAmount}
                        </h6>
                      </div>
                    </>
                  )}
                  {/* frequency */}
                  <div className="detail">
                    <h6 className="key">Billing Period</h6>
                    <h6>{selectedFrequency}</h6>
                  </div>
                  {/* No of recurring */}
                  <div className="detail">
                    <h6 className="key">No. Of Recurring</h6>
                    <h6>{noOfRecurrings}</h6>
                  </div>
                  {selectedFrequency !== "ONETIME" && (
                    <div className="detail">
                      <h6 className="key">Start Date</h6>
                      <h6>{formatDate(startDate)}</h6>
                    </div>
                  )}
                </div>
              </div>
              {/* card transactions error */}
              {cardTransactionData && cardTransactionData.apiError ? (
                <div className="error-msg">{cardTransactionData.apiError}</div>
              ) : (
                <>
                  {cardTransactionData && cardTransactionData.axiosError && (
                    <div className="error-msg">
                      {cardTransactionData.axiosError}
                    </div>
                  )}
                </>
              )}
              {/* new recurring error */}
              {newRecurringData && newRecurringData.apiError ? (
                <div className="error-msg">{newRecurringData.apiError}</div>
              ) : (
                <>
                  {newRecurringData && newRecurringData.axiosError && (
                    <div className="error-msg">
                      {newRecurringData.axiosError}
                    </div>
                  )}
                </>
              )}
              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleStepBackFromConfirm}
                  disabled={cardTransactionLoading || newRecurringLoading}
                >
                  BACK
                </button>
                <button
                  type="button"
                  className="submit addModal"
                  onClick={handleCardTransaction}
                  disabled={cardTransactionLoading || newRecurringLoading}
                >
                  {cardTransactionLoading || newRecurringLoading ? (
                    <MetroSpinner
                      loading={cardTransactionLoading || newRecurringLoading}
                      color="#fff"
                      size={22}
                    />
                  ) : (
                    "CONFIRM"
                  )}
                </button>
              </div>
            </div>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CardTransactionsPopup;
