/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/TaxReceipts.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  filterTaxReceiptsRecords,
  getAllData,
  getTaxReceiptsSummaryData,
} from "../../Store/Slices/Sidebar/TaxReceipts/TaxReceiptsSlice";
import { Toaster } from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { filter } from "react-icons-kit/feather/filter";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import axios from "axios";
import hostName from "../../config";
import TaxReceiptsSummary from "../TaxReceipts/TaxReceiptsSummary";
import TaxReceiptTable from "../TaxReceipts/TaxReceiptTable";
import FilterTaxReceiptsModal from "../TaxReceipts/FilterTaxReceiptsModal";
import NewReceiptPopup from "../TaxReceipts/NewReceiptPopup";
import SendReminderPopup from "../TaxReceipts/SendReminderPopup";
import IssueReceiptsPopup from "../TaxReceipts/IssueReceiptsPopup";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// get organization
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const TaxReceiptsComponent = ({ token }) => {
  // state
  const [selectedOrganization] = useState(getOrganization());

  // redux state
  const {
    taxReceiptsSummaryLoading,
    taxReceiptsSummaryData,
    taxReceiptsSummaryError,
    allLoading,
    allError,
  } = useSelector((state) => state.taxReceipts);

  // all api options data states
  const [entityOptions, setEntityOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [templateOptions, setTemplatesOptions] = useState([]);
  const [variableOptions, setVariablesOptions] = useState([]);
  const [allOptionsLoading, setAllOptionsLoading] = useState(true);

  // getting all options at once
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    Promise.all([
      axios.get(
        `${hostName}api/admin/v1/lov?listName=taxReceiptFilter`,
        axiosConfig
      ),
      axios.get(
        `${hostName}api/admin/v1/routeLov?routeName=taxReceipt`,
        axiosConfig
      ),
      axios.get(`${hostName}api/v1/public/country`),
      axios.get(`${hostName}api/v1/public/state?countryCode=US`),
      axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
      axios.get(`${hostName}api/admin/v1/letter?type=taxReceipt`, axiosConfig),
      axios.get(
        `${hostName}api/admin/v1/lov?listName=taxReceiptVariables`,
        axiosConfig
      ),
    ])
      .then(
        ([
          entityRes,
          routeRes,
          countriesRes,
          usaStatesRes,
          provincesRes,
          templateRes,
          variablesRes,
        ]) => {
          setEntityOptions(entityRes.data.data);
          setStatesOptions(routeRes.data.data.state);
          // Handle countries
          const countryOptions = countriesRes.data.map((country) => ({
            value: country.code,
            label: country.name,
          }));
          setCountries(countryOptions);

          // Handle USA states
          const usaStatesOptions = usaStatesRes.data.map((state) => ({
            value: state.code,
            label: state.name,
          }));
          setUsaStates(usaStatesOptions);

          // Handle Canada provinces
          const provincesOptions = provincesRes.data.map((province) => ({
            value: province.code,
            label: province.name,
          }));
          setProvinces(provincesOptions);

          // template options
          const templates = templateRes.data.data.map((template) => ({
            value: template.category + " - " + template.title,
            label: template.category + " - " + template.title,
            title: template.title,
            body: template.body,
          }));
          setTemplatesOptions(templates);

          // variable options
          setVariablesOptions(variablesRes.data.data);
        }
      )
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setAllOptionsLoading(false));
  }, []);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [
    taxReceiptsSummaryLoading,
    allLoading,
    allOptionsLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // states
  const [year, setYear] = useState((new Date().getFullYear() - 1).toString()); // current year - 1
  const [filterYear, setFilterYear] = useState(
    (new Date().getFullYear() - 1).toString()
  ); // current year - 1

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get tax receipts summary
  useEffect(() => {
    dispatch(
      getTaxReceiptsSummaryData({
        token,
        year,
      })
    )
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, [year]);

  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("20");

  // page size is used in filter modal
  const [pageSize, setPageSize] = useState("20");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // filter
  const [filterTaxReceiptsModal, setFilterTaxReceiptsModal] = useState(false);
  const [filteredParams, setFilteredParams] = useState(null);
  const [filteredFlag, setFilteredFlag] = useState(null);

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // get tax receipts all data
  useEffect(() => {
    if (filteredParams) {
      dispatch(
        filterTaxReceiptsRecords({
          token,
          page: 1,
          pageSize: filteredParams.pageSize,
          order: filteredParams.order,
          apiData: filteredParams.apiData,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    } else {
      dispatch(
        getAllData({
          token,
          page: 1,
          pageSize: "20",
          order: "asc",
          by: "email",
          year,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    }
  }, [filteredFlag, filteredParams, year]);

  // reset filter
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilterTaxReceiptsModal(false);
      setFilteredParams(null);
      setFilteredFlag(null);
      setYear((new Date().getFullYear() - 1).toString());
    }
    setEntitySelected([]);
    setEmail("");
    setFilterYear(year);
    setReceiptNumber("");
    setCode("");
    setStates([]);
    setPageSize(perPage);
    setSortBy(null);
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset entities
  const resetEntities = () => {
    setEntitySelected([]);
    setEmail("");
    setFilterYear(year);
    setReceiptNumber("");
    setCode("");
    setStates([]);
    setPageSize(perPage);
    setSortBy(null);
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // handle year change
  const handleYearChange = (value) => {
    if (year !== value) {
      setLoadings(true);
      setYear(value);
      setFilterYear(value);
      if (filteredParams) {
        setFilteredParams((prevParams) => ({
          ...prevParams,
          apiData: {
            ...prevParams.apiData,
            year: value,
          },
        }));
      }
    }
  };

  // email
  const [email, setEmail] = useState("");

  // code
  const [code, setCode] = useState("");

  // receipt number
  const [receiptNumber, setReceiptNumber] = useState("");

  // states
  const [states, setStates] = useState([]);
  const handleStatesChange = (item) => {
    if (states.includes(item)) {
      setStates(states.filter((checkedItem) => checkedItem !== item));
    } else {
      setStates([...states, item]);
    }
  };

  // sort by
  const [sortBy, setSortBy] = useState(null);

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // issue receipts popup
  const [issueReceiptsPopup, setIssueReceiptsPopup] = useState(false);

  // new receipt popup
  const [newReceiptPopup, setNewReceiptPopup] = useState(false);

  // send reminder popup
  const [sendReminderPopup, setSendReminderPopup] = useState(false);

  return (
    <>
      <Toaster />
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              <div className="media-heading-button-container">
                <h6>
                  Tax Receipts Summary -{" "}
                  <span style={{ color: "#007c16" }}>
                    {year} - {selectedOrganization?.currencySymbol}
                    {taxReceiptsSummaryData?.totalDonation}
                  </span>
                </h6>
                <div style={{ marginRight: 15 + "px" }}>
                  <Select
                    value={yearOptions.find((option) => option.value === year)}
                    onChange={(option) => {
                      handleYearChange(option ? option.value : null);
                    }}
                    options={yearOptions}
                    styles={styles}
                    menuPosition="fixed"
                  />
                </div>
              </div>
              {taxReceiptsSummaryError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {taxReceiptsSummaryError}
                </div>
              ) : (
                <TaxReceiptsSummary
                  currencySymbol={selectedOrganization?.currencySymbol}
                  filteredFlag={filteredFlag}
                  setFilteredFlag={setFilteredFlag}
                  setLoadings={setLoadings}
                  setFilteredParams={setFilteredParams}
                  resetEntities={resetEntities}
                  year={year}
                />
              )}
              <div className="media-heading-button-container">
                <h6>Records</h6>
                <div className="add-and-filter-btns-div">
                  <div className="action-btns-div">
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setIssueReceiptsPopup(true)}
                    >
                      Issue Receipts
                    </button>
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setSendReminderPopup(true)}
                    >
                      Send Reminder
                    </button>
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setNewReceiptPopup(true)}
                    >
                      New Receipt
                    </button>
                  </div>
                  <div className="filter-and-reset-btns-div">
                    <Tippy content="Filter Records">
                      <button
                        className="filter-media-modal-btn"
                        type="button"
                        onClick={() => setFilterTaxReceiptsModal(true)}
                      >
                        <Icon icon={filter} size={24} />
                      </button>
                    </Tippy>
                    {filteredParams && (
                      <Tippy content="Reset Records">
                        <button
                          className="filter-media-modal-btn reset"
                          type="button"
                          onClick={handleResetFilter}
                        >
                          <Icon icon={refreshCw} size={24} />
                        </button>
                      </Tippy>
                    )}
                  </div>
                </div>
              </div>
              {allError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {allError}
                </div>
              ) : (
                <TaxReceiptTable
                  token={token}
                  filteredParams={filteredParams}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                  setPageSize={setPageSize}
                  setLoadings={setLoadings}
                  year={year}
                  countries={countries}
                  usaStates={usaStates}
                  provinces={provinces}
                />
              )}
            </>
          )}
        </div>
      </div>
      {/* issue receipts */}
      {issueReceiptsPopup && (
        <IssueReceiptsPopup
          token={token}
          setIssueReceiptsPopup={setIssueReceiptsPopup}
          yearOptions={yearOptions}
          templateOptions={templateOptions}
          variableOptions={variableOptions}
        />
      )}
      {/* send reminder */}
      {sendReminderPopup && (
        <SendReminderPopup
          token={token}
          setSendReminderPopup={setSendReminderPopup}
          yearOptions={yearOptions}
        />
      )}
      {/* new receipt */}
      {newReceiptPopup && (
        <NewReceiptPopup
          token={token}
          setNewReceiptPopup={setNewReceiptPopup}
          yearOptions={yearOptions}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
        />
      )}
      {/* filter */}
      {filterTaxReceiptsModal && (
        <FilterTaxReceiptsModal
          setFilterTaxReceiptsModal={setFilterTaxReceiptsModal}
          setFilteredParams={setFilteredParams}
          entityOptions={entityOptions}
          handleSelectChange={handleSelectChange}
          entitySelected={entitySelected}
          email={email}
          setEmail={setEmail}
          states={states}
          handleStatesChange={handleStatesChange}
          statesOptions={statesOptions}
          code={code}
          setCode={setCode}
          filterYear={filterYear}
          setFilterYear={setFilterYear}
          setYear={setYear}
          yearOptions={yearOptions}
          receiptNumber={receiptNumber}
          setReceiptNumber={setReceiptNumber}
          setLoadings={setLoadings}
          setFilteredFlag={setFilteredFlag}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          handleResetFilter={handleResetFilter}
          pageSize={pageSize}
          handlePerPage={handlePerPage}
        />
      )}
    </>
  );
};

export default TaxReceiptsComponent;
