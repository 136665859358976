import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAmount } from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// custom text field
const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const UpdateAmountPopup = ({
  token,
  setUpdateAmountPopup,
  updateAmountObj,
}) => {
  // redux state
  const { updateAmountLoading, updateAmountData } = useSelector(
    (state) => state.eCheck
  );

  // close modal
  const handleCloseModal = () => {
    if (!updateAmountLoading) {
      setUpdateAmountPopup(false);
    }
  };

  // amount
  const [amount, setAmount] = useState(updateAmountObj.chargedAmount);

  // amount change
  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    newValue = newValue.replace(/[^0-9.]/g, "");
    const parts = newValue.split(".");
    if (parts.length > 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue);
  };

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // update amount
  const handleUpdateChargedAmount = (e) => {
    e.preventDefault();
    dispatch(
      updateAmount({
        token,
        scheduleTag: updateAmountObj.scheduleTag,
        amount: parseFloat(amount),
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setUpdateAmountPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Update Charged Amount</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form onSubmit={handleUpdateChargedAmount}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="amount">Amount</label>
                  {updateAmountObj.campaignMinimumAmount && (
                    <span
                      style={{
                        marginLeft: 10 + "px",
                        fontSize: 13 + "px",
                        fontWeight: 600,
                        color: "#6a6c6f",
                      }}
                    >
                      Minimum Amount:{" "}
                      {updateAmountObj.campaignMinimumAmountcurrencySymbol}
                      {updateAmountObj.campaignMinimumAmount}
                    </span>
                  )}
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={amount}
                    onChange={handleAmountChange}
                    inputMode="decimal"
                    title=""
                    required
                    placeholder="Amount"
                    helperText={
                      amount !== "" &&
                      amount < updateAmountObj.campaignMinimumAmount
                        ? `Please enter an amount greater than or equal to ${updateAmountObj.campaignMinimumAmount}`
                        : ""
                    }
                    error={
                      amount !== "" &&
                      amount < updateAmountObj.campaignMinimumAmount
                    }
                  />
                </MDBCol>
              </MDBRow>
              {updateAmountData && updateAmountData.apiError ? (
                <div className="error-msg">{updateAmountData.apiError}</div>
              ) : (
                <>
                  {updateAmountData && updateAmountData.axiosError && (
                    <div className="error-msg">
                      {updateAmountData.axiosError}
                    </div>
                  )}
                </>
              )}
              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  style={{
                    opacity:
                      amount < updateAmountObj.campaignMinimumAmount ||
                      parseFloat(amount) === updateAmountObj.chargedAmount ||
                      updateAmountLoading
                        ? 0.5
                        : 1,
                    pointerEvents:
                      amount < updateAmountObj.campaignMinimumAmount ||
                      parseFloat(amount) === updateAmountObj.chargedAmount ||
                      updateAmountLoading
                        ? "none"
                        : "auto",
                  }}
                >
                  {updateAmountLoading ? (
                    <MetroSpinner
                      loading={updateAmountLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default UpdateAmountPopup;
