import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../config";

export const loginUser = createAsyncThunk("user/loginUser", async (userObj) => {
  const request = await axios.post(
    `${hostName}api/admin/v1/auth/login`,
    userObj
  );
  const result = await request.data.data;
  localStorage.setItem("user", JSON.stringify(result));
  result.organizations.forEach((data) => {
    if (data.tagNumber === result.organizationTag) {
      localStorage.setItem("organization", JSON.stringify(data));
    }
  });
  return result;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },
  reducers: {
    resetLoginStates: () => {
      return {
        loading: false,
        user: null,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        localStorage.removeItem("user");
        if (action.error.message === "Request failed with status code 401") {
          state.error = "Access Denied! Invalid Username or Password";
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export default userSlice.reducer;
export const { resetLoginStates } = userSlice.actions;
