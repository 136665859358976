import React from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Icon from "react-icons-kit";
import { check } from "react-icons-kit/feather/check";

const ETransferConfiguration = ({
  handleDeleteETransferModal,
  handleEditETransferModal,
}) => {
  // redux state
  const { eTransferConfigurationData } = useSelector(
    (state) => state.eTransfer
  );

  return (
    <>
      {eTransferConfigurationData && eTransferConfigurationData.length > 0 ? (
        <>
          {eTransferConfigurationData.map((data) => (
            <div
              key={data.tagNumber}
              className="media-box"
              style={{
                borderTop:
                  data.state === "I"
                    ? "3px solid #dc3545"
                    : data.state === "V" && !data.status
                    ? "3px solid #f2b92c"
                    : data.state === "V" && data.status
                    ? "3px solid #007c16"
                    : "",
                background: "#fff",
                width: "100%",
              }}
            >
              <div className="recurring-row">
                <div className="recurring-leftside">
                  <div className="recurring-campaign-image">
                    <img src={data.image} alt="mail" />
                  </div>
                  <div className="user-name-email">
                    <h1 className="media-name" style={{ fontSize: "24px" }}>
                      {data.username}{" "}
                      {data.emailAlias && <>/ {data.emailAlias}</>}
                    </h1>
                    <h4>{data.lastModifiedBy}</h4>
                  </div>
                </div>
              </div>
              <div className="media-table-container">
                <MDBTable className="recurring-table">
                  <MDBTableHead className="media-thead">
                    <tr>
                      <th scope="col">Campaign</th>
                      <th scope="col">Type</th>
                      <th scope="col">Host</th>
                      <th scope="col">Port</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody className="media-body">
                    <tr>
                      <td>
                        <p className="text-muted mb-0">{data.campaign}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.type}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.host}</p>
                      </td>

                      <td>
                        <p className="text-muted mb-0">{data.port}</p>
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div className="media-table-container">
                <MDBTable className="recurring-table">
                  <MDBTableHead className="media-thead">
                    <tr>
                      <th scope="col">Protocol</th>
                      <th scope="col">Encryption</th>
                      <th scope="col">Alert Email</th>
                      <th scope="col">Archive Folder</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody className="media-body">
                    <tr>
                      <td>
                        <p className="text-muted mb-0">{data.protocol}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.encryption}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.alertEmail}</p>
                      </td>

                      <td>
                        <p className="text-muted mb-0">{data.archiveFolder}</p>
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div className="media-table-container">
                <MDBTable className="recurring-table">
                  <MDBTableHead className="media-thead">
                    <tr>
                      <th scope="col">Authentication</th>
                      <th scope="col">Status</th>
                      <th scope="col">State</th>
                      <th scope="col">Validate Certificate</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody className="media-body">
                    <tr>
                      <td>
                        <p className="text-muted mb-0">{data.authentication}</p>
                      </td>
                      <td>
                        <div
                          style={{
                            backgroundColor: data.status
                              ? "#007C16"
                              : "#f2b92c",
                            width: 40 + "px",
                            height: 40 + "px",
                            borderRadius: 50 + "%",
                            color: data.status ? "#fff" : "#000",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 auto",
                          }}
                        >
                          <Icon size={24} icon={check} />
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            backgroundColor:
                              data.state === "I" ? "#dc3545" : "#007c16",
                            width: 40 + "px",
                            height: 40 + "px",
                            borderRadius: 50 + "%",
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 auto",
                          }}
                        >
                          <Icon size={24} icon={check} />
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            backgroundColor: data.validateCert
                              ? "#007C16"
                              : "#f2b92c",
                            width: 40 + "px",
                            height: 40 + "px",
                            borderRadius: 50 + "%",
                            color: data.validateCert ? "#fff" : "#000",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 auto",
                          }}
                        >
                          <Icon size={24} icon={check} />
                        </div>
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div className="media-table-container">
                <MDBTable className="recurring-table">
                  <MDBTableHead className="media-thead">
                    <tr>
                      <th scope="col">Start Date</th>
                      <th scope="col">Last Run</th>
                      <th scope="col">Created</th>
                      <th scope="col">Updated</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody className="media-body">
                    <tr>
                      <td>
                        <p className="text-muted mb-0">{data.startDate}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.lastRun}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.createDatetime}</p>
                      </td>

                      <td>
                        <p className="text-muted mb-0">{data.updateDatetime}</p>
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div className="edit-and-delete-media-div">
                <button
                  className="edit-btn"
                  onClick={() => {
                    handleEditETransferModal(data);
                  }}
                >
                  Edit
                </button>
                <button
                  className="delete-btn"
                  onClick={() => {
                    handleDeleteETransferModal(data);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No ETransfer Configuration Found">
            <img src={noDataFound} alt="No ETransfer Configuration Found" />
          </Tippy>
        </div>
      )}
    </>
  );
};

export default ETransferConfiguration;
