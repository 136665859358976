import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredDonorLastNMonthSummary } from "../../../../Store/Slices/Sidebar/Donors/DonorHighlightsSlice";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import Tippy from "@tippyjs/react";
import { MetroSpinner } from "react-spinners-kit";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import noDataFound from "../../../../Images/noDataFound.png";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

const NMonthSummary = ({ token, email }) => {
  // redux state
  const {
    donorFilteredLastNMonthSummaryLoading,
    donorLastNMonthSummaryData,
    donorLastNMonthSummaryError,
  } = useSelector((state) => state.donorHighlights);

  // states
  const [numberInput, setNumberInput] = useState(20);
  const [selectedNumber, setSelectedNumber] = useState(numberInput);
  const [popup, setPopup] = useState(false);

  // number change
  const handleNumberChange = (event) => {
    let newValue = parseInt(event.target.value, 10);
    if (newValue < 1) {
      newValue = 1;
    } else if (newValue > 20) {
      newValue = 20;
    }
    setNumberInput(newValue);
  };

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle filter
  const handleNumberFilter = () => {
    setPopup(false);
    // Check if numberInput is NaN, and if it is, set it to 1
    let filteredNumber = isNaN(numberInput) ? 1 : numberInput;
    setSelectedNumber(filteredNumber);
    const singleObj = {
      token,
      n: filteredNumber,
      email,
    };
    dispatch(getFilteredDonorLastNMonthSummary(singleObj))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  };

  return (
    <>
      <div className="box-header">
        <span>
          Last <span style={{ color: "#007c16" }}>{selectedNumber}</span> Month
          {selectedNumber > 1 && <>s</>} Summary
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span
            style={{ cursor: "pointer", color: "#6a6c6f" }}
            onClick={() => setPopup(!popup)}
          >
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Months</h4>
          <CustomTextField
            type="number"
            variant="outlined"
            fullWidth
            autoComplete="off"
            value={numberInput}
            onChange={handleNumberChange}
            min={1}
            max={20}
            size="small"
          />
          <div className="popup-filter-btn-div">
            <button type="button" onClick={handleNumberFilter}>
              Filter
            </button>
          </div>
        </div>
      </div>
      <div className="lifetime-content-wrapper">
        {donorFilteredLastNMonthSummaryLoading ? (
          <div className="content-wrappr-loader">
            <MetroSpinner
              loading={donorFilteredLastNMonthSummaryLoading}
              size={30}
              color="#007c16"
            />
          </div>
        ) : (
          <>
            {donorLastNMonthSummaryError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {donorLastNMonthSummaryError}
              </div>
            ) : (
              <>
                {donorLastNMonthSummaryData ? (
                  <>
                    <div className="data-item">
                      <div className="data-label">Total Contribution</div>
                      <div className="data-value">
                        {donorLastNMonthSummaryData.currencySymbol}
                        {donorLastNMonthSummaryData.totalContribution}
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-label">No of. Contribution</div>
                      <div className="data-value">
                        {donorLastNMonthSummaryData.numberOfContribution}
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-label">Since</div>
                      <div className="data-value">
                        {donorLastNMonthSummaryData.since}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="content-wrappr-loader">
                    <Tippy content="No Data Found">
                      <img
                        src={noDataFound}
                        alt="No Data Found"
                        width={`100px`}
                        height={`auto`}
                      />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default NMonthSummary;
