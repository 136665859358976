import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredDaySummaryByCampaign } from "../../Store/Slices/Sidebar/Dashboard/DaySummaryByCampaignSlice";
import { MetroSpinner } from "react-spinners-kit";
import DatePicker from "react-date-picker";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import { minus } from "react-icons-kit/feather/minus";
import noDataFound from "../../Images/noDataFound.png";

// Register the plugin to all charts:
ChartJS.register(ChartDataLabels);

const DaySummaryByCampaign = ({ token }) => {
  // redux state
  const {
    filteredDaySummaryByCampaignLoading,
    daySummaryByCampaignData,
    daySummaryByCampaignError,
  } = useSelector((state) => state.daySummaryByCampaign);

  // calculate total amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (
      daySummaryByCampaignData &&
      daySummaryByCampaignData.datasets &&
      daySummaryByCampaignData.datasets[0] &&
      daySummaryByCampaignData.datasets[0].data
    ) {
      if (daySummaryByCampaignData.datasets[0].data.length > 0) {
        const calculatedSum = daySummaryByCampaignData.datasets[0].data.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        const roundedSum = calculatedSum.toFixed(2);
        setSum(roundedSum);
      } else {
        setSum("0.00");
      }
    } else {
      setSum("0.00");
    }
  }, [daySummaryByCampaignData]);

  // states
  const [unformattedDate, setUnformattedDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState("");
  const [popup, setPopup] = useState(false);

  // setting unformatted date into the formatted date
  useEffect(() => {
    const year = unformattedDate.getFullYear().toString();
    const month = (unformattedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = unformattedDate.getDate().toString().padStart(2, "0");

    const formatted = `${year}-${month}-${day}`;
    setFormattedDate(formatted);
  }, [unformattedDate]);

  // dispatch
  const dispatch = useDispatch();

  // useNavigate
  const navigate = useNavigate();

  // handle date change
  const handleDateChange = (value) => {
    if (value.toDateString() !== unformattedDate.toDateString()) {
      setUnformattedDate(value);
      setPopup(false);
      const dateObj = new Date(value);
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      const formatted = `${year}-${month}-${day}`;
      const singleObj = {
        token,
        date: formatted,
      };
      dispatch(getFilteredDaySummaryByCampaign(singleObj))
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    }
  };

  return (
    <>
      <div className="text-and-filter-div">
        <span>
          Donations <Icon icon={minus} size={12} /> {formattedDate}{" "}
          <Icon icon={minus} size={12} />{" "}
          <span style={{ color: "#007C16" }}>
            {daySummaryByCampaignData && (
              <>{daySummaryByCampaignData.currencySymbol}</>
            )}
            {sum}
          </span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Date</h4>
          <DatePicker
            value={unformattedDate}
            onChange={(value) => handleDateChange(value)}
            clearIcon={false}
          />
        </div>
      </div>
      <div className="graph-container">
        {filteredDaySummaryByCampaignLoading ? (
          <MetroSpinner
            size={30}
            color="#007C16"
            loading={filteredDaySummaryByCampaignLoading}
          />
        ) : (
          <>
            {daySummaryByCampaignError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {daySummaryByCampaignError}
              </div>
            ) : (
              <>
                {daySummaryByCampaignData &&
                daySummaryByCampaignData.datasets &&
                daySummaryByCampaignData.datasets[0] &&
                daySummaryByCampaignData.datasets[0].data &&
                daySummaryByCampaignData.datasets[0].data.length > 0 ? (
                  <Bar
                    data={daySummaryByCampaignData}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            callback: (value) => {
                              if (value >= 1000) {
                                return value
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              } else {
                                return value.toString();
                              }
                            },
                          },
                        },
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          backgroundColor: "#fff",
                          titleColor: "#000",
                          bodyColor: "#000",
                          callbacks: {
                            label: (context) => {
                              if (context.datasetIndex === 0) {
                                // First dataset (Amount)
                                return (
                                  `${daySummaryByCampaignData.currencySymbol}` +
                                  context.formattedValue
                                );
                              } else if (context.datasetIndex === 1) {
                                // Second dataset (Number of Transactions)
                                return (
                                  "Transactions: " + context.formattedValue
                                );
                              } else {
                                return context.formattedValue;
                              }
                            },
                          },
                        },
                        datalabels: {
                          display: false,
                        },
                      },
                    }}
                  />
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Data Found">
                      <img src={noDataFound} alt="No Data Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DaySummaryByCampaign;
