/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

const SearchableDropdown = ({
  options,
  label,
  selectedVal,
  handleSelectedOrganization,
  handleChange,
  selectedOrganization,
}) => {
  // states
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputWidth, setInputWidth] = useState("auto");

  // ref
  const inputRef = useRef(null);
  const spanRef = useRef(null);

  // adjust input width
  const adjustInputWidth = () => {
    if (spanRef.current && inputRef.current) {
      spanRef.current.textContent = inputRef.current.value;
      const newWidth = spanRef.current.offsetWidth + 75; // Add some padding
      setInputWidth(newWidth < 250 ? 250 : newWidth);
    }
  };

  // attach click event listner on document
  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  // toggle
  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
    if (e && e.target !== inputRef.current) {
      // Construct the value to be passed to handleChange
      const value = selectedOrganization.location
        ? `${selectedOrganization.name} - ${selectedOrganization.location}`
        : selectedOrganization.name;

      // empty query
      setQuery("");

      // Call handleChange with the constructed value
      handleChange(value);
    }
  }

  // Utility function to check if the option is selected
  const isOptionSelected = (option, selectedVal) => {
    if (!selectedVal) return false; // early return if no selectedVal

    // extract name and location from selected val
    const [name, location] = selectedVal.split(" - ");

    // if location
    if (location) {
      const isSelected =
        option[label] === name.trim() && option.location === location.trim();
      return isSelected;
    }

    // if no location
    const isSelected = option[label] === selectedVal.trim();
    return isSelected;
  };

  // select option from dropdown menu
  const selectOption = (option, e) => {
    e.stopPropagation();

    setIsOpen(false);
    setQuery("");

    // If option.location is not null, include it in the handleChange call
    const value = option.location
      ? `${option[label]} - ${option.location}`
      : option[label];
    handleChange(value);
    handleSelectedOrganization(option);
  };

  // get display value
  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };

  // call adjust input width function
  useEffect(() => {
    adjustInputWidth();
  }, [getDisplayValue]);

  // filter options
  const filter = (options) => {
    return options.filter((option) => {
      const queryParts = query
        .split("-")
        .map((part) => part.trim().toLowerCase());
      const labelMatches = option[label].toLowerCase().includes(queryParts[0]);
      const locationMatches = queryParts[1]
        ? option.location?.toLowerCase().includes(queryParts[1])
        : true; // if there's no second part, we ignore the location

      return labelMatches && locationMatches;
    });
  };

  return (
    <div className="dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
            style={{ width: inputWidth }}
          />
        </div>
        <span ref={spanRef} className="hidden-span"></span>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options dropdown-menu ${isOpen ? "open" : ""}`}>
        {filter(options).map((option) => {
          const selected = isOptionSelected(option, selectedVal);
          return (
            <div
              onClick={(e) => selectOption(option, e)}
              className={`option ${selected ? "selected" : ""}`}
              key={`${option.tagNumber}`}
            >
              {option[label]}
              {option.location ? ` - ${option.location}` : ""}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchableDropdown;
