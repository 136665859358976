import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// get donor lifetime
export const getDonorLifetime = createAsyncThunk(
  "donorHighlights/getDonorLifetime",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/lifetime?email=${singleObj.email}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// get donor yearly
export const getDonorYearly = createAsyncThunk(
  "donorHighlights/getDonorYearly",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/yearly?email=${singleObj.email}&year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// get donor yearly - filtered
export const getFilteredDonorYearly = createAsyncThunk(
  "donorHighlights/getFilteredDonorYearly",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/yearly?email=${singleObj.email}&year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// get donor KeyDates
export const getDonorKeyDates = createAsyncThunk(
  "donorHighlights/getDonorKeyDates",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/keyDates?email=${singleObj.email}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// get donor last n month summary where n = no. of months
export const getDonorLastNMonthSummary = createAsyncThunk(
  "donorHighlights/getDonorLastNMonthSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/lastNthSummary?email=${singleObj.email}&n=${singleObj.n}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// get filtered donor last n month summary where n = no. of months
export const getFilteredDonorLastNMonthSummary = createAsyncThunk(
  "donorHighlights/getFilteredDonorLastNMonthSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/lastNthSummary?email=${singleObj.email}&n=${singleObj.n}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// get donor recent transactions
export const getDonorRecentTransactions = createAsyncThunk(
  "donorHighlights/getDonorRecentTransactions",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/recentTransaction?email=${singleObj.email}&n=${singleObj.n}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// get filtered donor recent transactions
export const getDonorFilteredRecentTransactions = createAsyncThunk(
  "donorHighlights/getDonorFilteredRecentTransactions",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/recentTransaction?email=${singleObj.email}&n=${singleObj.n}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// year over years
export const getDonorYearOverYears = createAsyncThunk(
  "donorHighlights/getDonorYearOverYears",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/yearOverYears?email=${singleObj.email}&numberOfYears=${singleObj.numberOfYears}&groupData=false`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter year over years
export const getDonorFilterYearOverYears = createAsyncThunk(
  "donorHighlights/getDonorFilterYearOverYears",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/yearOverYears?email=${singleObj.email}&numberOfYears=${singleObj.numberOfYears}&groupData=false`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// campaigns summary
export const getDonorCampaignsSummary = createAsyncThunk(
  "donorHighlights/getDonorCampaignsSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/campaignSummary?email=${singleObj.email}&year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter campaigns summary
export const getDonorFilterCampaignsSummary = createAsyncThunk(
  "donorHighlights/getDonorFilterCampaignsSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/highlights/campaignSummary?email=${singleObj.email}&year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const donorHighlightsSlice = createSlice({
  name: 'donorHighlights',
  initialState: {
    donorLifetimeLoading: false,
    donorLifetimeData: null,
    donorLifetimeError: null,
    donorYearlyLoading: false,
    donorFilteredYearlyLoading: false,
    donorYearlyData: null,
    donorYearlyError: null,
    donorKeyDatesLoading: false,
    donorKeyDatesData: null,
    donorKeyDatesError: null,
    donorLastNMonthSummaryLoading: false,
    donorFilteredLastNMonthSummaryLoading: false,
    donorLastNMonthSummaryData: null,
    donorLastNMonthSummaryError: null,
    recentTransactionsLoading: false,
    filteredRecentTransactionsLoading: false,
    recentTransactionsData: null,
    recentTransactionsError: null,
    yearOverYearsLoading: false,
    filterYearOverYearsLoading: false,
    yearOverYearsData: null,
    yearOverYearsError: null,
    campaignsSummaryLoading: false,
    filterCampaignsSummaryLoading: false,
    campaignsSummaryData: null,
    campaignsSummaryError: null,
  },
  extraReducers: (builder)=>{
    builder
    // get donor lifetime
    .addCase(getDonorLifetime.pending,(state)=>{
      state.donorLifetimeLoading = true;
      state.donorLifetimeData = null;
      state.donorLifetimeError = null;
    })
    .addCase(getDonorLifetime.fulfilled,(state,action)=>{
      state.donorLifetimeLoading = false;
      state.donorLifetimeData = action.payload;
      state.donorLifetimeError = null;
    })
    .addCase(getDonorLifetime.rejected,(state,action)=>{
      state.donorLifetimeLoading = false;
      state.donorLifetimeData = null;
      state.donorLifetimeError = action.error.message;
    })
    // get donor yearly
    .addCase(getDonorYearly.pending,(state)=>{
      state.donorYearlyLoading = true;
      state.donorYearlyData = null;
      state.donorYearlyError = null;
    })
    .addCase(getDonorYearly.fulfilled,(state,action)=>{
      state.donorYearlyLoading = false;
      state.donorYearlyData = action.payload;
      state.donorYearlyError = null;
    })
    .addCase(getDonorYearly.rejected,(state,action)=>{
      state.donorYearlyLoading = false;
      state.donorYearlyData = null;
      state.donorYearlyError = action.error.message;
    })
     // get filtered donor yearly
     .addCase(getFilteredDonorYearly.pending,(state)=>{
      state.donorFilteredYearlyLoading = true;
      state.donorYearlyData = null;
      state.donorYearlyError = null;
    })
    .addCase(getFilteredDonorYearly.fulfilled,(state,action)=>{
      state.donorFilteredYearlyLoading = false;
      state.donorYearlyData = action.payload;
      state.donorYearlyError = null;
    })
    .addCase(getFilteredDonorYearly.rejected,(state,action)=>{
      state.donorFilteredYearlyLoading = false;
      state.donorYearlyData = null;
      state.donorYearlyError = action.error.message;
    })
    // get donor key dates
    .addCase(getDonorKeyDates.pending,(state)=>{
      state.donorKeyDatesLoading = true;
      state.donorKeyDatesData = null;
      state.donorKeyDatesError = null;
    })
    .addCase(getDonorKeyDates.fulfilled,(state,action)=>{
      state.donorKeyDatesLoading = false;
      state.donorKeyDatesData = action.payload;
      state.donorKeyDatesError = null;
    })
    .addCase(getDonorKeyDates.rejected,(state,action)=>{
      state.donorKeyDatesLoading = false;
      state.donorKeyDatesData = null;
      state.donorKeyDatesError = action.error.message;
    })
     // get donor last n moth summary where n = no. of months
     .addCase(getDonorLastNMonthSummary.pending,(state)=>{
      state.donorLastNMonthSummaryLoading = true;
      state.donorLastNMonthSummaryData = null;
      state.donorLastNMonthSummaryError = null;
    })
    .addCase(getDonorLastNMonthSummary.fulfilled,(state,action)=>{
      state.donorLastNMonthSummaryLoading = false;
      state.donorLastNMonthSummaryData = action.payload;
      state.donorLastNMonthSummaryError = null;
    })
    .addCase(getDonorLastNMonthSummary.rejected,(state,action)=>{
      state.donorLastNMonthSummaryLoading = false;
      state.donorLastNMonthSummaryData = null;
      state.donorLastNMonthSummaryError = action.error.message;
    })
    // get filtered donor last n moth summary where n = no. of months
    .addCase(getFilteredDonorLastNMonthSummary.pending,(state)=>{
      state.donorFilteredLastNMonthSummaryLoading = true;
      state.donorLastNMonthSummaryData = null;
      state.donorLastNMonthSummaryError = null;
    })
    .addCase(getFilteredDonorLastNMonthSummary.fulfilled,(state,action)=>{
      state.donorFilteredLastNMonthSummaryLoading = false;
      state.donorLastNMonthSummaryData = action.payload;
      state.donorLastNMonthSummaryError = null;
    })
    .addCase(getFilteredDonorLastNMonthSummary.rejected,(state,action)=>{
      state.donorFilteredLastNMonthSummaryLoading = false;
      state.donorLastNMonthSummaryData = null;
      state.donorLastNMonthSummaryError = action.error.message;
    })
    // get recent transactions
    .addCase(getDonorRecentTransactions.pending,(state)=>{
      state.recentTransactionsLoading = true;
      state.recentTransactionsData = null;
      state.recentTransactionsError = null;
    })
    .addCase(getDonorRecentTransactions.fulfilled,(state,action)=>{
      state.recentTransactionsLoading = false;
      state.recentTransactionsData = action.payload;
      state.recentTransactionsError = null;
    })
    .addCase(getDonorRecentTransactions.rejected,(state,action)=>{
      state.recentTransactionsLoading = false;
      state.recentTransactionsData = null;
      state.recentTransactionsError = action.error.message;
    })
    // get filtered recent transactions
    .addCase(getDonorFilteredRecentTransactions.pending,(state)=>{
      state.filteredRecentTransactionsLoading = true;
      state.recentTransactionsData = null;
      state.recentTransactionsError = null;
    })
    .addCase(getDonorFilteredRecentTransactions.fulfilled,(state,action)=>{
      state.filteredRecentTransactionsLoading = false;
      state.recentTransactionsData = action.payload;
      state.recentTransactionsError = null;
    })
    .addCase(getDonorFilteredRecentTransactions.rejected,(state,action)=>{
      state.filteredRecentTransactionsLoading = false;
      state.recentTransactionsData = null;
      state.recentTransactionsError = action.error.message;
    })
    // get year over year
    .addCase(getDonorYearOverYears.pending,(state)=>{
      state.yearOverYearsLoading = true;
      state.yearOverYearsData = null;
      state.yearOverYearsError = null;
    })
    .addCase(getDonorYearOverYears.fulfilled,(state,action)=>{
      state.yearOverYearsLoading = false;
      state.yearOverYearsData = action.payload;
      state.yearOverYearsError = null;
    })
    .addCase(getDonorYearOverYears.rejected,(state,action)=>{
      state.yearOverYearsLoading = false;
      state.yearOverYearsData = null;
      state.yearOverYearsError = action.error.message;
    })
    // get filter year over year
    .addCase(getDonorFilterYearOverYears.pending,(state)=>{
      state.filterYearOverYearsLoading = true;
      state.yearOverYearsData = null;
      state.yearOverYearsError = null;
    })
    .addCase(getDonorFilterYearOverYears.fulfilled,(state,action)=>{
      state.filterYearOverYearsLoading = false;
      state.yearOverYearsData = action.payload;
      state.yearOverYearsError = null;
    })
    .addCase(getDonorFilterYearOverYears.rejected,(state,action)=>{
      state.filterYearOverYearsLoading = false;
      state.yearOverYearsData = null;
      state.yearOverYearsError = action.error.message;
    })
     // get campaigns summary
     .addCase(getDonorCampaignsSummary.pending,(state)=>{
      state.campaignsSummaryLoading = true;
      state.campaignsSummaryData = null;
      state.campaignsSummaryError = null;
    })
    .addCase(getDonorCampaignsSummary.fulfilled,(state,action)=>{
      state.campaignsSummaryLoading = false;
      state.campaignsSummaryData = action.payload;
      state.campaignsSummaryError = null;
    })
    .addCase(getDonorCampaignsSummary.rejected,(state,action)=>{
      state.campaignsSummaryLoading = false;
      state.campaignsSummaryData = null;
      state.campaignsSummaryError = action.error.message;
    })
    // get filter campaigns summary
    .addCase(getDonorFilterCampaignsSummary.pending,(state)=>{
      state.filterCampaignsSummaryLoading = true;
      state.campaignsSummaryData = null;
      state.campaignsSummaryError = null;
    })
    .addCase(getDonorFilterCampaignsSummary.fulfilled,(state,action)=>{
      state.filterCampaignsSummaryLoading = false;
      state.campaignsSummaryData = action.payload;
      state.campaignsSummaryError = null;
    })
    .addCase(getDonorFilterCampaignsSummary.rejected,(state,action)=>{
      state.filterCampaignsSummaryLoading = false;
      state.campaignsSummaryData = null;
      state.campaignsSummaryError = action.error.message;
    })
  }
});

export default donorHighlightsSlice.reducer;
