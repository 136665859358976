import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../../config";

// get channels summary
export const getChannelsSummary = createAsyncThunk(
  "channels/getChannelsSummary",
  async (token) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/channelSummary`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// get channels data
export const getChannelsData = createAsyncThunk(
  "channels/getChannelsData",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/channel?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter channels
export const filterChannels = createAsyncThunk(
  "channels/filterChannels",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "type",
      "organization_defined_name",
      "payment_processor",
      "status",
      "serial_number",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/channel?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// update channel
export const updateChannel = createAsyncThunk(
  "channels/updateChannel",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        organizationDefinedName: singleObj.organizationDefinedName,
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/channel/${singleObj.tagNumber}`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// get channel details
export const getChannelDetails = createAsyncThunk(
  "channels/getChannelDetails",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };

    const request = await axios.get(
      `${hostName}api/admin/v1/channel/${singleObj.tagNumber}?year=${singleObj.year}&month=${singleObj.month}&day=${singleObj.day}`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

const channelsSlice = createSlice({
  name: "channels",
  initialState: {
    // channels summary
    channelsSummaryLoading: false,
    channelsSummaryData: null,
    channelsSummaryError: null,
    // channels data
    channelsLoading: false,
    channelsData: null,
    channelsError: null,
    // update channel
    updateChannelLoading: false,
    updateChannelData: null,
    // channel details
    channelDetailsLoading: false,
    channelDetailsData: null,
    channelDetailsError: null,
  },
  extraReducers: (builder) => {
    builder
      // get channels summary
      .addCase(getChannelsSummary.pending, (state) => {
        state.channelsSummaryLoading = true;
        state.channelsSummaryData = null;
        state.channelsSummaryError = null;
      })
      .addCase(getChannelsSummary.fulfilled, (state, action) => {
        state.channelsSummaryLoading = false;
        state.channelsSummaryData = action.payload;
        state.channelsSummaryError = null;
      })
      .addCase(getChannelsSummary.rejected, (state, action) => {
        state.channelsSummaryLoading = false;
        state.channelsSummaryData = null;
        state.channelsSummaryError = action.error.message;
      })
      // get channels data
      .addCase(getChannelsData.pending, (state) => {
        state.channelsLoading = true;
        state.channelsData = null;
        state.channelsError = null;
      })
      .addCase(getChannelsData.fulfilled, (state, action) => {
        state.channelsLoading = false;
        state.channelsData = action.payload;
        state.channelsError = null;
      })
      .addCase(getChannelsData.rejected, (state, action) => {
        state.channelsLoading = false;
        state.channelsData = null;
        state.channelsError = action.error.message;
      })
      // filter channels
      .addCase(filterChannels.pending, (state) => {
        state.channelsLoading = true;
        state.channelsData = null;
        state.channelsError = null;
      })
      .addCase(filterChannels.fulfilled, (state, action) => {
        state.channelsLoading = false;
        state.channelsData = action.payload;
        state.channelsError = null;
      })
      .addCase(filterChannels.rejected, (state, action) => {
        state.channelsLoading = false;
        state.channelsData = null;
        state.channelsError = action.error.message;
      })
      // update channel
      .addCase(updateChannel.pending, (state) => {
        state.updateChannelLoading = true;
        state.updateChannelData = null;
      })
      .addCase(updateChannel.fulfilled, (state, action) => {
        state.updateChannelLoading = false;
        state.updateChannelData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const channelIndex = state.channelsData.data.findIndex(
            (channel) => channel.tagNumber === tagNumber
          );

          if (channelIndex !== -1) {
            state.channelsData.data[channelIndex] = data;
          }
        }
      })
      // get channels details
      .addCase(getChannelDetails.pending, (state) => {
        state.channelDetailsLoading = true;
        state.channelDetailsData = null;
        state.channelDetailsError = null;
      })
      .addCase(getChannelDetails.fulfilled, (state, action) => {
        state.channelDetailsLoading = false;
        state.channelDetailsData = action.payload;
        state.channelDetailsError = null;
      })
      .addCase(getChannelDetails.rejected, (state, action) => {
        state.channelDetailsLoading = false;
        state.channelDetailsData = null;
        state.channelDetailsError = action.error.message;
      });
  },
});

export default channelsSlice.reducer;
