import React from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";

const TaxReceiptsSummary = ({
  currencySymbol,
  filteredFlag,
  setFilteredFlag,
  setLoadings,
  resetEntities,
  setFilteredParams,
  year,
}) => {
  // redux state
  const { taxReceiptsSummaryData } = useSelector((state) => state.taxReceipts);

  // handle card click
  const handleFilteredFlag = (flag) => {
    if (filteredFlag !== flag) {
      setLoadings(true);
      resetEntities();
      setFilteredFlag(flag);
      setFilteredParams({
        apiData: {
          by: "state",
          state: [flag],
          year,
        },
        order: "asc",
        pageSize: "20",
      });
    }
  };

  return (
    <>
      {/* amounts */}
      <div className="taxreceipt-summary-row">
        {taxReceiptsSummaryData && taxReceiptsSummaryData.amounts.length > 0 ? (
          <>
            {taxReceiptsSummaryData.amounts.map((data, index) => (
              <div className="taxreceipt-summary-box" key={index} style={{pointerEvents: "none"}}>
                <h4>{data.label}</h4>
                <p>
                  {currencySymbol}
                  {data.amount}
                </p>
                <p>{data.transactions}</p>
                <div className="summary-progress-bar-div">
                  <div
                    className="summary-progress-bar"
                    style={{
                      width: data.percentage + "%",
                    }}
                  ></div>
                </div>
                <img src={data.image} alt="icon" />
              </div>
            ))}
          </>
        ) : (
          <div className="no-data-found-div">
            <Tippy content="No Tax Receipts Amount Summary Found">
              <img
                src={noDataFound}
                alt="No Tax Receipts Amount Summary Found"
              />
            </Tippy>
          </div>
        )}
      </div>
      {/* states */}
      <div className="taxreceipt-summary-row">
        {taxReceiptsSummaryData && taxReceiptsSummaryData.states.length > 0 ? (
          <>
            {taxReceiptsSummaryData.states.map((data, index) => (
              <div
                className="taxreceipt-summary-box states"
                key={index}
                onClick={() => handleFilteredFlag(data.filterFlag)}
                style={{
                  border:
                    filteredFlag === data.filterFlag && "2px solid #007c16",
                }}
              >
                <h4>{data.label}</h4>
                <p>{data.count}</p>
                <p>{data.percentage}%</p>
                <div className="summary-progress-bar-div">
                  <div
                    className="summary-progress-bar"
                    style={{
                      width: data.percentage + "%",
                    }}
                  ></div>
                </div>
                <img src={data.image} alt="icon" />
              </div>
            ))}
          </>
        ) : (
          <div className="no-data-found-div">
            <Tippy content="No Tax Receipts States Summary Found">
              <img
                src={noDataFound}
                alt="No Tax Receipts States Summary Found"
              />
            </Tippy>
          </div>
        )}
      </div>
    </>
  );
};

export default TaxReceiptsSummary;
