import React, { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { users } from "react-icons-kit/feather/users";
import { creditCard } from "react-icons-kit/feather/creditCard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  importRecords,
  clearPrevImportedData,
} from "../../Store/Slices/Sidebar/Data/DataSlice";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { MetroSpinner } from "react-spinners-kit";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const NewImportPopup = ({ token, setNewImportPopup }) => {
  // redux state
  const { newImportLoading, newImportData } = useSelector(
    (state) => state.dataManagement
  );

  // close modal
  const handleCloseModal = () => {
    if (!newImportLoading) {
      setNewImportPopup(false);
    }
  };

  // states
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [importSuccessMsg, setImportSuccessMsg] = useState(null);

  // ref
  const fileInputRef = useRef(null);

  // option change
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // file change
  const handleFileChange = (event) => {
    const allowedFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const file = event.target.files[0];
    if (file) {
      // Check file type
      if (!allowedFileTypes.includes(file.type)) {
        setSelectedFile(null);
        setFileError("Invalid file type. Only XLSX files are allowed.");
        return;
      }
      // File is valid
      setSelectedFile(file);
      setFileError(null);
      setImportSuccessMsg(null);
      dispatch(clearPrevImportedData());
    }
  };

  // import submit
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      importRecords({
        token,
        type: selectedOption,
        file: selectedFile,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setImportSuccessMsg(res.payload.successMsg);
        setSelectedFile(null);
        setSelectedOption(null);
        fileInputRef.current.value = null;
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>New Import</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form className="update-media-form" onSubmit={handleSubmit}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <h6>Select Import Type</h6>
                  <div className="import-radio-container">
                    <label
                      className={`radio-button ${
                        selectedOption === "transactions" ? "selected" : ""
                      }`}
                      onClick={() => handleOptionChange("transactions")}
                    >
                      <div className="icon">
                        <Icon
                          icon={creditCard}
                          size={80}
                          style={{ color: "#007c16" }}
                        />
                      </div>
                      <div className="label-text">Transactions</div>
                    </label>
                    <label
                      className={`radio-button ${
                        selectedOption === "donors" ? "selected" : ""
                      }`}
                      onClick={() => handleOptionChange("donors")}
                    >
                      <div className="icon">
                        <Icon
                          icon={users}
                          size={80}
                          style={{ color: "#007c16" }}
                        />
                      </div>
                      <div className="label-text">Donors</div>
                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>File (Upload XLSX File Only)</label>
                  <input
                    type="file"
                    accept=".xlsx"
                    onChange={handleFileChange}
                    className="edit-media-inputs"
                    ref={fileInputRef}
                  />
                </MDBCol>
              </MDBRow>
              {fileError && <div className="error-msg">{fileError}</div>}
              {newImportData && newImportData.apiError ? (
                <div className="error-msg">{newImportData.apiError}</div>
              ) : (
                <>
                  {newImportData && newImportData.axiosError && (
                    <div className="error-msg">{newImportData.axiosError}</div>
                  )}
                </>
              )}
              {importSuccessMsg && (
                <div className="success-msg">{importSuccessMsg}</div>
              )}
              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={newImportLoading}
                  style={{
                    opacity: !selectedFile || !selectedOption ? 0.5 : 1,
                    pointerEvents:
                      !selectedFile || !selectedOption ? "none" : "auto",
                  }}
                >
                  {newImportLoading ? (
                    <MetroSpinner
                      size={22}
                      color="#fff"
                      loading={newImportLoading}
                    />
                  ) : (
                    "IMPORT"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default NewImportPopup;
