/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/Notifications.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  filterNotifications,
  getAllNotifications,
  getConfigurationData,
  getUnreadNotifications,
  markAsRead,
} from "../../Store/Slices/Sidebar/Notifications/NotificationsSlice";
import { Toaster } from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { filter } from "react-icons-kit/feather/filter";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import axios from "axios";
import hostName from "../../config";
import NotificationsTable from "../Notifications/NotificationsTable";
import ConfigurationTable from "../Notifications/ConfigurationTable";
import FilterNotificationsModal from "../Notifications/FilterNotificationsModal";
import NotificationDetailsPopup from "../Notifications/NotificationDetailsPopup";
import EditNotificationPopup from "../Notifications/EditNotificationPopup";
import EditConfigurationPopup from "../Notifications/EditConfigurationPopup";

const NotificationsComponent = ({ token }) => {
  // redux state
  const { notificationsLoading, configurationLoading } = useSelector(
    (state) => state.notifications
  );

  // tabs
  const [activeTab, setActiveTab] = useState("Notifications");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // states for grid view
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("20");

  // page size is used in filter modal
  const [pageSize, setPageSize] = useState("20");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // filtered params
  const [filteredParams, setFilteredParams] = useState(null);

  // filter modal
  const [filterNotificationsModal, setFilterNotificationsModal] =
    useState(false);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get configuration data
  useEffect(() => {
    dispatch(getConfigurationData({ token }))
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // get all notifications data
  useEffect(() => {
    if (!filteredParams) {
      dispatch(
        getAllNotifications({
          token,
          page: 1,
          pageSize: "20",
          order: "asc",
          by: "status",
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    } else {
      dispatch(
        filterNotifications({
          token,
          page: 1,
          pageSize: filteredParams.pageSize,
          order: filteredParams.order,
          apiData: filteredParams.apiData,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setTotalPages(res.payload.links.lastPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
        }
      });
    }
  }, [filteredParams]);

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // all api options data states
  const [entityOptions, setEntityOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [transactionTypeOptions, setTransactionTypeOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [allOptionsLoading, setAllOptionsLoading] = useState(true);

  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    Promise.all([
      axios.get(
        `${hostName}api/admin/v1/lov?listName=notificationFilter`,
        axiosConfig
      ),
      axios.get(
        `${hostName}api/admin/v1/routeLov?routeName=notifications`,
        axiosConfig
      ),
    ])
      .then(([entitiesRes, routeRes]) => {
        // Handle filters
        setEntityOptions(entitiesRes.data.data);
        setStatusOptions(routeRes.data.data.notificationStatus);
        setStateOptions(routeRes.data.data.notificationState);
        setTransactionTypeOptions(
          routeRes.data.data.notificationTransactionType
        );
        setTypeOptions(routeRes.data.data.notificationType);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setAllOptionsLoading(false));
  }, []);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [
    notificationsLoading,
    allOptionsLoading,
    configurationLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // type
  const [type, setType] = useState([]);
  const handleTypeChange = (item) => {
    if (type.includes(item)) {
      setType(type.filter((checkedItem) => checkedItem !== item));
    } else {
      setType([...type, item]);
    }
  };

  // status
  const [status, setStatus] = useState([]);
  const handleStatusChange = (item) => {
    if (status.includes(item)) {
      setStatus(status.filter((checkedItem) => checkedItem !== item));
    } else {
      setStatus([...status, item]);
    }
  };

  // state
  const [state, setState] = useState([]);
  const handleStateChange = (item) => {
    if (state.includes(item)) {
      setState(state.filter((checkedItem) => checkedItem !== item));
    } else {
      setState([...state, item]);
    }
  };

  // transaction type
  const [transactionType, setTransactionType] = useState(null);

  // local create date time
  const [localCreateDateTime, setLocalCreateDateTime] = useState(null);

  // last modified date time
  const [lastModifiedDateTime, setLastModifiedDateTime] = useState(null);

  // sort by
  const [sortBy, setSortBy] = useState({
    value: "status",
    label: "Status",
  });

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // reset filter
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilteredParams(null);
      setFilterNotificationsModal(false);
    }
    setEntitySelected([]);
    setType([]);
    setStatus([]);
    setState([]);
    setTransactionType(null);
    setLastModifiedDateTime(null);
    setLocalCreateDateTime(null);
    setPageSize(perPage);
    setSortBy({
      value: "status",
      label: "Status",
    });
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // notification details
  const [notificationDetailsPopup, setNotificationDetailsPopup] =
    useState(false);
  const [notificationDetailsObj, setNotificationDetailsObj] = useState(null);

  const handleNotificationDetails = (obj) => {
    setNotificationDetailsPopup(true);
    setNotificationDetailsObj(obj);

    // call mark as read api when status is not read
    if (obj.status !== "Read") {
      dispatch(
        markAsRead({
          token,
          tagNumber: obj.tagNumber,
        })
      ).then((res) => {
        if (!res.payload.error) {
          // get unread notifications
          dispatch(getUnreadNotifications({ token }))
            .unwrap()
            .catch((err) => {
              if (err.message === "Request failed with status code 401") {
                localStorage.removeItem("user");
                navigate("/login");
              }
            });
        } else if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    }
  };

  // edit notification
  const [editNotificationPopup, setEditNotificationPopup] = useState(false);
  const [editNotificationObj, setEditNotificationObj] = useState(null);

  const handleEditNotification = (obj) => {
    setEditNotificationPopup(true);
    setEditNotificationObj(obj);
  };

  // edit configuration
  const [editConfigurationPopup, setEditConfigurationPopup] = useState(false);
  const [editConfigurationObj, setEditConfigurationObj] = useState(null);

  const handleEditConfiguration = (obj) => {
    setEditConfigurationPopup(true);
    setEditConfigurationObj(obj);
  };

  return (
    <>
      <Toaster />
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <div className="manage-organization">
              <div className="tabs-container">
                <div
                  className={`tab ${
                    activeTab === "Notifications" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Notifications")}
                >
                  Notifications
                </div>
                <div
                  className={`tab ${
                    activeTab === "Configuration" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Configuration")}
                >
                  Configuration
                </div>
              </div>

              {/* tab content */}
              {activeTab === "Notifications" && (
                <div className="tab-content">
                  <div
                    className="media-heading-button-container"
                    style={{ marginTop: "15px" }}
                  >
                    <h6>Notifications</h6>
                    <div className="add-and-filter-btns-div">
                      <div className="filter-and-reset-btns-div">
                        <Tippy content="Filter Records">
                          <button
                            className="filter-media-modal-btn"
                            type="button"
                            onClick={() => setFilterNotificationsModal(true)}
                          >
                            <Icon icon={filter} size={24} />
                          </button>
                        </Tippy>
                        {filteredParams && (
                          <Tippy content="Reset Records">
                            <button
                              className="filter-media-modal-btn reset"
                              type="button"
                              onClick={handleResetFilter}
                            >
                              <Icon icon={refreshCw} size={24} />
                            </button>
                          </Tippy>
                        )}
                      </div>
                    </div>
                  </div>
                  <NotificationsTable
                    token={token}
                    filteredParams={filteredParams}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    setTotalPages={setTotalPages}
                    setPageSize={setPageSize}
                    setLoadings={setLoadings}
                    handleNotificationDetails={handleNotificationDetails}
                    handleEditNotification={handleEditNotification}
                  />
                </div>
              )}
              {activeTab === "Configuration" && (
                <div className="tab-content">
                  <div
                    className="media-heading-button-container"
                    style={{ marginTop: "15px" }}
                  >
                    <h6>Configuration</h6>
                  </div>
                  <ConfigurationTable
                    handleEditConfiguration={handleEditConfiguration}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* filter */}
      {filterNotificationsModal && (
        <FilterNotificationsModal
          setFilterNotificationsModal={setFilterNotificationsModal}
          setFilteredParams={setFilteredParams}
          entityOptions={entityOptions}
          entitySelected={entitySelected}
          handleSelectChange={handleSelectChange}
          type={type}
          typeOptions={typeOptions}
          handleTypeChange={handleTypeChange}
          status={status}
          statusOptions={statusOptions}
          handleStatusChange={handleStatusChange}
          state={state}
          stateOptions={stateOptions}
          handleStateChange={handleStateChange}
          transactionType={transactionType}
          setTransactionType={setTransactionType}
          transactionTypeOptions={transactionTypeOptions}
          lastModifiedDateTime={lastModifiedDateTime}
          setLastModifiedDateTime={setLastModifiedDateTime}
          localCreateDateTime={localCreateDateTime}
          setLocalCreateDateTime={setLocalCreateDateTime}
          pageSize={pageSize}
          handlePerPage={handlePerPage}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          setLoadings={setLoadings}
          handleResetFilter={handleResetFilter}
        />
      )}

      {/* notification details */}
      {notificationDetailsPopup && (
        <NotificationDetailsPopup
          setNotificationDetailsPopup={setNotificationDetailsPopup}
          notificationDetailsObj={notificationDetailsObj}
        />
      )}

      {/* edit notification */}
      {editNotificationPopup && (
        <EditNotificationPopup
          token={token}
          setEditNotificationPopup={setEditNotificationPopup}
          editNotificationObj={editNotificationObj}
          stateOptions={stateOptions}
        />
      )}

      {/* edit configuration */}
      {editConfigurationPopup && (
        <EditConfigurationPopup
          token={token}
          setEditConfigurationPopup={setEditConfigurationPopup}
          editConfigurationObj={editConfigurationObj}
        />
      )}
    </>
  );
};

export default NotificationsComponent;
