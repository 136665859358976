import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";

const TotalDonations = () => {
  // redux state
  const { totalDonationsData, totalDonationsError } = useSelector(
    (state) => state.totalDonations
  );

  // formattion of data
  const formattedDonation =
    totalDonationsData !== null
      ? parseFloat(totalDonationsData.donation.replace(/,/g, ""))
      : 0;

  return (
    <div className="col-box bottom-most">
      <div className="icon-with-text">
        <span>
          Donations since{" "}
          {totalDonationsData !== null && (
            <span style={{ color: "#007C16" }}>
              {totalDonationsData.signupDate}
            </span>
          )}
        </span>
        <FontAwesomeIcon icon={faHandHoldingHeart} size="2xl" />
      </div>
      {totalDonationsError ? (
        <div className="error-msg" style={{ marginTop: 0 }}>
          {totalDonationsError}
        </div>
      ) : (
        <>
          {totalDonationsData !== null ? (
            <CountUp
              className="h1"
              start={0}
              end={formattedDonation}
              duration={2.5}
              decimals={2}
              decimal="."
              prefix={totalDonationsData.currencySymbol + " "}
              suffix=""
            />
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Data Found">
                <img src={noDataFound} alt="No Data Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TotalDonations;
