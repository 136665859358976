/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCallLog } from "../../Store/Slices/Sidebar/AppExplorer/AppExplorerSlice";
import { MetroSpinner } from "react-spinners-kit";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const CallLogPopup = ({ token, setCallLogPopup, callLogObj }) => {
  // redux state
  const { callLogLoading, callLogData, callLogError } = useSelector(
    (state) => state.appExplorer
  );

  // close modal
  const handleCloseModal = () => {
    if (!callLogLoading) {
      setCallLogPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get call log
  useEffect(() => {
    dispatch(
      getCallLog({
        token,
        integrationTag: callLogObj.tagNumber,
      })
    )
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media extra-width"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Notes - {callLogObj.applicationName}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            {callLogLoading ? (
              <div
                style={{
                  padding: 50 + "px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MetroSpinner
                  size={30}
                  color="#007C16"
                  loading={callLogLoading}
                />
              </div>
            ) : (
              <>
                {callLogError ? (
                  <div
                    style={{
                      padding: 50 + "px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="error-msg">{callLogError}</div>
                  </div>
                ) : (
                  <>
                    {callLogData?.length > 0 ? (
                      <>
                        <div className="transactions-table">
                          <MDBTable align="middle">
                            <MDBTableHead
                              className="thead"
                              style={{ zIndex: 0 }}
                            >
                              <tr>
                                <th scope="col">Notes</th>
                                <th scope="col">User</th>
                                <th scope="col">Date</th>
                              </tr>
                            </MDBTableHead>

                            <MDBTableBody>
                              {callLogData.map((data, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#fff" : "#fafafa",
                                  }}
                                  key={data.tagNumber}
                                >
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {data.notes}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {data.username}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="fw-normal mb-1"
                                      style={{
                                        fontSize: "16px",
                                      }}
                                    >
                                      {data.createDateTime}
                                    </p>
                                  </td>
                                </tr>
                              ))}
                            </MDBTableBody>
                          </MDBTable>
                        </div>
                      </>
                    ) : (
                      <div
                        className="no-data-found-div"
                        style={{ padding: 50 + "px" }}
                      >
                        <Tippy content="No Data Found" zIndex={100005}>
                          <img src={noDataFound} alt="No Data Found" />
                        </Tippy>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CallLogPopup;
