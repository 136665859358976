import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import {
  editManualTransaction,
  getDonorsByEmail,
} from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// get organization for currency symbol
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const EditTransactionsPopup = ({
  token,
  setEditTransactionPopup,
  editTransactionObj,
  campaignOptions,
  donorSpecific,
}) => {
  // state
  const [selectedOrganization] = useState(getOrganization()); // for currency symbol

  // redux state
  const { editManualTransactionLoading, editManualTransactionData } =
    useSelector((state) => state.transactions);

  // close modal
  const handleCloseModal = () => {
    if (!editManualTransactionLoading) {
      setEditTransactionPopup(false);
    }
  };

  // tender type radio
  const [selectedValue, setSelectedValue] = useState(
    editTransactionObj.cardType
  );
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // timer state
  const [timer, setTimer] = useState(null);

  // suggestions
  const [suggestions, setSuggestions] = useState([]);

  // email
  const [transactionEmail, setTransactionEmail] = useState(
    editTransactionObj.email
  );

  // event
  const handleTransactionEmail = (value) => {
    setTransactionEmail(value);
    // Clear any existing timer
    if (timer) {
      clearTimeout(timer);
    }

    // create a new timer of 1 second
    const newTimer = setTimeout(() => {
      if (value.length > 3) {
        dispatch(
          getDonorsByEmail({
            token,
            email: value,
          })
        ).then((res) => {
          if (res.payload && res.payload.data && res.payload.data.length > 0) {
            setSuggestions(res.payload.data);
          } else if (
            res.payload &&
            res.payload.data &&
            res.payload.data.length < 1
          ) {
            setSuggestions([]);
          } else if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      } else {
        setSuggestions([]);
      }
    }, 1000);

    setTimer(newTimer);
  };

  // bank details if tender type (selectedValue) is cheque
  const [bankName, setBankName] = useState(editTransactionObj.cardBrand);
  const [accountNo, setAccountNo] = useState(editTransactionObj.cardAccount);
  const [chequeNo, setChequeNo] = useState(editTransactionObj.cardIssueCode);

  // amount
  const [amount, setAmount] = useState(editTransactionObj.approvedAmount);

  // amount change
  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue);
  };

  // notes
  const [notes, setNotes] = useState(editTransactionObj.userNotes);

  // campaign
  const [selectedCampaign, setSelectedCampaign] = useState({
    value: "",
    label: "",
    minimumAmount: "",
  });

  // updating selected campaign
  useEffect(() => {
    if (campaignOptions) {
      const wantedCampaign = campaignOptions.find(
        (option) => option.value === editTransactionObj.campaignTag
      );
      if (wantedCampaign) {
        setSelectedCampaign(wantedCampaign);
      } else {
        setSelectedCampaign({
          value: "",
          label: "",
          minimumAmount: "",
        });
      }
    }
  }, [editTransactionObj, campaignOptions]);

  // date
  const [displayDateTime, setDisplayDateTime] = useState(null);
  const [apiDateTime, setApiDateTime] = useState(null);

  // Convert the formatted API response to a Date object for Edit datepicker to show
  useEffect(() => {
    if (editTransactionObj.transactionDate) {
      const parsedEditDateTime = new Date(
        editTransactionObj.transactionDate.replace(/-/g, "/")
      );
      setDisplayDateTime(parsedEditDateTime);
    }
  }, [editTransactionObj]);

  // Convert unformatted display date into formatted string for API
  useEffect(() => {
    if (displayDateTime) {
      const year = displayDateTime.getFullYear();
      const month = String(displayDateTime.getMonth() + 1).padStart(2, "0");
      const day = String(displayDateTime.getDate()).padStart(2, "0");
      const hours = String(displayDateTime.getHours()).padStart(2, "0");
      const minutes = String(displayDateTime.getMinutes()).padStart(2, "0");

      const formatted = `${year}-${month}-${day} ${hours}:${minutes}`;
      setApiDateTime(formatted);
    } else {
      setApiDateTime(null);
    }
  }, [displayDateTime]);

  // dispatch and use navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // edit manual transaction submit event
  const handleEditManualTransactionSubmit = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      tagNumber: editTransactionObj.transactionTag,
      tenderType: selectedValue,
      campaignTag: selectedCampaign.value,
      amount: parseFloat(amount),
      dateTime: apiDateTime,
      userNotes: notes,
      email: transactionEmail,
    };
    if (selectedValue === "cheque") {
      singleObj.bankName = bankName;
      singleObj.accountNumber = accountNo;
      singleObj.chequeNumber = chequeNo;
    }

    if (amount >= selectedCampaign.minimumAmount) {
      dispatch(editManualTransaction(singleObj)).then((res) => {
        if (res.payload.successMsg) {
          setEditTransactionPopup(false);
          toast.success(`${res.payload.successMsg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          res.payload.axiosError === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              Edit Manual Transaction - {editTransactionObj.transactionTag}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form
              className="update-media-form"
              onSubmit={handleEditManualTransactionSubmit}
            >
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Tender Type</label>
                  <div className="tender-row">
                    <div className="custom-radio">
                      <label>
                        <input
                          type="radio"
                          className="input-radio"
                          name="tenderType"
                          id="cashRadio"
                          value="cash"
                          checked={selectedValue === "cash"}
                          onChange={handleRadioChange}
                        />{" "}
                        Cash
                      </label>
                    </div>
                    <div className="custom-radio">
                      <label>
                        <input
                          type="radio"
                          className="input-radio"
                          name="tenderType"
                          id="chequeRadio"
                          value="cheque"
                          checked={selectedValue === "cheque"}
                          onChange={handleRadioChange}
                        />{" "}
                        Cheque
                      </label>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Email</label>
                  <div className="email-input-container">
                    <input
                      type="email"
                      className="edit-media-inputs"
                      placeholder="Email"
                      value={transactionEmail || ""}
                      onChange={(e) => handleTransactionEmail(e.target.value)}
                      readOnly={donorSpecific === true}
                    />
                    {/* suggestion popup */}
                    {suggestions.length > 0 && (
                      <div className="suggestions-popup">
                        {suggestions.map((suggestion, index) => (
                          <div
                            key={index}
                            className="suggestion-item"
                            onClick={() => {
                              setTransactionEmail(suggestion.email);
                              setSuggestions([]);
                            }}
                          >
                            <div className="suggestion-email">
                              {suggestion.email}
                            </div>
                            <div className="suggestion-name">
                              {suggestion.firstName} {suggestion.lastName}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </MDBCol>
              </MDBRow>
              {selectedValue === "cheque" && (
                <>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Bank Name</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder="Bank Name"
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Account No.</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder="Account No."
                        value={accountNo}
                        onChange={(e) => setAccountNo(e.target.value)}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Cheque No.</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder="Cheque No."
                        value={chequeNo}
                        onChange={(e) => setChequeNo(e.target.value)}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                </>
              )}
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Campaign</label>
                  <Select
                    styles={styles}
                    required
                    menuPlacement="bottom"
                    value={campaignOptions.find(
                      (option) => option.value === selectedCampaign.value
                    )}
                    onChange={(option) => setSelectedCampaign(option)}
                    options={campaignOptions}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Amount</label>
                  {selectedCampaign.minimumAmount && (
                    <span
                      style={{
                        marginLeft: 10 + "px",
                        fontSize: 13 + "px",
                        fontWeight: 600,
                        color: "#6a6c6f",
                      }}
                    >
                      Minimum Amount:
                      {selectedOrganization?.currencySymbol}
                      {selectedCampaign.minimumAmount}
                    </span>
                  )}
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={amount}
                    onChange={handleAmountChange}
                    required
                    placeholder="Amount"
                    helperText={
                      amount !== "" && amount < selectedCampaign.minimumAmount
                        ? `Please enter an amount greater than or equal to ${selectedCampaign.minimumAmount}`
                        : ""
                    }
                    error={
                      amount !== "" && amount < selectedCampaign.minimumAmount
                    }
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Notes</label>
                  <textarea
                    className="edit-media-inputs text-area"
                    value={notes || ""}
                    onChange={(e) => setNotes(e.target.value)}
                    maxLength={500}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Transaction Date</label>
                  <DatePicker
                    style={{ width: 100 + "%" }}
                    className="edit-media-inputs"
                    selected={displayDateTime}
                    onChange={(value) => setDisplayDateTime(value)}
                    showTimeSelect
                    timeIntervals={5}
                    timeFormat="HH:mm"
                    dateFormat="yyyy-MM-dd HH:mm"
                    timeCaption="Time"
                    placeholderText="Date and Time"
                    isClearable
                  />
                </MDBCol>
              </MDBRow>

              {editManualTransactionData &&
                editManualTransactionData.tagNumber ===
                  editTransactionObj.transactionTag &&
                (editManualTransactionData.apiError ||
                  editManualTransactionData.axiosError) && (
                  <div className="error-msg">
                    {editManualTransactionData.apiError ||
                      editManualTransactionData.axiosError}
                  </div>
                )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={editManualTransactionLoading}
                  style={{
                    opacity: amount < selectedCampaign.minimumAmount ? 0.5 : 1,
                    pointerEvents:
                      amount < selectedCampaign.minimumAmount ? "none" : "auto",
                  }}
                >
                  {editManualTransactionLoading ? (
                    <MetroSpinner
                      loading={editManualTransactionLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default EditTransactionsPopup;
