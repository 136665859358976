import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../Store/Slices/Sidebar/AppExplorer/AppExplorerSlice";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import connector from "../../Images/integration.png";
import connected from "../../Images/connected.png";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import DetailsDrawer from "./DetailsDrawer";
import toast from "react-hot-toast";

const Apps = ({
  token,
  organizations,
  handleCallLogPopup,
  handleDisconnectPopup,
  handleSharePopup,
  handleSwitchPopup,
  handleTestPopup,
  handleSyncPopup,
}) => {
  // redux state
  const { appsData } = useSelector((state) => state.appExplorer);

  // details drawer
  const [drawer, setDrawer] = useState(false);
  const [drawerObj, setDrawerObj] = useState(null);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // handle connect
  const handleConnect = (app) => {
    // Calculate the window's position to center it on the screen
    const width = 600;
    const height = 700;
    // Calculate the position to center the window
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    // Open the OAuth login in a new centered window
    const oauthWindow = window.open(
      `${app.baseUrl}`,
      "_blank",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    // Poll the new window for the OAuth response
    const interval = setInterval(() => {
      if (oauthWindow.closed) {
        clearInterval(interval);
      }

      try {
        // Check if the new window URL contains the code after redirection
        const urlParams = new URLSearchParams(oauthWindow.location.search);

        // Loop through requiredParameters and check if they exist in the URL
        const allParamsPresent = app.requiredParameters.every((param) =>
          urlParams.has(param)
        );

        if (allParamsPresent) {
          // Stop polling since we have the authorization code
          clearInterval(interval);

          // Log the retrieved parameters
          const retrievedParams = {};
          app.requiredParameters.forEach((param) => {
            retrievedParams[param] = urlParams.get(param);
          });

          // Add the integrationTag key
          retrievedParams["integrationTag"] = app.tagNumber;

          // Remove everything after the question mark, including the question mark itself
          const cleanTargetUrl = app.targetUrl.split("?")[0];

          // Close the OAuth window once the parameters are retrieved
          oauthWindow.close();

          // post request
          dispatch(
            postRequest({
              token,
              targetUrl: cleanTargetUrl,
              retrievedParams,
            })
          ).then((res) => {
            if (res.payload.successMsg) {
              // show success msg
              toast.success(`${res.payload.successMsg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (
              res.payload.axiosError === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else if (
              res.payload.apiError &&
              typeof res.payload.apiError === "string"
            ) {
              // show error msg
              toast.error(`${res.payload.apiError}`, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    }, 1000);
  };

  return (
    <>
      <div className="apps-wrapper">
        {appsData?.length > 0 ? (
          <>
            {appsData.map((app) => (
              <div key={app.tagNumber} className="app-box">
                <div
                  className="app-card-header"
                  style={{
                    background: app.brandColor,
                  }}
                >
                  <img
                    decoding="async"
                    src={app.state === "C" ? connected : connector}
                    alt={app.stateText}
                  />
                  <div
                    className={`tool-icon-2 ${
                      app.state === "C" && "connected"
                    }`}
                  >
                    <div
                      style={{ position: "relative", paddingBottom: "100%" }}
                    >
                      <img
                        decoding="async"
                        src={app.logo}
                        alt="logo"
                        style={{
                          position: "absolute",
                          top: "50%",
                          width: "100%",
                          maxWidth: "100px",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="app-card-body">
                  <h3>{app.applicationName}</h3>
                  <p
                    onClick={() => {
                      setDrawer(true);
                      setDrawerObj(app);
                    }}
                  >
                    {app.longDescriptionLimited}
                  </p>
                </div>
                <div className="app-card-footer">
                  <Tippy content={app.stateText}>
                    <div className="state-img">
                      <img src={app.stateImage} alt="state" />
                    </div>
                  </Tippy>
                  <Tippy content={app.statusText}>
                    <div className="status-img">
                      <img src={app.statusImage} alt="status" />
                    </div>
                  </Tippy>
                  <MDBDropdown dropup group>
                    <MDBDropdownToggle
                      color="success"
                      style={{
                        background: "#007c16",
                      }}
                    >
                      Actions
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem
                        link
                        onClick={(e) => {
                          e.preventDefault();
                          handleConnect(app);
                        }}
                      >
                        {app.state === "I" || app.state === "C"
                          ? "Reconnect"
                          : "Connect"}
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        link
                        style={{
                          pointerEvents: app.canTest ? "auto" : "none",
                          opacity: app.canTest ? 1 : 0.5,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleTestPopup(app);
                        }}
                      >
                        Test
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        link
                        onClick={(e) => {
                          e.preventDefault();
                          handleSwitchPopup(app);
                        }}
                        style={{
                          pointerEvents:
                            app.organizationIntegrationTag !== null
                              ? "auto"
                              : "none",
                          opacity:
                            app.organizationIntegrationTag !== null ? 1 : 0.5,
                        }}
                      >
                        Switch Status
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        link
                        style={{
                          pointerEvents:
                            organizations.length > 1 && app.canShare
                              ? "auto"
                              : "none",
                          opacity:
                            organizations.length > 1 && app.canShare ? 1 : 0.5,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSharePopup(app);
                        }}
                      >
                        Share
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        link
                        onClick={(e) => {
                          e.preventDefault();
                          handleCallLogPopup(app);
                        }}
                      >
                        Notes
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        link
                        style={{
                          pointerEvents:
                            app.status === "A" && app.state === "C"
                              ? "auto"
                              : "none",
                          opacity:
                            app.status === "A" && app.state === "C" ? 1 : 0.5,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSyncPopup(app);
                        }}
                      >
                        Sync
                      </MDBDropdownItem>
                      <>
                        {app?.state !== "N" && app?.status !== "N" && (
                          <MDBDropdownItem
                            link
                            onClick={(e) => {
                              e.preventDefault();
                              handleDisconnectPopup(app);
                            }}
                          >
                            Disconnect
                          </MDBDropdownItem>
                        )}
                      </>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="no-data-found-div">
            <Tippy content="No Apps Found">
              <img src={noDataFound} alt="No Apps Found" />
            </Tippy>
          </div>
        )}
      </div>

      {/* details drawer */}
      {drawer && <DetailsDrawer setDrawer={setDrawer} drawerObj={drawerObj} />}
    </>
  );
};

export default Apps;
