import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getDonorFilterYearOverYears } from "../../../../Store/Slices/Sidebar/Donors/DonorHighlightsSlice";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import Tippy from "@tippyjs/react";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import noDataFound from "../../../../Images/noDataFound.png";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
// Register the plugin to all charts:
ChartJS.register(ChartDataLabels);

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const YearOverYears = ({ token, email }) => {
  // redux state
  const { filterYearOverYearsLoading, yearOverYearsData, yearOverYearsError } =
    useSelector((state) => state.donorHighlights);

  // calculate total amount
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (yearOverYearsData && yearOverYearsData.values.length > 0) {
      const calculatedAmounts = yearOverYearsData.values.map((item) => {
        const { amounts } = item;
        return amounts.reduce((acc, curr) => acc + curr, 0);
      });

      const totalAmount = calculatedAmounts.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const roundedSum = totalAmount.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [yearOverYearsData]);

  // popup
  const [popup, setPopup] = useState(false);

  const [defaultYears, setDefaultYears] = useState("2");

  // number of years array
  const numberOfYearsOptions = [
    { value: "1", label: "01" },
    { value: "2", label: "02" },
    { value: "3", label: "03" },
    { value: "4", label: "04" },
    { value: "5", label: "05" },
  ];

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handle year change
  const handleNumberOfYearsChange = (value) => {
    if (defaultYears !== value) {
      setPopup(false);
      setDefaultYears(value);
      const singleObj = {
        token,
        numberOfYears: value,
        email,
      };
      dispatch(getDonorFilterYearOverYears(singleObj))
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    }
  };

  // parse data for line chart
  const parseData = (data) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Adding 1 to match the month index

    const months = Array.from({ length: 12 }, (_, index) => {
      const monthName = new Date(0, index).toLocaleString("en-US", {
        month: "short",
      });
      return monthName;
    });

    const datasets = data.map((item, index) => {
      const { year, amounts, months: dataMonths } = item;

      const formattedAmounts = Array.from({ length: 12 }, (_, monthIndex) => {
        if (
          year < currentYear ||
          (year === currentYear && monthIndex < currentMonth)
        ) {
          const month = monthIndex + 1;
          const monthIndexInData = dataMonths.indexOf(month);
          return monthIndexInData !== -1 ? amounts[monthIndexInData] : 0;
        } else {
          return null; // Use null for future months in the current year
        }
      });

      return {
        label: year.toString(),
        data: formattedAmounts,
        backgroundColor: generateBackgroundColors(data.length)[
          index % generateBackgroundColors(data.length).length
        ],
        borderColor: generateBorderColors(data.length)[
          index % generateBorderColors(data.length).length
        ],
        lineTension: 0.4,
        borderWidth: 2,
      };
    });

    return {
      labels: months,
      datasets: datasets,
    };
  };

  // parse data for line chart
  const formattedData = parseData(yearOverYearsData?.values ?? []);

  // genearte border colors
  function generateBorderColors(length) {
    const colors = [];

    // Define the colors in the gradient
    const gradientColors = [
      "#34495e",
      "#9b59b6",
      "#3498db",
      "#62cb31",
      "#ffb606",
    ];

    for (let i = 0; i < length; i++) {
      const index = i % gradientColors.length;
      colors.push(gradientColors[index]);
    }

    return colors;
  }

  // genearte background colors
  function generateBackgroundColors(length) {
    const colors = [];

    // Define the colors in the gradient
    const gradientColors = [
      "#a6b2bf", // lighter shade of #34495e
      "#e9d7f0", // lighter shade of #9b59b6
      "#bce4fc", // lighter shade of #3498db
      "#c4f2ad", // lighter shade of #62cb31
      "#ffe78a", // lighter shade of #ffb606
    ];

    for (let i = 0; i < length; i++) {
      const index = i % gradientColors.length;
      colors.push(gradientColors[index]);
    }

    return colors;
  }

  return (
    <>
      <div className="box-header">
        <span>
          Total Donations Amount -{" "}
          <span style={{ color: "#007c16" }}>
            {yearOverYearsData && <>{yearOverYearsData.currencySymbol}</>}
            {sum}
          </span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span
            style={{ cursor: "pointer", color: "#6a6c6f" }}
            onClick={() => setPopup(!popup)}
          >
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Number of Years</h4>
          <div style={{ width: 100 + "%" }}>
            <Select
              value={numberOfYearsOptions.find(
                (option) => option.value === defaultYears
              )}
              onChange={(option) => handleNumberOfYearsChange(option.value)}
              options={numberOfYearsOptions}
              styles={styles}
            />
          </div>
        </div>
      </div>
      <div className="lifetime-content-wrapper">
        {filterYearOverYearsLoading ? (
          <div className="content-wrappr-loader">
            <MetroSpinner
              loading={filterYearOverYearsLoading}
              size={30}
              color="#007c16"
            />
          </div>
        ) : (
          <>
            {yearOverYearsError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {yearOverYearsError}
              </div>
            ) : (
              <>
                {formattedData.datasets.length > 0 ? (
                  <div className="donor-line-chart-container">
                    <Line
                      data={formattedData}
                      options={{
                        scales: {
                          y: {
                            beginAtZero: true,
                            ticks: {
                              callback: (value) => {
                                if (value >= 1000) {
                                  return value
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                } else {
                                  return value.toString();
                                }
                              },
                            },
                          },
                        },
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: true,
                            position: "bottom",
                            align: "center",
                            labels: {
                              usePointStyle: true,
                              padding: 15,
                            },
                          },
                          tooltip: {
                            backgroundColor: "#fff",
                            titleColor: "#000",
                            bodyColor: "#000",
                            callbacks: {
                              label: (context) =>
                                `${yearOverYearsData.currencySymbol}` +
                                context.formattedValue,
                            },
                          },
                          datalabels: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <div className="content-wrappr-loader">
                    <Tippy content="No Data Found">
                      <img
                        src={noDataFound}
                        alt="No Data Found"
                        width={`100px`}
                        height={`auto`}
                      />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default YearOverYears;
