import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { MDBCheckbox } from "mdb-react-ui-kit";
import DatePicker from "react-date-picker";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// multi select animated components
const animatedComponents = makeAnimated();

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const FilterListViewModal = ({
  setFilterListViewModal,
  setListViewFilteredParams,
  listViewEntityOptions,
  entitySelectedListView,
  handleSelectChangeListView,
  chargedAmount,
  handleChargedAmountChange,
  campaignTagListView,
  handleCampaignTagChangeListView,
  emailListView,
  setEmailListView,
  invoiceListView,
  setInvoiceListView,
  batchIdListView,
  setBatchIdListView,
  createdByListView,
  setCreatedByListView,
  billingPeriodListView,
  handleBillingPeriodChangeListView,
  frequencyOptions,
  statusListView,
  handleStatusChangeListView,
  statusOptions,
  scheduleStatus,
  handleScheduleStatusChange,
  scheduleStatusOptions,
  instituteNumberListView,
  setInstituteNumberListView,
  instituteOptions,
  transitNumberListView,
  setTransitNumberListView,
  processDate,
  setProcessDate,
  returnDate,
  setReturnDate,
  listViewPageSize,
  handlePerPageInListView,
  sortByListView,
  setSortByListView,
  orderByListView,
  setOrderByListView,
  setLoadings,
  setFilteredFlag,
  handleListViewResetFilter,
}) => {
  // redux nodes
  const { nodesData } = useSelector((state) => state.transactions);

  // close modal
  const handleCloseModal = () => {
    setFilterListViewModal(false);
  };

  // should render fields?
  const shouldRenderChargedAmountField = entitySelectedListView.some(
    (option) => option.value === "charged_amount"
  );

  const shouldRenderCampaignTagField = entitySelectedListView.some(
    (option) => option.value === "campaign_tag"
  );

  const shouldRenderEmailField = entitySelectedListView.some(
    (option) => option.value === "email"
  );

  const shouldRenderInvoiceField = entitySelectedListView.some(
    (option) => option.value === "invoice"
  );

  const shouldRenderBatchIdField = entitySelectedListView.some(
    (option) => option.value === "batch_id"
  );

  const shouldRenderCreatedByField = entitySelectedListView.some(
    (option) => option.value === "created_by"
  );

  const shouldRenderBillingPeriodField = entitySelectedListView.some(
    (option) => option.value === "billing_period"
  );

  const shouldRenderStatusField = entitySelectedListView.some(
    (option) => option.value === "status"
  );

  const shouldRenderScheduleStatusField = entitySelectedListView.some(
    (option) => option.value === "schedule_status"
  );

  const shouldRenderInstituteNumberField = entitySelectedListView.some(
    (option) => option.value === "institute_number"
  );

  const shouldRenderTransitNumberField = entitySelectedListView.some(
    (option) => option.value === "transit_number"
  );

  const shouldRenderProcessDateField = entitySelectedListView.some(
    (option) => option.value === "process_date"
  );

  const shouldRenderReturnDateField = entitySelectedListView.some(
    (option) => option.value === "return_date"
  );

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // handle transit number
  const handleTransitNumber = (event) => {
    const input = event.target.value;
    // Use a regular expression to allow only digits and limit the length to 10.
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 10);
    setTransitNumberListView(sanitizedInput);
  };

  // handle process date
  const handleProcessDateChange = (value) => {
    if (value) {
      setProcessDate(value);
    } else {
      setProcessDate(null);
    }
  };

  // handle return date
  const handleReturnDateChange = (value) => {
    if (value) {
      setReturnDate(value);
    } else {
      setReturnDate(null);
    }
  };

  // form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Prepare the data to be sent as API parameters based on the selected options
    const apiData = {};

    // Add 'amount' to the apiData if it's selected
    if (shouldRenderChargedAmountField) {
      apiData.charged_amount = parseFloat(chargedAmount);
    }

    // Add 'campaign_tag' to the apiData if it's selected
    if (shouldRenderCampaignTagField) {
      apiData.campaign_tag = campaignTagListView;
    }

    // Add 'created_by' to the apiData if it's selected
    if (shouldRenderCreatedByField) {
      apiData.created_by = createdByListView;
    }

    // Add 'invoice' to the apiData if it's selected
    if (shouldRenderInvoiceField) {
      apiData.invoice = invoiceListView;
    }

    // Add 'email' to the apiData if it's selected
    if (shouldRenderEmailField) {
      apiData.email = emailListView;
    }

    // Add 'batch_id' to the apiData if it's selected
    if (shouldRenderBatchIdField) {
      apiData.batch_id = batchIdListView;
    }

    // Add 'billing_period' to the apiData if it's selected
    if (shouldRenderBillingPeriodField) {
      apiData.billing_period = billingPeriodListView;
    }

    // add status
    if (shouldRenderStatusField) {
      apiData.status = statusListView;
    }

    // add schedule status
    if (shouldRenderScheduleStatusField) {
      apiData.schedule_status = scheduleStatus;
    }

    // add institute number
    if (shouldRenderInstituteNumberField) {
      apiData.institute_number = instituteNumberListView;
    }

    // add transit number
    if (shouldRenderTransitNumberField) {
      apiData.transit_number = transitNumberListView;
    }

    // add process date/
    if (shouldRenderProcessDateField) {
      apiData.process_date = formatDate(processDate);
    }

    // add return date
    if (shouldRenderReturnDateField) {
      apiData.return_date = formatDate(returnDate);
    }

    // sort by
    if (sortByListView) {
      apiData.by = sortByListView.value;
    }

    // if amount > 0
    if (shouldRenderChargedAmountField && chargedAmount > 0) {
      setLoadings(true);
      setFilteredFlag(null);
      setFilterListViewModal(false);
      setListViewFilteredParams({
        apiData,
        pageSize: listViewPageSize,
        order: orderByListView.value,
      });
    } else if (!shouldRenderChargedAmountField) {
      setLoadings(true);
      setFilteredFlag(null);
      setFilterListViewModal(false);
      setListViewFilteredParams({
        apiData,
        pageSize: listViewPageSize,
        order: orderByListView.value,
      });
    }
  };

  // format date
  const formatDate = (dateObj) => {
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Filter eCheck Transactions</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <h6
              style={{
                borderBottom: 1 + "px solid #eaeaea",
                paddingBottom: 5 + "px",
              }}
            >
              Select Fields
            </h6>

            <Select
              isMulti
              components={animatedComponents}
              styles={styles}
              options={listViewEntityOptions}
              onChange={handleSelectChangeListView}
              value={entitySelectedListView}
            />
            <form
              style={{ marginTop: 10 + "px" }}
              className="update-media-form"
              onSubmit={handleFormSubmit}
            >
              {/* charged amount */}
              {shouldRenderChargedAmountField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "charged_amount"
                      )?.label
                    }
                  </label>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={chargedAmount}
                    onChange={(e) => handleChargedAmountChange(e)}
                    inputMode="decimal"
                    step="0.01"
                    title=""
                    required
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "charged_amount"
                      )?.label
                    }
                    helperText={
                      chargedAmount !== "" && chargedAmount <= 0
                        ? "Value must be greater than 0"
                        : ""
                    }
                    error={chargedAmount !== "" && chargedAmount <= 0}
                  />
                </div>
              )}
              {/* campaign_tag */}
              {shouldRenderCampaignTagField && nodesData && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "campaign_tag"
                      )?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {nodesData.campaign_tag.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={campaignTagListView.includes(item.value)}
                          onChange={() =>
                            handleCampaignTagChangeListView(item.value)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* email */}
              {shouldRenderEmailField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "email"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "email"
                      )?.label
                    }
                    required
                    value={emailListView}
                    onChange={(e) => setEmailListView(e.target.value)}
                  />
                </div>
              )}
              {/* invoice */}
              {shouldRenderInvoiceField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "invoice"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "invoice"
                      )?.label
                    }
                    required
                    value={invoiceListView}
                    onChange={(e) => setInvoiceListView(e.target.value)}
                  />
                </div>
              )}
              {/* batch_id */}
              {shouldRenderBatchIdField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "batch_id"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "batch_id"
                      )?.label
                    }
                    required
                    value={batchIdListView}
                    onChange={(e) => setBatchIdListView(e.target.value)}
                  />
                </div>
              )}
              {/* created_by */}
              {shouldRenderCreatedByField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "created_by"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "created_by"
                      )?.label
                    }
                    required
                    value={createdByListView}
                    onChange={(e) => setCreatedByListView(e.target.value)}
                  />
                </div>
              )}
              {/* billing_period */}
              {shouldRenderBillingPeriodField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "billing_period"
                      )?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {frequencyOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={billingPeriodListView.includes(item.value)}
                          onChange={() =>
                            handleBillingPeriodChangeListView(item.value)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* status */}
              {shouldRenderStatusField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "status"
                      )?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {statusOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={statusListView.includes(item.value)}
                          onChange={() =>
                            handleStatusChangeListView(item.value)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* schedule status */}
              {shouldRenderScheduleStatusField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "schedule_status"
                      )?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {scheduleStatusOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={scheduleStatus.includes(item.value)}
                          onChange={() =>
                            handleScheduleStatusChange(item.value)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* institute_number */}
              {shouldRenderInstituteNumberField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "institute_number"
                      )?.label
                    }
                  </label>
                  <Select
                    required
                    options={instituteOptions}
                    value={instituteOptions.find(
                      (option) => option.value === instituteNumberListView
                    )}
                    onChange={(option) =>
                      setInstituteNumberListView(option.value)
                    }
                    styles={styles}
                  />
                </div>
              )}
              {/* transit number */}
              {shouldRenderTransitNumberField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "transit_number"
                      )?.label
                    }
                  </label>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={transitNumberListView}
                    onChange={handleTransitNumber}
                    title=""
                    required
                    placeholder={
                      entitySelectedListView.find(
                        (option) => option.value === "transit_number"
                      )?.label
                    }
                  />
                </div>
              )}
              {shouldRenderProcessDateField && (
                <div className="input-margins-div">
                  <h6>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "process_date"
                      )?.label
                    }
                  </h6>
                  <DatePicker
                    value={processDate}
                    onChange={handleProcessDateChange}
                    clearIcon={false}
                    required
                  />
                </div>
              )}
              {shouldRenderReturnDateField && (
                <div className="input-margins-div">
                  <h6>
                    {
                      entitySelectedListView.find(
                        (option) => option.value === "return_date"
                      )?.label
                    }
                  </h6>
                  <DatePicker
                    value={returnDate}
                    onChange={handleReturnDateChange}
                    clearIcon={false}
                    required
                  />
                </div>
              )}
              {/* page size */}
              <div className="input-margins-div">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === listViewPageSize
                  )}
                  onChange={(option) => handlePerPageInListView(option.value)}
                  options={perPageOptions}
                  styles={styles}
                />
              </div>
              {/* sort by */}
              <div className="input-margins-div">
                <label>Sort by</label>
                <Select
                  value={sortByListView}
                  onChange={(option) => setSortByListView(option)}
                  options={listViewEntityOptions}
                  styles={styles}
                  isClearable
                />
              </div>
              {/* order by */}
              <div className="input-margins-div">
                <label>Order by</label>
                <Select
                  value={orderByListView}
                  onChange={(option) => setOrderByListView(option)}
                  options={[
                    { value: "asc", label: "Ascending" },
                    { value: "desc", label: "Descending" },
                  ]}
                  styles={styles}
                />
              </div>
              <div className="reset-and-filter-div">
                <button
                  type="button"
                  className="reset-fields-btn"
                  onClick={handleListViewResetFilter}
                >
                  RESET
                </button>
                <button type="submit" className="filter-records-btn">
                  FILTER
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterListViewModal;
