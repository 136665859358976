import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getFeeSummary = createAsyncThunk(
  "feeSummary/getFeeSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/fee?year=${singleObj.year}&month=${singleObj.month}&day=${singleObj.day}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredFeeSummary = createAsyncThunk(
  "filteredFeeSummary/getFilteredFeeSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/fee?year=${singleObj.year}&month=${singleObj.month}&day=${singleObj.day}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const feeSummarySlice = createSlice({
  name: "feeSummary",
  initialState: {
    feeSummaryLoading: false,
    feeSummaryData: null,
    feeSummaryError: null,
    filteredFeeSummaryLoading: false,
  },
  extraReducers: (builder) => {
    builder
      // first render
      .addCase(getFeeSummary.pending, (state) => {
        state.feeSummaryLoading = true;
        state.feeSummaryData = null;
        state.feeSummaryError = null;
      })
      .addCase(getFeeSummary.fulfilled, (state, action) => {
        state.feeSummaryLoading = false;
        state.feeSummaryData = action.payload;
        state.feeSummaryError = null;
      })
      .addCase(getFeeSummary.rejected, (state, action) => {
        state.feeSummaryLoading = false;
        state.feeSummaryData = null;
        state.feeSummaryError = action.error.message;
      })
      // filter render
      .addCase(getFilteredFeeSummary.pending, (state) => {
        state.filteredFeeSummaryLoading = true;
        state.feeSummaryData = null;
        state.feeSummaryError = null;
      })
      .addCase(getFilteredFeeSummary.fulfilled, (state, action) => {
        state.filteredFeeSummaryLoading = false;
        state.feeSummaryData = action.payload;
        state.feeSummaryError = null;
      })
      .addCase(getFilteredFeeSummary.rejected, (state, action) => {
        state.filteredFeeSummaryLoading = false;
        state.feeSummaryData = null;
        state.feeSummaryError = action.error.message;
      });
  },
});

export default feeSummarySlice.reducer;
