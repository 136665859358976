import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// get users --- array of objects
export const getCrmUsers = createAsyncThunk(
  "users/getCrmUsers",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/user`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// update user
export const updateCrmUser = createAsyncThunk(
  "users/updateCrmUser",
  async (singleObj) => {
    try {
      const data = {
        requestType: singleObj.requestType,
        firstName: singleObj.firstName,
        lastName: singleObj.lastName,
        email: singleObj.email,
        language: singleObj.language,
        status: singleObj.status,
        role: singleObj.role,
      };
      if (singleObj.accountPassword) {
        data.accountPassword = singleObj.accountPassword;
      }
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/user/${singleObj.tagNumber}`,
        data,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// delete user
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/user/${singleObj.tagNumber}`,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// add user
export const addUser = createAsyncThunk("users/addUser", async (singleObj) => {
  try {
    const data = {
      requestType: singleObj.requestType,
      firstName: singleObj.firstName,
      lastName: singleObj.lastName,
      email: singleObj.email,
      accountPassword: singleObj.accountPassword,
      language: singleObj.language,
      status: singleObj.status,
    };
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.post(
      `${hostName}api/admin/v1/user`,
      data,
      axiosConfig
    );
    const response = await request.data;
    return {
      data: response.data,
      successMsg: response.messageDetails,
      apiError: null,
      axiosError: null,
    };
  } catch (error) {
    return {
      data: null,
      successMsg: null,
      apiError: error.response.data.messageDetails,
      axiosError: error.message,
    };
  }
});

const crmUsersSlice = createSlice({
  name: "users",
  initialState: {
    usersLoading: false,
    usersData: null,
    usersError: null,
    updateUserLoading: false,
    updateUserData: null,
    deleteUserLoading: false,
    deleteUserData: null,
    addUserLoading: false,
    addUserData: null,
  },
  extraReducers: (builder) => {
    builder
      // get users
      .addCase(getCrmUsers.pending, (state) => {
        state.usersLoading = true;
        state.usersData = null;
        state.usersError = null;
      })
      .addCase(getCrmUsers.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.usersData = action.payload;
        state.usersError = null;
      })
      .addCase(getCrmUsers.rejected, (state, action) => {
        state.usersLoading = false;
        state.usersData = null;
        state.usersError = action.error.message;
      })
      // update user
      .addCase(updateCrmUser.pending, (state) => {
        state.updateUserLoading = true;
        state.updateUserData = null;
      })
      .addCase(updateCrmUser.fulfilled, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const userIndex = state.usersData.findIndex(
            (user) => user.tagNumber === tagNumber
          );
          if (userIndex !== -1) {
            state.usersData[userIndex] = data;
          }
        }
      })
      // delete user
      .addCase(deleteUser.pending, (state) => {
        state.deleteUserLoading = true;
        state.deleteUserData = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteUserLoading = false;
        state.deleteUserData = action.payload;
        const { axiosError, tagNumber } = action.payload;
        if (!axiosError) {
          state.usersData = state.usersData.filter(
            (data) => data.tagNumber !== tagNumber
          );
        }
      })
      // add user
      .addCase(addUser.pending, (state) => {
        state.addUserLoading = true;
        state.addUserData = null;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.addUserLoading = false;
        state.addUserData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.usersData.unshift(data);
        }
      });
  },
});

export default crmUsersSlice.reducer;
