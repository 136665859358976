import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render channels
export const getCampaignSummary = createAsyncThunk(
  "campaignSummary/getCampaignSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const checkedItemsQuery = singleObj.checkedItems
      .map((item) => `channelType[]=${item}`)
      .join("&");
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/campaignSummary?${checkedItemsQuery}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render channels
export const getFilteredCampaignSummary = createAsyncThunk(
  "filteredCampaignSummary/getFilteredCampaignSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const checkedItemsQuery = singleObj.checkedItems
      .map((item) => `channelType[]=${item}`)
      .join("&");
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/campaignSummary?${checkedItemsQuery}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// campaign year summary filter
export const getFilteredCampaignYearSummary = createAsyncThunk(
  "filteredCampaignYearSummary/getFilteredCampaignYearSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const checkedItemsQuery = singleObj.checkedItems
      .map((item) => `channelType[]=${item}`)
      .join("&");
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/campaignYearSummary?year=${singleObj.year}&${checkedItemsQuery}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// campaign year month summary filter
export const getFilteredCampaignYearMonthSummary = createAsyncThunk(
  "filteredCampaignYearMonthSummary/getFilteredCampaignYearMonthSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const checkedItemsQuery = singleObj.checkedItems
      .map((item) => `channelType[]=${item}`)
      .join("&");
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/campaignYearMonthSummary?year=${singleObj.year}&month=${singleObj.month}&${checkedItemsQuery}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// campaign year month day summary filter
export const getFilteredCampaignYearMonthDaySummary = createAsyncThunk(
  "filteredCampaignYearMonthDaySummary/getFilteredCampaignYearMonthDaySummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const checkedItemsQuery = singleObj.checkedItems
      .map((item) => `channelType[]=${item}`)
      .join("&");
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/campaignYearMonthDaySummary?year=${singleObj.year}&month=${singleObj.month}&day=${singleObj.day}&${checkedItemsQuery}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const campaignSummarySlice = createSlice({
  name: "campaignSummary",
  initialState: {
    campaignSummaryLoading: false,
    campaignSummaryData: null,
    campaignSummaryError: null,
    filteredCampaignSummaryLoading: false,
  },
  extraReducers: (builder) => {
    builder
      // first render channels
      .addCase(getCampaignSummary.pending, (state) => {
        state.campaignSummaryLoading = true;
        state.campaignSummaryData = null;
        state.campaignSummaryError = null;
      })
      .addCase(getCampaignSummary.fulfilled, (state, action) => {
        state.campaignSummaryLoading = false;
        state.campaignSummaryData = action.payload;
        state.campaignSummaryError = null;
      })
      .addCase(getCampaignSummary.rejected, (state, action) => {
        state.campaignSummaryLoading = false;
        state.campaignSummaryData = null;
        state.campaignSummaryError = action.error.message;
      })
      // filter render channels
      .addCase(getFilteredCampaignSummary.pending, (state) => {
        state.filteredCampaignSummaryLoading = true;
        state.campaignSummaryData = null;
        state.campaignSummaryError = null;
      })
      .addCase(getFilteredCampaignSummary.fulfilled, (state, action) => {
        state.filteredCampaignSummaryLoading = false;
        state.campaignSummaryData = action.payload;
        state.campaignSummaryError = null;
      })
      .addCase(getFilteredCampaignSummary.rejected, (state, action) => {
        state.filteredCampaignSummaryLoading = false;
        state.campaignSummaryData = null;
        state.campaignSummaryError = action.error.message;
      })
      // campaign year summary filter
      .addCase(getFilteredCampaignYearSummary.pending, (state) => {
        state.filteredCampaignSummaryLoading = true;
        state.campaignSummaryData = null;
        state.campaignSummaryError = null;
      })
      .addCase(getFilteredCampaignYearSummary.fulfilled, (state, action) => {
        state.filteredCampaignSummaryLoading = false;
        state.campaignSummaryData = action.payload;
        state.campaignSummaryError = null;
      })
      .addCase(getFilteredCampaignYearSummary.rejected, (state, action) => {
        state.filteredCampaignSummaryLoading = false;
        state.campaignSummaryData = null;
        state.campaignSummaryError = action.error.message;
      })
      // campaign year month summary filter
      .addCase(getFilteredCampaignYearMonthSummary.pending, (state) => {
        state.filteredCampaignSummaryLoading = true;
        state.campaignSummaryData = null;
        state.campaignSummaryError = null;
      })
      .addCase(
        getFilteredCampaignYearMonthSummary.fulfilled,
        (state, action) => {
          state.filteredCampaignSummaryLoading = false;
          state.campaignSummaryData = action.payload;
          state.campaignSummaryError = null;
        }
      )
      .addCase(
        getFilteredCampaignYearMonthSummary.rejected,
        (state, action) => {
          state.filteredCampaignSummaryLoading = false;
          state.campaignSummaryData = null;
          state.campaignSummaryError = action.error.message;
        }
      )
      // campaign year month day summary filter
      .addCase(getFilteredCampaignYearMonthDaySummary.pending, (state) => {
        state.filteredCampaignSummaryLoading = true;
        state.campaignSummaryData = null;
        state.campaignSummaryError = null;
      })
      .addCase(
        getFilteredCampaignYearMonthDaySummary.fulfilled,
        (state, action) => {
          state.filteredCampaignSummaryLoading = false;
          state.campaignSummaryData = action.payload;
          state.campaignSummaryError = null;
        }
      )
      .addCase(
        getFilteredCampaignYearMonthDaySummary.rejected,
        (state, action) => {
          state.filteredCampaignSummaryLoading = false;
          state.campaignSummaryData = null;
          state.campaignSummaryError = action.error.message;
        }
      );
  },
});

export default campaignSummarySlice.reducer;
