import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const NotificationDetailsPopup = ({
  setNotificationDetailsPopup,
  notificationDetailsObj,
}) => {
  // close modal
  const handleCloseModal = () => {
    setNotificationDetailsPopup(false);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Notification Details</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="transaction-details">
              {/* message */}
              {notificationDetailsObj.message && (
                <div className="notes-div">
                  <h5>Message</h5>
                  <div>
                    {notificationDetailsObj.message
                      .split("<br/>")
                      .map((line, index) => (
                        <p
                          key={index}
                          style={{
                            marginBottom: "4px",
                          }}
                        >
                          {line}
                        </p>
                      ))}
                  </div>
                </div>
              )}

              {/* notes */}
              {notificationDetailsObj.notes && (
                <div className="notes-div">
                  <h5>Notes</h5>
                  <div>
                    {notificationDetailsObj.notes
                      .split("<BR/>")
                      .map((line, index) => (
                        <p key={index} style={{ marginBottom: "4px" }}>
                          {line}
                        </p>
                      ))}
                  </div>
                </div>
              )}
            </div>
            <div
              className="reset-and-filter-div"
              style={{ padding: 10 + "px" }}
            >
              <button
                type="button"
                className="reset-fields-btn"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default NotificationDetailsPopup;
