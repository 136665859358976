/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { MetroSpinner } from "react-spinners-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCampaign,
  deleteModalMount,
} from "../../Store/Slices/Sidebar/Campaigns/CampaignSlice";

function DeleteCampaignModal({ token, setOpen, id }) {
  // backshadow variants
  const backVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  // modal variants
  const modalVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const dispatch = useDispatch();

  const { deleteLoader, deleteError } = useSelector((state) => state.campaign);

  useEffect(() => {
    // delete modal open then show initial state
    dispatch(deleteModalMount());
  }, []);

  const handleDelete = () => {
    dispatch(deleteCampaign({ token, id }));
  };

  const handleCloseModal = () => {
    if (!deleteLoader) {
      setOpen(false);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Delete Campaign</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="delete-consequences-container">
              <p style={{ color: "#DC3545" }}>
                Are you sure you want to delete this campaign?
              </p>
              <p style={{ color: "#6a6c6f" }}>You cannot undo this action.</p>
            </div>

            {deleteError && <div className="error-msg">{deleteError}</div>}

            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="submit delete"
                onClick={handleDelete}
                disabled={deleteLoader}
              >
                {deleteLoader ? (
                  <MetroSpinner loading={deleteLoader} size={22} color="#fff" />
                ) : (
                  "DELETE"
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}

export default DeleteCampaignModal;
