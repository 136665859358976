import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

export const getLast12MonthsData = createAsyncThunk(
  "last12MonthsData/getLast12MonthsData",
  async (adminToken) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${adminToken}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/lastTwelveMonthSummary`,
      axiosConfig
    );
    const response = await request.data.data;
    if (response.values.length > 0) {
      return {
        currencySymbol: response.currencySymbol,
        labels: response.values.map((individualData) => individualData.month),
        datasets: [
          {
            label: "Amount",
            data: response.values.map((individualData) =>
              parseFloat(individualData.amount.replace(/,/g, ""))
            ),
            backgroundColor: "#d3ecc8",
            borderColor: "#007C16",
            fill: true,
            lineTension: 0.4,
          },
        ],
      };
    } else {
      return {
        currencySymbol: response.currencySymbol
      }
    }
  }
);

const last12MonthsDataSlice = createSlice({
  name: "last12monthsData",
  initialState: {
    last12MonthsLoading: false,
    last12MonthsData: null,
    last12MonthsError: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLast12MonthsData.pending, (state) => {
        state.last12MonthsLoading = true;
        state.last12MonthsData = null;
        state.last12MonthsError = null;
      })
      .addCase(getLast12MonthsData.fulfilled, (state, action) => {
        state.last12MonthsLoading = false;
        state.last12MonthsData = action.payload;
        state.last12MonthsError = null;
      })
      .addCase(getLast12MonthsData.rejected, (state, action) => {
        state.last12MonthsLoading = false;
        state.last12MonthsData = null;
        state.last12MonthsError = action.error.message;
      });
  },
});

export default last12MonthsDataSlice.reducer;
