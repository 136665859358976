import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../config";

// get news
export const getNews = createAsyncThunk("news/getNews", async () => {
  const request = await axios.get(`${hostName}api/v1/merviceNews`);
  const response = await request.data;
  const newResponse = await response.data.map((item) => ({
    mediaType: item.mediaType,
    url: item.url,
    duration: item.duration,
    name: item.name,
    description: item.description,
  }));
  localStorage.setItem("newsData", JSON.stringify(newResponse));
  return newResponse;
});

const newsSlice = createSlice({
  name: "news",
  initialState: {
    newsLoading: true,
    newsData: null,
    newsError: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNews.pending, (state) => {
        state.newsLoading = true;
        state.newsData = null;
        state.newsError = null;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.newsLoading = false;
        state.newsData = action.payload;
        state.newsError = null;
      })
      .addCase(getNews.rejected, (state, action) => {
        state.newsLoading = false;
        state.newsData = null;
        state.newsError = action.error.message;
      });
  },
});

export default newsSlice.reducer;
