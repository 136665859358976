import { AnimatePresence } from "framer-motion";
import DonorBox from "./DonorBox";

const Donors = ({
  handleEditDonor,
  handleDeleteDonor,
  donorsData,
  goToSpecificDonor,
  loadedImages,
}) => {
  return (
    <AnimatePresence>
      {donorsData.data.map((donor) => (
        <DonorBox
          key={donor.tagNumber}
          donor={donor}
          goToSpecificDonor={goToSpecificDonor}
          handleEditDonor={handleEditDonor}
          handleDeleteDonor={handleDeleteDonor}
          loadedImages={loadedImages}
        />
      ))}
    </AnimatePresence>
  );
};

export default Donors;
