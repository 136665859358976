import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendEmailInECheck } from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import {
  filterListViewRecords,
  readListViewData,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { MetroSpinner } from "react-spinners-kit";
import Icon from "react-icons-kit";
import { basic_paperplane } from "react-icons-kit/linea/basic_paperplane";
import { basic_download } from "react-icons-kit/linea/basic_download";
import { basic_todo_txt } from "react-icons-kit/linea/basic_todo_txt";
import { software_pencil } from "react-icons-kit/linea/software_pencil";
import { basic_trashcan_remove } from "react-icons-kit/linea/basic_trashcan_remove";
import toast from "react-hot-toast";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import axios from "axios";
import hostName from "../../config";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const ECheckTable = ({
  token,
  listViewFilteredParams,
  listViewPerPage,
  setListViewPerPage,
  listViewCurrentPage,
  setListViewCurrentPage,
  listViewTotalPages,
  setListViewTotalPages,
  setListViewPageSize,
  handleCancelPaymentPopup,
  handleUpdateAmountPopup,
  handleTransactionDetailsPopup,
  setLoadings,
}) => {
  // redux state
  const { listViewData } = useSelector((state) => state.eCheck);

  // redux state
  const { sendEmailInECheckLoading, sendEmailInECheckData } = useSelector(
    (state) => state.eCheck
  );

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (listViewPerPage !== value) {
      setListViewPerPage(value);
      setLoadings(true);
      if (listViewFilteredParams) {
        dispatch(
          filterListViewRecords({
            token,
            page: 1,
            pageSize: value,
            order: listViewFilteredParams.order,
            apiData: listViewFilteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          readListViewData({
            token,
            page: 1,
            pageSize: value,
            by: "email",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (listViewCurrentPage !== value) {
      setListViewCurrentPage(value);
      setLoadings(true);
      if (listViewFilteredParams) {
        dispatch(
          filterListViewRecords({
            token,
            page: value,
            pageSize: listViewPerPage,
            order: listViewFilteredParams.order,
            apiData: listViewFilteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          readListViewData({
            token,
            page: value,
            pageSize: listViewPerPage,
            by: "email",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // email send event
  const handleSendEmail = (data) => {
    dispatch(
      sendEmailInECheck({
        token,
        tagNumber: data.transactionTag,
      })
    ).then((res) => {
      if (!res.payload.axiosError) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(`${res.payload.apiError}`, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // download receipt states
  const [downloadReceiptLoading, setDownloadReceiptLoading] = useState(false);
  const [downloadReceiptTransactionTag, setDownloadReceiptTransactionTag] =
    useState(null);

  // handle download receipt
  const handleDownloadReceipt = (data) => {
    setDownloadReceiptLoading(true);
    setDownloadReceiptTransactionTag(data.transactionTag);

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    };

    axios
      .get(
        `${hostName}api/admin/v1/transaction/reports/receipt?pTransactionTag=${data.transactionTag}`,
        axiosConfig
      )
      .then((response) => {
        // download pdf
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${data.transactionTag}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          toast.error(`${error.message}`, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .finally(() => setDownloadReceiptLoading(false));
  };

  return (
    <>
      {listViewData && listViewData.data.length > 0 ? (
        <>
          <div className="transactions-table">
            <MDBTable align="middle">
              <MDBTableHead className="thead">
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">Campaign</th>
                  <th scope="col">Charged Amount</th>
                  <th scope="col">Billing Period</th>
                  <th scope="col">Institute Name</th>
                  <th scope="col">Transit Number</th>
                  <th scope="col">Account#</th>
                  <th scope="col">Status</th>
                  <th scope="col">Process Date</th>
                  <th scope="col">Settlement Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {listViewData.data.map((data, index) => (
                  // no unique key available in data
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                    }}
                  >
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.email}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.campaign}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{
                          fontSize: "16px",
                          textAlign: "right",
                        }}
                      >
                        {data.currencySymbol}
                        {data.chargedAmount}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.billingPeriod}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.instituteName}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.transitNumber}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.accountNumber}
                      </p>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Tippy content={data.scheduleStatusText} zIndex={10004}>
                        <img
                          src={data.scheduleStatusImage}
                          alt="status"
                          style={{
                            width: 30 + "px",
                            cursor: "pointer",
                          }}
                        />
                      </Tippy>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        {data.processDate && <>{data.processDate}</>}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        {data.settlementDate && <>{data.settlementDate}</>}
                      </p>
                    </td>
                    <td className="icons-td">
                      <div className="actions-icon-container">
                        {/* details */}
                        <Tippy content="Transaction Details" zIndex={10004}>
                          <span
                            onClick={() => handleTransactionDetailsPopup(data)}
                          >
                            <Icon icon={basic_todo_txt} size={20} />
                          </span>
                        </Tippy>
                        {/* send email */}
                        {sendEmailInECheckData &&
                        sendEmailInECheckData.tagNumber ===
                          data.transactionTag &&
                        sendEmailInECheckLoading ? (
                          <span className="loader-span">
                            <MetroSpinner
                              loading={sendEmailInECheckLoading}
                              color="#007c16"
                              size={20}
                            />
                          </span>
                        ) : (
                          <Tippy content="Send Email" zIndex={10004}>
                            <span
                              onClick={() => handleSendEmail(data)}
                              style={{
                                opacity: data.transactionTag ? 1 : 0.5,
                                pointerEvents: data.transactionTag
                                  ? "auto"
                                  : "none",
                              }}
                            >
                              <Icon icon={basic_paperplane} size={20} />
                            </span>
                          </Tippy>
                        )}
                        {/* download receipt */}
                        {downloadReceiptLoading &&
                        downloadReceiptTransactionTag ===
                          data.transactionTag ? (
                          <span className="loader-span">
                            <MetroSpinner
                              loading={downloadReceiptLoading}
                              color="#007c16"
                              size={20}
                            />
                          </span>
                        ) : (
                          <Tippy content="Download Receipt" zIndex={10004}>
                            <span
                              onClick={() => handleDownloadReceipt(data)}
                              style={{
                                opacity: data.transactionTag ? 1 : 0.5,
                                pointerEvents: data.transactionTag
                                  ? "auto"
                                  : "none",
                              }}
                            >
                              <Icon icon={basic_download} size={20} />
                            </span>
                          </Tippy>
                        )}
                        {/* update charged amount */}
                        <Tippy content="Update Charged Amount" zIndex={10004}>
                          <span
                            style={{
                              opacity: data.canCancel ? 1 : 0.5,
                              pointerEvents: data.canCancel ? "auto" : "none",
                            }}
                            onClick={() => handleUpdateAmountPopup(data)}
                          >
                            <Icon icon={software_pencil} size={20} />
                          </span>
                        </Tippy>
                        {/* cancel payment */}
                        <Tippy content="Delete Payment" zIndex={10004}>
                          <span
                            style={{
                              opacity: data.scheduleStatus === "Q" ? 1 : 0.5,
                              pointerEvents:
                                data.scheduleStatus === "Q" ? "auto" : "none",
                            }}
                            onClick={() => handleCancelPaymentPopup(data)}
                          >
                            <Icon icon={basic_trashcan_remove} size={20} />
                          </span>
                        </Tippy>
                      </div>
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          </div>
          <div className="transactions-pagination">
            <div className="per-page-and-pagination">
              <div className="per-page">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === String(listViewPerPage)
                  )}
                  onChange={(option) => handlePerPage(option.value)}
                  options={perPageOptions}
                  styles={styles}
                  menuPlacement="top"
                />
              </div>
              <ResponsivePagination
                current={listViewCurrentPage}
                total={listViewTotalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No eCheck Transactions Found">
            <img src={noDataFound} alt="No eCheck Transactions Found" />
          </Tippy>
        </div>
      )}
    </>
  );
};

export default ECheckTable;
