import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MDBCheckbox } from "mdb-react-ui-kit";
import DatePicker from "react-date-picker";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// multi select animated components
const animatedComponents = makeAnimated();

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const FilterNotificationsModal = ({
  setFilterNotificationsModal,
  setFilteredParams,
  entityOptions,
  entitySelected,
  handleSelectChange,
  type,
  typeOptions,
  handleTypeChange,
  status,
  statusOptions,
  handleStatusChange,
  state,
  stateOptions,
  handleStateChange,
  transactionType,
  setTransactionType,
  transactionTypeOptions,
  lastModifiedDateTime,
  setLastModifiedDateTime,
  localCreateDateTime,
  setLocalCreateDateTime,
  pageSize,
  handlePerPage,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
  setLoadings,
  handleResetFilter,
}) => {
  // close modal
  const handleCloseModal = () => {
    setFilterNotificationsModal(false);
  };

  // should render fields?
  const shouldRenderTypeField = entitySelected.some(
    (option) => option.value === "type"
  );

  const shouldRenderStateField = entitySelected.some(
    (option) => option.value === "state"
  );

  const shouldRenderTransactionTypeField = entitySelected.some(
    (option) => option.value === "transaction_type"
  );

  const shouldRenderLocalCreateDateTimeField = entitySelected.some(
    (option) => option.value === "local_create_datetime"
  );

  const shouldRenderLastModifiedDateTimeField = entitySelected.some(
    (option) => option.value === "last_modified_datetime"
  );

  const shouldRenderStatusField = entitySelected.some(
    (option) => option.value === "status"
  );

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // local_create_datetime
  const handleLocalCreateDateTime = (value) => {
    if (value) {
      setLocalCreateDateTime(value);
    } else {
      setLocalCreateDateTime(null);
    }
  };

  // last_modified_datetime
  const handleLastModifiedDateTime = (value) => {
    if (value) {
      setLastModifiedDateTime(value);
    } else {
      setLastModifiedDateTime(null);
    }
  };

  // filter event
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Prepare the data to be sent as API parameters based on the selected options
    const apiData = {};

    // Add 'type' to the apiData if it's selected
    if (shouldRenderTypeField) {
      apiData.type = type;
    }

    // Add 'transaction_type' to the apiData if it's selected
    if (shouldRenderTransactionTypeField) {
      apiData.transaction_type = transactionType;
    }

    // add status
    if (shouldRenderStatusField) {
      apiData.status = status;
    }

    // add state
    if (shouldRenderStateField) {
      apiData.state = state;
    }

    // add local_create_datetime
    if (shouldRenderLocalCreateDateTimeField && localCreateDateTime) {
      apiData.local_create_datetime = formatDate(localCreateDateTime);
    }

    // add last_modified_datetime
    if (shouldRenderLastModifiedDateTimeField && lastModifiedDateTime) {
      apiData.last_modified_datetime = formatDate(lastModifiedDateTime);
    }

    // sort by
    if (sortBy) {
      apiData.by = sortBy.value;
    }

    setLoadings(true);
    setFilterNotificationsModal(false);
    setFilteredParams({
      apiData,
      pageSize,
      order: orderBy.value,
    });
  };

  // format date
  const formatDate = (dateObj) => {
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Filter Notifications</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <h6
              style={{
                borderBottom: 1 + "px solid #eaeaea",
                paddingBottom: 5 + "px",
              }}
            >
              Select Fields
            </h6>
            <Select
              isMulti
              components={animatedComponents}
              styles={styles}
              options={entityOptions}
              onChange={handleSelectChange}
              value={entitySelected}
            />
            <form
              style={{ marginTop: 10 + "px" }}
              className="update-media-form"
              onSubmit={handleFormSubmit}
            >
              {/* type */}
              {shouldRenderTypeField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "type")
                        ?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {typeOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={type.includes(item.value)}
                          onChange={() => handleTypeChange(item.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* transaction_type */}
              {shouldRenderTransactionTypeField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "transaction_type"
                      )?.label
                    }
                  </label>
                  <Select
                    required
                    options={transactionTypeOptions}
                    value={transactionTypeOptions.find(
                      (option) => option.value === transactionType
                    )}
                    onChange={(option) => setTransactionType(option.value)}
                    styles={styles}
                  />
                </div>
              )}
              {/* state */}
              {shouldRenderStateField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "state")
                        ?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {stateOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={state.includes(item.value)}
                          onChange={() => handleStateChange(item.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* status */}
              {shouldRenderStatusField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "status")
                        ?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {statusOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={status.includes(item.value)}
                          onChange={() => handleStatusChange(item.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* local_create_datetime */}
              {shouldRenderLocalCreateDateTimeField && (
                <div className="input-margins-div">
                  <h6>
                    {
                      entitySelected.find(
                        (option) => option.value === "local_create_datetime"
                      )?.label
                    }
                  </h6>

                  <DatePicker
                    value={localCreateDateTime}
                    onChange={handleLocalCreateDateTime}
                    clearIcon={false}
                    required
                  />
                </div>
              )}
              {/* last_modified_datetime */}
              {shouldRenderLastModifiedDateTimeField && (
                <div className="input-margins-div">
                  <h6>
                    {
                      entitySelected.find(
                        (option) => option.value === "last_modified_datetime"
                      )?.label
                    }
                  </h6>
                  <DatePicker
                    value={lastModifiedDateTime}
                    onChange={handleLastModifiedDateTime}
                    clearIcon={false}
                    required
                  />
                </div>
              )}
              {/* page size */}
              <div className="input-margins-div">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === pageSize
                  )}
                  onChange={(option) => handlePerPage(option.value)}
                  options={perPageOptions}
                  styles={styles}
                />
              </div>
              {/* sort by */}
              <div className="input-margins-div">
                <label>Sort by</label>
                <Select
                  value={sortBy}
                  onChange={(option) => setSortBy(option)}
                  options={entityOptions}
                  styles={styles}
                  isClearable
                />
              </div>
              {/* order by */}
              <div className="input-margins-div">
                <label>Order by</label>
                <Select
                  value={orderBy}
                  onChange={(option) => setOrderBy(option)}
                  options={[
                    { value: "asc", label: "Ascending" },
                    { value: "desc", label: "Descending" },
                  ]}
                  styles={styles}
                />
              </div>
              <div className="reset-and-filter-div">
                <button
                  type="button"
                  className="reset-fields-btn"
                  onClick={handleResetFilter}
                >
                  RESET
                </button>
                <button type="submit" className="filter-records-btn">
                  FILTER
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterNotificationsModal;
