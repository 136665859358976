import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// first render
export const getRecurringDetails = createAsyncThunk(
  "recurringDetails/getRecurringDetails",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/recurring?year=${singleObj.year}&month=${singleObj.month}&day=${singleObj.day}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// filter render
export const getFilteredRecurringDetails = createAsyncThunk(
  "filteredRecurringDetails/getFilteredRecurringDetails",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/dashboard/recurring?year=${singleObj.year}&month=${singleObj.month}&day=${singleObj.day}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const recurringDetailsSlice = createSlice({
  name: "recurringDetails",
  initialState: {
    recurringDetailsLoading: false,
    recurringDetailsData: null,
    recurringDetailsError: null,
    filteredRecurringDetailsLoading: false,
  },
  extraReducers: (builder) => {
    // first render
    builder
      .addCase(getRecurringDetails.pending, (state) => {
        state.recurringDetailsLoading = true;
        state.recurringDetailsData = null;
        state.recurringDetailsError = null;
      })
      .addCase(getRecurringDetails.fulfilled, (state, action) => {
        state.recurringDetailsLoading = false;
        state.recurringDetailsData = action.payload;
        state.recurringDetailsError = null;
      })
      .addCase(getRecurringDetails.rejected, (state, action) => {
        state.recurringDetailsLoading = false;
        state.recurringDetailsData = null;
        state.recurringDetailsError = action.error.message;
      })
      // filter render
      .addCase(getFilteredRecurringDetails.pending, (state) => {
        state.filteredRecurringDetailsLoading = true;
        state.recurringDetailsData = null;
        state.recurringDetailsError = null;
      })
      .addCase(getFilteredRecurringDetails.fulfilled, (state, action) => {
        state.filteredRecurringDetailsLoading = false;
        state.recurringDetailsData = action.payload;
        state.recurringDetailsError = null;
      })
      .addCase(getFilteredRecurringDetails.rejected, (state, action) => {
        state.filteredRecurringDetailsLoading = false;
        state.recurringDetailsData = null;
        state.recurringDetailsError = action.error.message;
      });
  },
});

export default recurringDetailsSlice.reducer;
