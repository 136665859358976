import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { basic_download } from "react-icons-kit/linea/basic_download";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadTransactionReceipt } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { MetroSpinner } from "react-spinners-kit";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const TransactionDetailsPopup = ({
  token,
  setTransactionDetailsPopup,
  transactionObj,
}) => {
  // redux state
  const { transactionsData, downloadReceiptLoading, downloadReceiptData } =
    useSelector((state) => state.transactions);

  // close modal
  const handleCloseModal = () => {
    if (!downloadReceiptLoading) {
      setTransactionDetailsPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // download receipt
  const handleDownloadReceipt = (tag) => {
    dispatch(
      downloadTransactionReceipt({
        token,
        transactionTag: tag,
      })
    ).then((res) => {
      if (!res.payload.error) {
        // download pdf
        const url = window.URL.createObjectURL(new Blob([res.payload.file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${tag}.pdf`);
        document.body.appendChild(link);
        link.click();
      } else if (res.payload.error === "Request failed with status code 401") {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Transaction Details</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="transaction-details">
              <div className="header">
                <div className="merchant">
                  <h4>{transactionObj.nodeName}</h4>
                  <h5>{transactionObj.campaign}</h5>
                </div>
                <div className="amount">
                  {transactionsData?.data?.currencySymbol}
                  {transactionObj.approvedAmount}
                </div>
              </div>
              <div className="content">
                {/* type cash or cheque */}
                <div className="detail">
                  <h6 className="key">Type</h6>
                  <h6 style={{ textTransform: "capitalize" }}>
                    {transactionObj.cardType}
                  </h6>
                </div>
                {/* transaction date */}
                <div className="detail">
                  <h6 className="key">Fee</h6>
                  <h6>
                    {transactionsData?.data?.currencySymbol}
                    {transactionObj.fee}
                  </h6>
                </div>
                {/* transaction date */}
                <div className="detail">
                  <h6 className="key">Date</h6>
                  <h6>{transactionObj.transactionDate}</h6>
                </div>
                {/* entry mode */}
                <div className="detail">
                  <h6 className="key">Entry Mode</h6>
                  <h6>{transactionObj.entryMode}</h6>
                </div>
                {/* cardholder name */}
                {transactionObj.cardholderName && (
                  <div className="detail">
                    <h6 className="key">Cardholder Name</h6>
                    <h6>{transactionObj.cardholderName}</h6>
                  </div>
                )}
                {/* source */}
                <div className="detail">
                  <h6 className="key">Channel Type</h6>
                  <h6>{transactionObj.nodeType}</h6>
                </div>
                {/* approval code */}
                {transactionObj.approvalCode && (
                  <div className="detail">
                    <h6 className="key">Approval Code</h6>
                    <h6>{transactionObj.approvalCode}</h6>
                  </div>
                )}
                {/* sequence no */}
                {transactionObj.sequenceNumber && (
                  <div className="detail">
                    <h6 className="key">Sequence No</h6>
                    <h6>{transactionObj.sequenceNumber}</h6>
                  </div>
                )}
                {/* notes */}
                <div className="detail">
                  <h6 className="key">Notes</h6>
                  <h6 style={{ textTransform: "capitalize" }}>
                    {transactionObj.userNotes && (
                      <>{transactionObj.userNotes.toLowerCase()}</>
                    )}
                  </h6>
                </div>
                {/* first six */}
                {transactionObj.cardType === "CREDIT" && (
                  <div className="detail">
                    <h6 className="key">First Six</h6>
                    <h6>{transactionObj.firstSix}</h6>
                  </div>
                )}
              </div>
              {/* card image and account no */}
              {transactionObj.cardType !== "cash" && (
                <div className="card-details">
                  <div className="card-image">
                    <img alt="card" src={transactionObj.cardImage} />
                  </div>
                  <h5>{transactionObj.cardAccount}</h5>
                </div>
              )}
            </div>
            {/* error in case */}
            {downloadReceiptData && downloadReceiptData.error && (
              <div className="error-msg">{downloadReceiptData.error}</div>
            )}
            <div
              className="reset-and-filter-div"
              style={{ padding: 10 + "px" }}
            >
              <button
                type="button"
                className="reset-fields-btn"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
              <button
                type="button"
                className="filter-records-btn"
                style={{
                  opacity: transactionObj.transactionTag ? 1 : 0.5,
                  pointerEvents: transactionObj.transactionTag
                    ? "auto"
                    : "none",
                }}
                onClick={() =>
                  handleDownloadReceipt(transactionObj.transactionTag)
                }
              >
                {downloadReceiptLoading ? (
                  <MetroSpinner
                    loading={downloadReceiptLoading}
                    color="#fff"
                    size={22}
                  />
                ) : (
                  <>
                    <Icon
                      icon={basic_download}
                      size={20}
                      style={{ marginRight: 10 + "px" }}
                    />
                    DOWNLOAD
                  </>
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default TransactionDetailsPopup;
