import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { users } from "react-icons-kit/feather/users";
import { creditCard } from "react-icons-kit/feather/creditCard";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { MetroSpinner } from "react-spinners-kit";
import axios from "axios";
import hostName from "../../config";
import { useNavigate } from "react-router-dom";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const DownloadTemplatePopup = ({ token, setDownloadTemplatePopup }) => {
  // states
  const [downloadTemplateLoading, setDownloadTemplateLoading] = useState(false);
  const [downloadTemplateError, setDownloadTemplateError] = useState(null);

  // close modal
  const handleCloseModal = () => {
    if (!downloadTemplateLoading) {
      setDownloadTemplatePopup(false);
    }
  };

  // states
  const [selectedOption, setSelectedOption] = useState(null);

  // option change
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  // navigate
  const navigate = useNavigate();

  // download template
  const handleDownloadTemplateEvent = (e) => {
    e.preventDefault();
    
    setDownloadTemplateLoading(true);
    setDownloadTemplateError(null);

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    };

    axios
      .get(
        `${hostName}api/admin/v1/download/importTemplates?type=${selectedOption}`,
        axiosConfig
      )
      .then((response) => {
        // download pdf
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${selectedOption}.xlsx`);
        document.body.appendChild(link);
        link.click();
        // set selected option to null
        setSelectedOption(null);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setDownloadTemplateError(error.message);
        }
      }).finally(()=>setDownloadTemplateLoading(false));
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Download Template</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form
              className="update-media-form"
              onSubmit={handleDownloadTemplateEvent}
            >
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <h6>Select Import Type</h6>
                  <div className="import-radio-container">
                    <label
                      className={`radio-button ${
                        selectedOption === "transactions" ? "selected" : ""
                      }`}
                      onClick={() => handleOptionChange("transactions")}
                    >
                      <div className="icon">
                        <Icon
                          icon={creditCard}
                          size={80}
                          style={{ color: "#007c16" }}
                        />
                      </div>
                      <div className="label-text">Transactions</div>
                    </label>
                    <label
                      className={`radio-button ${
                        selectedOption === "donors" ? "selected" : ""
                      }`}
                      onClick={() => handleOptionChange("donors")}
                    >
                      <div className="icon">
                        <Icon
                          icon={users}
                          size={80}
                          style={{ color: "#007c16" }}
                        />
                      </div>
                      <div className="label-text">Donors</div>
                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
              {downloadTemplateError && (
                <div className="error-msg">{downloadTemplateError}</div>
              )}
              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CLOSE
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={downloadTemplateLoading}
                  style={{
                    opacity: !selectedOption ? 0.5 : 1,
                    pointerEvents: !selectedOption ? "none" : "auto",
                  }}
                >
                  {downloadTemplateLoading ? (
                    <MetroSpinner
                      size={22}
                      color="#fff"
                      loading={downloadTemplateLoading}
                    />
                  ) : (
                    "DOWNLOAD"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DownloadTemplatePopup;
