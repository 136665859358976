import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../../config";

// get tax receipts summary
export const getTaxReceiptsSummaryData = createAsyncThunk(
  "taxReceipts/getTaxReceiptsSummaryData",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/taxReceipt/summary?year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data;
    return response.data;
  }
);

// get all data taxreceipts
export const getAllData = createAsyncThunk(
  "taxReceipts/getAllData",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/taxReceipt/getAll?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}&year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter tax receipts records
export const filterTaxReceiptsRecords = createAsyncThunk(
  "taxReceipts/filterTaxReceiptsRecords",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "email",
      "receipt_number",
      "code",
      "year",
      "state",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/taxReceipt/getAll?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// send email in tax receipt
export const sendEmailInTaxReceipts = createAsyncThunk(
  "taxReceipts/sendEmailInTaxReceipts",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        tagNumber: singleObj.tagNumber,
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/taxReceipt/sendEmail`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// add new receipt
export const addNewReceipt = createAsyncThunk(
  "taxReceipts/addNewReceipt",
  async (singleObj) => {
    try {
      const data = {
        email: singleObj.email,
        year: singleObj.year,
        prefix: singleObj.prefix,
      };

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/taxReceipt/add`,
        data,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
      };
    }
  }
);

// send reminder
export const sendReminder = createAsyncThunk(
  "taxReceipts/sendReminder",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        year: singleObj.year,
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/taxReceipt/sendReminder`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      return {
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
      };
    }
  }
);

// issue receipts
export const issueReceipts = createAsyncThunk(
  "taxReceipts/issueReceipts",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        year: singleObj.year,
        prefix: singleObj.prefix,
        subject: singleObj.subject,
        body: singleObj.message,
        sample: singleObj.sample,
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/taxReceipt/queueAll`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        sample: singleObj.sample,
      };
    } catch (error) {
      return {
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        sample: singleObj.sample,
      };
    }
  }
);

const taxReceiptsSlice = createSlice({
  name: "taxReceipts",
  initialState: {
    // tax receipts
    taxReceiptsSummaryLoading: false,
    taxReceiptsSummaryData: null,
    taxReceiptsSummaryError: null,
    // get all
    allLoading: false,
    allData: null,
    allError: null,
    // send email in tax receipts
    sendEmailInTaxReceiptsLoading: false,
    sendEmailInTaxReceiptsData: null,
    // add new receipt
    newReceiptLoading: false,
    newReceiptData: null,
    // send reminder
    sendReminderLoading: false,
    sendReminderData: null,
    // issue receipts
    issueReceiptsLoading: false,
    issueReceiptsData: null,
  },
  reducers: {
    clearPrevReceiptData: (state) => {
      state.newReceiptData = null;
    },
    clearPrevIssueReceiptData: (state) => {
      state.issueReceiptsData = null;
    },
    updateDonorInTaxReceiptTable: (state, action) => {
      const { donor, tagNumber } = action.payload;
      if (donor) {
        // Find the index of the object with the matching tagNumber
        const index = state.allData.data.findIndex(
          (item) => item.tagNumber === tagNumber
        );
        // If the object with the matching tagNumber is found
        if (index !== -1) {
          state.allData.data[index].donor = donor;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // get tax receipts summary
      .addCase(getTaxReceiptsSummaryData.pending, (state) => {
        state.taxReceiptsSummaryLoading = true;
        state.taxReceiptsSummaryData = null;
        state.taxReceiptsSummaryError = null;
      })
      .addCase(getTaxReceiptsSummaryData.fulfilled, (state, action) => {
        state.taxReceiptsSummaryLoading = false;
        state.taxReceiptsSummaryData = action.payload;
        state.taxReceiptsSummaryError = null;
      })
      .addCase(getTaxReceiptsSummaryData.rejected, (state, action) => {
        state.taxReceiptsSummaryLoading = false;
        state.taxReceiptsSummaryData = null;
        state.taxReceiptsSummaryError = action.error.message;
      })
      // get all data
      .addCase(getAllData.pending, (state) => {
        state.allLoading = true;
        state.allData = null;
        state.allError = null;
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allLoading = false;
        state.allData = action.payload;
        state.allError = null;
      })
      .addCase(getAllData.rejected, (state, action) => {
        state.allLoading = false;
        state.allData = null;
        state.allError = action.error.message;
      })
      // filter tax receipt records
      .addCase(filterTaxReceiptsRecords.pending, (state) => {
        state.allLoading = true;
        state.allData = null;
        state.allError = null;
      })
      .addCase(filterTaxReceiptsRecords.fulfilled, (state, action) => {
        state.allLoading = false;
        state.allData = action.payload;
        state.allError = null;
      })
      .addCase(filterTaxReceiptsRecords.rejected, (state, action) => {
        state.allLoading = false;
        state.allData = null;
        state.allError = action.error.message;
      })
      // send email in tax receipts
      .addCase(sendEmailInTaxReceipts.pending, (state, action) => {
        state.sendEmailInTaxReceiptsLoading = true;
        state.sendEmailInTaxReceiptsData = action.meta.arg;
      })
      .addCase(sendEmailInTaxReceipts.fulfilled, (state, action) => {
        state.sendEmailInTaxReceiptsLoading = false;
        state.sendEmailInTaxReceiptsData = action.payload;
      })
      // add new receipt
      .addCase(addNewReceipt.pending, (state) => {
        state.newReceiptLoading = true;
        state.newReceiptData = null;
      })
      .addCase(addNewReceipt.fulfilled, (state, action) => {
        state.newReceiptLoading = false;
        state.newReceiptData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.allData.data.unshift(action.payload.data);
        }
      })
      // send reminder
      .addCase(sendReminder.pending, (state) => {
        state.sendReminderLoading = true;
        state.sendReminderData = null;
      })
      .addCase(sendReminder.fulfilled, (state, action) => {
        state.sendReminderLoading = false;
        state.sendReminderData = action.payload;
      })
      // issue receipts
      .addCase(issueReceipts.pending, (state, action) => {
        state.issueReceiptsLoading = true;
        state.issueReceiptsData = action.meta.arg;
      })
      .addCase(issueReceipts.fulfilled, (state, action) => {
        state.issueReceiptsLoading = false;
        state.issueReceiptsData = action.payload;
      });
  },
});

export default taxReceiptsSlice.reducer;
export const {
  clearPrevReceiptData,
  clearPrevIssueReceiptData,
  updateDonorInTaxReceiptTable,
} = taxReceiptsSlice.actions;
