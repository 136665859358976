import React from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";

const ChannelsSummary = ({
  setLoadings,
  resetEntities,
  filteredFlag,
  setFilteredFlag,
  setFilteredParams,
}) => {
  // redux state
  const { channelsSummaryData } = useSelector((state) => state.channels);

  // handle card click
  const handleFilteredFlag = (flag) => {
    if (filteredFlag !== flag) {
      setLoadings(true);
      resetEntities();
      setFilteredFlag(flag);
      setFilteredParams({
        apiData: {
          by: "type",
          type: [flag],
        },
        order: "asc",
        pageSize: "10",
      });
    }
  };

  return (
    <div className="schedule-summary-row">
      {channelsSummaryData && channelsSummaryData.length > 0 ? (
        <>
          {channelsSummaryData.map((data, index) => (
            <div
              className="summary-box"
              key={index}
              onClick={() => handleFilteredFlag(data.filteredFlag)}
              style={{
                border:
                  filteredFlag === data.filteredFlag && "2px solid #007c16",
              }}
            >
              <h4>{data.label}</h4>
              <p>{data.value}</p>
              <img src={data.image} alt="icon" />
            </div>
          ))}
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No Channels Summary Found">
            <img src={noDataFound} alt="No Channels Summary Found" />
          </Tippy>
        </div>
      )}
    </div>
  );
};

export default ChannelsSummary;
