import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// get specific donors --- array of objects
export const getSpecificDonor = createAsyncThunk(
  "donors/getSpecificDonor",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/donor?first_name=${singleObj.firstName}&last_name=${singleObj.lastName}`,
      axiosConfig
    );
    const response = await request.data.data;
    const foundDonor = response.find((donor) => {
      return (
        donor.firstName.toLowerCase() === singleObj.firstName.toLowerCase() &&
        donor.lastName.toLowerCase() === singleObj.lastName.toLowerCase() &&
        String(donor.tagNumber) === String(singleObj.tagNumber)
      );
    });
    if (foundDonor) {
      return foundDonor;
    } else {
      return null;
    }
  }
);

// upload photo
export const uploadPhoto = createAsyncThunk(
  "donors/uploadPhoto",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
          "Content-Type": "multipart/form-data", // Set the Content-Type header to 'multipart/form-data'
        },
      };

      const formData = new FormData();
      formData.append("profileImage", singleObj.profileImage);
      formData.append("tagNumber", singleObj.tagNumber);

      const request = await axios.post(
        `${hostName}api/admin/v1/donor/updateProfileImage?_method=PUT`,
        formData,
        axiosConfig
      );

      const response = await request.data;

      return {
        data: response.data, // will be the profileImage URL
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// delete photo
export const deletePhoto = createAsyncThunk(
  "donors/deletePhoto",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/donor/deleteProfileImage`,
        {
          ...axiosConfig,
          data: { tagNumber: singleObj.tagNumber },
        }
      );
      const response = await request.data;
      return {
        data: response.data, // response.data will be null
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// get donors --- array of objects
export const getDonors = createAsyncThunk(
  "donors/getDonors",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/donor?page=${singleObj.page}&pageSize=${singleObj.pageSize}&by=${singleObj.by}&order=${singleObj.order}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter records
export const filterDonorRecords = createAsyncThunk(
  "donors/filterDonorRecords",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "first_name",
      "last_name",
      "email",
      "street_address",
      "city",
      "province_state",
      "postal_zip_code",
      "toDate",
      "fromDate",
      "account_type",
      "business_name",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/donor?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// export donors
export const exportDonors = createAsyncThunk(
  "donors/exportDonors",
  async (singleObj) => {
    const { filteredParams, token } = singleObj;
    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "first_name",
      "last_name",
      "email",
      "street_address",
      "city",
      "province_state",
      "postal_zip_code",
      "toDate",
      "fromDate",
      "account_type",
      "business_name",
      "by",
    ];

    Object.keys(filteredParams.apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = filteredParams.apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const request = await axios.get(
      `${hostName}api/admin/v1/donor/data/export`,
      {
        params: filteredData,
        ...axiosConfig,
        responseType: "arraybuffer",
      }
    );
    const response = await request.data;
    return response;
  }
);

// delete donor
export const deleteDonor = createAsyncThunk(
  "donors/deleteDonor",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/donor/donor/${singleObj.tagNumber}`,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// add donor object
export const addDonor = createAsyncThunk(
  "donors/addDonor",
  async (singleObj) => {
    try {
      const data = {
        firstName: singleObj.firstName,
        middleInitials: singleObj.middleName,
        lastName: singleObj.lastName,
        phone: singleObj.phone,
        email: singleObj.email,
        streetAddress: singleObj.streetAddress,
        city: singleObj.city,
        country: singleObj.country,
        provinceState: singleObj.provinceState,
        postalZipCode: singleObj.postalZipCode,
        allowContact: "1",
        notifyNewCampaign: "1",
        type: "Admin",
        accountType: singleObj.accountType,
      };

      if (singleObj.password) {
        data.accountPassword = singleObj.password;
      }

      if (singleObj.accountType === "B") {
        data.businessName = singleObj.businessName;
      }

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/donor/donor`,
        data,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
      };
    }
  }
);

// update donor
export const updateDonor = createAsyncThunk(
  "donors/updateDonor",
  async (singleObj) => {
    try {
      const data = {
        firstName: singleObj.firstName,
        middleInitials: singleObj.middleName,
        lastName: singleObj.lastName,
        email: singleObj.email,
        phone: singleObj.phone,
        streetAddress: singleObj.streetAddress,
        city: singleObj.city,
        country: singleObj.country,
        provinceState: singleObj.provinceState,
        postalZipCode: singleObj.postalZipCode,
        allowContact: "1",
        notifyNewCampaign: "1",
        accountType: singleObj.accountType,
      };

      if (singleObj.accountType === "B") {
        data.businessName = singleObj.businessName;
      }

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.put(
        `${hostName}api/admin/v1/donor/donor/${singleObj.tagNumber}`,
        data,
        axiosConfig
      );

      const response = await request.data;

      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

const donorsCrudSlice = createSlice({
  name: "donors",
  initialState: {
    // specific donor
    specificDonorLoading: false,
    specificDonorData: null,
    specificDonorError: null,
    // upload photo
    uploadPhotoLoading: false,
    uploadPhotoData: null,
    // delete photo
    deletePhotoLoading: false,
    deletePhotoData: null,
    // get
    donorsLoading: false,
    donorsData: null,
    donorsError: null,
    // delete
    deleteDonorLoading: false,
    deleteDonorData: null,
    // add donor
    addDonorLoading: false,
    addDonorData: null,
    // update donor
    updateDonorLoading: false,
    updateDonorData: null,
    // export
    exportDonorsLoading: false,
    exportDonorsError: null,
  },
  reducers: {
    clearAddDonorModalStates: (state) => {
      state.addDonorData = null;
    },
    clearEditDonorModalStates: (state) => {
      state.updateDonorData = null;
    },
    clearDeleteDonorModalStates: (state) => {
      state.deleteDonorData = null;
    },
    clearUploadPhotoModalStates: (state) => {
      state.uploadPhotoData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get all donors
      .addCase(getSpecificDonor.pending, (state) => {
        state.specificDonorLoading = true;
        state.specificDonorData = null;
        state.specificDonorError = null;
      })
      .addCase(getSpecificDonor.fulfilled, (state, action) => {
        state.specificDonorLoading = false;
        state.specificDonorData = action.payload;
        state.specificDonorError = null;
      })
      .addCase(getSpecificDonor.rejected, (state, action) => {
        state.specificDonorLoading = false;
        state.specificDonorData = null;
        state.specificDonorError = action.error.message;
      })
      // upload photo
      .addCase(uploadPhoto.pending, (state) => {
        state.uploadPhotoLoading = true;
        state.uploadPhotoData = null;
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        state.uploadPhotoLoading = false;
        state.uploadPhotoData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          if (state.specificDonorData.tagNumber === tagNumber) {
            state.specificDonorData.profileImage = data; // will have the actual profileImage URL
          }
        }
      })
      // delete photo
      .addCase(deletePhoto.pending, (state) => {
        state.deletePhotoLoading = true;
        state.deletePhotoData = null;
      })
      .addCase(deletePhoto.fulfilled, (state, action) => {
        state.deletePhotoLoading = false;
        state.deletePhotoData = action.payload;
        const { data, tagNumber, axiosError } = action.payload;
        if (!axiosError) {
          if (state.specificDonorData.tagNumber === tagNumber) {
            state.specificDonorData.profileImage = data; // null
          }
        }
      })
      // get donors
      .addCase(getDonors.pending, (state) => {
        state.donorsLoading = true;
        state.donorsData = null;
        state.donorsError = null;
      })
      .addCase(getDonors.fulfilled, (state, action) => {
        state.donorsLoading = false;
        state.donorsData = action.payload;
        state.donorsError = null;
      })
      .addCase(getDonors.rejected, (state, action) => {
        state.donorsLoading = false;
        state.donorsData = null;
        state.donorsError = action.error.message;
      })
      // filter donor records
      .addCase(filterDonorRecords.pending, (state) => {
        state.donorsLoading = true;
        state.donorsData = null;
        state.donorsError = null;
      })
      .addCase(filterDonorRecords.fulfilled, (state, action) => {
        state.donorsLoading = false;
        state.donorsData = action.payload;
        state.donorsError = null;
      })
      .addCase(filterDonorRecords.rejected, (state, action) => {
        state.donorsLoading = false;
        state.donorsData = null;
        state.donorsError = action.error.message;
      })
      // export donors
      .addCase(exportDonors.pending, (state) => {
        state.exportDonorsLoading = true;
        state.exportDonorsError = null;
      })
      .addCase(exportDonors.fulfilled, (state) => {
        state.exportDonorsLoading = false;
        state.exportDonorsError = null;
      })
      .addCase(exportDonors.rejected, (state, action) => {
        state.exportDonorsLoading = false;
        state.exportDonorsError = action.error.message;
      })
      // delete donor
      .addCase(deleteDonor.pending, (state) => {
        state.deleteDonorLoading = true;
        state.deleteDonorData = null;
      })
      .addCase(deleteDonor.fulfilled, (state, action) => {
        state.deleteDonorLoading = false;
        state.deleteDonorData = action.payload;
        const { axiosError, tagNumber } = action.payload;
        if (!axiosError) {
          state.donorsData.data = state.donorsData.data.filter(
            (data) => data.tagNumber !== tagNumber
          );
        }
      })
      // add donor
      .addCase(addDonor.pending, (state) => {
        state.addDonorLoading = true;
        state.addDonorData = null;
      })
      .addCase(addDonor.fulfilled, (state, action) => {
        state.addDonorLoading = false;
        state.addDonorData = action.payload;
        const { data } = action.payload;
        if (data && state.donorsData) {
          state.donorsData.data.unshift(data);
        }
      })
      // update user
      .addCase(updateDonor.pending, (state) => {
        state.updateDonorLoading = true;
        state.updateDonorData = null;
      })
      .addCase(updateDonor.fulfilled, (state, action) => {
        state.updateDonorLoading = false;
        state.updateDonorData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const userIndex = state.donorsData.data.findIndex(
            (user) => user.tagNumber === tagNumber
          );
          if (userIndex !== -1) {
            state.donorsData.data[userIndex] = data;
          }
        }
      });
  },
});

export default donorsCrudSlice.reducer;
export const {
  clearAddDonorModalStates,
  clearEditDonorModalStates,
  clearDeleteDonorModalStates,
  clearUploadPhotoModalStates,
} = donorsCrudSlice.actions;
