/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/RecordTransactions.scss";
import axios from "axios";
import hostName from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getTransactions,
  filterTransactionRecords,
  exportTransactionRecords,
  getNodes,
} from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { filter } from "react-icons-kit/feather/filter";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import { arrowUp } from "react-icons-kit/feather/arrowUp";
import { mail } from "react-icons-kit/feather/mail";
import { Toaster, toast } from "react-hot-toast";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import TransactionsTable from "../Transactions/TransactionsTable";
import EmailChangePopup from "../Transactions/EmailChangePopup";
import TransactionDetailsPopup from "../Transactions/TransactionDetailsPopup";
import DeleteTransactionPopup from "../Transactions/DeleteTransactionPopup";
import ManualTransactionsPopup from "../Transactions/ManualTransactionsPopup";
import ManualEditTransactionPopup from "../Transactions/ManualEditTransactionPopup";
import FilterTransactionsModal from "../Transactions/FilterTransactionsModal";
import CardTransactionsPopup from "../Transactions/CardTransactionsPopup";
import UpdateCampaignPopup from "../Transactions/UpdateCampaignPopup";
import RefundPopup from "../Transactions/RefundPopup";
import ReplaceEmailPopup from "../Transactions/ReplaceEmailPopup";

// get organization
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const TransactionsComponent = ({ token }) => {
  // selected org
  const [selectedOrganization] = useState(getOrganization());

  // redux state
  const { transactionsLoading } = useSelector((state) => state.transactions);

  // states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("20");

  // page size is used in filter modal
  const [pageSize, setPageSize] = useState("20");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // filtered params
  const [filteredParams, setFilteredParams] = useState(null);

  // filter modal
  const [filterTransactionsModal, setFilterTransactionsModal] = useState(false);

  //  dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get data
  useEffect(() => {
    if (!filteredParams) {
      dispatch(
        getTransactions({
          token,
          page: 1,
          pageSize: "20",
          by: "email",
          order: "asc",
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    } else {
      dispatch(
        filterTransactionRecords({
          token,
          page: 1,
          pageSize: filteredParams.pageSize,
          order: filteredParams.order,
          apiData: filteredParams.apiData,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setTotalPages(res.payload.links.lastPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
        }
      });
    }
  }, [filteredParams]);

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // all api options data states
  const [entityOptions, setEntityOptions] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [allOptionsLoading, setAllOptionsLoading] = useState(true);
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    Promise.all([
      axios.get(
        `${hostName}api/admin/v1/lov?listName=transactionFilter`,
        axiosConfig
      ),
      axios.get(`${hostName}api/v1/public/country`),
      axios.get(`${hostName}api/v1/public/state?countryCode=US`),
      axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
      dispatch(getNodes(token)),
    ])
      .then(([res, countriesRes, usaStatesRes, provincesRes, campaignRes]) => {
        // filter res
        setEntityOptions(res.data.data);

        // Handle countries
        const countryOptions = countriesRes.data.map((country) => ({
          value: country.code,
          label: country.name,
        }));
        setCountries(countryOptions);

        // Handle USA states
        const usaStatesOptions = usaStatesRes.data.map((state) => ({
          value: state.code,
          label: state.name,
        }));
        setUsaStates(usaStatesOptions);

        // Handle Canada provinces
        const provincesOptions = provincesRes.data.map((province) => ({
          value: province.code,
          label: province.name,
        }));
        setProvinces(provincesOptions);

        // campaignRes
        setCampaignOptions(campaignRes.payload.active_campaign);
        setAllCampaigns(campaignRes.payload.campaign_tag);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setAllOptionsLoading(false));
  }, []);

  // state to check if any loading from redux is true
  const [loadings, setLoadings] = useState(true);

  // checking if any loading is true
  const allLoadingStates = [transactionsLoading, allOptionsLoading];
  useEffect(() => {
    const isAnyChildLoading = allLoadingStates.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadingStates]);

  // filter states

  // approved amount
  const [approvedAmount, setApprovedAmount] = useState("");
  const handleApprovedAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setApprovedAmount(newValue);
  };

  // invoice
  const [invoice, setInvoice] = useState("");

  // card account
  const [cardAccount, setCardAccount] = useState("");

  // approval code
  const [approvalCode, setApprovalCode] = useState("");

  // email
  const [email, setEmail] = useState("");

  // tax receipt
  const [taxReceipt, setTaxReceipt] = useState(0);

  // from date
  const [fromDate, setFromDate] = useState(null);

  // to date
  const [toDate, setToDate] = useState(null);

  // userNotes
  const [userNotes, setUserNotes] = useState("");

  // campaignTag
  const [campaignTag, setCampaignTag] = useState([]);
  const handleCampaignTagChange = (item) => {
    if (campaignTag.includes(item)) {
      setCampaignTag(campaignTag.filter((checkedItem) => checkedItem !== item));
    } else {
      setCampaignTag([...campaignTag, item]);
    }
  };

  // node type
  const [nodeType, setNodeType] = useState([]);
  const handleNodeTypeChange = (item) => {
    if (nodeType.includes(item)) {
      setNodeType(nodeType.filter((checkedItem) => checkedItem !== item));
    } else {
      setNodeType([...nodeType, item]);
    }
  };

  // transaction type
  const [transactionType, setTransactionType] = useState([]);
  const handleTransactionTypeChange = (item) => {
    if (transactionType.includes(item)) {
      setTransactionType(
        transactionType.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setTransactionType([...transactionType, item]);
    }
  };

  // card type or tender type
  const [cardType, setCardType] = useState([]);
  const handleCardTypeChange = (item) => {
    if (cardType.includes(item)) {
      setCardType(cardType.filter((checkedItem) => checkedItem !== item));
    } else {
      setCardType([...cardType, item]);
    }
  };

  // nodeName or channelName
  const [nodeName, setNodeName] = useState([]);
  const handleNodeNameChange = (item) => {
    if (nodeName.includes(item)) {
      setNodeName(nodeName.filter((checkedItem) => checkedItem !== item));
    } else {
      setNodeName([...nodeName, item]);
    }
  };

  // payment processor
  const [paymentProcessor, setPaymentProcessor] = useState([]);
  const handlePaymentProcessorChange = (item) => {
    if (paymentProcessor.includes(item)) {
      setPaymentProcessor(
        paymentProcessor.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setPaymentProcessor([...paymentProcessor, item]);
    }
  };

  // sort by
  const [sortBy, setSortBy] = useState({
    value: "email",
    label: "Email",
  });

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // reset filters
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilteredParams(null);
      setFilterTransactionsModal(false);
    }
    setEntitySelected([]);
    setApprovedAmount("");
    setInvoice("");
    setCardAccount("");
    setApprovalCode("");
    setEmail("");
    setTaxReceipt(0);
    setFromDate(null);
    setToDate(null);
    setUserNotes("");
    setCardType([]);
    setNodeName([]);
    setPaymentProcessor([]);
    setNodeType([]);
    setTransactionType([]);
    setCampaignTag([]);
    setPageSize(perPage);
    setSortBy({
      value: "email",
      label: "Email",
    });
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // change email popup
  const [emailChangePopup, setEmailChangePopup] = useState(false);
  const [transaction, setTransaction] = useState(null);

  const handleEmailChangePopup = (transaction) => {
    setEmailChangePopup(true);
    setTransaction(transaction);
  };

  // transaction details popup
  const [transactionDetailsPopup, setTransactionDetailsPopup] = useState(false);
  const [transactionObj, setTarnsactionObj] = useState(null);

  const handleTransactionDetailsPopup = (transaction) => {
    setTransactionDetailsPopup(true);
    setTarnsactionObj(transaction);
  };

  // update campaign popup
  const [updateCampaignPopup, setUpdateCampaignPopup] = useState(false);
  const [updateCampaignObj, setUpdateCampaignObj] = useState(null);

  const handleUpdateCampaignPopup = (transaction) => {
    setUpdateCampaignPopup(true);
    setUpdateCampaignObj(transaction);
  };

  // delete transaction popup
  const [deleteTransactionPopup, setDeleteTransactionPopup] = useState(false);
  const [deleteTransactionObj, setDeleteTransactionObj] = useState(null);

  const handleDeleteTransactionsPopup = (transaction) => {
    setDeleteTransactionPopup(true);
    setDeleteTransactionObj(transaction);
  };

  // manual transaction modal
  const [manualTransactionsPopup, setManualTransactionsPopup] = useState(false);

  const handleManualTransactionsPopup = (e) => {
    e.preventDefault();
    setManualTransactionsPopup(true);
  };

  // edit transaction popup
  const [editTransactionPopup, setEditTransactionPopup] = useState(false);
  const [editTransactionObj, setEditTransactionObj] = useState(null);

  const handleEditTransactionPopup = (transaction) => {
    setEditTransactionPopup(true);
    setEditTransactionObj(transaction);
  };

  // card transaction modal
  const [cardTransactionsPopup, setCardTransactionsPopup] = useState(false);

  const handleCardTransactionsPopup = (e) => {
    e.preventDefault();
    setCardTransactionsPopup(true);
  };

  // transaction type popup (refund)
  const [transactionTypePopup, setTransactionTypePopup] = useState(false);
  const [transactionTypeObj, setTransactionTypeObj] = useState(null);

  const handleTransactionTypePopup = (transaction) => {
    setTransactionTypePopup(true);
    setTransactionTypeObj(transaction);
  };

  // replace email popup
  const [replaceEmailPopup, setReplaceEmailPopup] = useState(false);

  // redux state export transaction loading
  const { exportTransactionLoading } = useSelector(
    (state) => state.transactions
  );

  // export transactions
  const handleExportRecords = () => {
    const params = filteredParams || {
      apiData: {
        by: "email",
      },
    };
    dispatch(
      exportTransactionRecords({
        token,
        filteredParams: params,
      })
    ).then((res) => {
      if (res.payload) {
        // download xlsx
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transactions.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else if (res.error.message === "Request failed with status code 401") {
        localStorage.removeItem("user");
        navigate("/login");
      } else if (res.error.message && typeof res.error.message === "string") {
        toast.error(`${res.error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // one time boolean
  const [oneTime] = useState(true);

  return (
    <>
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              <Toaster />
              <div className="media-heading-button-container">
                <h6>Transactions</h6>
                <div className="add-and-filter-btns-div">
                  <div className="action-btns-div">
                    {/* dropdown */}
                    <MDBDropdown>
                      <MDBDropdownToggle className="organizations-dropdown-button">
                        NEW
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem
                          link
                          onClick={handleManualTransactionsPopup}
                        >
                          <span>Manual Transaction</span>
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          link
                          disabled={selectedOrganization.gatewayNodeTag === 0}
                          onClick={handleCardTransactionsPopup}
                        >
                          <span>Card Transaction</span>
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </div>
                  <div className="filter-and-reset-btns-div">
                    {/* filter */}
                    <Tippy content="Filter Records">
                      <button
                        className="filter-media-modal-btn"
                        type="button"
                        onClick={() => setFilterTransactionsModal(true)}
                      >
                        <Icon icon={filter} size={24} />
                      </button>
                    </Tippy>
                    {/* reset */}
                    {filteredParams && (
                      <Tippy content="Reset Records">
                        <button
                          className="filter-media-modal-btn reset"
                          type="button"
                          onClick={handleResetFilter}
                        >
                          <Icon icon={refreshCw} size={24} />
                        </button>
                      </Tippy>
                    )}
                    {/* replace email */}
                    <Tippy content="Replace Email">
                      <button
                        className="filter-media-modal-btn reset"
                        type="button"
                        onClick={() => setReplaceEmailPopup(true)}
                      >
                        <Icon icon={mail} size={24} />
                      </button>
                    </Tippy>
                    {/* export */}
                    <Tippy
                      content={
                        exportTransactionLoading
                          ? "Please Wait"
                          : "Export Transactions"
                      }
                    >
                      <button
                        className="filter-media-modal-btn reset"
                        type="button"
                        onClick={handleExportRecords}
                      >
                        {exportTransactionLoading ? (
                          <MetroSpinner
                            loading={exportTransactionLoading}
                            size={24}
                            color="#6a6c6f"
                          />
                        ) : (
                          <Icon icon={arrowUp} size={24} />
                        )}
                      </button>
                    </Tippy>
                  </div>
                </div>
              </div>

              {/* transaction table */}
              <TransactionsTable
                token={token}
                filteredParams={filteredParams}
                setLoadings={setLoadings}
                currentPage={currentPage}
                totalPages={totalPages}
                perPage={perPage}
                setCurrentPage={setCurrentPage}
                setTotalPages={setTotalPages}
                setPerPage={setPerPage}
                setPageSize={setPageSize}
                handleEmailChangePopup={handleEmailChangePopup}
                handleTransactionDetailsPopup={handleTransactionDetailsPopup}
                handleUpdateCampaignPopup={handleUpdateCampaignPopup}
                handleDeleteTransactionsPopup={handleDeleteTransactionsPopup}
                handleEditTransactionPopup={handleEditTransactionPopup}
                handleTransactionTypePopup={handleTransactionTypePopup}
                transactionTypePopup={transactionTypePopup}
              />
            </>
          )}
        </div>
      </div>

      {/* email change */}
      {emailChangePopup && (
        <EmailChangePopup
          token={token}
          setEmailChangePopup={setEmailChangePopup}
          transaction={transaction}
        />
      )}

      {/* transaction details */}
      {transactionDetailsPopup && (
        <TransactionDetailsPopup
          token={token}
          setTransactionDetailsPopup={setTransactionDetailsPopup}
          transactionObj={transactionObj}
        />
      )}

      {/* update campaign */}
      {updateCampaignPopup && (
        <UpdateCampaignPopup
          token={token}
          setUpdateCampaignPopup={setUpdateCampaignPopup}
          updateCampaignObj={updateCampaignObj}
          allCampaigns={allCampaigns}
        />
      )}

      {/* delete transaction */}
      {deleteTransactionPopup && (
        <DeleteTransactionPopup
          token={token}
          setDeleteTransactionPopup={setDeleteTransactionPopup}
          deleteTransactionObj={deleteTransactionObj}
        />
      )}

      {/* transaction type refund */}
      {transactionTypePopup && (
        <RefundPopup
          token={token}
          setTransactionTypePopup={setTransactionTypePopup}
          transactionTypeObj={transactionTypeObj}
        />
      )}

      {/* manual transaction */}
      {manualTransactionsPopup && (
        <ManualTransactionsPopup
          token={token}
          setManualTransactionsPopup={setManualTransactionsPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
        />
      )}

      {/* edit manual transaction */}
      {editTransactionPopup && (
        <ManualEditTransactionPopup
          token={token}
          setEditTransactionPopup={setEditTransactionPopup}
          editTransactionObj={editTransactionObj}
          campaignOptions={campaignOptions}
        />
      )}

      {/* card transaction */}
      {cardTransactionsPopup && (
        <CardTransactionsPopup
          token={token}
          setCardTransactionsPopup={setCardTransactionsPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
          oneTime={oneTime}
        />
      )}

      {/* replace email popup */}
      {replaceEmailPopup && (
        <ReplaceEmailPopup
          token={token}
          setReplaceEmailPopup={setReplaceEmailPopup}
        />
      )}

      {/* filter transactions */}
      {filterTransactionsModal && (
        <FilterTransactionsModal
          setFilterTransactionsModal={setFilterTransactionsModal}
          entitySelected={entitySelected}
          entityOptions={entityOptions}
          handleSelectChange={handleSelectChange}
          setFilteredParams={setFilteredParams}
          approvedAmount={approvedAmount}
          handleApprovedAmountChange={handleApprovedAmountChange}
          invoice={invoice}
          setInvoice={setInvoice}
          cardAccount={cardAccount}
          setCardAccount={setCardAccount}
          approvalCode={approvalCode}
          setApprovalCode={setApprovalCode}
          email={email}
          setEmail={setEmail}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          userNotes={userNotes}
          setUserNotes={setUserNotes}
          taxReceipt={taxReceipt}
          setTaxReceipt={setTaxReceipt}
          campaignTag={campaignTag}
          handleCampaignTagChange={handleCampaignTagChange}
          nodeType={nodeType}
          handleNodeTypeChange={handleNodeTypeChange}
          nodeName={nodeName}
          handleNodeNameChange={handleNodeNameChange}
          cardType={cardType}
          handleCardTypeChange={handleCardTypeChange}
          paymentProcessor={paymentProcessor}
          handlePaymentProcessorChange={handlePaymentProcessorChange}
          transactionType={transactionType}
          handleTransactionTypeChange={handleTransactionTypeChange}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          pageSize={pageSize}
          handlePerPage={handlePerPage}
          setLoadings={setLoadings}
          handleResetFilter={handleResetFilter}
        />
      )}
    </>
  );
};

export default TransactionsComponent;
