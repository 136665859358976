import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// create an async thunk get icons
export const getIcons = createAsyncThunk("GETICONS", async (token) => {
  const axiosConfig = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const request = await axios.get(`${hostName}api/admin/v1/icon`, axiosConfig);
  const response = await request.data.data;
  return response;
});

// create an async thunk get icons
export const getNode = createAsyncThunk("GETNODE", async (token) => {
  const axiosConfig = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const request = await axios.get(`${hostName}api/admin/v1/node`, axiosConfig);
  const response = await request.data.data;
  return response;
});

export const GetIconsAndNodesSlice = createSlice({
  name: "CAMPAIGN/GETICONSANDNODE",
  initialState: {
    // icons
    iconsData: [],
    iconsLoader: false,
    iconsError: null,

    // get node
    nodeData: [],
    nodeLoader: false,
    nodeError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIcons.pending, (state) => {
        state.iconsLoader = true;
      })
      .addCase(getIcons.fulfilled, (state, action) => {
        state.iconsLoader = false;
        state.iconsError = null;
        state.iconsData = action.payload;
      })
      .addCase(getIcons.rejected, (state, action) => {
        state.iconsLoader = false;
        state.iconsError = action.error.message;
      })

      .addCase(getNode.pending, (state) => {
        state.nodeLoader = true;
      })
      .addCase(getNode.fulfilled, (state, action) => {
        state.nodeLoader = false;
        state.nodeError = null;
        state.nodeData = action.payload;
      })
      .addCase(getNode.rejected, (state, action) => {
        state.nodeLoader = false;
        state.nodeError = action.error.message;
      });
  },
});
export default GetIconsAndNodesSlice.reducer;
