import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilteredRecurringDetails } from "../../Store/Slices/Sidebar/Dashboard/RecurringDetailsSlice";
import { MetroSpinner } from "react-spinners-kit";
import DatePicker from "react-date-picker";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { moreVertical } from "react-icons-kit/feather/moreVertical";
import { x } from "react-icons-kit/feather/x";
import { minus } from "react-icons-kit/feather/minus";
import noDataFound from "../../Images/noDataFound.png";
import {
  MDBBadge,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";

function generateColors(length) {
  const colors = [];

  // Define the colors in the gradient
  const gradientColors = [
    "#34495e",
    "#9b59b6",
    "#3498db",
    "#62cb31",
    "#ffb606",
    "#e67e22",
    "#e74c3c",
    "#c0392b",
    "#175935",
    "#1f9d61",
    "#cc2473",
    "#d828c9",
    "#453095",
    "#9100ff",
    "#884a39",
    "#c38154",
  ];

  // Generate new colors after the eighth position
  const gradientLength = gradientColors.length;
  for (let i = 0; i < length; i++) {
    let color;
    if (i < gradientLength) {
      color = gradientColors[i];
    } else {
      // Generate a new color based on a pattern
      const colorIndex = i % gradientLength;
      const patternIndex = Math.floor(i / gradientLength) - 1;
      const patternColor = gradientColors[colorIndex];

      const patternColors = [
        "#212e48",
        "#800080",
        "#2d86ff",
        "#00d327",
        "#ffcb2b",
        "#ffa800",
        "#f25410",
        "#cd4c3a",
      ];

      const newColorIndex = patternIndex % patternColors.length;
      const newColor = patternColors[newColorIndex];

      // Combine the pattern color and new color
      color = mixColors(patternColor, newColor);
    }
    colors.push(color);
  }
  return colors;
}

// Helper function to mix two colors
function mixColors(color1, color2) {
  // Perform your logic to mix the colors
  // Here's a simple example that mixes the colors by averaging their RGB values
  const rgb1 = hexToRGB(color1);
  const rgb2 = hexToRGB(color2);

  const mixedColor = {
    r: Math.floor((rgb1.r + rgb2.r) / 2),
    g: Math.floor((rgb1.g + rgb2.g) / 2),
    b: Math.floor((rgb1.b + rgb2.b) / 2),
  };
  return rgbToHex(mixedColor);
}

// Helper function to convert hex color to RGB object
function hexToRGB(hex) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
}

// Helper function to convert RGB object to hex color
function rgbToHex(rgb) {
  const { r, g, b } = rgb;
  const hexR = r.toString(16).padStart(2, "0");
  const hexG = g.toString(16).padStart(2, "0");
  const hexB = b.toString(16).padStart(2, "0");
  return `#${hexR}${hexG}${hexB}`;
}

const RecurringDetails = ({ token }) => {
  // redux state
  const {
    filteredRecurringDetailsLoading,
    recurringDetailsData,
    recurringDetailsError,
  } = useSelector((state) => state.recurringDetails);

  // Calculate the total sum of amounts
  const [sum, setSum] = useState("0.00");
  useEffect(() => {
    if (recurringDetailsData && recurringDetailsData.values.length > 0) {
      const totalSum = recurringDetailsData.values.reduce(
        (sum, obj) => sum + obj.amount,
        0
      );
      const roundedSum = totalSum.toFixed(2);
      setSum(roundedSum);
    } else {
      setSum("0.00");
    }
  }, [recurringDetailsData]);

  // progress bars colors
  const [progressColors, setProgressColors] = useState([]);
  useEffect(() => {
    if (recurringDetailsData && recurringDetailsData.values.length > 0) {
      const colors = generateColors(recurringDetailsData.values.length);
      setProgressColors(colors);
    } else {
      setProgressColors([]);
    }
  }, [recurringDetailsData]);

  // states
  const [startDate, setStartDate] = useState(new Date());
  const [year, setYear] = useState(null);
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [popup, setPopup] = useState(false);

  // setting up year month and day
  useEffect(() => {
    if (startDate instanceof Date && !isNaN(startDate)) {
      setYear(startDate.getFullYear().toString());
      setDay(startDate.getDate().toString().padStart(2, "0"));
      setMonth((startDate.getMonth() + 1).toString().padStart(2, "0"));
    } else {
      console.log("get full year error in the useEffect");
    }
  }, [startDate]);

  // dispatch function
  const dispatch = useDispatch();

  // navigate function
  const navigate = useNavigate();

  // handle date change
  const handleDateChange = (value) => {
    if (value instanceof Date && !isNaN(value)) {
      if (value.toDateString() !== startDate.toDateString()) {
        setPopup(false);
        setStartDate(value);
        const singleObj = {
          token,
          year: value.getFullYear().toString(),
          month: (value.getMonth() + 1).toString().padStart(2, "0"),
          day: value.getDate().toString().padStart(2, "0"),
        };
        dispatch(getFilteredRecurringDetails(singleObj))
          .unwrap()
          .catch((err) => {
            if (err.message === "Request failed with status code 401") {
              localStorage.removeItem("user");
              navigate("/login");
            }
          });
      }
    } else {
      console.log("get full year error in the handleDateChange");
    }
  };

  return (
    <>
      <div className="text-and-filter-div">
        <span>
          Recurring Details <Icon icon={minus} size={12} /> {year}-{month}-{day}{" "}
          <Icon icon={minus} size={12} />{" "}
          <span style={{ color: "#007C16" }}>
            {recurringDetailsData && <>{recurringDetailsData.currencySymbol}</>}
            {sum}
          </span>
        </span>
        <Tippy content={popup ? "Cancel" : "More"}>
          <span style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
            {popup ? (
              <Icon icon={x} size={16} />
            ) : (
              <Icon icon={moreVertical} size={16} />
            )}
          </span>
        </Tippy>
        <div className={popup ? "popup open" : "popup"}>
          <h4>Filter by Date</h4>
          <DatePicker
            value={startDate}
            onChange={(date) => handleDateChange(date)}
            clearIcon={false}
          />
        </div>
      </div>
      <div
        className={`custom-table-container${
          recurringDetailsData && recurringDetailsData.values.length > 0
            ? ""
            : " flex"
        }`}
      >
        {filteredRecurringDetailsLoading ? (
          <MetroSpinner
            size={30}
            color="#007C16"
            loading={filteredRecurringDetailsLoading}
          />
        ) : (
          <>
            {recurringDetailsError ? (
              <div className="error-msg" style={{ marginTop: 0 }}>
                {recurringDetailsError}
              </div>
            ) : (
              <>
                {recurringDetailsData &&
                recurringDetailsData.values.length > 0 ? (
                  <MDBTable align="middle">
                    <MDBTableHead className="thead">
                      <tr>
                        <th scope="col">Campaign</th>
                        <th scope="col">Amount</th>
                        <th scope="col">No. of transactions</th>
                        <th scope="col" style={{ width: "25%" }}>
                          Progress
                        </th>
                        <th scope="col">Percentage</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {recurringDetailsData.values.map((data, index) => {
                        // Calculate the percentage for each object
                        const percentage = ((data.amount / sum) * 100).toFixed(
                          2
                        );
                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={`${recurringDetailsData.themeHome}${data.icon}`}
                                  alt="Campaign Img"
                                  style={{ width: "45px", height: "45px" }}
                                  className="rounded-circle"
                                />
                                <div className="ms-3">
                                  <p className="fw-bold mb-1">
                                    {data.campaign}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p
                                className="fw-normal mb-1"
                                style={{ fontSize: "16px" }}
                              >
                                {recurringDetailsData.currencySymbol}
                                {data.amount}
                              </p>
                            </td>
                            <td>
                              <MDBBadge color="success" pill>
                                {data.numberOfTransaction}
                              </MDBBadge>
                            </td>
                            <td>
                              <div className="progress-trail">
                                <div
                                  className="table-progressbar"
                                  style={{
                                    width: `${percentage}%`,
                                    backgroundColor: progressColors[index],
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <MDBBtn
                                color="link"
                                rounded
                                size="sm"
                                style={{
                                  fontSize: "16px",
                                  whiteSpace: "nowrap",
                                  backgroundColor: progressColors[index],
                                  color: "#fff",
                                  width: "85px",
                                }}
                              >
                                {percentage} %
                              </MDBBtn>
                            </td>
                          </tr>
                        );
                      })}
                    </MDBTableBody>
                  </MDBTable>
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Data Found">
                      <img src={noDataFound} alt="No Data Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RecurringDetails;
